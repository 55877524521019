import React, { useState } from 'react';
import {
  CompressOutlined,
  EditOutlined,
  FullscreenOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { Patient } from '../../Sections/PatientsList/patientsList.section.custom';
import PatientModal from '../PatientComponents/patient.modal';
interface PatientInformationProps {
  patient: Patient;
  setPatient: any;
}
function PatientInformation({ patient, setPatient }: PatientInformationProps) {
  const [open, setOpen] = useState(false);
  const closeNewPatientModal = () => {
    setOpen(!open);
  };
  const showModal = () => {
    setOpen(true);
  };
  function formatBirthDate(birthDate: any) {
    const options: any = { month: 'long', day: 'numeric', year: 'numeric' };
    return new Date(birthDate).toLocaleDateString('en-US', options);
  }
  const [newPatient, setNewPatient] = useState<Patient>(patient);
  return (
    <>
      <div className="flex h-full w-full justify-between rounded-lg bg-[#FFFFFF] p-4 shadow-sm">
        <div className="flex w-[90%] items-center">
          <div className="">
            <img
              src="https://media.licdn.com/dms/image/D4E12AQEud3Ll5MI7cQ/article-inline_image-shrink_1000_1488/0/1660833954461?e=1717632000&v=beta&t=ruo0tXitiN25PHWR_alUJSlA6DYIGQMhrqWcpKaQ3sg"
              alt="image"
              className="h-[100px] w-[100px] rounded-[50%]"
              crossOrigin="anonymous"
            />
          </div>
          {/* this code will be updated after brainstorm as abdou said */}
          <div className="flex w-full justify-between space-x-4 pl-4 text-sm text-[#647A8B]">
            <div className="w-1/5 space-y-2">
              <div className="title">
                <div className="">Name :</div>
                <div>{patient?.patient_name}</div>
              </div>
              <div className="title">
                <div className="">Middle Name :</div>
                <div>{patient?.patient_middle_name}</div>
              </div>
              <div className="title">
                <div className="">Last Name :</div>
                <div>{patient?.patient_last_name}</div>
              </div>
            </div>
            <div className="w-1/5 space-y-2">
              <div className="title">
                <div className="">Email :</div>
                <div>{patient?.email}</div>
              </div>
              <div className="title">
                <div className="">Phone :</div>
                <div>{patient?.phone_number}</div>
              </div>
              <div className="title">
                <div className="">Birth Date :</div>
                <div>{formatBirthDate(patient?.birth_date)}</div>
              </div>
            </div>
            <div className="w-1/5 space-y-2">
              <div className="title">
                <div className="">Country :</div>
                <div>{patient?.country}</div>
              </div>
              <div className="title">
                <div className="">City :</div>
                <div>{patient?.city}</div>
              </div>
              <div className="title">
                <div className="">State :</div>
                <div>{patient?.state}</div>
              </div>
            </div>
            <div className="w-1/5 space-y-2">
              <div className="title">
                <div className="">EC Name :</div>
                <div>{patient?.emergency_contact_name}</div>
              </div>
              <div className="title">
                <div className="">EC Phone :</div>
                <div>{patient?.emergency_contact_phone}</div>
              </div>
              <div className="title">
                <div className="">EC Relationship :</div>
                <div>{patient?.emergency_contact_relationship}</div>
              </div>
            </div>
            <div className="w-1/5 space-y-2">
              <div className="title">
                <div className="">Policy Number :</div>
                <div>{patient?.policy_number}</div>
              </div>
              <div className="title">
                <div className="">Medical Plan :</div>
                <div>{patient?.medical_plan}</div>
              </div>
            </div>
          </div>
          {open && (
            <PatientModal
              setNewPatient={setNewPatient}
              Patient={newPatient}
              closeNewPatientModal={closeNewPatientModal}
              edit={true}
              setPatientEdit={setPatient}
            />
          )}
        </div>
        <div className=" flex w-[10%] flex-col items-end justify-between">
          <button
            className="text-[#005DD4]"
            onClick={showModal}
          >
            Edit <EditOutlined />
          </button>
          <button className="text-[#21426D]">
            Medical Background <FullscreenOutlined />{' '}
          </button>
        </div>
      </div>
    </>
  );
}

export default PatientInformation;
