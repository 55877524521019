const {
  API_SUCCESS,
  GET_STUDIES,
  API_ERROR,
  ADD_STUDY,
  PREDICT,
  // GET_REPORT,
  GET_STUDY,
  UPDATE_NEEDS_RELOAD,
  // DELETE_REPORT,
  DELETE_STUDY,
  RESET_STUDY_STATE,
  ARCHIVE_STUDY,
  UNARCHIVE_STUDY,
} = require('./actionType');

const INIT_STATE = {
  studies: [],
  //error: {},
  // report: {},
  study: {},
  needsReload: false,
  studyDeleted: false,
  totalCount: 0,
  hasMore: false,
  error: null,
  loading: false,
  success: false,
  filtered_conditions: {},
};

const Study = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STUDIES:
      return {
        ...state,
        loading: true,
      };
    // case GET_REPORT:
    //   return {
    //     ...state,
    //     loading: true,
    //   }
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_STUDIES:
          return {
            ...state,
            studies: action.payload.data.data,
            isStudySuccess: true,
            totalCount: action.payload.data.total_count,
            hasMore: action.payload.data.has_more,
            filtered_conditions: action.payload.data.filtered_conditions,
            success: true,
            loading: false,
          };
        case ADD_STUDY:
          return {
            ...state,
            response: action.payload.data,
            isStudySuccess: true,
          };
        case PREDICT:
          return {
            ...state,
            study: action.payload.data,
            needsReload: true,
            isStudySuccess: true,
          };
        // case DELETE_REPORT:
        //   return {
        //     ...state,
        //     needsReload: true,
        //     isStudySuccess: true,
        //   };
        case DELETE_STUDY:
          return {
            ...state,
            studyDeleted: !state.studyDeleted,
          };
        // case GET_REPORT:
        //   return {
        //     ...state,
        //     report: action.payload.data,
        //     isStudySuccess: true,
        //     loading: false,
        //   };
        case GET_STUDY:
          return {
            ...state,
            study: action.payload.data,
            isStudySuccess: true,
          };
        case ARCHIVE_STUDY:
          return {
            ...state,
            needsReload: true,
            isStudySuccess: true,
          };
        case UNARCHIVE_STUDY:
          return {
            ...state,
            needsReaload: true,
            isStudySuccess: true,
          };
        case RESET_STUDY_STATE:
          console.log('reset state 22', INIT_STATE);
          return {
            ...state,
            studies: {},
            // report: {},
            study: {},
            needsReload: false,
            studyDeleted: false,
            totalCount: 0,
            hasMore: false,
            currentPage: 1,
            itemsPerPage: 4,
            error: null,
            loading: false,
            success: false,
            filtered_conditions: {},
          };
      }

    case API_ERROR:
      switch (action.payload.actionType) {
        case GET_STUDIES:
          return {
            ...state,
            error: action.payload.error,
            isStudySuccess: false,
          };
        case ADD_STUDY:
          return {
            ...state,
            error: action.payload.error,
            isStudySuccess: false,
          };
        case PREDICT:
          return {
            ...state,
            error: { message: 'Something went wrong with the inference' },
            isStudySuccess: false,
          };
        // case DELETE_REPORT:
        //   return {
        //     ...state,
        //     isStudySuccess: false,
        //   };
        case DELETE_STUDY:
          return {
            ...state,
            studyDeleted: !state.studyDeleted,
          };
        // case GET_REPORT:
        //   return {
        //     ...state,
        //     isStudySuccess: false,
        //   };
        case GET_STUDY:
          return {
            ...state,
            isStudySuccess: false,
          };
      }

    case UPDATE_NEEDS_RELOAD:
      return {
        ...state,
        needsReload: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Study;
