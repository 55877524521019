import React from 'react';
import { useNavigate } from 'react-router-dom';
const myButtonStyles = {
  width: '260px',
  color: 'white',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '10px',
  fontSize: '25px',
};

const Landing: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center">
      <div className="grid w-3/4 justify-items-center px-6">
        <h2
          className="mb-3 text-5xl font-bold"
          style={{ textAlign: 'center' }}
        >
          Democratizing Quality Ultrasound
        </h2>
        <h3
          className="mb-6 font-sans text-2xl text-[#5A5A5A]"
          style={{ textAlign: 'center' }}
        >
          Improving prenatal and maternal care on a global scale
        </h3>
        <br></br>
        <button
          id="my-button"
          className="bg-blue-500"
          style={myButtonStyles}
          onClick={() => {
            navigate({ pathname: '/home' });
          }}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};
export default Landing;
