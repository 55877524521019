import React from 'react';

const paginate = (currentPage, lastPage, clickEvent, keysSelectedPlane) => {
  const range = [];
  const delta = 1;
  let startNum = Math.max(2, currentPage - delta);
  let endNum = Math.min(lastPage - 1, currentPage + delta);

  for (let i = startNum; i <= endNum; i++) {
    console.log('range i', i);
    range.push(i);
  }
  console.log('range', range, 'startNum', startNum, 'endNum', endNum);

  if (startNum > 2) {
    range.unshift('...');
  }

  if (endNum < lastPage - 1) {
    range.push('...');
  }

  range.unshift(1);
  if (lastPage !== 1) range.push(lastPage);

  const pages = range.map((i, index) => (
    <li
      key={index}
      className={`mx-1 ${i === currentPage ? 'bg-blue-500 p-0.5 text-white' : 'bg-white text-blue-500'} cursor-pointer rounded border-black`}
      onClick={() => i !== '...' && clickEvent(i)}
    >
      {i === '...' ? '...' : keysSelectedPlane[i - 1]}
    </li>
  ));

  return pages;
};

function FramePagination({ current, last, gotoPage, keysSelectedPlane }) {
  return (
    <nav aria-label="Page navigation">
      <ul className="flex list-none items-center justify-center p-0">
        <li
          className={`mx-1 ${current === 1 ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} rounded px-1 py-1`}
          onClick={() => current > 1 && gotoPage(current - 1)}
        >
          &laquo;
        </li>

        {paginate(current, last, gotoPage, keysSelectedPlane)}

        <li
          className={`mx-1 ${current === last ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} rounded px-1 py-1`}
          onClick={() => current < last && gotoPage(current + 1)}
        >
          &raquo;
        </li>
      </ul>
    </nav>
  );
}

export default FramePagination;
