import { combineReducers } from 'redux';

import Study from './study/reducer';
import Report from './report/reducer';
import Profile from './profile/reducer';
import SR from './sr/reducer';
import Patient from './patient/reducer';
import SR_Report from './sr_report/reducer';

const rootReducer = combineReducers({ Study, Report, Profile, SR, Patient, SR_Report });

export default rootReducer;
