import { AddStudie } from '../../../UI/interfaces';
import { useFetch, usePost } from '../../reactQuery_old/reactQuery';
import * as url from '../../urlHelper';

// how to use useFetch function in the codebase:

interface measurementsKeys {
  studyId: string;
  serieId: string;
  instanceId: string;
}

export const getRecentStudies = () => useFetch(`${url.GET_STUDIES}`);
export const getStudyMeasurements = (search: measurementsKeys) =>
  useFetch(
    `${url.GET_STUDIES}/${search.studyId}/series/${search.serieId}/instances/${search.instanceId}`
  );

export const useAddStudie = (
  updater: (oldData: any[], newData: any) => any[],
  onSuccess?: (response: any, data: any) => void,
  onError?: (error: any, data: any) => void
) => usePost<any[], any>(url.STUDY_ADD, undefined, null, onSuccess, onError);
