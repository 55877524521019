import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import Home from './UI/Pages/HomePage/home.page';
import Account from './UI/Pages/AccountPage/account.page';
import Report from './UI/Pages/ReportPage/report.page';
import Analytics from './UI/Pages/AnalyticsPage/analytics.page';
import Exams from './UI/Pages/ExamsPage/exams.page';
import PatientPageLayout from './UI/Layouts/PatientLayout/patientPage.layout';
import { Link } from 'react-router-dom';
import Patient from './UI/Pages/PatientPage/patient.page';
import Reports from './UI/Pages/ReportsPage/reports.page';
import Settings from './UI/Pages/SettingsPage/settings.page';
import Support from './UI/Pages/SupportPage/support.page';
import PatientsListCustom from './UI/Sections/PatientsList/patientsList.section.custom';
import TempExam from './UI/Pages/ExamsPage/TempExam';
import UserManagement from './UI/Pages/UserManagement/user_management.page';
import RealTimeInferencing from './UI/Pages/RealtimeInferencingPage/realtime-inferencing.page';
import ProfilePage from './UI/Pages/AccountPage/account.page';
import NewRealtimePage from './UI/Pages/NewRealtimePage';
import PatientManagement from './UI/Pages/PatientManagement/patient_management.page';
import RealTimeInferencingUDP from './UI/Pages/RealtimeInferencingPageUDP/realtime-inferencing.page.udp';

export const ProtectedPages = [
  { path: '/home', element: <Home /> },
  // { path: '/account', element: <Account /> },
  { path: '/profile/:uid', element: <ProfilePage /> },
  { path: '/report', element: <Report /> },
  { path: '/analytics', element: <Analytics /> },
  // { path: '/realtime', element: <RealTime /> },
  { path: '/realtime', element: <NewRealtimePage /> },
  { path: '/realtime-webrtc', element: <RealTimeInferencingUDP /> },
  { path: '/realtime-inferencing', element: <RealTimeInferencing /> },
  // { path: '/realtime-inferencing', element: <NewRealtimePage /> },
  { path: '/exams', element: <TempExam /> },
  { path: '/patients_old', element: <PatientsListCustom /> },
  { path: '/patients', element: <PatientManagement /> },
  { path: '/patients/:PatientID', element: <Patient /> },
  { path: '/reports', element: <Reports /> },
  { path: '/settings', element: <Settings /> },
  { path: '/support', element: <Support /> },
  { path: '/users', element: <UserManagement /> },
  { path: '*', component: <Navigate to="/" /> },
];

const Pages = () => {
  return (
    <Routes>
      <Route
        exact={true}
        path="/home"
        element={<Home />}
      />
      <Route
        exact={true}
        path="/account"
        element={<Account />}
      />
      <Route
        exact={true}
        path="/report"
        element={<Report />}
      />
      <Route
        exact={true}
        path="/analytics"
        element={<Analytics />}
      />
      <Route
        exact={true}
        path="/exams"
        element={<Exams />}
      />
      <Route
        exact={true}
        path="/patients_old"
        element={<PatientPageLayout />}
        handle={{
          crumb: () => <Link to="/patients">patients</Link>,
        }}
      >
        {/* <Route exact={true} path="" element={<PatientsList />} /> */}
        <Route
          exact={true}
          path=""
          element={<PatientsListCustom />}
        />
        <Route
          exact={true}
          path=":PatientID"
          element={<Patient />}
          handle={{
            crumb: () => <Link to="/patients/:PatientID">patientID</Link>,
          }}
        />
      </Route>

      <Route
        exact={true}
        path="/reports"
        element={<Reports />}
      />
      <Route
        exact={true}
        path="/settings"
        element={<Settings />}
      />
      <Route
        exact={true}
        path="/support"
        element={<Support />}
      />
      <Route
        exact={true}
        path="/account"
        element={<Account />}
      />
    </Routes>
  );
};

export default Pages;
