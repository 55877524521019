import React from 'react';
import { BsJournalMedical } from 'react-icons/bs';
import { GiFullFolder } from 'react-icons/gi';
import { FaHospitalUser } from 'react-icons/fa6';
import { TbReportMedical } from 'react-icons/tb';
import CardItems from './component/Card';
import AreaChart from './component/Charts';

const Analytics: React.FC = () => {
  const studies = [
    {
      name: 'Evolution of studies',
      data: [4321, 4599, 4238, 4482, 4367, 4210, 4519],
      color: '#36a2eb',
    },
  ];
  const series = [
    {
      name: 'Evolution of series',
      data: [6576, 7115, 6594, 6986, 6659, 6412, 6969],
      color: '#7dc162',
    },
  ];
  const patients = [
    {
      name: 'Evolution of patients',
      data: [3711, 3920, 3745, 3876, 3778, 3655, 3891],
      color: '#ffcd56',
    },
  ];
  const report = [
    {
      name: 'Evolution of reports',
      data: [5023, 5321, 5018, 5243, 5109, 4932, 5261],
      color: '#ff6384',
    },
  ];
  const categories = [
    '2023/01/13',
    '2023/02/16',
    '2023/02/20',
    '2023/02/29',
    '2023/03/14',
    '2023/03/15',
    '2023/04/10',
  ];
  return (
    <div className="mx-auto flex h-full flex-col overflow-scroll">
      <div className="grid grid-cols-2 gap-4 py-8 px-11 lg:py-6 xl:flex xl:space-x-4">
        <CardItems
          title="Number of studies"
          icon={BsJournalMedical}
          totalNumber={14553}
          percent={13}
        />
        <CardItems
          title="Number of series"
          icon={GiFullFolder}
          totalNumber={13456}
          percent={-34}
        />
        <CardItems
          title="Number of patients"
          icon={FaHospitalUser}
          totalNumber={65190}
          percent={45}
        />
        <CardItems
          title="Number of report"
          icon={TbReportMedical}
          totalNumber={675}
          percent={-11}
        />
      </div>
      <div className="grid grid-cols-1 gap-4 px-11 lg:grid-cols-2">
        <div className="flex w-full flex-col rounded-md border border-[#dee2e6] py-5 px-3">
          <div className="flex items-center justify-between text-[#868e96]">
            <div className="text-lg font-semibold">Evolution of studies</div>
          </div>
          <div>
            <AreaChart
              series={studies}
              categories={categories}
              lineColor="#36a2eb"
            />
          </div>
        </div>
        <div className="flex w-full flex-col rounded-md border border-[#dee2e6] py-5 px-3">
          <div className="flex items-center justify-between text-[#868e96]">
            <div className="text-lg font-semibold">Evolution of series</div>
          </div>
          <div>
            <AreaChart
              series={series}
              categories={categories}
              lineColor="#7dc162"
            />
          </div>
        </div>
        <div className="flex w-full flex-col rounded-md border border-[#dee2e6] py-5 px-3">
          <div className="flex items-center justify-between text-[#868e96]">
            <div className="text-lg font-semibold">Evolution of patients</div>
          </div>
          <div>
            <AreaChart
              series={patients}
              categories={categories}
              lineColor="#ffcd56"
            />
          </div>
        </div>
        <div className="flex w-full flex-col rounded-md border border-[#dee2e6] py-5 px-3">
          <div className="flex items-center justify-between text-[#868e96]">
            <div className="text-lg font-semibold">Evolution of reports</div>
          </div>
          <div>
            <AreaChart
              series={report}
              categories={categories}
              lineColor="#ff6384"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
