export var colorClassnamesMap = {
  inherit: '',
  blue: 'text-blue-600',
  red: 'text-red-600',
  green: 'text-green-600',
  yellow: 'text-yellow-600',
  indigo: 'text-indigo-600',
  dark: 'text-gray-600',
  white: 'text-white',
};
export var sizeMap = {
  small: 'h-4 w-4',
  medium: 'h-5 w-5',
  large: 'h-7 w-7',
  extraLarge: 'h-10 w-10',
};
