var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
        return t;
      };
    return __assign.apply(this, arguments);
  };
var __rest =
  (this && this.__rest) ||
  function (s, e) {
    var t = {};
    for (var p in s)
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === 'function')
      for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
          t[p[i]] = s[p[i]];
      }
    return t;
  };
import React from 'react';
import cn from 'classnames';
// import { VscLoading } from "react-icons/vsc";
import { BiLoaderCircle } from 'react-icons/bi';
import { colorClassnamesMap, sizeMap } from './constants';
export var Spinner = function (_a) {
  var _b = _a.color,
    color = _b === void 0 ? 'dark' : _b,
    _c = _a.size,
    size = _c === void 0 ? 'medium' : _c,
    _d = _a.className,
    className = _d === void 0 ? '' : _d,
    props = __rest(_a, ['color', 'size', 'className']);
  return React.createElement(
    BiLoaderCircle,
    __assign({}, props, {
      className: cn(
        'inline-block align-middle animate-spin',
        className,
        colorClassnamesMap[color],
        sizeMap[size]
      ),
    })
  );
};
// Spinner.propTypes = {
//   size: PropTypes.oneOf(["small", "medium", "large", "extraLarge"]),
//   color: PropTypes.oneOf([
//     "inherit",
//     "blue",
//     "red",
//     "green",
//     "yellow",
//     "white",
//     "dark",
//     "indigo",
//   ]),
// };
// Spinner.defaultProps = {
//   color: "white",
//   size: "medium",
// };
export default Spinner;
