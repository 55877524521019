import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import {
  getStudies as getStudiesApi,
  generateSR as generateSRApi,
} from '../../helpers/backendHelper';
import { GENERATE_SR } from './actionType';
import { srApiError, srApiSuccess } from './actions';

function* generateSR({ payload }) {
  try {
    const response = yield call(generateSRApi, payload);
    console.log('ya hala', response);
    yield put(srApiSuccess(GENERATE_SR, response));
  } catch (error) {
    console.log(error);
    yield put(srApiError(GENERATE_SR, error));
  }
}

export function* watchSR() {
  yield takeEvery(GENERATE_SR, generateSR);
}

function* srSaga() {
  yield all([fork(watchSR)]);
}

export default srSaga;
