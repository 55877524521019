import React from 'react';
import { CiImageOff } from 'react-icons/ci';
import { SlCheck } from 'react-icons/sl';
import { TiDeleteOutline } from 'react-icons/ti';

function Card({ children, className = '' }) {
  return (
    <div className={`rounded-md border border-[#A6ABC8] bg-[#F7FAFE] p-2 ${className}`}>
      {children}
    </div>
  );
}

export default Card;
