import React from 'react';
import { DicomSeries, DicomUploadStatus } from '../../interfaces';
import CompactUIDDisplay from './CompactUIDDisplay';

interface InstancesErrorTableProps {
  series: DicomSeries;
  getStatusStyle: (status: string) => { text: string; className: string };
}

function InstancesErrorTable({ series, getStatusStyle }: InstancesErrorTableProps) {
  return (
    <>
      <table className="w-full border-collapse">
        <thead>
          <tr className="text-left text-[#1F384C]">
            <th className="w-1/5 border border-gray-200 py-1 pl-1">Instance UID</th>
            <th className="w-1/5 border border-gray-200 py-1 pl-1">Pixel Spacing</th>
            <th className="w-1/5 border border-gray-200 py-1 pl-1">Frames</th>
            <th className="w-1/5 border border-gray-200 py-1 pl-1">Upload Status</th>
            <th className="w-1/5 border border-gray-200 py-1 pl-1">Error</th>
          </tr>
        </thead>
        <tbody>
          {series.instances
            .filter(instance => instance.upload_status === DicomUploadStatus.Failed)
            .map(instance => (
              <tr
                key={instance.sop_instance_uid}
                className="hover:bg-gray-100"
              >
                <td className="border p-2">
                  <CompactUIDDisplay uid={instance.sop_instance_uid} />
                </td>
                <td className="border p-2">{instance.pixel_spacing?.join(', ') || 'N/A'}</td>
                <td className="border p-2">{instance.frames || 'N/A'}</td>
                <td className="border p-2">
                  <span
                    className={`rounded px-2 py-1 text-xs font-semibold ${getStatusStyle(instance.upload_status).className}`}
                  >
                    {getStatusStyle(instance.upload_status).text}
                  </span>
                </td>
                <td className="border p-2 text-red-600">
                  <span className="rounded-md border border-red-500 bg-red-300 p-1">
                    {instance.error || 'Unknown error'}
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}

export default InstancesErrorTable;
