import { ConfigProvider, DatePicker, Select } from 'antd';
import Input from '../Input/Input.component';
import React, { useState } from 'react';
import locale from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MedicalPlan, Patient } from '../../Sections/PatientsList/patientsList.section.custom';
import PatientModalController from './patient.modal.controller';
import { errorPatient } from './patient.modal';

interface PatientFormProps {
  handleNewPatientChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCreatePatient: any;
  newPatient: Patient;
  handleNewPatientBirthDate: any;
  handleSelectChange: any;
  condition: undefined | number;
  edit: boolean;
  error: Map<string, errorPatient> | null;
  HandleChangeComponents: () => void;
  // HandleConditionSwitch: () => void;
}

function PatientForm({
  handleCreatePatient,
  handleNewPatientChange,
  newPatient,
  handleNewPatientBirthDate,
  handleSelectChange,
  condition,
  HandleChangeComponents,
  edit,
  error,
}: PatientFormProps) {
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      patient_name: newPatient?.patient_name || '',
      patient_middle_name: newPatient?.patient_middle_name || '',
      patient_last_name: newPatient?.patient_last_name || '',
      policy_number: newPatient?.policy_number || '',
      insurance_provider: newPatient?.insurance_provider || '',
      phone_number: newPatient?.phone_number || '',
      email: newPatient?.email || '',
      emergency_details: newPatient?.emergency_details || '',
      address: newPatient?.address || '',
      birth_date: newPatient?.birth_date ? dayjs(newPatient.birth_date).format('YYYY-MM-DD') : '',
    },
    validationSchema: Yup.object({
      patient_name: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, 'First Name should be alphabets')
        .required('Please Enter the First Name'),
      patient_last_name: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, 'Last Name should be alphabets')
        .required('Please Enter the Last Name'),
      birth_date: Yup.date()
        .nullable()
        .transform((value, originalValue) =>
          dayjs(originalValue, 'YYYY-MM-DD', true).isValid() ? value : new Date('')
        )
        .typeError('Date of Birth must be in the format YYYY-MM-DD') // Handle invalid date format
        .required('Please Enter a valide date'), // Date is not required
      phone_number: Yup.string()
        .nullable() // Allow null values
        .notRequired() // Make it not required
        .matches(/^\+?[0-9]+(?:[ ]?[0-9]+)*$/, 'Must be a valid phone number'),
      email: Yup.string()
        .email('Please enter a valid email address') // Valid email format
        .matches(
          /^[\w-]+(\.[\w-]+)*@[\w-]+\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2})?$/,
          'Please enter a valid email'
        ) // More strict regex
        .notRequired(),
    }),
    onSubmit: values => {
      handleCreatePatient({
        patient_last_name: values.patient_last_name,
        patient_name: values.patient_name,
        birth_date: values.birth_date,
      });
    },
  });

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    console.log('validation :', validation);
    validation.handleSubmit();
  }
  const handleDatePicker = date => {
    // handleNewPatientBirthDate(date);
    validation.setFieldValue('birth_date', date ? dayjs(date) : '');
  };

  const handlemsgError = msg => {
    return msg.replace(/^Value error, /, '');
  };
  return (
    <div className="py-3 px-5">
      <form
        onSubmit={handleSubmit}
        className="flex h-full flex-col justify-between"
      >
        <div>
          <div className={`mb-3 flex w-full items-center justify-between space-x-2 `}>
            <div
              className={`input-container h-fill relative w-[50%] [&>input]:border [&>input]:border-red-500`}
            >
              <label className="absolute left-0 top-0 z-10 ml-1 text-[8px] font-medium text-[#7B7B7B]">
                {error?.get('patient_name') ? (
                  <div className="text-red-500">
                    {handlemsgError(error?.get('patient_name')?.message)}
                  </div>
                ) : validation.errors.patient_name ? (
                  <div className="text-red-500">Please enter a valid name</div>
                ) : newPatient?.patient_name === '' || !newPatient?.patient_name ? (
                  ''
                ) : (
                  'First Name'
                )}
              </label>

              <Input
                type="text"
                placeholder="First Name"
                onChange={validation.handleChange}
                value={newPatient?.patient_name}
                onBlur={validation.handleBlur}
                id="patientName"
                name="patient_name"
                invalid={validation.errors.patient_name ? true : false}
                inerror={error?.get('patient_name') ? true : false}
              />
            </div>

            <div className=" input-container relative w-[50%]">
              <label className="absolute left-0 top-0 z-10 ml-1 text-[8px] font-medium text-[#7B7B7B]">
                {error?.get('patient_middle_name') ? (
                  <div className="text-red-500">
                    {handlemsgError(error?.get('patient_middle_name')?.message)}
                  </div>
                ) : newPatient?.patient_middle_name === '' || !newPatient?.patient_middle_name! ? (
                  ''
                ) : (
                  'Middle Name'
                )}
              </label>
              <Input
                placeholder="Middle Name"
                type="text"
                value={newPatient?.patient_middle_name}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={validation.errors.patient_middle_name ? true : false}
                id="middleName"
                name="patient_middle_name"
                inerror={error?.get('patient_middle_name') ? true : false}
              />
            </div>
          </div>
          <div className="mb-3 flex w-full items-center justify-between space-x-2 ">
            <div className=" input-container relative w-[100%]">
              {/* <label className="text-[#7B7B7B] text-[8px] font-medium left-0 z-10 ml-1 absolute top-0">
                                {newPatient?.patient_last_name === '' || !newPatient?.patient_last_name ? '' : 'Last Name'}
                                
                            </label> */}
              <label className="absolute left-0 top-0 z-10 ml-1 text-[8px] font-medium text-[#7B7B7B]">
                {error?.get('patient_last_name') ? (
                  <div className="text-red-500">
                    {handlemsgError(error?.get('patient_last_name')?.message)}
                  </div>
                ) : validation.touched.patient_last_name && validation.errors.patient_last_name ? (
                  <div className="text-red-500">Please enter a valid name</div>
                ) : newPatient?.patient_last_name === '' || !newPatient?.patient_last_name ? (
                  ''
                ) : (
                  'Last Name'
                )}
              </label>
              <Input
                placeholder="Last Name"
                type="text"
                value={newPatient?.patient_last_name}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={validation.errors.patient_last_name ? true : false}
                id="lastName"
                name="patient_last_name"
                inerror={error?.get('patient_last_name') ? true : false}
              />
            </div>
            <div className="input-container relative w-full">
              <label className="absolute left-0 top-0 z-10 ml-1 text-[8px] font-medium text-[#7B7B7B]">
                {error?.get('birth_date') ? (
                  <div className="text-red-500">
                    {handlemsgError(error?.get('birth_date')?.message)}
                  </div>
                ) : validation.errors.birth_date ? (
                  <div className="text-red-500">{validation.errors.birth_date}</div>
                ) : newPatient?.birth_date === '' || !newPatient?.birth_date ? (
                  ''
                ) : (
                  'Date of birth'
                )}
              </label>
              <ConfigProvider locale={locale}>
                <DatePicker
                  placeholder="Date of birth"
                  value={validation.values.birth_date || null}
                  className={`main-input-void  mt-0 w-full rounded-md bg-white px-3 py-2 text-sm placeholder-opacity-50 ${
                    error?.get('birth_date') ||
                    (validation.errors.birth_date && validation.touched.birth_date)
                      ? 'border border-red-500'
                      : ''
                  }`}
                  onChange={handleDatePicker}
                  maxDate={dayjs()}
                  id="birth_date"
                  name="birth_date"
                  status={error?.get('birth_date') || validation.errors.birth_date ? 'error' : ''}
                />
              </ConfigProvider>
            </div>
          </div>

          <div className="mb-3 flex w-full items-center justify-between space-x-2">
            <div className="input-container relative w-[50%]">
              <Input
                placeholder="Insurance Provider"
                type="text"
                value={newPatient?.insurance_provider}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={validation.errors.insurance_provider ? true : false}
                id="insurance_provider"
                name="insurance_provider"
                inerror={error?.get('insurance_provider') ? true : false}
              />
              <label className="absolute left-0 top-0 z-10 ml-1 text-[8px] font-medium text-[#7B7B7B]">
                {error?.get('insurance_provider') ? (
                  <div className="text-red-500">
                    {handlemsgError(error?.get('insurance_provider')?.message)}
                  </div>
                ) : newPatient?.insurance_provider === '' || !newPatient?.insurance_provider! ? (
                  ''
                ) : (
                  'Insurance provider'
                )}
              </label>
            </div>
            <div className="input-container relative w-[50%]">
              <Input
                placeholder="Policy number"
                type="text"
                value={newPatient?.policy_number}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={validation.errors.policy_number ? true : false}
                id="policy_number"
                name="policy_number"
                inerror={error?.get('policy_number') ? true : false}
              />
              <label className="absolute left-0 top-0 z-10 ml-1 text-[8px] font-medium text-[#7B7B7B]">
                {error?.get('policy_number') ? (
                  <div className="text-red-500">
                    {handlemsgError(error?.get('policy_number')?.message)}
                  </div>
                ) : newPatient?.policy_number === '' || !newPatient?.policy_number! ? (
                  ''
                ) : (
                  'Policy number'
                )}
              </label>
            </div>
          </div>
        </div>
        <div>
          <div className="mb-3 flex w-full space-x-4">
            <div className="input-container relative w-[50%]">
              <label className="absolute left-0 top-0 z-10 ml-1 text-[8px] font-medium text-[#7B7B7B]">
                {error?.get('phone_number') ? (
                  <div className="text-red-500">
                    {handlemsgError(error?.get('phone_number')?.message)}
                  </div>
                ) : validation.errors.phone_number ? (
                  <div className="text-red-500">{validation.errors.phone_number}</div>
                ) : newPatient?.phone_number === '' || !newPatient?.phone_number ? (
                  ''
                ) : (
                  'Phone Number'
                )}
              </label>
              <Input
                placeholder="Phone Number"
                type="text"
                value={newPatient?.phone_number}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={validation.errors.phone_number ? true : false}
                id="phoneNumber"
                name="phone_number"
                inerror={error?.get('phone_number') ? true : false}
              />
            </div>

            <div className="input-container relative w-[50%]">
              <Input
                placeholder="Email"
                type="text"
                value={newPatient?.email}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={validation.errors.email ? true : false}
                id="email"
                name="email"
                inerror={error?.get('email') ? true : false}
              />
              <label className="absolute left-0 top-0 z-10 ml-1 text-[8px] font-medium text-[#7B7B7B]">
                {error?.get('email') ? (
                  <div className="text-red-500">{handlemsgError(error?.get('email')?.message)}</div>
                ) : validation.errors.email ? (
                  <div className="text-red-500">Please enter a valid email</div>
                ) : newPatient?.email === '' || !newPatient?.email ? (
                  ''
                ) : (
                  'Email'
                )}
              </label>
            </div>
          </div>
          <div className="mb-3 flex w-full items-center justify-between space-x-2">
            <div className="input-container relative w-full">
              <Input
                placeholder="Emergency Details"
                type="text"
                value={newPatient?.emergency_details}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={validation.errors.emergency_details ? true : false}
                id="emergency_details"
                name="emergency_details"
                inerror={error?.get('emergency_details') ? true : false}
              />

              <label className="absolute left-0 top-0 z-10 ml-1 text-[8px] font-medium text-[#7B7B7B]">
                {error?.get('emergency_details') ? (
                  <div className="text-red-500">
                    {handlemsgError(error?.get('emergency_contact_name')?.message)}
                  </div>
                ) : newPatient?.emergency_details === '' || !newPatient?.emergency_details! ? (
                  ''
                ) : (
                  'Emergency Contact Name'
                )}
              </label>
            </div>

            <div className="input-container relative w-full">
              <Input
                placeholder="Address"
                type="text"
                value={newPatient?.address}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={validation.errors.address ? true : false}
                id="address"
                name="address"
                inerror={error?.get('address') ? true : false}
              />
              <label className="absolute left-0 top-0 z-10 ml-1 text-[8px] font-medium text-[#7B7B7B]">
                {error?.get('address') ? (
                  <div className="text-red-500">
                    {handlemsgError(error?.get('address')?.message)}
                  </div>
                ) : newPatient?.address === '' || !newPatient?.address! ? (
                  ''
                ) : (
                  'Address Line 1'
                )}
              </label>
            </div>
          </div>
        </div>
        <div className="mt-3 flex w-full justify-end">
          <button
            type="submit"
            onClick={HandleChangeComponents}
            className="flex w-48 items-center justify-center space-x-2 rounded border border-[#005DD4] px-2 py-1 font-semibold text-[#005DD4]  hover:bg-[#015DDD] hover:text-white"
          >
            {edit ? 'Update Patient' : 'Add Patient'}
          </button>
        </div>
      </form>
    </div>
  );
}
export default PatientForm;
