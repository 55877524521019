import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';

interface DrawingModeState {
  drawingState: boolean;
}

interface DrawingModeContextValue extends DrawingModeState {
  getState: () => DrawingModeState;
  setDrawingState: (drawingState: boolean) => void;
}

const DEFAULT_STATE: DrawingModeState = {
  drawingState: true,
};

export const DrawingModeContext = createContext<DrawingModeContextValue | undefined>(undefined);

const reducer = (
  state: DrawingModeState,
  action: { type: string; payload: boolean }
): DrawingModeState => {
  switch (action.type) {
    case 'SET_DRAWING_STATE':
      return { ...state, drawingState: action.payload };
    default:
      return state;
  }
};
export default function DrawingModeProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

  const getState = useCallback(() => state, [state]);

  const setDrawingState = useCallback(
    (drawingState: boolean) => dispatch({ type: 'SET_DRAWING_STATE', payload: drawingState }),
    []
  );

  const value: DrawingModeContextValue = { ...state, getState, setDrawingState };

  return <DrawingModeContext.Provider value={value}>{children}</DrawingModeContext.Provider>;
}

DrawingModeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDrawingMode = (): DrawingModeContextValue => {
  const context = useContext(DrawingModeContext);
  if (context === undefined) {
    throw new Error('useDrawingMode must be used within a DrawingModeProvider');
  }
  return context;
};
