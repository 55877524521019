import React, { useEffect, useRef, useState } from 'react';
import dicomParser from 'dicom-parser';

import { Modal } from 'antd';
import newSource from '../../../../assets/newSource.png';
import { APIClient } from '../../../helpers/apiHelper';
import { AiOutlinePlusCircle } from 'react-icons/ai';

// import FileLoaderService from 'platform/viewer/src/routes/Local/fileLoaderService';
import FileLoaderService from '../../../../../../../platform/viewer/src/routes/Local/fileLoaderService';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { toast } from 'react-toastify';
import Footer from './Footer';
import Header from './Header';
import NoDataSelected from './NoDataSelected';
import ExpandableTable from './tables';
import {
  AddStudie,
  DicomDataStructure,
  DicomSeries,
  DicomStudy,
  DicomUploadStatus,
  StudieInfo,
  StudySerieUploadInfo,
  StudyUploadError,
  StudyUploadResponse,
  isApiError,
} from '../../interfaces';
import { useAddStudie } from '../../../helpers/api/study/study.api';
import { AxiosError } from 'axios';
export const api = new APIClient();
interface Props {
  title?: string;
  description?: string;
  page?: string;
}

const NewExam: React.FC<Props> = ({ title, description, page }: Props) => {
  const [uploadType, setUploadType] = useState<string>('import files');
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRefMore = useRef<HTMLInputElement>(null);
  const [modalHeight, setModalHeight] = useState(0);
  const modalTabs = [{ title: 'Import Files' }, { title: 'Import a Folder' }];
  const [activeModalTab, setActiveModalTab] = useState('Import Files');
  const [dicomDataStructure, setDicomDataStructure] = useState<DicomDataStructure>([]);
  const [processedSOPInstanceUIDs, setProcessedSOPInstanceUIDs] = useState(new Set<string>());
  const [studySerieUploadInfo, setStudySerieUploadInfo] = useState<StudySerieUploadInfo[]>([]);

  const checkSerieUploadStatus = (serie: DicomSeries) => {
    const hasFailed = serie.instances.some(
      instance => instance.upload_status === DicomUploadStatus.Failed
    );

    if (hasFailed) {
      return DicomUploadStatus.Failed;
    }

    const isAllInstancesUploaded = serie.instances.every(
      instance => instance.upload_status === DicomUploadStatus.Successful
    );

    return isAllInstancesUploaded ? DicomUploadStatus.Successful : serie.upload_status;
  };

  const checkStudyUploadStatus = (study: DicomStudy) => {
    const hasFailed = study.series.some(
      series => series.upload_status === DicomUploadStatus.Failed
    );

    if (hasFailed) {
      return DicomUploadStatus.Failed;
    }

    const isAllSeriesUploaded = study.series.every(
      series => series.upload_status === DicomUploadStatus.Successful
    );

    return isAllSeriesUploaded ? DicomUploadStatus.Successful : study.upload_status;
  };

  const updateStudySerieUploadInfo = (
    studyUID: string,
    seriesUID: string,
    updatedSeries: DicomSeries
  ) => {
    setStudySerieUploadInfo(prev => {
      const existingInfoIndex = prev.findIndex(
        info => info.study_instance_uid === studyUID && info.series_instance_uid === seriesUID
      );

      const totalFiles = updatedSeries.instances.length;
      const totalUploaded = updatedSeries.instances.filter(
        instance => instance.upload_status === DicomUploadStatus.Successful
      ).length;
      const totalFailed = updatedSeries.instances.filter(
        instance => instance.upload_status === DicomUploadStatus.Failed
      ).length;
      const failedInstances = updatedSeries.instances.filter(
        instance => instance.upload_status === DicomUploadStatus.Failed
      );

      const updatedInfo: StudySerieUploadInfo = {
        study_instance_uid: studyUID,
        series_instance_uid: seriesUID,
        total_files: totalFiles,
        total_success: totalUploaded,
        total_failed: totalFailed,
        failed_instances: failedInstances,
      };

      if (existingInfoIndex >= 0) {
        return [
          ...prev.slice(0, existingInfoIndex),
          updatedInfo,
          ...prev.slice(existingInfoIndex + 1),
        ];
      }

      return [...prev, updatedInfo];
    });
  };

  const onSuccess = (response: StudyUploadResponse, data: any) => {
    setDicomDataStructure(prev => {
      const newDicomDataStructure = prev.map(study => {
        const updatedStudy = { ...study };

        updatedStudy.series = study.series.map(series => {
          const updatedSeries = { ...series };
          updatedSeries.instances = series.instances.map(instance =>
            instance.sop_instance_uid === response.data.result.sop_instance_uid
              ? { ...instance, upload_status: DicomUploadStatus.Successful }
              : instance
          );

          updatedSeries.upload_status = checkSerieUploadStatus(updatedSeries);

          updateStudySerieUploadInfo(
            study.study_instance_uid,
            series.series_instance_uid,
            updatedSeries
          );

          return updatedSeries;
        });

        updatedStudy.upload_status = checkStudyUploadStatus(updatedStudy);

        return updatedStudy;
      });

      return newDicomDataStructure;
    });
  };

  const onError = (error: AxiosError<StudyUploadError>, data: any) => {
    console.log('error', error);
    if (error.response && isApiError(error.response.data)) {
      const errorResponse = error.response.data;
      const err = errorResponse.error;
      const message = errorResponse.message;

      const error_study_info = errorResponse.details?.original_study_info;
      const dict_error_study_info = error_study_info ? JSON.parse(error_study_info) : null;

      console.log('errorResponse', errorResponse);

      if (dict_error_study_info) {
        setDicomDataStructure(prev => {
          const newDicomDataStructure = prev.map(study => {
            const updatedStudy = { ...study };

            updatedStudy.series = study.series.map(series => {
              const updatedSeries = { ...series };
              updatedSeries.instances = series.instances.map(instance => {
                if (instance.sop_instance_uid === dict_error_study_info.sop_instance_uid) {
                  return { ...instance, upload_status: DicomUploadStatus.Failed, error: err };
                }
                return instance;
              });

              updatedSeries.upload_status = checkSerieUploadStatus(updatedSeries);

              updateStudySerieUploadInfo(
                study.study_instance_uid,
                series.series_instance_uid,
                updatedSeries
              );

              return updatedSeries;
            });

            updatedStudy.upload_status = checkStudyUploadStatus(updatedStudy);

            return updatedStudy;
          });

          return newDicomDataStructure;
        });
      }
    } else {
      console.error('Study upload error:', error);
    }

    console.error('Study upload error:', error);
  };
  const mutationAdd = useAddStudie((oldData, newData) => [...oldData, newData], onSuccess, onError);
  const ErrorNotify = str => toast.error(str);

  useEffect(() => {
    const calculateModalHeight = () => {
      const screenHeight = window.innerHeight;
      setModalHeight(screenHeight * 0.8);
    };

    calculateModalHeight();
    window.addEventListener('resize', calculateModalHeight);

    return () => {
      window.removeEventListener('resize', calculateModalHeight);
    };
  }, []);

  const handleDragOver = e => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = async e => {
    e.preventDefault();
    setIsDragOver(false);

    const items = e.dataTransfer.items;
    const fileList = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry();
      if (item) {
        if (item.isFile) {
          if (uploadType !== 'import files') {
            ErrorNotify('Please upload folders only in this section.');
            return;
          }
          const file = items[i].getAsFile();
          fileList.push(file);
        } else if (item.isDirectory) {
          if (uploadType === 'import files') {
            ErrorNotify('Please upload files only in this section.');
            return;
          }
          await traverseDirectory(item, fileList);
        }
      }
    }
    console.log('i m here 1');
    await newHandleFiles(fileList);
  };

  async function traverseDirectory(directoryEntry, fileList) {
    const directoryReader = directoryEntry.createReader();
    return new Promise<void>((resolve, reject) => {
      directoryReader.readEntries(async entries => {
        for (let i = 0; i < entries.length; i++) {
          const entry = entries[i];
          if (entry.isFile) {
            // Handle files inside the directory
            const file = await getFileFromEntry(entry);
            fileList.push(file);
          } else if (entry.isDirectory) {
            // Recursively traverse subdirectories
            await traverseDirectory(entry, fileList);
          }
        }
        resolve();
      }, reject);
    });
  }

  function getFileFromEntry(fileEntry) {
    return new Promise((resolve, reject) => {
      fileEntry.file(resolve, reject);
    });
  }

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };
  const handleFileSelectMore = () => {
    fileInputRefMore.current.click();
  };

  const handleFileInputChange = e => {
    const files = e.target.files;
    console.log('i m here 2');

    newHandleFiles(files);
  };
  console.log('dicomDataStructure', dicomDataStructure);
  console.log('studySerieUploadInfo', studySerieUploadInfo);

  const newHandleFiles = async (files): Promise<DicomDataStructure> => {
    const localProcessedSOPInstanceUIDs = new Set<string>();
    const localdicomDataStructure: DicomDataStructure = [];

    const validModalities = new Set(['US', 'SR']);
    let invalidFileNotified = false;

    const notifyInvalidFileOnce = (message: string) => {
      if (!invalidFileNotified) {
        ErrorNotify(message);
        invalidFileNotified = true;
      }
    };

    const extractDicomData = async (file: File) => {
      try {
        const fileLoaderService = new FileLoaderService(file);
        const imageId = fileLoaderService.addFile(file);
        const image = await fileLoaderService.loadFile(file, imageId);
        return await fileLoaderService.getDataset(image, imageId);
      } catch (error) {
        notifyInvalidFileOnce('Only US or SR modality files are supported. Upload failed.');
        return null;
      }
    };
    const updateStudySerieUploadInfo = (
      studyUID: string,
      seriesUID: string,
      incrementFiles: number
    ) => {
      setStudySerieUploadInfo(prev => {
        const existingInfo = prev.find(
          info => info.study_instance_uid === studyUID && info.series_instance_uid === seriesUID
        );

        if (existingInfo) {
          return prev.map(info =>
            info.study_instance_uid === studyUID && info.series_instance_uid === seriesUID
              ? { ...info, total_files: info.total_files + incrementFiles }
              : info
          );
        }

        return [
          ...prev,
          {
            study_instance_uid: studyUID,
            series_instance_uid: seriesUID,
            total_files: incrementFiles,
            total_success: 0,
            total_failed: 0,
            failed_instances: [],
          },
        ];
      });
    };
    const findOrCreateStudy = (
      studyInstanceUID: string,
      studyData: Partial<DicomStudy>
    ): DicomStudy => {
      let study = dicomDataStructure.find(s => s.study_instance_uid === studyInstanceUID);

      if (!study) {
        study = {
          study_instance_uid: studyInstanceUID,
          study_date: studyData.study_date,
          study_time: studyData.study_time,
          study_id: studyData.study_id,
          upload_status: DicomUploadStatus.Pending,
          series: [],
        };
        dicomDataStructure.push(study);
      } else if (
        study.upload_status === DicomUploadStatus.Successful ||
        study.upload_status === DicomUploadStatus.Failed
      ) {
        study.upload_status = DicomUploadStatus.Pending;
      }

      return study;
    };

    const findOrCreateSeries = (
      study: DicomStudy,
      seriesInstanceUID: string,
      seriesData: Partial<DicomSeries>
    ): DicomSeries => {
      let series = study.series.find(s => s.series_instance_uid === seriesInstanceUID);

      if (!series) {
        series = {
          series_instance_uid: seriesInstanceUID,
          series_modality: seriesData.series_modality,
          series_number: seriesData.series_number,
          physician_name: seriesData.physician_name,
          series_institution_name: seriesData.series_institution_name,
          upload_status: DicomUploadStatus.Pending,
          frame_numbers: 0,
          instances: [],
        };
        study.series.push(series);
      } else if (
        series.upload_status === DicomUploadStatus.Successful ||
        series.upload_status === DicomUploadStatus.Failed
      ) {
        series.upload_status = DicomUploadStatus.Pending;
      }

      updateStudySerieUploadInfo(study.study_instance_uid, seriesInstanceUID, 0);

      return series;
    };

    for (let i = 0; i < files?.length; i++) {
      const file = files[i];
      const dicomData = await extractDicomData(file);

      if (dicomData) {
        const {
          StudyInstanceUID,
          SeriesInstanceUID,
          Modality,
          NumberOfFrames,
          AvailableTransferSyntaxUID,
          StudyDate,
          StudyTime,
          StudyDescription,
          StudyID,
          SOPInstanceUID,
          SOPClassUID,
          ImageType,
          TransferSyntaxUID,
          ReferringPhysicianName,
          InstitutionName,
          SeriesNumber,
          PixelSpacing,
          PixelData,
          Height,
          Width,
        } = dicomData;
        console.log('dicomData', dicomData);

        if (localProcessedSOPInstanceUIDs.has(SOPInstanceUID)) {
          notifyInvalidFileOnce('Duplicate files detected. Upload failed.');
          continue;
        }
        if (processedSOPInstanceUIDs.has(SOPInstanceUID)) {
          notifyInvalidFileOnce('Duplicate files detected. Upload failed.');
          continue;
        }

        if (!validModalities.has(Modality)) {
          notifyInvalidFileOnce('Only US or SR modality files are supported. Upload failed.');
          continue;
        }

        try {
          const arrayBuffer = await file.arrayBuffer();
          const byteArray = new Uint8Array(arrayBuffer);
          dicomParser.parseDicom(byteArray);

          localProcessedSOPInstanceUIDs.add(SOPInstanceUID);
          setProcessedSOPInstanceUIDs(prev => new Set(prev).add(SOPInstanceUID));

          const study = findOrCreateStudy(StudyInstanceUID, {
            study_date: StudyDate,
            study_time: StudyTime,
            study_id: StudyID,
          });

          const series = findOrCreateSeries(study, SeriesInstanceUID, {
            series_modality: Modality,
            series_number: SeriesNumber,
            physician_name: ReferringPhysicianName?.Alphabetic,
            series_institution_name: InstitutionName,
          });

          // must check if the framenumber is in the dicomData or not if not take the length of the pixelData else take 1
          const instancePixelDataLength = dicomData?.PixelData?.length || 0;

          series.instances.push({
            sop_instance_uid: SOPInstanceUID,
            sop_class_uid: SOPClassUID,
            image_type: ImageType,
            transfer_syntax_uid: AvailableTransferSyntaxUID || TransferSyntaxUID,
            instance_description: StudyDescription,
            height: Height,
            width: Width,
            pixel_spacing: PixelSpacing,
            frames: PixelData?.length || 1,
            file: file,
            upload_status: DicomUploadStatus.Pending,
          });

          series.frame_numbers = series.instances.reduce(
            (sum, instance) => sum + (instance.frames || 0),
            0
          );
          updateStudySerieUploadInfo(StudyInstanceUID, SeriesInstanceUID, 1);
        } catch (error) {
          console.error('Error processing DICOM file:', error);
        }
      }
    }

    const fileList = localdicomDataStructure.flatMap(study =>
      study.series.flatMap(series =>
        series.instances.map(instance => ({
          index: Math.random(),
          data: instance.file,
          fileName: (instance.file as File).name,
          size: (instance.file as File).size,
          modality: series.series_modality,
          studyInstanceUID: study.study_instance_uid,
          seriesInstanceUID: series.series_instance_uid,
          upload_status: DicomUploadStatus.Pending,
        }))
      )
    );

    const studies = fileList.reduce((acc, file) => {
      const { studyInstanceUID } = file;
      if (!acc[studyInstanceUID]) {
        acc[studyInstanceUID] = {
          studyInstanceUID,
          seriesInstanceUID: file.seriesInstanceUID,
          modality: file.modality,
          files: [],
        };
      }
      acc[studyInstanceUID].files.push(file);
      return acc;
    }, {});

    setDicomDataStructure(prev => [...prev, ...localdicomDataStructure]);

    return localdicomDataStructure;
  };

  const showModal = () => {
    setOpen(true);
  };
  console.log('mutationAdd', mutationAdd);

  const handleOk = async () => {
    function transformDicomData(jsonData: DicomDataStructure): AddStudie[] {
      const transformedData: AddStudie[] = [];

      jsonData.forEach((study: DicomStudy) => {
        if (study?.patient_id === undefined) {
          ErrorNotify('Please select a patient.');
          return;
        }
        study.series.forEach(series => {
          series.instances.forEach(instance => {
            if (
              instance.upload_status === DicomUploadStatus.Successful ||
              instance?.error === 'SeriesAlreadyExists'
            ) {
              return;
            }

            const studieInfo: StudieInfo = {
              study_instance_uid: study.study_instance_uid,
              study_date: study.study_date,
              study_time: study.study_time || '',
              study_id: study.study_id || '',
              patient_id: study.patient_id || undefined,
              patient_name: study.patient_name,
              series_instance_uid: series.series_instance_uid,
              series_modality: series.series_modality,
              sop_instance_uid: instance.sop_instance_uid,
              transfer_syntax_uid: instance.transfer_syntax_uid || undefined,
              sop_class_uid: instance.sop_class_uid,
              pixel_spacing: instance.pixel_spacing || undefined,
              frames: instance.frames || undefined,
              study_upload_status: study.upload_status,
              series_upload_status: series.upload_status,
              instance_upload_status: instance.upload_status,
            };

            transformedData.push({
              file: instance.file,
              StudieInfo: studieInfo,
            });
          });
        });
      });

      return transformedData;
    }

    const data = transformDicomData(dicomDataStructure);

    for (const study of data) {
      const { StudieInfo, file } = study;
      const study_instance_uid = StudieInfo?.study_instance_uid;
      const series_instance_uid = StudieInfo?.series_instance_uid;
      const sop_instance_uid = StudieInfo?.sop_instance_uid;

      // Update the upload status
      setDicomDataStructure(prev => {
        const newDicomDataStructure = prev.map(existingStudy => {
          if (existingStudy.study_instance_uid === study_instance_uid) {
            const updatedStudy = {
              ...existingStudy,
              upload_status: DicomUploadStatus.Uploading,
            };

            updatedStudy.series = existingStudy.series.map(series => {
              if (series.series_instance_uid === series_instance_uid) {
                return {
                  ...series,
                  upload_status: DicomUploadStatus.Uploading,
                  instances: series.instances.map(instance =>
                    instance.sop_instance_uid === sop_instance_uid
                      ? { ...instance, upload_status: DicomUploadStatus.Uploading }
                      : instance
                  ),
                };
              }
              return series;
            });

            return updatedStudy;
          }
          return existingStudy;
        });

        return newDicomDataStructure;
      });

      // Prepare and send FormData
      const formData = new FormData();
      if (file instanceof File) {
        formData.append(`file`, file, file.name);
      }

      formData.append(`studyInfo`, JSON.stringify(StudieInfo));

      try {
        await mutationAdd.mutateAsync(formData); // Await each upload
      } catch (error) {
        console.error('Upload failed for:', StudieInfo, error);
        // Handle failure (e.g., rollback upload status)
      }
    }
  };

  const handleReloadStudy = async (studyInstanceUID: string) => {
    function transformDicomData(
      jsonData: DicomDataStructure,
      specificStudyInstanceUID: string
    ): AddStudie[] {
      const transformedData: AddStudie[] = [];

      const studyToReload = jsonData.find(
        study => study.study_instance_uid === specificStudyInstanceUID
      );

      if (!studyToReload) {
        ErrorNotify('Study not found for reload.');
        return [];
      }

      if (studyToReload?.patient_id === undefined) {
        ErrorNotify('Please select a patient.');
        return [];
      }

      studyToReload.series.forEach(series => {
        series.instances.forEach(instance => {
          if (instance.upload_status === DicomUploadStatus.Successful) {
            return;
          }

          const studieInfo: StudieInfo = {
            study_instance_uid: studyToReload.study_instance_uid,
            study_date: studyToReload.study_date,
            study_time: studyToReload.study_time || '',
            study_id: studyToReload.study_id || '',
            patient_id: studyToReload.patient_id || undefined,
            patient_name: studyToReload.patient_name,
            series_instance_uid: series.series_instance_uid,
            series_modality: series.series_modality,
            sop_instance_uid: instance.sop_instance_uid,
            transfer_syntax_uid: instance.transfer_syntax_uid || undefined,
            sop_class_uid: instance.sop_class_uid,
            pixel_spacing: instance.pixel_spacing || undefined,
            frames: instance.frames || undefined,
            study_upload_status: studyToReload.upload_status,
            series_upload_status: series.upload_status,
            instance_upload_status: instance.upload_status,
          };

          transformedData.push({
            file: instance.file,
            StudieInfo: studieInfo,
          });
        });
      });

      return transformedData;
    }

    const data = transformDicomData(dicomDataStructure, studyInstanceUID);
    setDicomDataStructure(prev => {
      return prev.map(existingStudy => {
        if (existingStudy.study_instance_uid === studyInstanceUID) {
          const updatedStudy = {
            ...existingStudy,
            upload_status: DicomUploadStatus.Uploading,
          };

          updatedStudy.series = existingStudy.series.map(series => ({
            ...series,
            upload_status: DicomUploadStatus.Uploading,
            instances: series.instances.map(instance => ({
              ...instance,
              upload_status: DicomUploadStatus.Uploading,
            })),
          }));

          return updatedStudy;
        }
        return existingStudy;
      });
    });

    for (const study of data) {
      const { StudieInfo, file } = study;
      const series_instance_uid = StudieInfo?.series_instance_uid;
      const sop_instance_uid = StudieInfo?.sop_instance_uid;

      const formData = new FormData();
      if (file instanceof File) {
        formData.append(`file`, file, file.name);
      }

      formData.append(`studyInfo`, JSON.stringify(StudieInfo));

      try {
        await mutationAdd.mutateAsync(formData);
      } catch (error) {
        console.error('Upload failed for:', StudieInfo, error);
      }
    }
  };

  const handleCancel = () => {
    setDicomDataStructure([]);
    setProcessedSOPInstanceUIDs(new Set<string>());
    setOpen(false);
  };
  const renderUploadInput = () => {
    if (uploadType === 'import files') {
      return (
        <input
          type="file"
          className="hidden"
          ref={fileInputRef}
          onChange={handleFileInputChange}
          multiple
          // accept=".dcm"
        />
      );
    } else if (uploadType === 'import a folder') {
      return (
        <input
          type="file"
          className="hidden"
          ref={fileInputRef}
          onChange={handleFileInputChange}
          // Use type assertion for webkitdirectory
          // @ts-ignore
          webkitdirectory=""
          // @ts-ignore
          mozdirectory=""
        />
      );
    }
  };
  const renderUploadInputMore = () => {
    if (uploadType === 'import files') {
      return (
        <input
          type="file"
          className="hidden"
          ref={fileInputRefMore}
          onChange={handleFileInputChange}
          multiple
          // accept=".dcm"
        />
      );
    } else if (uploadType === 'import a folder') {
      return (
        <input
          type="file"
          className="hidden"
          ref={fileInputRefMore}
          onChange={handleFileInputChange}
          // Use type assertion for webkitdirectory
          // @ts-ignore
          webkitdirectory=""
          // @ts-ignore
          mozdirectory=""
        />
      );
    }
  };

  return (
    <div className="flex h-full items-center justify-center">
      {page == 'home' ? (
        <div className="flex cursor-pointer flex-col items-center justify-center ">
          <h5 className="text-base font-normal text-[#21426D]">{title}</h5>
          <div className="">
            <img
              src={newSource}
              alt="newExam"
              onClick={showModal}
            />
          </div>
          <div className="flex items-center justify-center px-6 text-center text-xs">
            {description}
          </div>
        </div>
      ) : page == 'patientDetails' ? (
        <button
          className="flex cursor-pointer items-center justify-center  space-x-2 rounded-md bg-[#005DD4] px-1 py-[2px] text-white"
          onClick={showModal}
        >
          <span>New Case</span>
          <AiOutlinePlusCircle size={16} />
        </button>
      ) : page == 'examPage' ? (
        <button
          onClick={showModal}
          className="flex items-center justify-center space-x-2 rounded border border-[#005DD4] px-2 py-1 font-semibold text-[#005DD4]  hover:bg-[#015DDD] hover:text-white"
        >
          <IoMdAddCircleOutline size={15} />
          <div>Add new exam</div>
        </button>
      ) : (
        <></>
      )}

      <Modal
        title={'Add new exam'}
        open={open}
        onCancel={handleCancel}
        width={null}
        className="h-2/3 w-2/3"
        confirmLoading={confirmLoading}
        centered={true}
        footer={
          <Footer
            handleFileSelectMore={handleFileSelectMore}
            renderUploadInputMore={renderUploadInputMore}
            handleCancel={handleCancel}
            handleOk={handleOk}
            setDicomDataStructure={setDicomDataStructure}
            len={processedSOPInstanceUIDs?.size}
            setProcessedSOPInstanceUIDs={setProcessedSOPInstanceUIDs}
            setStudySerieUploadInfo={setStudySerieUploadInfo}
            dicomDataStructure={dicomDataStructure}
          />
        }
        // maskStyle={
        //   {
        //     backdropFilter: 'blur(var(--blur-backdrop))',
        //     '--blur-backdrop': '10px',
        //   } as any
        // }
      >
        <Header
          modalTabs={modalTabs}
          activeModalTab={activeModalTab}
          setActiveModalTab={setActiveModalTab}
          setUploadType={setUploadType}
          len={processedSOPInstanceUIDs?.size}
        />
        {dicomDataStructure?.length === 0 ? (
          <NoDataSelected
            isDragOver={isDragOver}
            handleDragOver={handleDragOver}
            handleDragLeave={handleDragLeave}
            handleDrop={handleDrop}
            handleFileSelect={handleFileSelect}
            uploadType={uploadType}
            renderUploadInput={renderUploadInput}
          />
        ) : (
          <div className="overflow-hidden">
            <div className="custom-scrollbar h-[420px] overflow-y-scroll">
              {/* <UploadStudyExam /> */}
              <ExpandableTable
                data={dicomDataStructure}
                setDicomDataStructure={setDicomDataStructure}
                handleReloadStudy={handleReloadStudy}
                setProcessedSOPInstanceUIDs={setProcessedSOPInstanceUIDs}
                setStudySerieUploadInfo={setStudySerieUploadInfo}
                studySerieUploadInfo={studySerieUploadInfo}
              />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default NewExam;
