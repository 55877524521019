import React, { useEffect, useState } from 'react';
import './recentReports.section.css';
import 'react-alice-carousel/lib/alice-carousel.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './recentReports.section.css';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import deepecho from '../../assets/deepecho.png';
import { List } from 'antd';
import { useGetRecentReports } from '../../../helpers/api/report/report.api';
interface ReportItem {
  title: string;
  imgSrc: string;
  date?: string;
}

const RecentReportsList: Array<ReportItem> = [
  {
    title: 'Doctor Name',
    imgSrc: 'https://images.sampleforms.com/wp-content/uploads/2020/03/Medical-Claim-Form.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
  {
    title: 'Doctor Name',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
    date: '2022-01-01 00:00 PM',
  },
];

const RecentReports: React.FC = () => {
  const { isLoading, data } = useGetRecentReports();
  useEffect(() => {
    console.log('sqsq', data);
  }, [data]);
  function parseDate(inputDateStr) {
    // Check if inputDateStr matches the first format "dd-mm-yyyy-hh:mm:ss"
    const matchFormat1 = /^(\d{2})-(\d{2})-(\d{4})-(\d{2}):(\d{2}):(\d{2})$/.exec(inputDateStr);
    if (matchFormat1) {
      const [, day, month, year] = matchFormat1;
      return `${getMonthName(parseInt(month))} ${parseInt(day)} ${parseInt(year)}`;
    }

    // Try parsing with Date constructor for the second format
    const dateObj = new Date(inputDateStr);
    if (!isNaN(dateObj.getTime())) {
      return `${getMonthName(dateObj.getMonth() + 1)} ${dateObj.getDate()} ${dateObj.getFullYear()}`;
    }

    // If parsing fails for both formats, return null or handle the error as needed
    return null;
  }

  function getMonthName(monthNumber) {
    // Array of month names (adjust per your locale if needed)
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return monthNames[monthNumber - 1];
  }
  return (
    <div className="flex h-full w-full gap-5 overflow-auto ">
      <div className="inline-block h-full w-full overflow-auto">
        {/*<List
          itemLayout="vertical"
          size="large"
          dataSource={RecentReportsList}
          renderItem={(item) => (
            <List.Item
            >
              <div className='flex justify-between items-center'>
                <div className='flex items-center justify-center space-x-1'>
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#deeafa] p-2 text-[#2d84c7]">{' '}DN</div>
                  <div className="text-[#1F384C]">
                    <p className="text-sm font-bold">Doctor Name</p>
                    <p className="text-xs font-medium">Ibn Sina Hospital</p>
                    <p className="text-xs font-medium">created: January 1, 2024</p>
                  </div>
                </div>
                <div className=''>
                  <img
                    width={100}
                    height={100}
                    alt="logo"
                    src={deepecho}
                  />
                </div>
              </div>
            
            </List.Item>
          )}
        />*/}
        {isLoading ? (
          <div className="flex h-full w-full items-center justify-center">
            <p className="border border-blue-400 p-3 text-lg font-normal text-[#5A5A5A]">
              Loading...
            </p>
          </div>
        ) : (
          <>
            {data?.data?.length === 0 && (
              <div className="flex h-full w-full items-center justify-center">
                <p className="border border-blue-400 p-3 text-lg font-normal text-[#5A5A5A]">
                  No Report available
                </p>
              </div>
            )}
            {data?.data?.map((exam, index) => (
              <div
                className="inline-flex w-1/6 flex-col p-2 text-center "
                key={index}
              >
                <img
                  src={deepecho}
                  draggable={false}
                  crossOrigin="anonymous"
                  className="h-[75%] w-full rounded-lg xl:w-[250px]"
                />
                <div className="text-[8px] font-normal text-[#5A5A5A]">{parseDate(exam?.date)}</div>
                <div className="text-xs font-normal text-black">{exam?.patient_name}</div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default RecentReports;
