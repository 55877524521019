import { useEffect, useRef, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { CiEdit } from 'react-icons/ci';
import '../style/index.css';
import { useAuth } from 'react-oidc-context';
import React from 'react';
import { Switch } from 'antd';
import TooltipDeepecho from '../../../../Components/tooltip';

function HeaderContent({ firstName, lastName, age, setGlobalState, lmp, globalState, latency }) {
  const auth = useAuth();
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const dropdownRef = useRef(null);
  const [isShow, setIsShow] = useState(false);
  const [examDuration, setExamDuration] = useState(60);
  const lastDurationRef = useRef(60);

  const handleShow = () => {
    setIsShow(!isShow);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsShow(false);
    }
  };

  const [birthDate, setBirthDate] = useState(null);

  const handleLogout = () => {
    auth.signoutSilent();
  };

  const calculateAge = (birthDate: string): number => {
    const dob = new Date(birthDate);
    const diff = Date.now() - dob.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const capitalizeFirstLetter = string => {
    if (!string) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
    setGlobalState(prevState => ({
      ...prevState,
      isVideoFrozen: !prevState.isVideoFrozen,
      isStart: !prevState.isStart,
    }));
  };

  const [fps, setFps] = useState(0);
  const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());

  useEffect(() => {
    const now = Date.now();
    const interval = now - lastUpdateTime;
    if (interval !== 0) {
      const currentFps = Math.round(1000 / interval);
      setFps(currentFps);
    }
    setLastUpdateTime(now);

    return () => setFps(0);
  }, [latency]);

  useEffect(() => {
    let timer;
    if (globalState?.isStart) {
      // Only reset to 120 if this is a new exam start (not a pause resume)
      if (globalState?.isExamEnded) {
        setExamDuration(60);
      } else {
        // Resume from last known duration
        setExamDuration(lastDurationRef.current);
      }

      timer = setInterval(() => {
        setExamDuration(prevDuration => {
          if (prevDuration <= 0) {
            setGlobalState(prevState => ({
              ...prevState,
              isStart: false,
              isVideoFrozen: true,
              isExamEnded: true,
            }));
            clearInterval(timer);
            return 0;
          }
          const newDuration = prevDuration - 1;
          lastDurationRef.current = newDuration; // Store the current duration
          return newDuration;
        });
      }, 1000);
    } else {
      // Store the current duration when pausing
      lastDurationRef.current = examDuration;
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [globalState?.isStart]);

  // Reset duration when a new exam starts
  useEffect(() => {
    if (globalState?.isExamEnded) {
      setExamDuration(60);
      lastDurationRef.current = 60;
    }
  }, [globalState?.isExamEnded]);

  const formatDuration = duration => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="flex w-full justify-between  text-sm text-black">
      <div className="flex w-full items-center">
        <div className="mr-2 flex items-center justify-center text-xs text-white">
          <span>
            <span className="mr-2 text-black">Start/Stop</span>
            <Switch
              checked={globalState?.isStart}
              className="custom-switch"
              onChange={onChange}
              size="small"
            />
          </span>
        </div>
        <div className="flex items-center justify-center">
          <TooltipDeepecho
            text={
              'You can start/stop the examination by clicking on the mirrored ultrasound machine feed.'
            }
          />
        </div>
      </div>
      <div className="flex items-center justify-center">
        {process.env.NODE_ENV !== 'productionXX' && (
          <div className="flex h-full w-44 items-center justify-center space-x-4">
            {latency ? (
              <div className="flex w-full items-center justify-end">
                <div className="flex items-center space-x-1">
                  <div className="whitespace-nowrap">Latency : {latency} ms</div>
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      backgroundColor:
                        latency < 300
                          ? 'green'
                          : latency >= 300 && latency <= 400
                            ? 'orange'
                            : 'red',
                    }}
                  ></div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {latency ? (
              <div className="flex w-full items-center justify-end">
                <div className="flex items-center space-x-1">
                  <div className="whitespace-nowrap">{fps} FPS</div>
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      backgroundColor: 'black',
                    }}
                  ></div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      <div className="flex w-full items-center justify-end">
        <span className="mr-2">Exam Duration: {formatDuration(examDuration)}</span>
        <span className="mr-2">Machine Type: {globalState?.machineType}</span>
        <div
          className={`flex h-6 w-6 items-center justify-center rounded-full text-[8px] text-white ${globalState?.isStart ? (globalState?.encounterError ? 'bg-red-500' : 'bg-green-500') : 'bg-red-500'}`}
        >
          {globalState?.isStart ? (globalState?.encounterError ? 'ERR' : 'ON') : 'OFF'}
        </div>
      </div>
    </div>
  );
}
export default HeaderContent;
