const { API_SUCCESS, API_ERROR, UPDATE_PREDICTIONS_STATE, GENERATE_SR } = require('./actionType');

const INIT_STATE = {
  SRs: {},
  error: {},
  needsReload: false,
};

const SR = (state = INIT_STATE, action) => {
  console.log(action);
  let SOPInstanceUID;
  let SeriesInstanceUID;
  let SerieIdentifier;
  let PredtionState;

  switch (action.type) {
    case API_SUCCESS:
      SOPInstanceUID = action.payload?.data?.report?.SOPInstanceUID;
      SeriesInstanceUID = action.payload?.data?.report?.SeriesInstanceUID;
      SerieIdentifier = `${SeriesInstanceUID}${SOPInstanceUID}`;
      switch (action.payload.actionType) {
        case GENERATE_SR:
          return {
            ...state,
            SRs: {
              ...state.SRs,
              [SerieIdentifier]: 'PREDICTED',
            },
            needsReload: true,
          };

        default:
          return { ...state };
      }

    case API_ERROR:
      switch (action.payload.actionType) {
        case GENERATE_SR:
          return {
            ...state,
            needsReload: false,
            error: action.payload.error,
          };

        default:
          return { ...state };
      }

    case UPDATE_PREDICTIONS_STATE:
      SOPInstanceUID = action.payload?.SOPInstanceUID;
      SeriesInstanceUID = action.payload?.SeriesInstanceUID;
      SerieIdentifier = SeriesInstanceUID + SOPInstanceUID;
      PredtionState = action.payload?.PredtionState;
      return {
        ...state,
        SRs: {
          ...state.SRs,
          [SerieIdentifier]: PredtionState,
        },
      };

    default:
      return { ...state };
  }
};

export default SR;
