import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStudies } from '../../../store/actions';
import { APIClient } from '../../../helpers/apiHelper';
import Stepper from '../../Components/Stepper/Stepper';
import { Switch } from '../../Components/Switch/switch';
import OrganizationType from './organization.type';
import OrganizationInfo from './organization.info';
import OrganizationContact from './organization.contact';
import OrganizationVerified from './organization.verified';
import { MdConstruction, MdOutlineLiveHelp } from 'react-icons/md';
import { IoSettingsOutline } from 'react-icons/io5';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { FaLocationArrow } from 'react-icons/fa';
import { useNavigate } from 'react-router';

const api = new APIClient();
const backend_url = process.env.BACKEND_URL;

interface step {
  title: string;
  number: number;
}

const Support: React.FC = () => {
  const [response, setResponse] = useState('');
  const [current, setCurrent] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const getRes = async () => {
      const res = await api.get(`${backend_url}/itworks`);
      setResponse(res);
    };

    getRes();
  }, []);
  console.log(current);

  return (
    // <div>
    //   <h1>Support</h1>
    //   {response}
    //   <div>
    //     <Stepper current={current} max={4} steps={[{ title: 'Organization Type', number: 1 }, { title: 'Organization Info', number: 2 }, { title: 'Key Personal Contact', number: 3 }, { title: 'Get Verified', number: 4 },]} />
    //     <Switch condition={current}>
    //       <Switch.Case when={1}>
    //         <OrganizationInfo />
    //       </Switch.Case>
    //       <Switch.Case when={2}>
    //         <OrganizationContact />
    //       </Switch.Case>
    //       <Switch.Case when={3}>
    //         <OrganizationVerified />
    //       </Switch.Case>
    //       <Switch.Default>
    //         <OrganizationType/>
    //       </Switch.Default>
    //     </Switch>
    //     <button onClick={() => setCurrent(prev => prev + 1)} className='w-[300px] h-12 bg-indigo-500 m-9'>up me</button>
    //     <button onClick={() => setCurrent(prev => prev - 1)} className='w-[300px] h-12 bg-indigo-500 m-9'>down me</button>
    //   </div>
    // </div>
    <div className="flex h-full w-full flex-col items-center justify-center space-y-3">
      <div className="flex w-80 flex-col items-center justify-center rounded-xl border-4 border-[#1c7dbf] p-3 text-[#1c7dbf]">
        <MdConstruction size={100} />
        <div className="text-xl font-bold">UNDER CONSTRUCTION</div>
        <div className="mt-5 inline-flex items-center justify-center space-x-2 text-black">
          <IoIosHelpCircleOutline size={16} />
          <div>Help page coming soon ...</div>
        </div>
      </div>
      <div>
        <button
          className="inline-flex cursor-pointer items-center justify-center space-x-2
        rounded-md border-2 border-[#1c7dbf] bg-[#1c7dbf] p-2 font-medium text-white hover:bg-white hover:text-[#1c7dbf]"
          onClick={() => {
            navigate('/home');
          }}
        >
          <FaLocationArrow />
          <div>Go to dashboard</div>
        </button>
      </div>
    </div>
  );
};

export default Support;
