import React, { useEffect, useRef, useState, useMemo } from 'react';

import axios from 'axios';
import dayjs from 'dayjs';
import { Input } from '../../shared/atoms';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/en_US';
import PatientModal from '../../Components/PatientComponents/patient.modal';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { getPatients, deletePatient, resetState } from '../../../store/actions';
import _ from 'lodash';
import SelectedPatientInfo from '../../Components/PatientComponents/selected.patient.info';
import PatientTable from '../../Components/PatientComponents/patient.table';
import { useDebouncedCallback } from 'use-debounce';
import { useQuery } from '@tanstack/react-query';
import LoaderPage from '../../Components/Loader/loader.page';
import DateRangePicker from '../../Components/date-range-picker';
import * as rdrLocales from 'react-date-range/dist/locale';
import { format } from 'date-fns';
import FilterDropdown from './filter-btn';
import FilterForm from './filter-btn';
import { IoMdAddCircleOutline } from 'react-icons/io';
import ToggleSwitch from './toggle-switch';
import NoDataFound from '../../Components/NoDataFound';
import { ToastContainer, toast } from 'react-toastify';
import { PaginationState } from '@tanstack/react-table';
import { usePaginatedPatients } from '../../../helpers/api/patient/patients';
export enum MedicalPlan {
  'UnitedHealthcare',
  'MedicalPlan',
}
export enum Relationship {
  'Spouse',
  'Parent',
  'Child',
  'Other',
}
export interface Patient {
  id?: number;
  patient_id?: number;
  patient_name: string;
  patient_middle_name?: string;
  patient_last_name: string;
  policy_number?: string;
  insurance_provider?: string;
  email?: string;
  phone_number?: string;
  birth_date?: string;
  emergency_details?: string;
  address?: string;
}
interface FilteredConditions {
  condition__or?: any; // Adjust `any` to the actual type
  birth_date__gte?: string; // Use the actual type, e.g., `string` or `Date`
  [key: string]: any; // Allow additional properties if dynamic keys are expected
}

const PatientsListCustom: React.FC = () => {
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [error, setError] = useState<string | null>(null);
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
  const [archived, setArchived] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [operator, setOperator] = useState('or');
  const isFirstRender = useRef(true); // Flag to track first render
  const dispatch = useDispatch();
  const [newPatient, setNewPatient] = useState<Patient>({
    patient_name: '',
    patient_last_name: '',
  });
  function initialiseNewPatient() {
    setNewPatient({
      patient_name: '',
      patient_last_name: '',
    });
  }
  const { patientsState } = useSelector(state => ({
    patientsState: state.Patient,
  }));
  const { profile } = useSelector(state => ({
    profile: state.Profile,
  }));
  console.log('patientsState 55', patientsState);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    reset: false,
  });
  const [dateFilter, setDateFilter] = useState<any[]>([]);
  const [filters, setFilters] = useState({
    dates: {
      min: format(new Date('2021-01-01'), 'yyyy-MM-dd'),
      max: format(new Date(), 'yyyy-MM-dd'),
    },
  });
  console.log('dateRange :', dateRange);
  function handleSwitchChange(checked: boolean) {
    if (checked === false) {
      dispatch(
        getPatients({
          filter_options: {
            ...patientsState.filtered_conditions,
            archived: true,
            page: 1,
            items_per_page: itemsPerPage,
          },
        })
      );
      setArchived(true);
      setPage(1);
    } else {
      dispatch(
        getPatients({
          filter_options: {
            ...patientsState.filtered_conditions,
            archived: false,
            page: 1,
            items_per_page: itemsPerPage,
          },
        })
      );
      setArchived(false);
      setPage(1);
    }
  }
  // const argument = { page: patientsState.currentPage, items_per_page: patientsState.itemsPerPage, orgId: profile?.currentOrganization?.id, filter_options: {}};
  // function fetchPatients(argument) {
  //   console.log('fetchPatients', argument);
  //   return Promise.resolve({ data: {'data':'1'}, isLoading: true, isError: false });
  // }
  // const { data, isLoading, isError } = useQuery({ queryKey: ['todos', argument], queryFn: fetchPatients });

  useEffect(() => {
    dispatch(
      getPatients({
        filter_options: {
          page: page,
          items_per_page: itemsPerPage,
          archived: archived,
        },
      })
    );
    return () => {
      dispatch(resetState());
    };
  }, [dispatch, profile?.currentOrganization?.id]);
  // const searchPatients = (search) => {
  //   // Regex pattern for YYYY-MM-DD or other valid date formats
  //     // General search logic with optional birthdate range filtering
  //     const {
  //       birth_date__gte,
  //       birth_date__lte,
  //       ...filteredConditions
  //     } = patientsState.filtered_conditions || {};

  //     // Construct filterOptions with existing conditions and search term
  //     const filterOptions = {
  //       ...filteredConditions,
  //       search,
  //       is_deleted: false,
  //       page: 1,
  //       items_per_page: itemsPerPage,
  //       ...(birth_date__gte && { birth_date__gte }),
  //       ...(birth_date__lte && { birth_date__lte }),
  //     };

  //     dispatch(getPatients({ filter_options: filterOptions }));

  //   setPage(1); // Reset to the first page for new search results
  // };

  const searchPatients = filters => {
    console.log('filters', filters);
    let filterOptions;
    const { ...filteredConditions } = patientsState.filtered_conditions || {};
    let filteredConditionsObject: FilteredConditions = {};

    if (filteredConditions?.filters) {
      // Check if it's a string and contains JSON
      if (typeof filteredConditions.filters === 'string') {
        try {
          filteredConditionsObject = JSON.parse(filteredConditions.filters);
        } catch (e) {
          console.error('Invalid JSON string:', e);
        }
      } else if (typeof filteredConditions.filters === 'object') {
        // If it's already an object, assign it directly
        filteredConditionsObject = filteredConditions.filters;
      }
    }

    const { condition__or, ...filteredDate } = filteredConditionsObject || {};
    const birthDateFilters = {};
    if (filteredDate.birth_date__gte) {
      birthDateFilters['birth_date__gte'] = filteredDate.birth_date__gte;
    }
    if (filteredDate.birth_date__lte) {
      birthDateFilters['birth_date__lte'] = filteredDate.birth_date__lte;
    }

    // Sanitize filters
    if (filters?.patient_name__ilike) {
      filters['patient_name__ilike'] = `%${filters?.patient_name__ilike}%`;
    } else if (filters?.patient_name__ilike === '') {
      delete filters['patient_name__ilike'];
    }

    if (filters?.patient_last_name__ilike) {
      filters['patient_last_name__ilike'] = `%${filters?.patient_last_name__ilike}%`;
    } else if (filters?.patient_last_name__ilike === '') {
      delete filters['patient_last_name__ilike'];
    }

    if (filters?.email__ilike) {
      filters['email__ilike'] = `%${filters?.email__ilike}%`;
    } else if (filters?.email__ilike === '') {
      delete filters['email__ilike'];
    }

    // Ensure filters are encoded properly
    const encodedFilters = {};
    Object.keys(filters).forEach(key => {
      encodedFilters[key] = encodeURIComponent(filters[key]);
    });

    // Construct filterOptions based on the operator ('or' or other)
    if (operator === 'or') {
      filterOptions = JSON.stringify({
        condition__or: {
          ...encodedFilters,
        },
        ...birthDateFilters,
      });
    } else {
      delete encodedFilters?.condition__or;
      filterOptions = JSON.stringify({
        ...encodedFilters,
        ...birthDateFilters,
      });
    }

    // Remove `operator` if no other filters are present in filterOptions
    const parsedFilterOptions = JSON.parse(filterOptions);
    const filterKeys = Object.keys(parsedFilterOptions).filter(key => key !== 'condition__or');
    if (filterKeys.length === 0) {
      delete parsedFilterOptions.operator;
    }
    filterOptions = JSON.stringify(parsedFilterOptions);

    // Prepare the final options with other default filters
    const finalOptions = {
      ...filteredConditions,
      filters: filterOptions,
      is_deleted: false,
      archived: archived,
      page: 1,
      items_per_page: itemsPerPage,
    };

    // Dispatch the action with the final options
    dispatch(getPatients({ filter_options: finalOptions }));
    setPage(1);
  };

  const formatDate = dateString => {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const searchPatientsByDate = dates => {
    if (dates?.reset === false && (dates?.startDate || dates?.endDate)) {
      const formattedStartDate = formatDate(dates.startDate);
      const formattedEndDate = formatDate(dates.endDate);

      const { birth_date__gte, birth_date__lte, filters, ...existingConditions } =
        patientsState.filtered_conditions || {};

      // Parse the filters string if it's present
      const parsedFilters =
        typeof filters === 'string' && filters ? JSON.parse(filters) : filters || {};
      parsedFilters['birth_date__lte'] = formattedStartDate;
      // Check if the existing filters have a 'condition__or' property
      if (formattedStartDate) {
        parsedFilters['birth_date__gte'] = formattedStartDate;
      } else {
        delete parsedFilters['birth_date__gte'];
      }
      if (formattedEndDate) {
        parsedFilters['birth_date__lte'] = formattedEndDate;
      } else {
        delete parsedFilters['birth_date__lte'];
      }

      // Construct filterOptions without stringifying at this step
      const filterOptions = encodeURIComponent(JSON.stringify(parsedFilters));

      // Stringify only filterOptions
      const stringifiedFilterOptions = filterOptions;
      // Assemble final options with stringified filterOptions
      const finalOptions = {
        filters: filterOptions,
        is_deleted: false,
        page: 1,
        items_per_page: itemsPerPage,
      };

      // Dispatch with the updated filter options
      debouncedSearchBackend(finalOptions);
    }
  };

  const resetPatientsByDate = dates => {
    if (dates?.reset == true) {
      const { birth_date__gte, birth_date__lte, ...filteredConditions } =
        patientsState.filtered_conditions as unknown as {
          birth_date__gte: string;
          birth_date__lte: string;
        };
      const filteredConditionsObject = JSON.parse(filteredConditions['filters']);
      const { condition__or, ...filteredDate } = filteredConditionsObject;

      if (filteredDate.birth_date__gte) {
        delete filteredConditionsObject.birth_date__gte;
      }
      if (filteredDate.birth_date__lte) {
        delete filteredConditionsObject.birth_date__lte;
      }
      filteredConditions['filters'] = JSON.stringify(filteredConditionsObject);
      dispatch(
        getPatients({
          filter_options: { ...filteredConditions, page: 1, items_per_page: itemsPerPage },
        })
      );
    }
  };

  const debouncedSearchBackend = useDebouncedCallback(searchPatients, 500, {
    maxWait: 2000,
    leading: true,
    trailing: false,
  });
  useEffect(() => {
    if (search.length > 0) {
      debouncedSearchBackend(search);
    } else {
      const { birth_date__gte, birth_date__lte, filters, ...filteredConditions } =
        patientsState.filtered_conditions || {};

      // Construct filterOptions based on the presence of the `operator`
      let filterOptions;
      if (operator === 'or') {
        // Default filters to an empty object if it's undefined or null
        const safeFilters = filters || {};

        // Extract the specific keys outside of the condition__or object
        let parsedFilters = safeFilters;

        // If safeFilters is a string, parse it to an object
        if (typeof safeFilters === 'string') {
          parsedFilters = JSON.parse(safeFilters);
        }
        const { birth_date__lte, birth_date__gte, ...otherFilters } = parsedFilters;
        // Now create the filter options, adding birth_date__lte and birth_date__gte separately
        filterOptions = JSON.stringify({
          condition__or: {
            ...otherFilters,
          },
          birth_date__lte, // outside of condition__or
          birth_date__gte, // outside of condition__or
        });
        filterOptions = encodeURIComponent(filterOptions);
      } else {
        const filterspars = JSON.parse(filters);
        if (filterspars['condition__or']) {
          const conditionOrValue = filterspars['condition__or'];
          delete filterspars['condition__or'];
          Object.assign(filterspars, conditionOrValue);
          filterOptions = encodeURIComponent(JSON.stringify(filterspars));
        }
      }
      // Prepare the final options with pagination and other default filters
      const finalOptions = {
        filters: filterOptions,
        is_deleted: false,
        archived: archived,
        page: 1,
        items_per_page: itemsPerPage,
      };

      dispatch(getPatients({ filter_options: finalOptions }));
      setPage(1);
    }

    return () => {
      debouncedSearchBackend.cancel();
    };
  }, [operator, debouncedSearchBackend]);

  const deletePatientById = async (id: number) => {
    const newPage = patientsState?.patients.length === 1 && page > 1 ? page - 1 : page;
    dispatch(
      deletePatient({
        id: id,
        filter_options: {
          ...patientsState.filtered_conditions,
          page: newPage,
          items_per_page: itemsPerPage,
        },
      })
    );
    setPage(newPage);
  };
  const openModal = (patient: Patient) => {
    setIsEditing(false);
    setSelectedPatient(patient);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedPatient(null);
    setIsModalOpen(false);
  };

  const [newPatientModalOpen, setNewPatientModalOpen] = useState(false);

  const openNewPatientModal = () => {
    setIsEditing(false);
    setNewPatientModalOpen(true);
  };
  const openNewPatientModalEdit = (patient: Patient) => {
    setIsEditing(true);
    setNewPatient(patient);
    setNewPatientModalOpen(true);
  };
  const closeNewPatientModal = () => {
    setIsEditing(false);
    initialiseNewPatient();
    setNewPatientModalOpen(false);
    initialiseNewPatient();
  };

  return (
    <div className="mx-auto h-full w-full p-8">
      <ToastContainer
        position="top-right"
        autoClose={5000}
      />
      {newPatientModalOpen && (
        <PatientModal
          Patient={newPatient}
          setNewPatient={setNewPatient}
          closeNewPatientModal={closeNewPatientModal}
          edit={isEditing}
          setPatientEdit={undefined}
          page={page}
          itemsPerPage={itemsPerPage}
          isOpen={newPatientModalOpen}
          setNewPatientModalOpen={setNewPatientModalOpen}
        />
      )}
      <div className="flex w-full justify-between">
        <h1 className="h-[5%] text-[18px] font-medium text-[#1F384C]">Patients Records</h1>
        {/* <ToggleSwitch setOperator={setOperator}/> */}
      </div>

      <div className="h-[95%]">
        <div className="flex h-[60px] w-full items-center justify-between bg-white py-4 px-2">
          <div className="flex w-full">
            {/* <button className="flex h-8 items-center justify-center rounded border border-[#91B9ED] bg-[#ffffff]  px-4 py-2 text-[24px] font-bold text-white" onClick={openNewPatientModal}>
              <div className="flex items-center justify-center space-x-2">
                <img src="/icons/filter.svg" className="h-6" />
                <span className="text-[16px] text-[#25213B] font-medium">Filter</span>
              </div>
            </button> */}

            {/* <div className="ml-4 mr-4 flex h-8 items-center justify-center rounded bg-[#EEF5FF]">
              <img src="/icons/search.svg" className="ml-2 mr-8 h-4 w-4" />
              <input type='text' className="w-[380px] border border-transparent bg-transparent text-[#2B5170] placeholder-[#2B5170]" placeholder="Search Patients Records by Name or Date" onChange={(e) => setSearch(e.target.value)} />
            </div> */}
            <FilterForm
              onSearch={searchPatients}
              operator={operator}
              setOperator={setOperator}
            />
            <div className="mr-4 flex items-center">
              <DateRangePicker
                locale={rdrLocales.fr}
                range={dateRange}
                currentFixed={dateFilter}
                type="range"
                color="#f85a32"
                onChange={dates => {
                  setDateRange(dates);
                  searchPatientsByDate(dates);
                }}
                onReset={dates => {
                  resetPatientsByDate(dates);
                }}
                isDisabled={false}
                isLoading={false}
                inputRanges={[]}
                showDateDisplay={true}
                showMonthAndYearPickers={true}
                // maxDate={new Date()}
              />
            </div>
          </div>
          <div className="mr-3 flex items-center justify-center">
            <div className=" inline-flex items-center justify-center rounded-md bg-gray-200 text-sm font-semibold">
              <div
                className={`${!archived ? 'bg-[#005DD4] text-white shadow-inner' : ''} w-full cursor-pointer whitespace-nowrap rounded-md py-1.5 px-4 text-center`}
                onClick={() => {
                  handleSwitchChange(archived);
                }}
              >
                Active
              </div>
              <div
                className={`${!archived ? '' : 'bg-[#005DD4] text-white'} w-full cursor-pointer whitespace-nowrap rounded-md py-1.5 px-4 text-center`}
                onClick={() => {
                  handleSwitchChange(archived);
                }}
              >
                Achived
              </div>
            </div>
          </div>
          <div className="flex">
            {/* <button className="flex h-8 w-48 items-center justify-center rounded bg-[#005DD4] px-4 py-1 text-[16px] font-semibold text-white hover:bg-[#015DDD]" onClick={openNewPatientModal}>
              ADD PATIENT{' '}
              <span className="ml-2 flex h-full items-center justify-center pb-1 text-[20px]">+</span>
            </button> */}
            <button
              onClick={openNewPatientModal}
              className="flex w-48 items-center justify-center space-x-2 rounded border border-[#005DD4] px-2 py-1 font-semibold text-[#005DD4]  hover:bg-[#015DDD] hover:text-white"
            >
              <IoMdAddCircleOutline size={15} />
              <div>Add new patient</div>
            </button>
          </div>
        </div>
        <div className="h-[calc(100%-60px)] w-full overflow-auto ">
          {error && <p>{error}</p>}
          <div className="h-full">
            <PatientTable
              patientsState={patientsState}
              openNewPatientModalEdit={openNewPatientModalEdit}
              deletePatientById={deletePatientById}
              openModal={openModal}
              page={page}
              itemsPerPage={itemsPerPage}
              setPage={setPage}
              setItemsPerPage={setItemsPerPage}
              archived={archived}
            />
            {patientsState?.patients.length === 0 && !patientsState?.loading && (
              // <div className="w-full inset-20 border shadow-[rgba(0,0,0,0.5)_0px_5px_14px_1px] rounded-lg h-44 flex flex-col items-center justify-center text-xl text-gray-700">
              //   <p>No patients found</p>
              // </div>
              <NoDataFound title="No Patients Found" />
            )}
          </div>
          {patientsState?.loading === true && patientsState?.patients.length === 0 && (
            <LoaderPage />
          )}
          {selectedPatient && (
            <SelectedPatientInfo
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              selectedPatient={selectedPatient}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientsListCustom;
