import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import Draggable from 'react-draggable';
import { useGlobalState } from '../../../../state/RealtimeGlobalContextProvider';

const ButtonWithCursorMovement = ({ visible, begin, setBegin, deviceId }) => {
  const dragRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { globalState } = useGlobalState();

  useLayoutEffect(() => {
    const setStartPosition = () => {
      if (dragRef.current && dragRef.current.parentNode) {
        const parent = dragRef.current.parentNode;
        const x = (parent.offsetWidth - dragRef.current.offsetWidth) / 2;
        const y = parent.offsetHeight - dragRef.current.offsetHeight;
        setPosition({ x, y });
      }
    };

    setStartPosition();
    window.addEventListener('resize', setStartPosition);
    return () => window.removeEventListener('resize', setStartPosition);
  }, []);

  const eventControl = (event, info) => {
    // console.log(event.type);
    if (event.type === 'mousemove' || event.type === 'touchmove') {
      setIsDragging(true);
      setPosition({ x: info.x, y: info.y });
    }

    if (event.type === 'mouseup' || event.type === 'touchend') {
      setTimeout(() => {
        setIsDragging(false);
      }, 25);
    }
  };

  return (
    <>
      {/* <Draggable
        // nodeRef={dragRef}
        bounds="parent"
        onDrag={(e, data) => eventControl(e, data)}
        onStop={(e, data) => eventControl(e, data)}
        position={position}
      > */}
      <button
        // ref={dragRef}
        className={`${
          deviceId ? 'visible' : 'invisible'
        } z-20 cursor-pointer ${globalState.openPanel ? 'w-[95%]' : 'w-[94%]'} aligh-center rounded-[50%]} absolute flex h-[calc(100%-2%)] justify-center  text-white ${!begin ? '' : 'bg-black/30 backdrop-blur-sm'}`}
        onClick={() => {
          if (!isDragging && !visible) {
            setBegin(!begin);
          }
        }}
      ></button>
      {/* </Draggable> */}
    </>
  );
};

export default ButtonWithCursorMovement;
