import React from 'react';
import { FaFolder } from 'react-icons/fa';
import { PiFilesFill } from 'react-icons/pi';

interface NoDataSelectedProps {
  isDragOver: any;
  handleDragOver: any;
  handleDragLeave: any;
  handleDrop: any;
  handleFileSelect: any;
  uploadType: any;
  renderUploadInput: any;
}
function NoDataSelected({
  isDragOver,
  handleDragOver,
  handleDragLeave,
  handleDrop,
  handleFileSelect,
  uploadType,
  renderUploadInput,
}: NoDataSelectedProps) {
  return (
    <div
      className={`flex h-[420px] w-full cursor-pointer items-center justify-center rounded-md border-2 border-dashed border-gray-400 p-4 ${isDragOver ? 'bg-gray-100' : ''}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleFileSelect}
    >
      {renderUploadInput()}
      <div className="flex w-full flex-col items-center justify-center text-center text-[#005dd4] hover:text-blue-600">
        <div className="flex w-full items-center justify-center">
          {uploadType == 'import files' ? <PiFilesFill size={40} /> : <FaFolder size={40} />}
        </div>
        <p className="mt-1 w-full text-sm">
          {isDragOver
            ? 'Drop your files here'
            : uploadType == 'import files'
              ? 'Drag and drop your files here or click to select files'
              : 'Drag and drop your Folder here or click to select folder'}
        </p>
      </div>
    </div>
  );
}

export default NoDataSelected;
