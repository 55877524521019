import {
  GET_PATIENTS,
  ADD_PATIENT,
  API_ERROR,
  API_SUCCESS,
  UPDATE_PATIENT,
  DELETE_PATIENT,
  RESET_STATE,
  ARCHIVE_PATIENT,
  UNARCHIVE_PATIENT,
} from './actionType';

export const getPatients = data => {
  return {
    type: GET_PATIENTS,
    payload: { data },
  };
};
export const addPatient = data => {
  return {
    type: ADD_PATIENT,
    payload: { data },
  };
};

export const updatePatient = data => {
  return {
    type: UPDATE_PATIENT,
    payload: { data },
  };
};
export const deletePatient = data => {
  return {
    type: DELETE_PATIENT,
    payload: { data },
  };
};

export const patientApiSuccess = (actionType, data) => {
  return {
    type: API_SUCCESS,
    payload: { actionType, data },
  };
};

export const patientApiError = (actionType, error) => {
  return {
    type: API_ERROR,
    payload: { actionType, error },
  };
};

export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
export const archivePatient = payload => {
  return {
    type: ARCHIVE_PATIENT,
    payload,
  };
};
export const unarchivePatient = payload => {
  return {
    type: UNARCHIVE_PATIENT,
    payload,
  };
};
