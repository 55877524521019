import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { BsSave2 } from 'react-icons/bs';
import { FaDownload } from 'react-icons/fa6';
import { IoMdAdd } from 'react-icons/io';
import { LuClipboardEdit } from 'react-icons/lu';
import { RxReset } from 'react-icons/rx';

const ActionsBar: React.FC = props => {
  const {
    saveReport,
    resetReportObjValues,
    setExamData,
    examData,
    setResetActive,
    resetActive,
    downloadPDF,
    editMode,
    handleSwitchChange,
    finishMode,
    ohifViewer,
    finished,
    loadingFinished,
  } = props;

  return (
    <div
      className={`mb-4 mt-4 flex items-center ${ohifViewer === false ? 'justify-end' : 'justify-between'} w-full px-4 py-2`}
      style={{ height: '2%' }}
    >
      {ohifViewer && (
        <div className="inline-flex space-x-3">
          {!finished && (
            <Button
              onClick={saveReport}
              className="inline-flex cursor-pointer items-center justify-center space-x-2
           rounded-md border border-[#2d84c7] bg-white px-2 py-1 text-[#2d84c7] hover:bg-[#2d84c7] hover:text-white"
            >
              {loadingFinished ? (
                <div className="h-5 w-5 animate-spin rounded-full border-t-2 border-b-2 border-[#2d84c7]" />
              ) : (
                <IoMdAdd size={15} />
              )}
              <div>Finish Report</div>
            </Button>
          )}

          {/* {editMode ? ( */}
          {false ? (
            <Button
              className="inline-flex cursor-pointer items-center justify-center space-x-2
            rounded-md border border-[#2d84c7] bg-white px-2 py-1 text-[#2d84c7] hover:bg-[#2d84c7] hover:text-white"
              onClick={handleSwitchChange}
              disabled
            >
              <BsSave2 size={15} />
              <div>Save changes</div>
            </Button>
          ) : (
            <Button
              disabled
              className="inline-flex cursor-pointer items-center justify-center space-x-2
            rounded-md border border-[#2d84c7] bg-white px-2 py-1 text-[#2d84c7] hover:bg-[#2d84c7] hover:text-white"
              onClick={handleSwitchChange}
            >
              <LuClipboardEdit size={15} />
              <div>Edit</div>
            </Button>
          )}

          <Button
            // disabled={!resetActive || editMode}
            disabled
            className={`${(editMode || !resetActive) && 'cursor-not-allowed'} inline-flex cursor-pointer items-center justify-center space-x-2
          rounded-md border border-[#2d84c7] bg-white px-2 py-1 text-[#2d84c7] hover:bg-[#2d84c7] hover:text-white`}
            onClick={() => resetReportObjValues(setExamData, examData, setResetActive)}
          >
            <RxReset size={15} />
            <div>Reset</div>
          </Button>
        </div>
      )}
      <div>
        <Button
          onClick={downloadPDF}
          className="inline-flex cursor-pointer items-center justify-center space-x-2
          rounded-md border border-[#2d84c7] bg-white px-2 py-1 text-[#2d84c7] hover:bg-[#2d84c7] hover:text-white"
        >
          {' '}
          <FaDownload size={14} />
          <div>DownloadPDF</div>
        </Button>
      </div>
    </div>
  );
};

export default ActionsBar;
