import React from 'react';
import { Tooltip } from 'antd';
import { FaInfoCircle } from 'react-icons/fa'; // Example icon from React Icons

function TooltipDeepecho({ text, iconSize = 'small' }) {
  const sizeMap = {
    small: 16,
    medium: 20,
    large: 24,
  };

  const size = sizeMap[iconSize] || sizeMap.small;

  return (
    <Tooltip
      title={text}
      color="gray"
      overlayInnerStyle={{ color: 'white' }}
    >
      <FaInfoCircle
        size={size}
        className="cursor-pointer text-[#a1a1a1]"
      />
    </Tooltip>
  );
}

export default TooltipDeepecho;
