import React from 'react';
import femurImgS from '../../../../assets/femur.png';
import abdoImgS from '../../../../../../assets/abdoicon.png';
import afImgS from '../../../../assets/AF-pocket.png';
import faceImgS from '../../../../assets/brain.png';
// Import other images for the remaining plans here...

function DynamicPlanList({ globalState, setPlanSelected, setIsOpen, frames }) {
  const imageMap = {
    Cephalic: faceImgS,
    Femur: femurImgS,
    Abdominal: abdoImgS,
    'AF Pocket': afImgS,
    // 'Plan A': faceImgS,
    // 'Plan B': femurImgS,
    // 'Plan C': abdoImgS,
    // 'Plan D': afImgS,
    // 'Plan E': faceImgS,
    // 'Plan F': abdoImgS,
    // 'Plan G': femurImgS,
    // 'Plan H': afImgS,
    // 'Plan I': faceImgS,
    // 'Plan J': femurImgS,
    // 'Plan K': abdoImgS,
    // 'Plan L': afImgS,
    // 'Plan M': faceImgS,
    // 'Plan N': femurImgS,
    // 'Plan O': abdoImgS,
    // 'Plan P': afImgS,
    // 'Plan Q': faceImgS,
    // 'Plan R': femurImgS,
  };

  const plans = Object.keys(imageMap); // Use the keys from imageMap as plan names

  return (
    <div className="custom-scrollbar mt-6 flex max-h-64 flex-col items-center space-y-2 overflow-y-auto">
      {plans.map(planKey => (
        <div
          key={planKey}
          onClick={() => {
            if (frames?.length > 0) {
              const selectedPlanKey = planKey === 'AF Pocket' ? 'af_pocket' : planKey.toLowerCase();
              setPlanSelected(selectedPlanKey);
              setIsOpen(true);
            }
          }}
          className="flex flex-col items-center"
        >
          <div
            className={`${
              planKey === 'AF Pocket'
                ? globalState?.planes?.af_pocket?.score > 0
                  ? 'bg-[#32CD32] bg-opacity-60'
                  : 'bg-gray-400'
                : globalState?.planes?.[planKey.toLowerCase()]?.scoreOrigin > 0
                  ? 'bg-[#32CD32] bg-opacity-60'
                  : 'bg-gray-400'
            } rounded-full p-1`}
          >
            <img
              src={imageMap[planKey]}
              className="h-6 w-6"
              alt={planKey}
            />
          </div>
          <span className="mt-1 text-xs capitalize">{planKey}</span>
        </div>
      ))}
    </div>
  );
}

export default DynamicPlanList;
