import React from 'react';
import backgroundImage from '../assets/background.png';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { Link } from 'react-router-dom';

import { useAuth } from 'react-oidc-context';

const DefaultLayout = ({ children }) => {
  const auth = useAuth();

  const handleLogout = async () => {
    await auth.signoutRedirect();
  };

  return (
    <div className="flex min-h-screen">
      <div
        className="hidden w-1/2 bg-cover bg-center md:inline"
        style={{ backgroundImage: `url('${backgroundImage}')` }}
      ></div>

      <div className="w-full pt-4 md:w-1/2">
        <div className="-px-40 absolute inset-0 mx-40 bg-gradient-to-l from-white via-white to-transparent" />
        <div className="relative z-10 flex h-full items-center justify-center">
          <div className="w-full">{children}</div>
        </div>
      </div>

      <div className="fixed top-0 left-0 right-0 z-20 flex items-center justify-between bg-transparent p-4">
        <div className="mr-6">
          <Logo height={60} />
        </div>

        <div className="flex items-center space-x-8">
          <a
            href="#"
            className="text-black-700"
          >
            About
          </a>
          <a
            href="#"
            className="text-black-700"
          >
            Solution
          </a>
          <a
            href="#"
            className="text-black-700"
          >
            Impact
          </a>
          {!auth?.isAuthenticated ? (
            <>
              <Link
                to="/home"
                className="text-black-700"
              >
                SignIn
              </Link>
            </>
          ) : (
            <>
              <Link
                to="/home"
                className="text-black-700"
              >
                Workspace
              </Link>
              <div
                onClick={handleLogout}
                className="text-black-700 cursor-pointer rounded-md border py-1 px-2 transition duration-150 ease-in-out hover:bg-black hover:text-white"
              >
                Logout
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
