import React, { useEffect, useRef, useState } from 'react';
import ButtonWithCursorMovement from '../invisible-button-click/button-with-cursor-movement';
import { ImShrink2 } from 'react-icons/im';
import AlertView from '../alert-view';

const VideoModal = ({
  isOpen,
  onClose,
  videoStream,
  canvasDimensions,
  canvasRef,
  globalState,
  // toggleVideoFreeze,
  isChecked,
  handleChange,
  imgPlans,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const modalVideoRef = useRef(null);
  console.log('videoStream', videoStream);
  useEffect(() => {
    if (modalVideoRef.current && videoStream) {
      modalVideoRef.current.srcObject = videoStream; // Set the stream to modal's video element
    }
  }, [isOpen]); // Re-run this effect if the videoStream changes

  if (!isOpen) {
    return null;
  } // Don't render the modal if it's not open
  return (
    <div
      className="absolute inset-0 z-40 flex h-full items-center justify-center bg-white/30 backdrop-blur-sm" // Changed to bg-opacity for better visibility
      onClick={onClose}
    >
      <div
        className="relative flex h-full w-full items-center justify-center rounded-lg" // Add background gradient
        onClick={onClose}
      >
        <div
          className="absolute top-0 z-50 w-full bg-white/30 backdrop-blur-sm"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex justify-between px-6 xl:py-2">
            <div className="w-full"></div>
            <div className="flex w-full flex-col items-center justify-center space-y-1">
              <div className="font-medium text-[#A6ABC8]">Followed BY</div>
              <div className="font-medium text-[#1F384C]">Dr, Saad Slimani</div>
            </div>
            <div className="flex w-full items-center justify-end">
              <label className="switch">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={isChecked}
                  onChange={handleChange}
                />
                <div className="slider"></div>
              </label>
            </div>
          </div>
        </div>
        <div
          className="relative h-fit w-fit"
          onClick={e => e.stopPropagation()}
        >
          <button
            className={`${
              globalState?.deviceId ? 'visible' : 'invisible'
            } rounded-[50%]} absolute z-50 flex h-full w-full cursor-pointer items-center justify-center  text-white ${!globalState.isVideoFrozen ? '' : 'bg-black/30 backdrop-blur-sm'}`}
            onClick={() => {
              if (!isDragging) {
                toggleVideoFreeze(!globalState.isVideoFrozen);
              }
            }}
          ></button>
          <video
            ref={localVideoRef}
            autoPlay
            playsInline
            width={854} // Match your desired dimensions
            height={480}
            className={`${!isInferencing ? 'visible' : 'hidden'} max-h-[80vh] w-auto rounded-lg`}
            style={{ objectFit: 'contain' }}
          />
          {/* Inferencing video feed */}
          <video
            ref={videoRef}
            autoPlay
            playsInline
            width={854} // Match your desired dimensions
            height={480}
            className={`${isInferencing ? 'visible' : 'hidden'} max-h-[80vh] w-auto rounded-lg`}
            style={{ objectFit: 'contain' }}
          />
          {/* <canvas
            ref={canvasRef}
            width={canvasDimensions.width}
            height={canvasDimensions.height}
            className="hidden max-h-full max-w-full"
          />
          <div
            className="absolute bottom-3 right-3 z-50 cursor-pointer text-white"
            onClick={onClose}
          >
            <ImShrink2 size={18} />
          </div>
          {/* <div className="absolute  z-50 bottom-3 left-3">
            <img
              src={imgPlans}
              className="h-full w-full rounded-2xl border-2 border-[#a6abc8]"
            />
            <span className="absolute top-2 left-2 font-semibold text-white">
              Four Chanbre View Of The Heart
            </span>
          </div> */}
          <AlertView imgPlans={imgPlans} />
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
