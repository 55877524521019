import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  GET_PATIENTS,
  ADD_PATIENT,
  UPDATE_PATIENT,
  DELETE_PATIENT,
  RESET_STATE,
  ARCHIVE_PATIENT,
  UNARCHIVE_PATIENT,
} from './actionType';
import { patientApiSuccess, patientApiError } from './action';

import {
  archivePatientById,
  deleteExistingPatient,
  getAllPatients,
  postNewPatient,
  unarchivePatientById,
  updateExistingPatient,
} from '../../helpers/backendHelper';
import { filter } from 'lodash';
function* getPatients({ payload: { data } }) {
  try {
    const response = yield call(getAllPatients, data.filter_options);
    yield put(patientApiSuccess(GET_PATIENTS, response));
  } catch (error) {
    yield put(patientApiError(GET_PATIENTS, error));
  }
}

function* addPatient({ payload: { data } }) {
  try {
    const response = yield call(postNewPatient, data.Patient);
    const responsecalls = yield call(getAllPatients, data.orgId);

    yield put(patientApiSuccess(ADD_PATIENT, responsecalls));
  } catch (error) {
    yield put(patientApiError(ADD_PATIENT, error));
  }
}

function* updatePatient({ payload: { data } }) {
  try {
    const response = yield call(updateExistingPatient, data.Patient);
    const responsecalls = yield call(getAllPatients, data.orgId);
    yield put(patientApiSuccess(UPDATE_PATIENT, responsecalls));
  } catch (error) {
    yield put(patientApiError(UPDATE_PATIENT, error));
  }
}
function* archivePatient({ payload }) {
  try {
    // use forEach instead of for loop
    for (let i = 0; i < payload.items.length; i++) {
      if (payload.items[i].isChecked === true) {
        const response = yield call(archivePatientById, payload.items[i].patient_id);
      }
    }
    const response = yield call(getAllPatients, payload.filter_options);
    try {
      yield put(patientApiSuccess(GET_PATIENTS, response));
    } catch (error) {
      yield put(patientApiError(GET_PATIENTS, error));
    }
  } catch (error) {
    yield put(patientApiError(ARCHIVE_PATIENT, error));
  }
}
function* unarchivePatient({ payload }) {
  console.log(payload);
  try {
    for (let i = 0; i < payload.items.length; i++) {
      if (payload.items[i].isChecked === true) {
        const response = yield call(unarchivePatientById, payload.items[i].patient_id);
      }
    }
    const response = yield call(getAllPatients, payload.filter_options);
    try {
      yield put(patientApiSuccess(GET_PATIENTS, response));
    } catch (error) {
      yield put(patientApiError(GET_PATIENTS, error));
    }
  } catch (error) {
    yield put(patientApiError(UNARCHIVE_PATIENT, error));
  }
}
function* deletePatient({ payload }) {
  try {
    const response = yield call(deleteExistingPatient, payload.data.id);
    const responsecalls = yield call(getAllPatients, payload.data.filter_options);

    yield put(patientApiSuccess(DELETE_PATIENT, responsecalls));
  } catch (error) {
    yield put(patientApiError(DELETE_PATIENT, error));
  }
}

function* resetState() {
  try {
    yield put(patientApiSuccess(RESET_STATE, {}));
  } catch (error) {
    yield put(patientApiError(error));
  }
}

function* watchPatients() {
  yield takeEvery(GET_PATIENTS, getPatients);
  yield takeEvery(ADD_PATIENT, addPatient);
  yield takeEvery(UPDATE_PATIENT, updatePatient);
  yield takeEvery(DELETE_PATIENT, deletePatient);
  yield takeEvery(RESET_STATE, resetState);
  yield takeEvery(ARCHIVE_PATIENT, archivePatient);
  yield takeEvery(UNARCHIVE_PATIENT, unarchivePatient);
}

function* PatientSaga() {
  yield all([fork(watchPatients)]);
}

export default PatientSaga;
