import React, { Fragment, useState, useEffect, forwardRef } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { DateRangePicker as ReactDateRangePicker } from 'react-date-range';
import { RiCalendar2Line } from 'react-icons/ri';
import formatDate, { formatDateNoYear } from '../../lib/date-formaters-filter';
import { usePopper } from 'react-popper';
import getYear from 'date-fns/getYear';
import { defaultStaticRanges, defaultInputRanges } from 'react-date-range/dist/defaultRanges';
import {
  sub,
  isToday,
  isYesterday,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addDays,
  addMonths,
  isSameDay,
  subDays,
} from 'date-fns';
import { Button } from '../../Components/atoms/button';
import FilterButton from '../../Components/filter-button';
import * as rdrLocales from 'react-date-range/dist/locale';

type RangeObjectType = {
  startDate: Date;
  endDate: Date;
};

function getCurrentPickerButtonValue(type, range) {
  let valueText = '';
  if (type === 'range') {
    const startDate = range?.startDate;
    const endDate = range?.endDate;

    if (startDate && endDate) {
      if (getYear(startDate) === getYear(endDate)) {
        if (
          isSameDay(startOfMonth(new Date()), startDate) &&
          isSameDay(endOfMonth(new Date()), endDate)
        ) {
          valueText = `Ce mois`;
        } else if (
          isSameDay(startOfMonth(addMonths(new Date(), -1)), startDate) &&
          isSameDay(endOfMonth(addMonths(new Date(), -1)), endDate)
        ) {
          valueText = `Dernier mois`;
        } else if (
          isSameDay(startOfWeek(new Date()), startDate) &&
          isSameDay(endOfWeek(new Date()), endDate)
        ) {
          valueText = `Cette semaine`;
        } else if (
          isSameDay(startOfWeek(addDays(new Date(), -7)), startDate) &&
          isSameDay(endOfWeek(addDays(new Date(), -7)), endDate)
        ) {
          valueText = `Semaine passée`;
        } else if (isToday(startDate)) {
          valueText = `Aujourd'hui`;
        } else if (isYesterday(startDate)) {
          valueText = `Hier`;
        } else {
          valueText = `${formatDateNoYear(startDate)} - ${formatDate(endDate)}`;
        }
      } else {
        valueText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
      }
    } else if (startDate) {
      valueText = `from ${formatDate(startDate)}`;
    } else if (endDate) {
      valueText = `Up to ${formatDate(endDate)}`;
    }
  }
  return valueText || 'Select a date range';
}

const staticRangesLabels = {
  Today: 'Today',
  Yesterday: 'Yesterday',
  'This Week': 'This Week',
  'Last Week': 'Last Week',
  'This Month': 'This Month',
  'Last Month': 'Last Month',
  'After Day': 'After Day',
  'Before Day': 'Before Day',
};

const inputRangesLabels = {
  'days up to today': 'days up to today',
  'days starting today': 'days starting today',
};

function translateRange(dictionary) {
  return item => (dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item);
}
const customStaticRanges = selectedDate => {
  const dateToUse = selectedDate?.endDate || selectedDate?.startDate || new Date();
  return [
    {
      label: 'After Day',
      range: () => ({
        startDate: addDays(dateToUse, 0),
        endDate: null,
      }),
      isSelected: range => {
        const { startDate, endDate } = range;
        const afterDay = addDays(dateToUse, 0);
        return isSameDay(startDate, afterDay) && !endDate;
      },
    },
    {
      label: 'Before Day',
      range: () => ({
        startDate: null,
        endDate: subDays(dateToUse, 0),
      }),
      isSelected: range => {
        const { startDate, endDate } = range;
        const beforeDay = subDays(dateToUse, 0);
        return !startDate && isSameDay(endDate, beforeDay);
      },
    },
  ];
};

export default function DateRangePicker({
  locale = rdrLocales.enUS,
  type = 'range',
  range = {
    startDate: sub(new Date(), { weeks: 1 }),
    endDate: new Date(),
  },
  currentFixed = [],
  onChange = () => {},
  onCancel = () => {},
  onReset = () => {},
  DatePickerComponent = null,
  isDisabled = false,
  isLoading = false,
  ...otherProps
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const [selectedStartDate, setSelectedStartDate] = useState(range);

  const frStaticRanges = [
    ...defaultStaticRanges.map(translateRange(staticRangesLabels)),
    ...customStaticRanges(selectedStartDate).map(translateRange(staticRangesLabels)),
  ];

  return (
    <Popover className="relative w-60">
      {({ close }) => (
        <>
          <Popover.Button
            as={'div'}
            ref={setReferenceElement}
          >
            {DatePickerComponent ? (
              <div ref={setReferenceElement}>
                <DatePickerComponent
                  date={getCurrentPickerButtonValue(type, range)}
                  locale={locale}
                />
              </div>
            ) : (
              <FilterButton
                ref={setReferenceElement}
                filterName="Date"
                isActive={true}
                isDisabled={isDisabled}
                isLoading={isLoading}
                value={getCurrentPickerButtonValue(type, range)}
                Icon={
                  <RiCalendar2Line
                    size={18}
                    className="flex-shrink-0"
                  />
                }
              />
            )}
          </Popover.Button>
          <Popover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="z-[10002]"
          >
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              {type === 'range' && (
                <RangePicker
                  onChange={onChange}
                  onCancel={onCancel}
                  onReset={onReset}
                  closePopover={close}
                  range={range}
                  currentFixed={currentFixed}
                  locale={locale}
                  selectedStartDate={selectedStartDate}
                  setSelectedStartDate={setSelectedStartDate}
                  frStaticRanges={frStaticRanges}
                  {...otherProps}
                />
              )}
            </Transition>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}

const RangePicker = forwardRef<HTMLDivElement, any>(
  (
    {
      closePopover,
      onChange,
      onCancel,
      onReset,
      range,
      currentFixed,
      locale,
      selectedStartDate,
      setSelectedStartDate,
      frStaticRanges,
      ...otherProps
    },
    ref
  ) => {
    const [localDateRange, setLocalDateRange] = useState(range);

    useEffect(() => {
      setLocalDateRange(range);
    }, [range]);

    return (
      <div
        ref={ref}
        className="flex flex-col overflow-hidden rounded-md border bg-white shadow-xl"
      >
        <ReactDateRangePicker
          onChange={data => {
            setLocalDateRange(data.selection);
            setSelectedStartDate(data?.selection);
          }}
          locale={locale}
          ranges={[{ ...localDateRange, locale: locale, key: 'selection' }]}
          staticRanges={frStaticRanges}
          inputRanges={defaultInputRanges.map(translateRange(inputRangesLabels))}
          {...otherProps}
        />
        <div className="bg-system-gray-50 flex justify-end space-x-2 border-t px-1 py-1.5">
          <Button
            size="small"
            style="ghost"
            onClick={e => {
              e?.preventDefault();
              const today = new Date();
              const todayRange = { startDate: today, endDate: today };
              setLocalDateRange(todayRange);
              setSelectedStartDate(todayRange);
              onReset({ ...todayRange, reset: true });
              onChange({ ...todayRange, reset: true });
              closePopover();
            }}
          >
            Reset
          </Button>
          <Popover.Button
            as={Button}
            size="small"
            intent="dark"
            // style="ghost"
            onClick={onCancel}
          >
            Annuler
          </Popover.Button>
          <Button
            size="small"
            intent="primary"
            style="ghost"
            onClick={e => {
              e?.preventDefault();
              onChange({
                startDate: localDateRange.startDate,
                endDate: localDateRange.endDate,
                reset: false,
              });
              closePopover();
            }}
          >
            Valider la sélection
          </Button>
        </div>
      </div>
    );
  }
);
