import { toast } from "react-toastify";

const toastConfig = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const pushNotification = {
  success: (msg, config = {}) => {
    toast.success(msg, { ...toastConfig, ...config });
  },
  error: (msg, config = {}) => {
    toast.error(msg, { ...toastConfig, ...config });
  },
  info: (msg, config = {}) => {
    toast.info(msg, { ...toastConfig, ...config });
  },
  warning: (msg, config = {}) => {
    toast.warning(msg, { ...toastConfig, ...config });
  },
};

export default pushNotification;
