import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';

interface AreaChartProps {
  series: {
    name: string;
    data: number[];
    color: string;
  }[];
  categories: any[];
  lineColor: string;
}

const AreaChart: React.FC<AreaChartProps> = ({ series, categories, lineColor }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current && typeof ApexCharts !== 'undefined') {
      const options = {
        chart: {
          height: '100%',
          maxWidth: '100%',
          type: 'area',
          fontFamily: 'Inter, sans-serif',
          dropShadow: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          enabled: true,
          x: {
            show: false,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.55,
            opacityTo: 0,
            shade: lineColor,
            gradientToColors: [lineColor],
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          colors: [lineColor],
        },
        grid: {
          show: false,
          strokeDashArray: 4,
          padding: {
            left: 2,
            right: 2,
            top: 0,
          },
        },
        series,
        xaxis: {
          categories,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
      };

      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [series, categories]);

  return <div ref={chartRef} />;
};

export default AreaChart;
