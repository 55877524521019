export const AC = {
  14: {
    '3rd': 72.9,
    '5th': 73.8,
    '10th': 75.3,
    '50th': 80.6,
    '90th': 85.9,
    '95th': 87.4,
    '97th': 88.4,
  },
  15: {
    '3rd': 82.9,
    '5th': 84.1,
    '10th': 85.8,
    '50th': 91.9,
    '90th': 98.1,
    '95th': 99.8,
    '97th': 100.9,
  },
  16: {
    '3rd': 93.0,
    '5th': 94.3,
    '10th': 96.3,
    '50th': 103.2,
    '90th': 110.1,
    '95th': 112.1,
    '97th': 113.4,
  },
  17: {
    '3rd': 103.1,
    '5th': 104.5,
    '10th': 106.7,
    '50th': 114.4,
    '90th': 122.1,
    '95th': 124.3,
    '97th': 125.7,
  },
  18: {
    '3rd': 113.2,
    '5th': 114.8,
    '10th': 117.2,
    '50th': 125.6,
    '90th': 134.0,
    '95th': 136.4,
    '97th': 138.0,
  },
  19: {
    '3rd': 123.3,
    '5th': 125.0,
    '10th': 127.6,
    '50th': 136.7,
    '90th': 145.8,
    '95th': 148.4,
    '97th': 150.1,
  },
  20: {
    '3rd': 133.4,
    '5th': 135.2,
    '10th': 138.0,
    '50th': 147.7,
    '90th': 157.5,
    '95th': 160.3,
    '97th': 162.1,
  },
  21: {
    '3rd': 143.4,
    '5th': 145.3,
    '10th': 148.3,
    '50th': 158.7,
    '90th': 169.1,
    '95th': 172.0,
    '97th': 174.0,
  },
  22: {
    '3rd': 153.5,
    '5th': 155.5,
    '10th': 158.6,
    '50th': 169.6,
    '90th': 180.6,
    '95th': 183.7,
    '97th': 185.7,
  },
  23: {
    '3rd': 163.4,
    '5th': 165.6,
    '10th': 168.9,
    '50th': 180.4,
    '90th': 192.0,
    '95th': 195.3,
    '97th': 197.4,
  },
  24: {
    '3rd': 173.3,
    '5th': 175.6,
    '10th': 179.0,
    '50th': 191.2,
    '90th': 203.3,
    '95th': 206.8,
    '97th': 209.0,
  },
  25: {
    '3rd': 183.2,
    '5th': 185.5,
    '10th': 189.1,
    '50th': 201.8,
    '90th': 214.5,
    '95th': 218.1,
    '97th': 220.5,
  },
  26: {
    '3rd': 192.9,
    '5th': 195.4,
    '10th': 199.1,
    '50th': 212.4,
    '90th': 225.7,
    '95th': 229.5,
    '97th': 231.9,
  },
  27: {
    '3rd': 202.6,
    '5th': 205.1,
    '10th': 209.1,
    '50th': 222.9,
    '90th': 236.8,
    '95th': 240.7,
    '97th': 243.2,
  },
  28: {
    '3rd': 212.1,
    '5th': 214.7,
    '10th': 218.8,
    '50th': 233.3,
    '90th': 247.8,
    '95th': 251.9,
    '97th': 254.5,
  },
  29: {
    '3rd': 221.4,
    '5th': 224.2,
    '10th': 228.5,
    '50th': 243.6,
    '90th': 258.7,
    '95th': 263.0,
    '97th': 265.8,
  },
  30: {
    '3rd': 230.6,
    '5th': 233.5,
    '10th': 238.0,
    '50th': 253.8,
    '90th': 269.6,
    '95th': 274.1,
    '97th': 277.0,
  },
  31: {
    '3rd': 239.6,
    '5th': 242.6,
    '10th': 247.4,
    '50th': 263.9,
    '90th': 280.5,
    '95th': 285.2,
    '97th': 288.3,
  },
  32: {
    '3rd': 248.4,
    '5th': 251.6,
    '10th': 256.5,
    '50th': 273.9,
    '90th': 291.3,
    '95th': 296.3,
    '97th': 299.5,
  },
  33: {
    '3rd': 256.9,
    '5th': 260.3,
    '10th': 265.5,
    '50th': 283.8,
    '90th': 302.2,
    '95th': 307.4,
    '97th': 310.7,
  },
  34: {
    '3rd': 265.2,
    '5th': 268.7,
    '10th': 274.3,
    '50th': 293.6,
    '90th': 313.0,
    '95th': 318.5,
    '97th': 322.0,
  },
  35: {
    '3rd': 273.2,
    '5th': 276.9,
    '10th': 282.8,
    '50th': 303.3,
    '90th': 323.8,
    '95th': 329.6,
    '97th': 333.4,
  },
  36: {
    '3rd': 280.8,
    '5th': 284.8,
    '10th': 291.0,
    '50th': 312.8,
    '90th': 334.6,
    '95th': 340.9,
    '97th': 344.9,
  },
  37: {
    '3rd': 288.1,
    '5th': 292.4,
    '10th': 299.0,
    '50th': 322.3,
    '90th': 345.5,
    '95th': 352.1,
    '97th': 356.4,
  },
  38: {
    '3rd': 295.1,
    '5th': 299.6,
    '10th': 306.7,
    '50th': 331.6,
    '90th': 356.4,
    '95th': 363.5,
    '97th': 368.1,
  },
  39: {
    '3rd': 301.6,
    '5th': 306.5,
    '10th': 314.1,
    '50th': 340.8,
    '90th': 367.4,
    '95th': 375.0,
    '97th': 379.9,
  },
  40: {
    '3rd': 307.7,
    '5th': 312.9,
    '10th': 321.1,
    '50th': 349.8,
    '90th': 378.5,
    '95th': 386.7,
    '97th': 392.0,
  },
};
