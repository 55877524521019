import React from 'react';

function Stepper({ steps, currentStep, handleBack, handleNext }) {
  return (
    <div className="stepper-container">
      <div className="stepper">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${
              index + 1 <= currentStep ? 'active' : ''
            } ${index + 1 < currentStep ? 'completed' : ''}`}
          >
            <div className="circle">{step}</div>
            {index < steps.length - 1 && (
              <div
                className={`line ${
                  index + 1 < currentStep
                    ? 'filled'
                    : index + 1 === currentStep
                      ? 'half-filled'
                      : ''
                }`}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
export default Stepper;
