import React, { useEffect, useState } from 'react';

const ActionsBar: React.FC = props => {
  const {
    saveReport,
    resetReportObjValues,
    setExamData,
    examData,
    setResetActive,
    resetActive,
    downloadPDF,
    editMode,
    handleSwitchChange,
  } = props;

  return (
    <div
      className="flex justify-between"
      style={{ height: '2%' }}
    >
      <div>
        <button
          disabled
          onClick={saveReport}
        >
          ➕ Add Report
        </button>
        <button onClick={downloadPDF}> 📥 DownloadPDF</button>

        {/* {editMode ? ( */}
        {false ? (
          <button
            className="px-2"
            onClick={handleSwitchChange}
          >
            💾 Save changes
          </button>
        ) : (
          <button
            disabled
            className="px-2"
            onClick={handleSwitchChange}
          >
            🖋️ Edit
          </button>
        )}

        <button
          // disabled={!resetActive || editMode}
          disabled
          className={`${(editMode || !resetActive) && 'cursor-not-allowed'} `}
          onClick={() => resetReportObjValues(setExamData, examData, setResetActive)}
        >
          🔁 Reset
        </button>
      </div>
    </div>
  );
};

export default ActionsBar;
