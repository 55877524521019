import {
  ADD_STUDY,
  API_ERROR,
  API_SUCCESS,
  // DELETE_REPORT,
  DELETE_STUDY,
  // GET_REPORT,
  GET_STUDIES,
  GET_STUDY,
  PREDICT,
  UPDATE_NEEDS_RELOAD,
  RESET_STUDY_STATE,
  ARCHIVE_STUDY,
  UNARCHIVE_STUDY,
} from './actionType';

export const studyApiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const studyApiError = (actionType, error) => ({
  type: API_ERROR,
  payload: { actionType, error },
});

export const addStudy = data => {
  return {
    type: ADD_STUDY,
    payload: data,
  };
};

export const getStudy = payload => {
  return {
    type: GET_STUDY,
    payload,
  };
};

export const predict = payload => ({
  type: PREDICT,
  payload,
});

export const getStudies = data => ({
  type: GET_STUDIES,
  payload: { data },
});

// export const getReport = payload => {
//   console.log('get report', payload)
//   return {
//     type: GET_REPORT,
//     payload,
//   };
// };

// export const deleteReport = payload => ({
//   type: DELETE_REPORT,
//   payload,
// });

export const deleteStudy = payload => ({
  type: DELETE_STUDY,
  payload,
});

export const updateNeedsReload = payload => {
  return {
    type: UPDATE_NEEDS_RELOAD,
    payload,
  };
};
export const resetStudyState = () => {
  console.log('reset state');
  return {
    type: RESET_STUDY_STATE,
  };
};

export const archiveStudy = payload => {
  return {
    type: ARCHIVE_STUDY,
    payload,
  };
};
export const unarchiveStudy = payload => {
  return {
    type: UNARCHIVE_STUDY,
    payload,
  };
};
