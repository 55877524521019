import { is } from 'date-fns/locale';
import React, { useState, useRef } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

type Props = {
  slides: any[];
  children: (props: any) => JSX.Element;
  visibleItemsNumber?: number;
  start: number;
  setStart: (start: number) => void;
};

const Slider = React.memo<Props>(
  ({ slides, children, visibleItemsNumber = 3, start, setStart }) => {
    const [dragStartX, setDragStartX] = useState<number | null>(null);
    const [dragging, setDragging] = useState(false);
    const sliderRef = useRef<HTMLUListElement>(null);

    const isControlsVisible = slides.length > visibleItemsNumber;

    let visibleItems;
    if (isControlsVisible) {
      if (slides.length % 2 !== 0) {
        visibleItems = slides.slice(start, start + visibleItemsNumber);
      } else {
        visibleItems = slides
          .concat(slides.slice(0, visibleItemsNumber))
          .slice(start, start + visibleItemsNumber);
      }
    } else {
      visibleItems = slides;
    }

    const onNextClick = () => {
      setStart(start + 1 >= slides.length ? 0 : start + 1);
    };

    const onPrevClick = () => {
      setStart(start - 1 >= 0 ? start - 1 : slides.length - 1);
    };

    const onMouseDown = (e: React.MouseEvent) => {
      setDragStartX(e.clientX);
      setDragging(true);
    };

    const onMouseMove = (e: React.MouseEvent) => {
      if (dragging && dragStartX !== null) {
        const diffX = e.clientX - dragStartX;
        if (Math.abs(diffX) > 50) {
          if (diffX > 0) {
            onPrevClick();
          } else {
            onNextClick();
          }
          setDragStartX(e.clientX);
        }
      }
    };

    const onMouseUp = () => {
      setDragging(false);
      setDragStartX(null);
    };

    return (
      <div className="w-full">
        <div
          className={`flex ${slides.length > 1 ? 'justify-between' : 'justify-center'}  items-center px-6`}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseUp}
          ref={sliderRef}
        >
          {isControlsVisible && (
            <FaChevronLeft
              onClick={onPrevClick}
              className="h-5 w-4 cursor-pointer text-gray-600"
            />
          )}

          <ul className="flex list-none p-0">
            {visibleItems.map((slide: any, index: number) =>
              children ? (
                <li
                  key={index}
                  className="mr-8 w-full last:mr-0"
                >
                  {children(slide)}
                </li>
              ) : null
            )}
          </ul>

          {isControlsVisible && (
            <FaChevronRight
              onClick={onNextClick}
              className="h-5 w-4 cursor-pointer text-gray-600"
            />
          )}
        </div>
      </div>
    );
  }
);

export default Slider;
