import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import { APIClient } from '../../../helpers/apiHelper';
import axios from 'axios';
import FramesSlider from './framesSlider';

const api = new APIClient();

const FramesDisplayer: React.FC = props => {
  const {
    report,
    selectedFrame,
    setSelectedFrame,
    setSelectedFrameID,
    selectedFrameID,
    plan,
    resetCriteria2,
    examData,
    setExamData,
    editMode,
    qualityCriterias,
  } = props;

  const backend_url = process.env.BACKEND_URL;

  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const [imageData, setImageData] = useState(null);

  const fetchImage = imageURL => {
    try {
      axios
        .get(imageURL, {
          responseType: 'blob',
        })
        .then(response => {
          const blob = new Blob([response], {
            type: 'image/jpeg',
          });
          const objectURL = URL.createObjectURL(blob);

          setImageData(objectURL);
        })
        .catch(error => {
          console.error(error);
        });

      // const response = await api.get(imageURL);
      // const blob = new Blob([response], {
      //   type: 'image/jpeg',
      // });
      // const objectURL = URL.createObjectURL(blob);
      // setImageData(objectURL);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchImage(
      selectedFrame
        ? selectedFrame
        : `${backend_url}/api/reports/getFrame?studyId=${report.study_id}&seriesId=${report.series_id}&objectId=${report.planes[plan].frames[0].frameId}`
    );
  }, [selectedFrame]);

  return (
    <>
      <>
        {report.study_id && (
          <>
            {report.planes[plan].frames[0] &&
              report.planes[plan].frames[0].frameId &&
              imageData && (
                <img
                  onClick={showModal}
                  style={{
                    height: '250px',
                    width: '420px',
                    border: '1px solid gray',
                    margin: '0 auto',
                    cursor: 'pointer',
                  }}
                  crossOrigin="anonymous"
                  // src={
                  //   selectedFrame
                  //     ? selectedFrame
                  //     : `${dcm_url}/api/reports/getFrame?studyId=${report.study_id}&seriesId=${report.series_id}&objectId=${report.planes[plan].frames[0].frameId}`
                  // }
                  // `${dcm_url}/dcm4chee-arc/aets/DCM4CHEE/wado?requestType=WADO&studyUID=${report.study_id}&seriesUID=${report.series_id}&objectUID=${report.planes[plan].frames[0].frameId}&contentType=image/jpeg&frameNumber=1`
                  src={imageData}
                />
              )}
            <Modal
              width={1000}
              style={{
                height: '40%',
                top: '8vh',
              }}
              bodyStyle={{
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 200px)',
              }}
              title={'Frames'}
              open={open}
              onCancel={handleCancel}
              okText={'upload'}
              okButtonProps={{
                ghost: true,
              }}
              footer={null}
            >
              <FramesSlider
                resetCriteria2={resetCriteria2}
                report={report}
                qualityCriterias={qualityCriterias}
                selectedFrameID={selectedFrameID}
                selectedFrame={selectedFrame}
                setSelectedFrame={setSelectedFrame}
                setSelectedFrameID={setSelectedFrameID}
                plan={plan}
                handleCancel={handleCancel}
              />
            </Modal>
          </>
        )}
      </>
    </>
  );
};

export default FramesDisplayer;
