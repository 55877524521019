import {
  CopyOutlined,
  ExportOutlined,
  EyeOutlined,
  FolderOpenOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { use } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { archiveStudy, getStudies, getStudy, unarchiveStudy } from '../../../store/actions';
import LoaderPage from '../../Components/Loader/loader.page';
import { Study, StudyState } from '../../interfaces';

interface ExamTableProps {
  exams: StudyState;
  HandlePrevPage: any;
  handleNextNext: any;
  HandleRowChange: any;
  archived: boolean;
  itemsPerPage: number;
  page: number;
  setPage: (page: number) => void;
}
function ExamTable({
  exams,
  HandlePrevPage,
  handleNextNext,
  HandleRowChange,
  itemsPerPage,
  page,
  archived,
  setPage,
}: ExamTableProps) {
  const [row, setRow] = useState(itemsPerPage);
  const [copied, setCopied] = useState(false);
  const [data, setData] = useState<Study[]>([]);
  const [deleteselected, setDeleteSelected] = useState(false);
  const [archive, setArchive] = useState(false);
  const dispatch = useDispatch();
  const copyToClipboard = textToCopy => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => setCopied(true))
      .catch(error => console.error('Failed to copy:', error));
  };

  function handleArchive(items: Study) {
    const isLastItemOnLastPage = data.length === 1 && page > 1;
    if (isLastItemOnLastPage) {
      dispatch(
        archiveStudy({
          items: [{ ...items, isChecked: true }],
          filter_options: {
            ...exams?.filtered_conditions,
            page: page - 1,
            items_per_page: itemsPerPage,
          },
        })
      );
      setPage(page - 1);
      return;
    }
    dispatch(
      archiveStudy({
        items: [{ ...items, isChecked: true }],
        filter_options: { ...exams?.filtered_conditions, page: page, items_per_page: itemsPerPage },
      })
    );
  }
  function handleUnArchive(items: Study) {
    const isLastItemOnLastPage = data.length === 1 && page > 1;
    if (isLastItemOnLastPage) {
      dispatch(
        unarchiveStudy({
          items: [{ ...items, isChecked: true }],
          filter_options: {
            ...exams?.filtered_conditions,
            page: page - 1,
            items_per_page: itemsPerPage,
          },
        })
      );
      setPage(page - 1);
      return;
    }
    dispatch(
      unarchiveStudy({
        items: [{ ...items, isChecked: true }],
        filter_options: { ...exams?.filtered_conditions, page: page, items_per_page: itemsPerPage },
      })
    );
  }
  const handleCheckBoxChange = (e: any) => {
    const { id, checked } = e.target;
    setDeleteSelected(true);
    let checkedData = [];
    if (id === 'allselect') {
      checkedData = data.map((item: any) => {
        return { ...item, isChecked: checked };
      });
      setData(checkedData);
    } else {
      checkedData = data.map((item: any) => {
        if (item.study_instance_uid.toString() === id) {
          return { ...item, isChecked: checked };
        }
        return item;
      });
      setData(checkedData);
    }
    checkedData.filter((item: any) => item.isChecked)?.length !== 0
      ? setDeleteSelected(true)
      : setDeleteSelected(false);
  };
  // const ArchiveCheckedExams = () => {
  //     if (archived === false)
  //         dispatch(archiveStudy({ items: data, filter_options: { ...exams?.filtered_conditions, page: page, items_per_page: itemsPerPage } }))
  //     else
  //         dispatch(unarchiveStudy({ items: data, filter_options: { ...exams?.filtered_conditions, page: page, items_per_page: itemsPerPage } }))
  // }
  const ArchiveCheckedExams = () => {
    const isLastPageAndEmptyAfterAction =
      data.filter(item => item.isChecked).length === data.length && page > 1;

    if (archived === false) {
      // Check if archiving selected items will make the page empty
      if (isLastPageAndEmptyAfterAction) {
        dispatch(
          archiveStudy({
            items: data,
            filter_options: {
              ...exams?.filtered_conditions,
              page: page - 1,
              items_per_page: itemsPerPage,
            },
          })
        );
        setPage(page - 1); // Go to previous page
      } else {
        dispatch(
          archiveStudy({
            items: data,
            filter_options: {
              ...exams?.filtered_conditions,
              page: page,
              items_per_page: itemsPerPage,
            },
          })
        );
      }
    } else {
      // Check if unarchiving selected items will make the page empty
      if (isLastPageAndEmptyAfterAction) {
        dispatch(
          unarchiveStudy({
            items: data,
            filter_options: {
              ...exams?.filtered_conditions,
              page: page - 1,
              items_per_page: itemsPerPage,
            },
          })
        );
        setPage(page - 1); // Go to previous page
      } else {
        dispatch(
          unarchiveStudy({
            items: data,
            filter_options: {
              ...exams?.filtered_conditions,
              page: page,
              items_per_page: itemsPerPage,
            },
          })
        );
      }
    }
  };

  console.log('exams', exams);
  console.log('page', page);

  /*const HandleChangeType = (e: any) => {
        console.log('e.target.value', e.target.value)
        if (e.target.value === 'archive') {
            setArchive(true)
            dispatch(getStudies({ page: 1, items_per_page: itemsPerPage, filter_options: { ...exams?.filtered_conditions, archived: true } }))
        } else {
            dispatch(getStudies({ page: 1, items_per_page: itemsPerPage, filter_options: { archived: false } }))
        }
    }*/
  function formatDate(birthDate: any) {
    const options: any = { month: 'long', day: 'numeric', year: 'numeric' };
    return new Date(birthDate).toLocaleDateString('en-US', options);
  }

  useEffect(() => {
    console.log('exams111', exams);
    setData(exams?.studies);
  }, [exams]);
  return (
    <>
      <table
        className={`block  w-full border-collapse rounded-lg ${exams?.studies?.length === 0 || !exams?.studies?.length ? '' : 'h-full'}`}
      >
        <thead className="relative block h-[56px] w-full bg-[#EEF5FF] text-center text-[#000000]">
          <tr className="flex h-full w-full items-center justify-between border border-[#C7DBF5] text-[12px] font-semibold text-[#2B5170]">
            {/* <th className="pl-4 items-center basis-full grow block">
                            <div className='flex space-x-2'> */}
            <th className="block grow basis-full pl-4">
              <div className="">
                <input
                  type="checkbox"
                  className="h-6"
                  id="allselect"
                  checked={Array.isArray(data) && !data?.some(item => item.isChecked !== true)}
                  onChange={handleCheckBoxChange}
                />
                {deleteselected && (
                  <button
                    className="text-[14px] font-semibold text-[#005DD4]"
                    onClick={ArchiveCheckedExams}
                  >
                    <FolderOpenOutlined />
                  </button>
                )}
              </div>
            </th>
            {/* <th className="">MRN</th> */}
            <th className="block grow basis-full">Patient Name</th>
            <th className="block grow basis-full">Study Date</th>
            <th className="block grow basis-full">Study Instance UID</th>
            <th className="block grow basis-full">Description</th>
            <th className="block grow basis-full">Modality</th>
            <th className="block grow basis-full">ACTIONS</th>
          </tr>
        </thead>
        <tbody
          className={`relative block max-h-[calc(100%-96px)] w-full overflow-auto text-center`}
        >
          {Array.isArray(data) &&
            data?.map(exam => (
              <tr
                key={exam?.study_instance_uid}
                className="flex  w-full items-center justify-between border-b border-[#C7DBF5] hover:bg-gray-100"
              >
                {/* <td className="basis-full grow block custom-letter-spacing py-3 pl-4 text-left "> */}
                <td className="custom-letter-spacing block grow basis-full py-3 pl-4 ">
                  <input
                    type="checkbox"
                    className="h-6"
                    id={exam?.study_instance_uid}
                    checked={exam?.isChecked || false}
                    onChange={handleCheckBoxChange}
                  />
                </td>
                {/* <td className="custom-letter-spacing  text-[14px] font-medium text-[#25213B] ">
                            {exam?.id}
                        </td> */}
                <td className="custom-letter-spacing block grow basis-full text-center text-[#25213B] ">
                  <Link to={`/patients/${exam?.patient_patient_id}`}>
                    {exam?.patient_patient_name}
                  </Link>
                </td>
                <td className="custom-letter-spacing block grow basis-full text-center text-[#25213B] ">
                  {formatDate(exam?.study_creation_date)}
                </td>

                <td
                  className="custom-letter-spacing block grow basis-full text-center text-[14px] font-medium text-[#25213B]"
                  title={exam?.study_instance_uid}
                >
                  {exam?.study_instance_uid && exam.study_instance_uid?.length > 5
                    ? `${exam.study_instance_uid.substring(0, 10)}...`
                    : exam.study_instance_uid}
                  <button onClick={() => copyToClipboard(exam?.study_instance_uid)}>
                    <CopyOutlined className="text-blue-500" />
                  </button>
                </td>

                <td className="custom-letter-spacing block w-56 grow basis-full text-center text-[#25213B]">
                  {exam?.study_description !== 'null' && exam?.study_description?.length > 0
                    ? exam?.study_description
                    : 'no description'}
                </td>
                <td className="custom-letter-spacing block grow basis-full text-center text-[#25213B]">
                  {exam?.modality !== 'null' || !exam?.modality ? exam?.modality : ''}
                </td>
                <td className="custom-letter-spacing block grow basis-full text-center ">
                  {exam?.archived === false ? (
                    <>
                      <button className="w-10">
                        <Link
                          to={`/exams/viewer?StudyInstanceUIDs=${exam?.study_instance_uid}`}
                          className=" w-10"
                        >
                          <EyeOutlined />
                        </Link>
                      </button>
                      <button className="w-10">
                        <ExportOutlined />
                      </button>
                      <button
                        className="w-10"
                        onClick={() => handleArchive(exam)}
                      >
                        <FolderOpenOutlined />
                      </button>
                    </>
                  ) : (
                    <button
                      className="w-10"
                      onClick={() => handleUnArchive(exam)}
                    >
                      <FolderOpenOutlined />
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot
          className={`block h-[40px] w-full bg-[#EEF5FF] ${(exams?.studies?.length === 0 || !exams?.studies?.length) && !archive ? 'hidden' : ''}`}
        >
          <tr className="flex h-full w-full justify-between border">
            {/*<td>
                            <div className='inline-flex flex-row items-end space-x-4 text-[#2B5170] font-semibold text-sm'>
                                <select name="action" id="action" className='bg-[#EEF5FF]' defaultValue="Normal" onChange={HandleChangeType}>
                                    <option value="archive">Archive</option>
                                    <option value="Normal">Normal</option>
                                </select>
                            </div>
                </td>*/}
            <td
              colSpan={7}
              className="block grow basis-full space-x-4 px-4 text-right"
            >
              <div className="inline-flex flex-row items-end space-x-4 text-sm font-semibold text-[#2B5170]">
                <div>
                  Rows per page:
                  <select
                    name="row"
                    id="row"
                    onChange={HandleRowChange}
                    className="bg-[#EEF5FF]"
                  >
                    <option value={row}>{row}</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                  </select>
                </div>
                <div>
                  {exams?.totalCount > 0 &&
                    `${(page - 1) * itemsPerPage + 1}-${Math.min(itemsPerPage * page, exams?.totalCount)} of ${exams?.totalCount}`}
                </div>

                <div className="space-x-4">
                  <button
                    className="text-[14px] font-semibold text-[#005DD4]"
                    onClick={HandlePrevPage}
                    disabled={page > 1 ? false : true}
                  >
                    <LeftOutlined />
                  </button>
                  <button
                    className="text-[14px] font-semibold text-[#005DD4]"
                    onClick={handleNextNext}
                    disabled={!exams.hasMore}
                  >
                    <RightOutlined />
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      {exams?.loading === true && exams?.studies?.length === 0 && <LoaderPage />}
      {exams?.studies?.length === 0 && !exams?.loading && (
        <div className="flex h-44 w-full flex-col items-center justify-center text-xl text-gray-700">
          <p>No Archived Exams found</p>
        </div>
      )}
    </>
  );
}

export default ExamTable;
