import React, { createContext, useContext, useState } from 'react';

const SideBarCollapsed = createContext({
  collapsed: false,
  setCollapsed: bool => {},
});

export const useSideBarCollapsed = () => useContext(SideBarCollapsed);

export const SideBarCollapsedProvider = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <SideBarCollapsed.Provider value={{ collapsed, setCollapsed }}>
      {children}
    </SideBarCollapsed.Provider>
  );
};

export default SideBarCollapsedProvider;
