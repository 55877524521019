import React, { useEffect, useState } from 'react';
import { MdDownload } from 'react-icons/md';
import { ImPrinter } from 'react-icons/im';
import { IoIosShareAlt } from 'react-icons/io';
import reportLogo from '../../../../../../assets/logoReportDeep.png';
import QRCode from 'react-qr-code';
import { SlCheck } from 'react-icons/sl';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { LuLoader2 } from 'react-icons/lu';
import Modal from '../../../../Components/modal';
import ReportCard from './report-card';

function GenerateReportModal({ globalState, setGlobalState, plans }) {
  const [isLoading, setIsLoading] = useState(false); // Add loading stat
  const [imgFemur, setImgFemur] = useState(null);
  const [imgAbdo, setImgAbdo] = useState(null);
  const [imgCephalic, setImgCephalic] = useState(null);
  const [imgAfPocke, setImgAfPocket] = useState(null);
  const [qrSrc, setQrSrc] = useState('');
  console.log('just for test for report comp', globalState);
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-GB').replace(/\//g, '-');

  // Assuming 'globalState.selectedItemsMultidrop' is an array of selected labels
  // const selectedPlans = plans.filter(plan =>{
  //   console.log("selectedItemsMultidrop :",globalState?.selectedItemsMultidrop)
  //   return globalState?.selectedItemsMultidrop?.includes(plan.label)
  // }
  // );
  const selectedPlans = plans;
  console.log('selected plans :', selectedPlans);
  // Use 'selectedPlans' to display in the report as needed

  const handleCancel = () => {
    setGlobalState(prevState => ({
      ...prevState,
      openGR: !globalState?.openGR,
    }));
  };
  useEffect(() => {
    let isGeneratingPDF = true; // Flag to track PDF generation status

    const generatePDF = async () => {
      const modalContent = document.getElementById('modalContent'); // ID of the modal content
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pageHeight = pdf.internal.pageSize.height; // A4 page height in mm
      const pageWidth = pdf.internal.pageSize.width; // A4 page width in mm

      try {
        // Capture modal as canvas with `html2canvas`
        const canvas = await html2canvas(modalContent, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = pageWidth;
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale height proportionally
        console.log('imgHeight :', imgHeight);
        let currentPosition = 0; // Track where to split content vertically

        // Loop through each page
        while (currentPosition < imgHeight && isGeneratingPDF) {
          pdf.addImage(
            imgData,
            'PNG',
            0,
            -currentPosition, // Adjust image position to capture each page portion
            imgWidth,
            imgHeight - 7.5
          );

          currentPosition += pageHeight; // Move down by a page height for each iteration

          if (currentPosition < imgHeight) {
            pdf.addPage();
          } // Add a new page if there's more content
        }

        if (isGeneratingPDF) {
          // Check if PDF generation is still active
          setIsLoading(false);
          setQrSrc(pdf.output('bloburl').toString());
        }
      } catch (error) {
        setIsLoading(false);
        console.error('PDF generation error:', error);
      }
    };

    // Start generating the PDF if the modal is opened
    if (globalState?.openGR) {
      generatePDF();
    }

    // Cleanup function to set the flag when the component unmounts or modal closes
    return () => {
      isGeneratingPDF = false; // Stop PDF generation on cleanup
    };
  }, [globalState?.openGR]);

  const downloadPDF = async () => {
    setIsLoading(true);
    const modalContent = document.getElementById('modalContent'); // ID of the modal content
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageHeight = pdf.internal.pageSize.height; // A4 page height in mm
    const pageWidth = pdf.internal.pageSize.width; // A4 page width in mm

    try {
      // Capture modal as canvas with `html2canvas`
      const canvas = await html2canvas(modalContent, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale height proportionally
      console.log('imgHeight :', imgHeight);
      let currentPosition = 0; // Track where to split content vertically

      // Loop through each page
      while (currentPosition < imgHeight) {
        pdf.addImage(
          imgData,
          'PNG',
          0,
          -currentPosition, // Adjust image position to capture each page portion
          imgWidth,
          imgHeight - 7.5
        );

        currentPosition += pageHeight; // Move down by a page height for each iteration

        // if (currentPosition < imgHeight) pdf.addPage(); // Add a new page if there's more content
      }
      setIsLoading(false);
      pdf.save('modal-content.pdf');
    } catch (error) {
      setIsLoading(false);
      console.error('PDF generation error:', error);
    }
  };

  const printPDF = async () => {
    setIsLoading(true);
    const modalContent = document.getElementById('modalContent'); // ID of the modal content
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageHeight = pdf.internal.pageSize.height; // A4 page height in mm
    const pageWidth = pdf.internal.pageSize.width; // A4 page width in mm

    try {
      // Capture modal as canvas with `html2canvas`
      const canvas = await html2canvas(modalContent, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale height proportionally
      console.log('imgHeight :', imgHeight);
      let currentPosition = 0; // Track where to split content vertically

      // Loop through each page
      while (currentPosition < imgHeight) {
        pdf.addImage(
          imgData,
          'PNG',
          0,
          -currentPosition, // Adjust image position to capture each page portion
          imgWidth,
          imgHeight - 7.5
        );

        currentPosition += pageHeight; // Move down by a page height for each iteration

        // if (currentPosition < imgHeight) pdf.addPage(); // Add a new page if there's more content
      }
      setIsLoading(false);
      const pdfUrl = pdf.output('bloburl');
      window.open(pdfUrl, '_blank');
    } catch (error) {
      setIsLoading(false);
      console.error('PDF generation error:', error);
    }
  };

  console.log('globalState test', globalState?.openGR);
  return (
    <div className="">
      <Modal
        isOpen={globalState?.openGR}
        onClose={() => {
          handleCancel();
        }}
        size="fullScreen"
        overlayClassName="py-10"
        className="bg-white"
      >
        <Modal.Header
          title={
            <div className="flex w-full justify-between">
              <div className="flex w-full justify-start">Real Time Report</div>
              <div className="flex w-full justify-end space-x-3">
                <div
                  onClick={() => {
                    downloadPDF();
                  }}
                  className="cursor-pointer hover:text-[#8c8989]"
                >
                  {isLoading ? (
                    <LuLoader2 className="animate-spin" /> // Display loader icon with spinning animation
                  ) : (
                    <MdDownload />
                  )}
                </div>
                <div
                  onClick={printPDF}
                  className="cursor-pointer hover:text-[#8c8989]"
                >
                  <ImPrinter />
                </div>
                <div>
                  <IoIosShareAlt
                    onClick={() => {
                      handleCancel();
                    }}
                    className="cursor-pointer hover:text-[#8c8989]"
                  />
                </div>
              </div>
            </div>
          }
          onClose={() => {
            handleCancel();
          }}
          displayClose={false}
        />
        <Modal.Content>
          <div
            className="actual-receipt"
            id="modalContent"
          >
            <div className="flex w-full items-center border-t border-b border-[#A6ABC8] bg-white p-3 px-5">
              <div className="flex-col font-semibold">
                <div className="w-44 text-2xl text-[#000000]">Fetal Biometry</div>
                <div className="text-md text-[#2886C7]">Ultrasound Report</div>
              </div>
              <div className="flex h-16 w-full items-center justify-center">
                <img
                  src={reportLogo}
                  className="w-52 lg:w-72"
                />
              </div>
              <div className="w-fit">
                <div className="flex w-full">
                  <div className="flex w-[199.88px] flex-col items-end justify-center space-y-1">
                    <div className="text-sm font-semibold text-[#1F384C]">
                      <span className="text-[#000000]">Practicionaer name : </span>
                      Dr. {globalState?.doctorName}
                    </div>
                    <div className="text-sm font-semibold text-[#1F384C]">
                      <span className="text-[#000000]">Machine Type: </span>
                      {globalState?.machineType}
                    </div>
                    <div className="text-sm font-semibold text-[#1F384C]">
                      <span className="text-[#000000]">Exam date : </span>
                      {formattedDate}
                    </div>
                  </div>
                  <div className="flex w-[101.93px] flex-col items-center justify-center">
                    <div className="mb-1 text-xs font-bold">Access full exam</div>
                    <QRCode
                      className=""
                      value={qrSrc}
                      bgColor={'white'}
                      fgColor={'black'}
                      size={50}
                    />
                    <h4 className=" text-center text-sm">27534897</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white px-5 py-8">
              <div className="grid w-full grid-cols-1 gap-3 xl:grid-cols-2">
                {selectedPlans.map((item, index) => (
                  <ReportCard
                    key={index}
                    {...item}
                  />
                ))}
              </div>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
}
export default GenerateReportModal;
