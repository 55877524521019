// import reportLogo from "../../../assets/reportLogo.png";

export const reportAttrsVals_Orig = {
  section1: {
    col1: {
      firstHeader: 'FETAL BIOMETRY',
      secondHeader: 'ULTRASOUND BIOMETRY',
      examDate: '24-12-2023',
      practitioner: 'Dr. Hamid BENHAMID',
    },
    col2: {
      logo: 'reportLogo',
      email: 'patients@deepecho.io',
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy dummy text of the printing and typesetting",
    },
    col3: {
      firstCol: {
        title: 'Deepecho',
        qrValue: 'test test',
        qrBgColor: 'white',
        qrFgColor: 'black',
        qrSize: 100,
        ID: '275348975935',
      },
      secondCol: {
        RandomAttr: '98548783',
        TestID: 'Test Value',
        DeviceID: '4593859384',
        PatientCity: 'Douar l7ajja',
        PatientCountry: 'Morocco',
      },
    },
  },
  section2: {
    firstColFirstRow: {
      detectedPlan: 'Brain',
      quality: '97%',
      image:
        'https://www.shutterstock.com/shutterstock/photos/1493574323/display_1500/stock-photo-ultrasound-small-baby-at-weeks-weeks-pregnant-ultrasound-image-show-baby-or-fetus-1493574323.jpg',
      criterias: {
        criteria1: {
          value: 'Plan showing thalami',
          status: 'success',
        },
        criteria2: {
          value: 'Cerebellum not visible',
          status: 'success',
        },
        criteria3: {
          value: 'Symetrical plane',
          status: 'success',
        },
        criteria4: {
          value: 'Plan showing cavum septum pellucidi',
          status: 'success',
        },
        criteria5: {
          value: 'Head occupying more than half of the image',
          status: 'success',
        },
        criteria6: {
          value: 'less than 45 to horizontal',
          status: 'success',
        },
      },
    },
    secondColFirstRow: {
      detectedPlan: 'Brain',
      quality: '97%',
      image:
        'https://www.shutterstock.com/shutterstock/photos/1493574323/display_1500/stock-photo-ultrasound-small-baby-at-weeks-weeks-pregnant-ultrasound-image-show-baby-or-fetus-1493574323.jpg',
      criterias: {
        criteria1: {
          value: 'Plan showing thalami',
          status: 'faillure',
        },
        criteria2: {
          value: 'Cerebellum not visible',
          status: 'success',
        },
        criteria3: {
          value: 'Symetrical plane',
          status: 'success',
        },
        criteria4: {
          value: 'Plan showing cavum septum pellucidi',
          status: 'success',
        },
        criteria5: {
          value: 'Head occupying more than half of the image',
          status: 'success',
        },
        criteria6: {
          value: 'less than 45 to horizontal',
          status: 'success',
        },
      },
    },
    firstColSecondRow: {
      detectedPlan: 'Brain',
      quality: '97%',
      image:
        'https://www.shutterstock.com/shutterstock/photos/1493574323/display_1500/stock-photo-ultrasound-small-baby-at-weeks-weeks-pregnant-ultrasound-image-show-baby-or-fetus-1493574323.jpg',
      criterias: {
        criteria1: {
          value: 'Plan showing thalami',
          status: 'faillure',
        },
        criteria2: {
          value: 'Cerebellum not visible',
          status: 'success',
        },
        criteria3: {
          value: 'Symetrical plane',
          status: 'success',
        },
        criteria4: {
          value: 'Plan showing cavum septum pellucidi',
          status: 'success',
        },
        criteria5: {
          value: 'Head occupying more than half of the image',
          status: 'success',
        },
        criteria6: {
          value: 'less than 45 to horizontal',
          status: 'success',
        },
      },
    },
    secondColSecondRow: {
      detectedPlan: 'Brain',
      quality: '97%',
      image:
        'https://www.shutterstock.com/shutterstock/photos/1493574323/display_1500/stock-photo-ultrasound-small-baby-at-weeks-weeks-pregnant-ultrasound-image-show-baby-or-fetus-1493574323.jpg',
      criterias: {
        criteria1: {
          value: 'Plan showing thalami',
          status: 'faillure',
        },
        criteria2: {
          value: 'Cerebellum not visible',
          status: 'success',
        },
        criteria3: {
          value: 'Symetrical plane',
          status: 'success',
        },
        criteria4: {
          value: 'Plan showing cavum septum pellucidi',
          status: 'success',
        },
        criteria5: {
          value: 'Head occupying more than half of the image',
          status: 'success',
        },
        criteria6: {
          value: 'less than 45 to horizontal',
          status: 'success',
        },
      },
    },
  },
  section3: {
    generalInfoSection: {
      DOB: '12/12/1990',
      age: '32',
      lmp: '22/12/2023',
      RHP: 'Douar lahjer hospital',
      GA: '7w12',
      MS: 'followed patient',
      exam: 'first trimester',
    },
    examinationResSection: {
      resultsTable: {
        EGA: {
          current: '5w7d',
          previous: 'N/A',
          centile: '22%',
        },
        EFA: {
          current: '456 grams',
          previous: 'N/A',
          centile: '18%',
        },
        GRS: {
          current: 'Server FGR',
          previous: 'N/A',
          centile: '',
        },
        AFP: {
          current: 'No abnomalities',
          previous: 'N/A',
          centile: '',
        },
      },
      physicianComments: '',
    },
    examinationDetailsSection: {
      BPD: {
        value: '22 cen',
        ga: '5w4d',
        centile: '22%',
      },
      HC: {
        value: '22 cen',
        ga: '5w4d',
        centile: '22%',
      },
      FL: {
        value: '22 cen',
        ga: '5w4d',
        centile: '22%',
      },
      AC: {
        value: '22 cen',
        ga: '5w4d',
        centile: '22%',
      },
      SDP: {
        value: '22 cen',
        ga: '5w4d',
        centile: '22%',
      },
    },
  },
};

export const reportAttrsVals = {
  section1: {
    col1: {
      firstHeader: 'FETAL BIOMETRY',
      secondHeader: 'ULTRASOUND BIOMETRY',
      examDate: '24-12-2023',
      practitioner: 'Dr. Hamid BENHAMID',
    },
    col2: {
      logo: 'reportLogo',
      email: 'patients@deepecho.io',
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy dummy text of the printing and typesetting",
    },
    col3: {
      firstCol: {
        title: 'Deepecho',
        qrValue: 'test test',
        qrBgColor: 'white',
        qrFgColor: 'black',
        qrSize: 100,
        ID: '275348975935',
      },
      secondCol: {
        RandomAttr: '98548783',
        TestID: 'Test Value',
        DeviceID: '4593859384',
        PatientCity: 'Douar l7ajja',
        PatientCountry: 'Morocco',
      },
    },
  },
  section2: {
    firstColFirstRow: {
      detectedPlan: 'Brain',
      quality: '97%',
      image:
        'https://www.shutterstock.com/shutterstock/photos/1493574323/display_1500/stock-photo-ultrasound-small-baby-at-weeks-weeks-pregnant-ultrasound-image-show-baby-or-fetus-1493574323.jpg',
      criterias: {
        criteria1: {
          value: 'Plan showing thalami',
          status: 'success',
        },
        criteria2: {
          value: 'Cerebellum not visible',
          status: 'success',
        },
        criteria3: {
          value: 'Symetrical plane',
          status: 'success',
        },
        criteria4: {
          value: 'Plan showing cavum septum pellucidi',
          status: 'success',
        },
        criteria5: {
          value: 'Head occupying more than half of the image',
          status: 'success',
        },
        criteria6: {
          value: 'less than 45 to horizontal',
          status: 'success',
        },
      },
    },
    secondColFirstRow: {
      detectedPlan: 'Brain',
      quality: '97%',
      image:
        'https://www.shutterstock.com/shutterstock/photos/1493574323/display_1500/stock-photo-ultrasound-small-baby-at-weeks-weeks-pregnant-ultrasound-image-show-baby-or-fetus-1493574323.jpg',
      criterias: {
        criteria1: {
          value: 'Plan showing thalami',
          status: 'faillure',
        },
        criteria2: {
          value: 'Cerebellum not visible',
          status: 'success',
        },
        criteria3: {
          value: 'Symetrical plane',
          status: 'success',
        },
        criteria4: {
          value: 'Plan showing cavum septum pellucidi',
          status: 'success',
        },
        criteria5: {
          value: 'Head occupying more than half of the image',
          status: 'success',
        },
        criteria6: {
          value: 'less than 45 to horizontal',
          status: 'success',
        },
      },
    },
    firstColSecondRow: {
      detectedPlan: 'Brain',
      quality: '97%',
      image:
        'https://www.shutterstock.com/shutterstock/photos/1493574323/display_1500/stock-photo-ultrasound-small-baby-at-weeks-weeks-pregnant-ultrasound-image-show-baby-or-fetus-1493574323.jpg',
      criterias: {
        criteria1: {
          value: 'Plan showing thalami',
          status: 'faillure',
        },
        criteria2: {
          value: 'Cerebellum not visible',
          status: 'success',
        },
        criteria3: {
          value: 'Symetrical plane',
          status: 'success',
        },
        criteria4: {
          value: 'Plan showing cavum septum pellucidi',
          status: 'success',
        },
        criteria5: {
          value: 'Head occupying more than half of the image',
          status: 'success',
        },
        criteria6: {
          value: 'less than 45 to horizontal',
          status: 'success',
        },
      },
    },
    secondColSecondRow: {
      detectedPlan: 'Brain',
      quality: '97%',
      image:
        'https://www.shutterstock.com/shutterstock/photos/1493574323/display_1500/stock-photo-ultrasound-small-baby-at-weeks-weeks-pregnant-ultrasound-image-show-baby-or-fetus-1493574323.jpg',
      criterias: {
        criteria1: {
          value: 'Plan showing thalami',
          status: 'faillure',
        },
        criteria2: {
          value: 'Cerebellum not visible',
          status: 'success',
        },
        criteria3: {
          value: 'Symetrical plane',
          status: 'success',
        },
        criteria4: {
          value: 'Plan showing cavum septum pellucidi',
          status: 'success',
        },
        criteria5: {
          value: 'Head occupying more than half of the image',
          status: 'success',
        },
        criteria6: {
          value: 'less than 45 to horizontal',
          status: 'success',
        },
      },
    },
  },
  section3: {
    generalInfoSection: {
      DOB: '12/12/1990',
      age: '32',
      lmp: '22/12/2023',
      RHP: 'Douar lahjer hospital',
      GA: '7w12',
      MS: 'followed patient',
      exam: 'first trimester',
    },
    examinationResSection: {
      resultsTable: {
        EGA: {
          current: '5w7d',
          previous: 'N/A',
          centile: '22%',
        },
        EFA: {
          current: '456 grams',
          previous: 'N/A',
          centile: '18%',
        },
        GRS: {
          current: 'Server FGR',
          previous: 'N/A',
          centile: '',
        },
        AFP: {
          current: 'No abnomalities',
          previous: 'N/A',
          centile: '',
        },
      },
      physicianComments: '',
    },
    examinationDetailsSection: {
      BPD: {
        value: '22 cen',
        ga: '5w4d',
        centile: '22%',
      },
      HC: {
        value: '22 cen',
        ga: '5w4d',
        centile: '22%',
      },
      FL: {
        value: '22 cen',
        ga: '5w4d',
        centile: '22%',
      },
      AC: {
        value: '22 cen',
        ga: '5w4d',
        centile: '22%',
      },
      SDP: {
        value: '22 cen',
        ga: '5w4d',
        centile: '22%',
      },
    },
  },
};
