import { API_ERROR, API_SUCCESS, GENERATE_SR, UPDATE_PREDICTIONS_STATE } from './actionType';

export const srApiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const srApiError = (actionType, error) => ({
  type: API_ERROR,
  payload: { actionType, error },
});

export const generateSR = payload => {
  return {
    type: GENERATE_SR,
    payload,
  };
};

export const updatePredictionsState = payload => {
  return {
    type: UPDATE_PREDICTIONS_STATE,
    payload,
  };
};
