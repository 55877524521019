import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { A11y, Controller, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import cephalLogo from '../../../src/assets/svgs/brain-line.png';
import abdominalLogo from '../../../src/assets/svgs/stomach.png';
import femurLogo from '../../../src/assets/svgs/bone.png';
import { PanelService, ServicesManager, Types } from '@ohif/core';

import { Button, Icon, IconButton, Tooltip } from '../';

import 'swiper/css';
import 'swiper/css/navigation';
import './style.css';
import ProgressPlanes from './component/progressPlanes';

const borderSize = 4;
const expandedWidth = 248;
const collapsedWidth = 25;

const collapsedHideWidth = expandedWidth - collapsedWidth - borderSize;
const styleMap = {
  open: {
    left: { marginLeft: '0px' },
    right: { marginRight: '0px' },
  },
  closed: {
    left: { marginLeft: '0px' },
    right: { marginRight: '0px' },
  },
};

const classesMap = {
  open: {
    left: ``,
    right: ``,
  },
  closed: {
    left: `mr-2 items-end`,
    right: `ml-2 items-start`,
  },
};

const openStateIconName = {
  left: 'push-left',
  right: 'push-right',
};

const position = {
  left: {
    right: 5,
  },
  right: {
    left: 5,
  },
};

const SidePanel = ({
  servicesManager,
  side,
  className,
  activeTabIndex: activeTabIndexProp,
  tabs,
}) => {
  const panelService: PanelService = servicesManager.services.panelService;

  const { t } = useTranslation('SidePanel');
  const baseClasses = `transition-all py-2 duration-300 ease-in-out z-50 ${side == 'left' ? 'h-full' : 'h-full'} drop-shadow-lg rounded-md bg-white flex flex-col`;
  // Tracks whether this SidePanel has been opened at least once since this SidePanel was inserted into the DOM.
  // Thus going to the Study List page and back to the viewer resets this flag for a SidePanel.
  const [hasBeenOpened, setHasBeenOpened] = useState(activeTabIndexProp !== null);
  const [panelOpen, setPanelOpen] = useState(activeTabIndexProp !== null);
  const [activeTabIndex, setActiveTabIndex] = useState(activeTabIndexProp ?? 0);
  const swiperRef = useRef() as any;
  const [swiper, setSwiper] = useState<any>();

  const prevRef = React.useRef();
  const nextRef = React.useRef();
  const baseStyle = {
    maxWidth: `${panelOpen ? expandedWidth : '40'}px`,
    width: `${panelOpen ? expandedWidth : '40'}px`,
  };
  const openStatus = panelOpen ? 'open' : 'closed';
  const style = Object.assign({}, styleMap[openStatus][side], baseStyle);

  const ActiveComponent = tabs[activeTabIndex].content;
  console.log('ActiveComponent', ActiveComponent);
  useEffect(() => {
    setPanelOpen(true);
  }, []);

  useEffect(() => {
    if (panelOpen && swiper) {
      swiper.slideTo(activeTabIndex, 500);
    }
  }, [panelOpen, swiper]);

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  const updatePanelOpen = useCallback((panelOpen: boolean) => {
    setPanelOpen(panelOpen);
    if (panelOpen) {
      setHasBeenOpened(true);
    }
  }, []);

  const updateActiveTabIndex = useCallback(
    (activeTabIndex: number) => {
      setActiveTabIndex(activeTabIndex);
      updatePanelOpen(true);
    },
    [updatePanelOpen]
  );

  useEffect(() => {
    const activatePanelSubscription = panelService.subscribe(
      panelService.EVENTS.ACTIVATE_PANEL,
      (activatePanelEvent: Types.ActivatePanelEvent) => {
        if (!hasBeenOpened || activatePanelEvent.forceActive) {
          const tabIndex = tabs.findIndex(tab => tab.id === activatePanelEvent.panelId);
          if (tabIndex !== -1) {
            updateActiveTabIndex(tabIndex);
          }
        }
      }
    );

    return () => {
      activatePanelSubscription.unsubscribe();
    };
  }, [tabs, hasBeenOpened, panelService, updateActiveTabIndex]);

  const getCloseStateComponent = () => {
    const _childComponents = Array.isArray(tabs) ? tabs : [tabs];
    return (
      <>
        <div
          className={classnames(
            'flex h-[28px] w-full cursor-pointer items-center justify-center rounded-md bg-white',
            side === 'left' ? 'justify-end' : 'justify-start'
          )}
          onClick={() => {
            updatePanelOpen(prev => !prev);
          }}
          data-cy={`side-panel-header-${side}`}
        >
          <Icon
            name={'navigation-panel-right-reveal'}
            className={classnames('text-primary-active', side === 'left' && 'rotate-180 transform')}
          />
        </div>
        <div className={classnames('mt-3 flex w-full justify-center')}>
          {_childComponents.map((childComponent, index) => (
            <Tooltip
              position={side === 'left' ? 'right' : 'left'}
              key={index}
              content={`${childComponent.label}`}
              className={classnames(
                'flex items-center',
                side === 'left' ? 'justify-end ' : 'justify-start '
              )}
            >
              <IconButton
                id={`${childComponent.name}-btn`}
                variant="text"
                color="inherit"
                size="initial"
                className="text-primary-active"
                onClick={() => {
                  updateActiveTabIndex(index);
                }}
              >
                <Icon
                  name={childComponent.iconName}
                  className="text-primary-active"
                  style={{
                    width: '22px',
                    height: '22px',
                  }}
                />
              </IconButton>
            </Tooltip>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="my-2 space-y-1">
      {side == 'left' && (
        <div className=" rounded-md bg-white p-3 shadow-md">
          <div className="mb-2 grid w-4/5 grid-cols-2 divide-x text-sm	font-semibold">
            <div className="space-x-2 text-left">
              <span className="text-[#a6abc8]">ID </span>
              <span className="mr-2 text-[#818a9b]">D201547</span>
            </div>
            <div className="space-x-2 text-center">
              <span className="text-[#a6abc8]">Age </span>
              <span className="text-[#2886c7]">29 </span>
            </div>
          </div>
          <div className="mb-2 text-xl font-semibold text-[#1f384c]">Samira Ait Hafid</div>
          {/* <div className="mb-2 text-sm text-[#a6abc8]">Report Planes State</div>
          <div className="mt-4 grid w-full grid-cols-1 space-y-2 divide-y">
            <ProgressPlanes
              plane={'Cephal'}
              isComplete={true}
              percent={100}
              logo={cephalLogo}
            />
            <ProgressPlanes
              plane={'Femur'}
              isComplete={false}
              percent={100 / 3}
              logo={femurLogo}
            />
            <ProgressPlanes
              plane={'Abdominal'}
              isComplete={false}
              percent={(100 * 2) / 3}
              logo={abdominalLogo}
            />
            <ProgressPlanes
              plane={'AF-Pocket'}
              isComplete={false}
              percent={0}
              logo={cephalLogo}
            />
          </div> */}
        </div>
      )}

      <div
        className={classnames(className, baseClasses, classesMap[openStatus][side])}
        style={style}
      >
        {side == 'left' && (
          <div className="flex w-full justify-between pl-5 pr-2">
            <span className="text-sm font-medium text-[#a6abc8]">Series</span>
            <span className="text-sm text-[#a6abc8]">23-05-2024</span>
          </div>
        )}

        {panelOpen ? (
          <React.Fragment>
            {/** Panel Header with Arrow and Close Actions */}
            {/*  */}
            {tabs.length > 1 &&
              _getMoreThanOneTabLayout(
                swiperRef,
                setSwiper,
                prevRef,
                nextRef,
                tabs,
                activeTabIndex,
                updateActiveTabIndex
              )}
            {/** carousel navigation with the arrows */}
            {/** only show carousel nav if tabs are more than 3 tabs */}
            {tabs.length > 3 && (
              <div className="text-primary-active flex w-full justify-end gap-2 bg-white py-1 px-2">
                <button
                  ref={prevRef}
                  className="swiper-button-prev-custom"
                >
                  <Icon
                    name={'icon-prev'}
                    className={classnames('text-primary-active')}
                  />
                </button>
                <button
                  ref={nextRef}
                  className="swiper-button-next-custom"
                >
                  <Icon
                    name={'icon-next'}
                    className={classnames('text-primary-active')}
                  />
                </button>
              </div>
            )}
            <ActiveComponent />
          </React.Fragment>
        ) : (
          <React.Fragment>{getCloseStateComponent()}</React.Fragment>
        )}
      </div>
    </div>
  );
};

SidePanel.defaultProps = {
  defaultComponentOpen: null,
};

SidePanel.propTypes = {
  servicesManager: PropTypes.instanceOf(ServicesManager),
  side: PropTypes.oneOf(['left', 'right']).isRequired,
  className: PropTypes.string,
  activeTabIndex: PropTypes.number,
  tabs: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        iconName: PropTypes.string.isRequired,
        iconLabel: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        content: PropTypes.func, // TODO: Should be node, but it keeps complaining?
      })
    ),
  ]),
};

function _getMoreThanOneTabLayout(
  swiperRef: any,
  setSwiper: React.Dispatch<any>,
  prevRef: React.MutableRefObject<undefined>,
  nextRef: React.MutableRefObject<undefined>,
  tabs: any,
  activeTabIndex: any,
  updateActiveTabIndex
) {
  return (
    <div
      className="flex-static collapse-sidebar relative"
      style={{
        backgroundColor: '#06081f',
      }}
    >
      <div className="w-full ">
        <Swiper
          onInit={(core: SwiperCore) => {
            swiperRef.current = core.el;
          }}
          simulateTouch={false}
          modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
          slidesPerView={3}
          spaceBetween={5}
          onSwiper={swiper => setSwiper(swiper)}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
        >
          {tabs.map((obj, index) => (
            <SwiperSlide key={index}>
              <div
                className={classnames(
                  index === activeTabIndex ? 'bg-blue-800 text-white' : 'text-aqua-pale bg-white',
                  'flex cursor-pointer flex-col items-center justify-center  rounded-[4px] px-4 py-1 text-center hover:text-white '
                )}
                key={index}
                onClick={() => {
                  updateActiveTabIndex(index);
                }}
                data-cy={`${obj.name}-btn`}
              >
                <span>
                  <Icon
                    name={obj.iconName}
                    className={classnames(
                      index === activeTabIndex ? 'text-white' : 'text-primary-active'
                    )}
                    style={{
                      width: '22px',
                      height: '22px',
                    }}
                  />
                </span>
                <span className="mt-[5px] select-none whitespace-nowrap text-[10px] font-medium">
                  {obj.label}
                </span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default SidePanel;
