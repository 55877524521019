import {
  API_ERROR,
  API_SUCCESS,
  GET_REPORTS,
  GET_REPORT,
  DELETE_REPORT,
  RESET_REPORTS_STATE,
  RESET_REPORT_STATE,
  GET_STATE_REPORT,
} from './actionType';

export const reportApiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const reportApiError = (actionType, error) => ({
  type: API_ERROR,
  payload: { actionType, error },
});

export const getReport = payload => {
  return {
    type: GET_REPORT,
    payload,
  };
};
export const getReports = payload => {
  return {
    type: GET_REPORTS,
    payload,
  };
};

export const getStateReport = payload => {
  return {
    type: GET_STATE_REPORT,
    payload,
  };
};

export const deleteReport = payload => ({
  type: DELETE_REPORT,
  payload,
});
export const resetReportsState = () => {
  return {
    type: RESET_REPORTS_STATE,
  };
};

export const resetReportState = () => {
  return {
    type: RESET_REPORT_STATE,
  };
};
