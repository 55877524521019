import { ServicesManager } from '@ohif/core';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import MeasurementModeHeader from '../MeasurementModeHeader/MeasurementModeHeader';
import { CiFileOn } from 'react-icons/ci';

interface MeasurementOhifModeProps {
  data: any;
  measurementService: any;
  activeViewportIndex: number;
  onImageScrollbarChange: any;
  onClick: any;
  unlabeledMeasurements: any;
  drawingMode: boolean;
  mode?: boolean;
  changeDrawingMode: () => void;
}

function MeasurementOhifMode({
  data,
  measurementService,
  activeViewportIndex,
  onImageScrollbarChange,
  onClick,
  unlabeledMeasurements,
  drawingMode,
  mode,
  changeDrawingMode,
}: MeasurementOhifModeProps) {
  const [selectedMeasurement, setSelectedMeasurement] = useState(null);
  const [selectedMeasurementIndex, setSelectedMeasurementIndex] = useState(null);

  useEffect(() => {
    const selectedData = data.find(
      data => data?.label !== undefined && data?.isActive === true && !data?.label.includes('plane')
    );
    if (selectedData === undefined) return;

    const selectedUnlabeledMeasurement = unlabeledMeasurements.find(
      measurement => measurement.uid === selectedData.uid
    );
    if (selectedUnlabeledMeasurement === undefined) return;

    setSelectedMeasurement(selectedUnlabeledMeasurement);
    setSelectedMeasurementIndex(unlabeledMeasurements.indexOf(selectedUnlabeledMeasurement));
  }, [data, unlabeledMeasurements]);

  function HandleClick(index: number) {
    const selectedMeasurement = unlabeledMeasurements[index];
    const imageIndex = selectedMeasurement?.frameNumber;
    measurementService.jumpToMeasurement(activeViewportIndex, selectedMeasurement?.uid);
    // onImageScrollbarChange(imageIndex, activeViewportIndex);
    // onClick({
    //     uid: selectedMeasurement?.uid,
    //     isActive: false,
    // });
    setSelectedMeasurement(selectedMeasurement);
    setSelectedMeasurementIndex(index);
  }

  return (
    <>
      <div className="mb-1 mt-4 flex w-full flex-wrap justify-center gap-1">
        {unlabeledMeasurements.length === 0 ? (
          <div className="mt-12 flex w-full flex-col items-center justify-center text-center text-gray-500">
            <CiFileOn
              size={100}
              className="mb-2 rounded-full bg-blue-200 p-2 text-white"
            />
            <span className="text-center text-sm font-semibold text-[#1F384C]">
              No unlabeled measurement detected.
            </span>
            <span className="text-xs text-gray-400">
              Please Draw a measurement on the image to start the measurement process.
            </span>
          </div>
        ) : (
          unlabeledMeasurements.map((measurement, index) => (
            <div
              key={index}
              className={classnames('group flex cursor-pointer rounded transition duration-300', {
                'border-primary-light overflow-hidden': selectedMeasurementIndex === index,
              })}
              role="button"
            >
              <div
                className={classnames(
                  'w-6 rounded py-1 text-center text-base transition duration-300',
                  {
                    'bg-blue-600 text-white hover:bg-blue-500': selectedMeasurementIndex === index,
                    'border border-blue-300 bg-white text-blue-600 hover:bg-blue-100':
                      selectedMeasurementIndex !== index,
                  }
                )}
                onClick={() => HandleClick(index)}
              >
                {index + 1}
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
}

export default MeasurementOhifMode;
