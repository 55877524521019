import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { GET_PROFILE } from './actionTypes';
import { profileSuccess, profileError } from './actions';

//Include Both Helper File with needed methods

import { getJwtProfile } from '../../helpers/backendHelper';

function* getProfile() {
  try {
    const response = yield call(getJwtProfile);
    yield put(profileSuccess(response));
  } catch (error) {
    yield put(profileError(error));
  }
}

export function* watchProfile() {
  yield takeEvery(GET_PROFILE, getProfile);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
