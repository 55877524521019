import React, { useEffect, useState } from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';

import './latestExams.section.css';
import exam from '../../assets/exam.png';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { getRecentStudies } from '../../../helpers/api/study/study.api';
import { APIClient } from '../../../helpers/apiHelper';
import { Spin } from 'antd';

interface LatestExamsProps {
  datax: any;
}

const LatestExams: React.FC<LatestExamsProps> = ({ datax }) => {
  const { isLoading, data } = getRecentStudies();
  console.log('full records:', isLoading, ' : ', data);
  const backend_url: string = process.env.BACKEND_URL as string;
  const [images, setImages] = useState({});
  const [loadingImages, setLoadingImages] = useState({});
  const api = new APIClient();

  useEffect(() => {
    if (data?.data?.length) {
      data.data.forEach(async item => {
        setLoadingImages(prev => ({ ...prev, [item.study_instance_uid]: true }));
        const url = `${process.env.BACKEND_URL}/dcm4chee-arc/aets/DCM4CHEE/rs/getFrameByWado?studyUID=${item?.study_instance_uid}&seriesUID=${item?.series?.[0]?.series_id}&objectUID=${item?.series?.[0]?.sop_instance_uid}`;
        try {
          const response = await api.getImages(url);
          setImages(prevImages => ({
            ...prevImages,
            [item.study_instance_uid]: response,
          }));
        } catch (error) {
          console.error('Error fetching image:', error);
        } finally {
          setLoadingImages(prev => ({ ...prev, [item.study_instance_uid]: false }));
        }
      });
    }
  }, [data]);

  function formatDate(birthDate: any) {
    const options: any = { month: 'long', day: 'numeric', year: 'numeric' };
    return new Date(birthDate).toLocaleDateString('en-US', options);
  }

  return (
    <div className="inline-block h-full w-full overflow-auto">
      {isLoading ? (
        <div className="flex h-full w-full items-center justify-center">
          <p className="border border-blue-400 p-3 text-lg font-normal text-[#5A5A5A]">
            Loading...
          </p>
        </div>
      ) : (
        <>
          {data?.data?.length === 0 && (
            <div className="flex h-full w-full items-center justify-center">
              <p className="border border-blue-400 p-3 text-lg font-normal text-[#5A5A5A]">
                No exams available
              </p>
            </div>
          )}
          {Array.isArray(data?.data) &&
            data?.data?.map(item => (
              <Link
                to={`/exams/viewer?StudyInstanceUIDs=${item?.study_instance_uid}`}
                key={item.study_instance_uid}
              >
                <div className="inline-block w-1/6 p-2 text-center">
                  {loadingImages[item.study_instance_uid] ? (
                    <div className="flex items-center justify-center lg:h-[110px] lg:w-[171.2px]">
                      <Spin className="mr-2" />
                    </div>
                  ) : (
                    <img
                      src={images[item.study_instance_uid]}
                      alt="Exam Thumbnail"
                      draggable={false}
                      className="rounded-lg lg:h-[110px] lg:w-[171.2px]"
                    />
                  )}
                  <p className="text-[8px] font-normal text-[#5A5A5A]">
                    {formatDate(item?.created_at)}
                  </p>
                  <p className="text-xs font-normal text-black">{item.patient_patient_name}</p>
                </div>
              </Link>
            ))}
        </>
      )}
    </div>
  );
};

export default LatestExams;
