import React from 'react';
import { useState, useEffect } from 'react';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'; // Import icons

const AlertView = ({ imgPlans }) => {
  const [timerHeight, setTimerHeight] = useState(100); // Starting at 100% height

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimerHeight(prev => (prev > 0 ? prev - 20 : 0)); // Decrease by 20% every second
    }, 1000); // Runs every second

    return () => clearInterval(countdown); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="absolute  bottom-3 left-3 z-50">
      {/* Image Container */}
      <img
        src={imgPlans}
        alt="Four Chamber View"
        className="w-full rounded-2xl border-2 border-[#a6abc8]"
      />

      {/* Text Overlay */}
      <span className="absolute top-2 left-2 w-1/2 text-lg font-semibold text-white">
        Four Chamber View Of The Heart
      </span>

      {/* Icons for Check and Cross */}
      <div className="absolute top-2 right-5 flex items-center space-x-2">
        <AiOutlineCheckCircle
          className="text-green-500"
          size={25}
        />
        <AiOutlineCloseCircle
          className="text-red-500"
          size={25}
        />
      </div>

      {/* Red Timer (Vertical Bar) */}
      <div className="absolute top-5 right-3 h-[80%] w-1 overflow-hidden rounded-full bg-gray-300">
        <div
          className="rounded-full bg-red-600"
          style={{
            height: `${timerHeight}%`,
            transition: 'height 1s linear',
          }}
        ></div>
      </div>
    </div>
  );
};

export default AlertView;
