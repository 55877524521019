import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import './PageLayout.css';
import Navbar from './UI/Sections/Navbar/navbar.section';
import smallLogo from './DEEPECHO logo small.png';
import largeLogo from './DEEPECHO logo.png';
import Sidebar from './UI/Sections/SideBar/sidebar.section';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { withAuthenticationRequired } from 'react-oidc-context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { Content } = Layout;

const queryClient = new QueryClient();

export const deepechologo = {
  small: smallLogo,
  large: largeLogo,
};

const AppLayout = props => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Layout
        className="w-full overflow-hidden bg-white"
        style={{ minHeight: '400px', height: '100vh' }}
      >
        <div className="flex h-full">
          <Sidebar />
          <Layout>
            <Content>
              <QueryClientProvider client={queryClient}>
                <ToastContainer />
                <div className="h-full w-screen md:w-full">
                  {!location.pathname.startsWith('/exams/viewer') && (
                    <div className="bg-[#f7fafe]">
                      <Navbar />
                    </div>
                  )}
                  <div
                    className={`${location.pathname.startsWith('/exams/viewer') ? 'h-full' : 'h-[calc(100%-49.73px)] py-2'} bg-white`}
                  >
                    {props.children}
                  </div>
                </div>
              </QueryClientProvider>
            </Content>
          </Layout>
        </div>
      </Layout>
    </>
  );
};

// Check if auth is activated
const IS_AUTH_ACTIVATED = process.env.IS_AUTH_ACTIVATED === 'True';

export default IS_AUTH_ACTIVATED
  ? withAuthenticationRequired(AppLayout, {
      signinRedirectArgs: { redirect_uri: window.location.href },
    })
  : AppLayout;
