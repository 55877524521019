export const handleDOBChange = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      generalInfoSection: {
        ...examData.section3.generalInfoSection,
        DOB: e.target.value,
      },
    },
  });
};

export const handleAgeChange = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      generalInfoSection: {
        ...examData.section3.generalInfoSection,
        age: e.target.value,
      },
    },
  });
};

export const handleLMPChange = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      generalInfoSection: {
        ...examData.section3.generalInfoSection,
        lmp: e.target.value,
      },
    },
  });
};

export const handleGAChange = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      generalInfoSection: {
        ...examData.section3.generalInfoSection,
        lmp: e.target.value,
      },
    },
  });
};

export const handleExamChange = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      generalInfoSection: {
        ...examData.section3.generalInfoSection,
        lmp: e.target.value,
      },
    },
  });
};

export const handleRHPChange = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      generalInfoSection: {
        ...examData.section3.generalInfoSection,
        RHP: e.target.value,
      },
    },
  });
};

export const handleMSChange = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      generalInfoSection: {
        ...examData.section3.generalInfoSection,
        RHP: e.target.value,
      },
    },
  });
};

export const handleEGAChange_curr = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationResSection: {
        ...examData.section3.examinationResSection,
        resultsTable: {
          ...examData.section3.examinationResSection.resultsTable,
          EGA: {
            ...examData.section3.examinationResSection.resultsTable.EGA,
            current: e.target.value,
          },
        },
      },
    },
  });
};

export const handleEGAChange_prev = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationResSection: {
        ...examData.section3.examinationResSection,
        resultsTable: {
          ...examData.section3.examinationResSection.resultsTable,
          EGA: {
            ...examData.section3.examinationResSection.resultsTable.EGA,
            previous: e.target.value,
          },
        },
      },
    },
  });
};
export const handleEGAChange_cent = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationResSection: {
        ...examData.section3.examinationResSection,
        resultsTable: {
          ...examData.section3.examinationResSection.resultsTable,
          EGA: {
            ...examData.section3.examinationResSection.resultsTable.EGA,
            centile: e.target.value,
          },
        },
      },
    },
  });
};

export const handleEFAChange_curr = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationResSection: {
        ...examData.section3.examinationResSection,
        resultsTable: {
          ...examData.section3.examinationResSection.resultsTable,
          EFA: {
            ...examData.section3.examinationResSection.resultsTable.EFA,
            current: e.target.value,
          },
        },
      },
    },
  });
};

export const handleEFAChange_prev = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationResSection: {
        ...examData.section3.examinationResSection,
        resultsTable: {
          ...examData.section3.examinationResSection.resultsTable,
          EFA: {
            ...examData.section3.examinationResSection.resultsTable.EFA,
            previous: e.target.value,
          },
        },
      },
    },
  });
};

export const handleEFAChange_cent = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationResSection: {
        ...examData.section3.examinationResSection,
        resultsTable: {
          ...examData.section3.examinationResSection.resultsTable,
          EFA: {
            ...examData.section3.examinationResSection.resultsTable.EFA,
            centile: e.target.value,
          },
        },
      },
    },
  });
};

export const handleGRSChange_curr = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationResSection: {
        ...examData.section3.examinationResSection,
        resultsTable: {
          ...examData.section3.examinationResSection.resultsTable,
          GRS: {
            ...examData.section3.examinationResSection.resultsTable.GRS,
            current: e.target.value,
          },
        },
      },
    },
  });
};

export const handleGRSChange_prev = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationResSection: {
        ...examData.section3.examinationResSection,
        resultsTable: {
          ...examData.section3.examinationResSection.resultsTable,
          GRS: {
            ...examData.section3.examinationResSection.resultsTable.GRS,
            previous: e.target.value,
          },
        },
      },
    },
  });
};

export const handleGRSChange_cent = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationResSection: {
        ...examData.section3.examinationResSection,
        resultsTable: {
          ...examData.section3.examinationResSection.resultsTable,
          GRS: {
            ...examData.section3.examinationResSection.resultsTable.GRS,
            centile: e.target.value,
          },
        },
      },
    },
  });
};

export const handleAFPChange_curr = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationResSection: {
        ...examData.section3.examinationResSection,
        resultsTable: {
          ...examData.section3.examinationResSection.resultsTable,
          AFP: {
            ...examData.section3.examinationResSection.resultsTable.AFP,
            current: e.target.value,
          },
        },
      },
    },
  });
};

export const handleAFPChange_prev = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationResSection: {
        ...examData.section3.examinationResSection,
        resultsTable: {
          ...examData.section3.examinationResSection.resultsTable,
          AFP: {
            ...examData.section3.examinationResSection.resultsTable.AFP,
            previous: e.target.value,
          },
        },
      },
    },
  });
};

export const handleAFPChange_cent = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationResSection: {
        ...examData.section3.examinationResSection,
        resultsTable: {
          ...examData.section3.examinationResSection.resultsTable,
          AFP: {
            ...examData.section3.examinationResSection.resultsTable.AFP,
            centile: e.target.value,
          },
        },
      },
    },
  });
};

export const handleCommentsChange = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationResSection: {
        ...examData.section3.examinationResSection,
        physicianComments: e.target.value,
      },
    },
  });
};

// ====================================

export const handleBPDChange_val = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        BPD: {
          ...examData.section3.examinationDetailsSection.BPD,
          value: e.target.value,
        },
      },
    },
  });
};

export const handleBPDChange_ga = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        BPD: {
          ...examData.section3.examinationDetailsSection.BPD,
          ga: e.target.value,
        },
      },
    },
  });
};

export const handleBPDChange_cent = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        BPD: {
          ...examData.section3.examinationDetailsSection.BPD,
          centile: e.target.value,
        },
      },
    },
  });
};

export const handleHCChange_val = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        HC: {
          ...examData.section3.examinationDetailsSection.HC,
          value: e.target.value,
        },
      },
    },
  });
};

export const handleHCChange_ga = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        HC: {
          ...examData.section3.examinationDetailsSection.HC,
          ga: e.target.value,
        },
      },
    },
  });
};

export const handleHCChange_cent = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        HC: {
          ...examData.section3.examinationDetailsSection.HC,
          centile: e.target.value,
        },
      },
    },
  });
};

export const handleFLChange_val = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        FL: {
          ...examData.section3.examinationDetailsSection.FL,
          value: e.target.value,
        },
      },
    },
  });
};

export const handleFLChange_ga = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        FL: {
          ...examData.section3.examinationDetailsSection.FL,
          ga: e.target.value,
        },
      },
    },
  });
};

export const handleFLChange_cent = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        FL: {
          ...examData.section3.examinationDetailsSection.FL,
          centile: e.target.value,
        },
      },
    },
  });
};

export const handleACChange_val = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        AC: {
          ...examData.section3.examinationDetailsSection.AC,
          value: e.target.value,
        },
      },
    },
  });
};

export const handleACChange_ga = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        AC: {
          ...examData.section3.examinationDetailsSection.AC,
          ga: e.target.value,
        },
      },
    },
  });
};

export const handleACChange_cent = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        AC: {
          ...examData.section3.examinationDetailsSection.AC,
          centile: e.target.value,
        },
      },
    },
  });
};

export const handleSDPChange_val = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        SDP: {
          ...examData.section3.examinationDetailsSection.SDP,
          value: e.target.value,
        },
      },
    },
  });
};

export const handleSDPChange_ga = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        SDP: {
          ...examData.section3.examinationDetailsSection.SDP,
          ga: e.target.value,
        },
      },
    },
  });
};

export const handleSDPChange_cent = (e, setExamData, examData) => {
  e.preventDefault();
  setExamData({
    ...examData,
    section3: {
      ...examData.section3,
      examinationDetailsSection: {
        ...examData.section3.examinationDetailsSection,
        SDP: {
          ...examData.section3.examinationDetailsSection.SDP,
          centile: e.target.value,
        },
      },
    },
  });
};

export const onChangeSwitchFCFR_cri1 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      firstColFirstRow: {
        ...examData.section2.firstColFirstRow,
        criterias: {
          ...examData.section2.firstColFirstRow.criterias,
          criteria1: {
            ...examData.section2.firstColFirstRow.criterias.criteria1,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchFCFR_cri2 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      firstColFirstRow: {
        ...examData.section2.firstColFirstRow,
        criterias: {
          ...examData.section2.firstColFirstRow.criterias,
          criteria2: {
            ...examData.section2.firstColFirstRow.criterias.criteria2,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchFCFR_cri3 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      firstColFirstRow: {
        ...examData.section2.firstColFirstRow,
        criterias: {
          ...examData.section2.firstColFirstRow.criterias,
          criteria3: {
            ...examData.section2.firstColFirstRow.criterias.criteria3,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchFCFR_cri4 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      firstColFirstRow: {
        ...examData.section2.firstColFirstRow,
        criterias: {
          ...examData.section2.firstColFirstRow.criterias,
          criteria4: {
            ...examData.section2.firstColFirstRow.criterias.criteria4,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};
export const onChangeSwitchFCFR_cri5 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      firstColFirstRow: {
        ...examData.section2.firstColFirstRow,
        criterias: {
          ...examData.section2.firstColFirstRow.criterias,
          criteria5: {
            ...examData.section2.firstColFirstRow.criterias.criteria5,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};
export const onChangeSwitchFCFR_cri6 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      firstColFirstRow: {
        ...examData.section2.firstColFirstRow,
        criterias: {
          ...examData.section2.firstColFirstRow.criterias,
          criteria6: {
            ...examData.section2.firstColFirstRow.criterias.criteria6,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchFCSR_cri1 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      firstColSecondRow: {
        ...examData.section2.firstColSecondRow,
        criterias: {
          ...examData.section2.firstColSecondRow.criterias,
          criteria1: {
            ...examData.section2.firstColSecondRow.criterias.criteria1,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchFCSR_cri2 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      firstColSecondRow: {
        ...examData.section2.firstColSecondRow,
        criterias: {
          ...examData.section2.firstColSecondRow.criterias,
          criteria2: {
            ...examData.section2.firstColSecondRow.criterias.criteria2,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchFCSR_cri3 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      firstColSecondRow: {
        ...examData.section2.firstColSecondRow,
        criterias: {
          ...examData.section2.firstColSecondRow.criterias,
          criteria3: {
            ...examData.section2.firstColSecondRow.criterias.criteria3,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchFCSR_cri4 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      firstColSecondRow: {
        ...examData.section2.firstColSecondRow,
        criterias: {
          ...examData.section2.firstColSecondRow.criterias,
          criteria4: {
            ...examData.section2.firstColSecondRow.criterias.criteria4,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};
export const onChangeSwitchFCSR_cri5 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      firstColSecondRow: {
        ...examData.section2.firstColSecondRow,
        criterias: {
          ...examData.section2.firstColSecondRow.criterias,
          criteria5: {
            ...examData.section2.firstColSecondRow.criterias.criteria5,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};
export const onChangeSwitchFCSR_cri6 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      firstColSecondRow: {
        ...examData.section2.firstColSecondRow,
        criterias: {
          ...examData.section2.firstColSecondRow.criterias,
          criteria6: {
            ...examData.section2.firstColSecondRow.criterias.criteria6,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};
export const onChangeSwitchSCFR_cri1 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      secondColFirstRow: {
        ...examData.section2.secondColFirstRow,
        criterias: {
          ...examData.section2.secondColFirstRow.criterias,
          criteria1: {
            ...examData.section2.secondColFirstRow.criterias.criteria1,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchSCFR_cri2 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      secondColFirstRow: {
        ...examData.section2.secondColFirstRow,
        criterias: {
          ...examData.section2.secondColFirstRow.criterias,
          criteria2: {
            ...examData.section2.secondColFirstRow.criterias.criteria2,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchSCFR_cri3 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      secondColFirstRow: {
        ...examData.section2.secondColFirstRow,
        criterias: {
          ...examData.section2.secondColFirstRow.criterias,
          criteria3: {
            ...examData.section2.secondColFirstRow.criterias.criteria3,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchSCFR_cri4 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      secondColFirstRow: {
        ...examData.section2.secondColFirstRow,
        criterias: {
          ...examData.section2.secondColFirstRow.criterias,
          criteria4: {
            ...examData.section2.secondColFirstRow.criterias.criteria4,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};
export const onChangeSwitchSCFR_cri5 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      secondColFirstRow: {
        ...examData.section2.secondColFirstRow,
        criterias: {
          ...examData.section2.secondColFirstRow.criterias,
          criteria5: {
            ...examData.section2.secondColFirstRow.criterias.criteria5,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};
export const onChangeSwitchSCFR_cri6 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      secondColFirstRow: {
        ...examData.section2.secondColFirstRow,
        criterias: {
          ...examData.section2.secondColFirstRow.criterias,
          criteria6: {
            ...examData.section2.secondColFirstRow.criterias.criteria6,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchSCSR_cri1 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      secondColSecondRow: {
        ...examData.section2.secondColSecondRow,
        criterias: {
          ...examData.section2.secondColSecondRow.criterias,
          criteria1: {
            ...examData.section2.secondColSecondRow.criterias.criteria1,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchSCSR_cri2 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      secondColSecondRow: {
        ...examData.section2.secondColSecondRow,
        criterias: {
          ...examData.section2.secondColSecondRow.criterias,
          criteria2: {
            ...examData.section2.secondColSecondRow.criterias.criteria2,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchSCSR_cri3 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      secondColSecondRow: {
        ...examData.section2.secondColSecondRow,
        criterias: {
          ...examData.section2.secondColSecondRow.criterias,
          criteria3: {
            ...examData.section2.secondColSecondRow.criterias.criteria3,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};

export const onChangeSwitchSCSR_cri4 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      secondColSecondRow: {
        ...examData.section2.secondColSecondRow,
        criterias: {
          ...examData.section2.secondColSecondRow.criterias,
          criteria4: {
            ...examData.section2.secondColSecondRow.criterias.criteria4,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};
export const onChangeSwitchSCSR_cri5 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      secondColSecondRow: {
        ...examData.section2.secondColSecondRow,
        criterias: {
          ...examData.section2.secondColSecondRow.criterias,
          criteria5: {
            ...examData.section2.secondColSecondRow.criterias.criteria5,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};
export const onChangeSwitchSCSR_cri6 = (checked: boolean, setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      secondColSecondRow: {
        ...examData.section2.secondColSecondRow,
        criterias: {
          ...examData.section2.secondColSecondRow.criterias,
          criteria6: {
            ...examData.section2.secondColSecondRow.criterias.criteria6,
            status: checked ? 'success' : 'faillure',
          },
        },
      },
    },
  });
};
