import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import pushNotification from '../../UI/Pages/PatientManagement/notifications';

export function useGenericMutation(
  mutationFn,
  queryKey,
  errorMsg = 'Error while performing mutation',
  successMsg = 'Mutation performed successfully',
  updater = () => {}
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: mutationFn,
    onMutate: async data => {
      await queryClient.cancelQueries({ queryKey: queryKey });

      const previousData = queryClient.getQueryData(queryKey);

      // TODO: add updater caching

      return previousData;
    },
    onSuccess: () => {
      pushNotification.success(successMsg);
    },
    onError: (err, newEntity, context) => {
      // TODO: edit this to implement caching
      queryClient.setQueryData(queryKey, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
    },
  });
}

export function useFetch(queryFn, queryKey, config = {}) {
  return useQuery({
    queryKey: queryKey,
    queryFn: queryFn,
    staleTime: 5000,
    refetchOnWindowFocus: true,
    retry: 1,

    ...config,
  });
}

export function useInfiniteFetch(
  queryFn,
  queryKey,
  getNextPageParam,
  initialPageParam,
  config = {}
) {
  return useInfiniteQuery({
    queryKey: queryKey,
    queryFn: queryFn,
    getNextPageParam: getNextPageParam,
    initialPageParam: initialPageParam,
    ...config,
  });
}

export function useCreate(
  createFn,
  queryKey,
  errorMsg = 'Error while creating new entity',
  successMsg = 'Entity created successfully'
) {
  return useGenericMutation(createFn, queryKey, errorMsg, successMsg);
}

export function useEdit(
  editFn,
  queryKey,
  errorMsg = 'Error while editing entity',
  successMsg = 'Entity edited successfully'
) {
  return useGenericMutation(editFn, queryKey, errorMsg, successMsg);
}

export function useDelete(
  deleteFn,
  queryKey,
  errorMsg = 'Error while deleting entity',
  successMsg = 'Entity deleted successfully'
) {
  return useGenericMutation(deleteFn, queryKey, errorMsg, successMsg);
}

export function usePaginated(getPaginatedFn, queryKey, page, config = {}) {
  return useFetch(() => getPaginatedFn(page), queryKey, config);
}

export function useInfinite(getInfiniteUsersFn, queryKey, getNextPageParam, config = {}) {
  return useInfiniteFetch(getInfiniteUsersFn, queryKey, getNextPageParam, config);
}

export function useSingleFetch(getSingleFn, queryKey, id, config = {}) {
  return useFetch(() => getSingleFn(id), queryKey, config);
}
