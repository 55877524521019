import React from 'react';
import { MdConstruction } from 'react-icons/md';
import { IoSettingsOutline } from 'react-icons/io5';
import { FaLocationArrow } from 'react-icons/fa';
import { useNavigate } from 'react-router';
const Settings: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-full w-full flex-col items-center justify-center space-y-3">
      <div className="flex w-80 flex-col	items-center justify-center rounded-xl border-4 border-[#1c7dbf] p-3 text-[#1c7dbf]">
        <MdConstruction size={100} />
        <div className="text-xl font-bold">UNDER CONSTRUCTION</div>
        <div className="mt-5 inline-flex items-center justify-center space-x-2 text-black">
          <IoSettingsOutline size={14} />
          <div>Settings page coming soon ...</div>
        </div>
      </div>
      <div>
        <button
          className="inline-flex cursor-pointer items-center justify-center space-x-2
        rounded-md border-2 border-[#1c7dbf] bg-[#1c7dbf] p-2 font-medium text-white hover:bg-white hover:text-[#1c7dbf]"
          onClick={() => {
            navigate('/home');
          }}
        >
          <FaLocationArrow />
          <div>Go to dashboard</div>
        </button>
      </div>
    </div>
  );
};

export default Settings;
