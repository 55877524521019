import React, { useEffect, useState } from 'react';
import Slider from '../Slider/Slider';
import cephalic from '../../assets/cephalic.png';
import abdominal from '../../assets/abdominal.png';
import afpocket from '../../assets/afpocket.png';
import femur from '../../assets/femur.png';
import FramePagination from '../FramePagination/FramePagination';
import { getSOPInstanceAttributes } from '../../../../../../../extensions/cornerstone/src/utils/measurementServiceMappings/utils';
import PlaneInformation from '../PlaneInformation/PlaneInformation';
import { SlScreenDesktop } from 'react-icons/sl';
import { PiDesktopThin } from 'react-icons/pi';
import classNames from 'classnames';

interface PaginationStateType {
  pagination: {
    current: number;
    last: number;
  };
}
interface MeasurementDeepEchoModeProps {
  frameByPlane: any;
  data: any;
  measurementService: any;
  activeViewportIndex: number;
  onImageScrollbarChange: any;
  onClick: any;
  drawingMode: boolean;
  mode?: boolean;
  changeDrawingMode: () => void;
  SetFrame?: (num: number) => void;
  setPlane?: (plane: string) => void;
  isLoading?: boolean;
  isError?: boolean;
  setSerieId?: (id: string) => void;
}

function MeasurementDeepEchoMode({
  frameByPlane,
  data,
  measurementService,
  activeViewportIndex,
  onImageScrollbarChange,
  onClick,
  drawingMode,
  mode,
  changeDrawingMode,
  setPlane,
  SetFrame,
  isLoading,
  isError,
  setSerieId,
}: MeasurementDeepEchoModeProps) {
  const [paginationState, setPaginationState] = useState<PaginationStateType>({
    pagination: { current: 1, last: 1 },
  });
  const [planes, setPlanes] = useState<string[]>([]);
  const [keysByPlane, setKeysByPlane] = useState<{ [key: string]: string[] }>({});
  const [keysSelectedPlane, setKeysSelectedPlane] = useState<string[]>([]);
  const [startPlane, setStartPlane] = useState(0);
  const [selectedKey, setSelectedKey] = useState('');
  const [FrameId, setFrameId] = useState<string | undefined>();
  const [clicked, setClicked] = useState(false);
  const [page, setPage] = useState<number | null>(null);
  const [PlaneInfo, setPlaneInfo] = useState<any | null>(null);
  const [selectedPlane, setSelectedPlane] = useState<string | null>(null);
  const [selectedFrame, setSelectedFrame] = useState<number | null>(null);
  const [seriesIds, setSeriesIds] = useState<string[]>([]);
  const [selectedSerieId, setSelectedSerieId] = useState<number | null>(0);
  const imageMapping = {
    cephalic,
    abdominal,
    afpocket,
    femur,
  };
  useEffect(() => {
    const seriesKeys = Object.keys(frameByPlane);
    setSeriesIds(seriesKeys);

    if (frameByPlane == undefined || seriesKeys.length == 0) return;

    const planeKeys = Object.keys(frameByPlane[seriesKeys[selectedSerieId]]).filter(
      plane =>
        Object.keys(frameByPlane[seriesKeys[selectedSerieId]][plane])?.length > 0 &&
        ['cephalic', 'abdominal', 'afpocket', 'femur'].includes(plane)
    );

    const keysByPlan = planeKeys.reduce((acc, plane) => {
      const keys = Object.keys(frameByPlane[seriesKeys[selectedSerieId]][plane]);
      if (keys?.length > 0) {
        acc[plane] = keys;
      }
      return acc;
    }, {});

    setKeysByPlane(keysByPlan);
    setPlanes(planeKeys);
    // if (planeKeys?.length > 0 && drawingMode === false) {
    //     const selectedPlane = planeKeys[0];
    //     setSelectedPlane(selectedPlane);
    //     setPlane(selectedPlane);
    //     const selectedPlaneKeys = keysByPlan[selectedPlane] || [];
    //     const lastChunk = selectedPlaneKeys[selectedPlaneKeys?.length - 1];
    //     const lastKey = lastChunk[lastChunk?.length - 1];
    //     setSelectedKey(lastKey);
    //     setStartPlane(planeKeys.indexOf(selectedPlane));
    //     setKeysSelectedPlane(selectedPlaneKeys);
    //     const currentPage = selectedPlaneKeys.indexOf(selectedKey) + 1;
    //     setPaginationState({
    //         pagination: {
    //             current: currentPage,
    //             last: selectedPlaneKeys?.length,
    //         },
    //     });
    //     setPage(currentPage);
    // }

    if (planeKeys?.length > 0) {
      const measurements = measurementService.getMeasurements();
      const lastMeasurement = measurements.find(measurement => measurement?.selected === true);
      const imageId = getSOPInstanceAttributes(
        lastMeasurement?.metadata?.referencedImageId
      )?.frameNumber;

      setFrameId(imageId);

      // must use find instead of filter
      let selectedData = data?.filter(
        data =>
          data?.label !== undefined && data?.isActive === true && data?.label.includes('plane')
      )[0];
      if (selectedData === undefined) {
        selectedData = data?.filter(
          data => data?.label !== undefined && data?.label.includes('plane')
        )[0];
      }
      if (selectedData === undefined) return;
      const selectedPlane = selectedData?.label.split('_')[1];
      setSelectedPlane(selectedPlane);
      setPlane(selectedPlane);
      const selectedPlaneKeys = keysByPlan[selectedPlane] || [];
      if (selectedPlaneKeys?.length === 0) {
        return;
      }
      const lastChunk = selectedPlaneKeys[selectedPlaneKeys?.length - 1];
      const lastKey = lastChunk[lastChunk?.length - 1];
      setSelectedKey(lastKey);
      setStartPlane(planeKeys.indexOf(selectedPlane));
      setKeysSelectedPlane(selectedPlaneKeys);

      const currentPage = selectedPlaneKeys.indexOf(String(imageId)) + 1;
      setPlaneInfo(
        frameByPlane[seriesKeys[selectedSerieId]][selectedPlane][selectedPlaneKeys[currentPage - 1]]
      );

      setSelectedFrame(selectedPlaneKeys[currentPage - 1]);
      SetFrame(Number(selectedPlaneKeys[currentPage - 1]));
      setPaginationState({
        pagination: {
          current: currentPage,
          last: selectedPlaneKeys?.length,
        },
      });
      setPage(currentPage);
    }
  }, [frameByPlane, data, measurementService, mode, selectedSerieId]);

  const changeStartPlane = (index: number) => {
    setStartPlane(index);
    const selectedPlane = planes[index];
    setSelectedPlane(selectedPlane);
    setPlane(selectedPlane);
    const selectedPlaneKeys = keysByPlane[selectedPlane] || [];
    setKeysSelectedPlane(selectedPlaneKeys);
    setPaginationState({
      pagination: {
        current: 1,
        last: selectedPlaneKeys?.length,
      },
    });
    setPlaneInfo(frameByPlane[seriesIds[selectedSerieId]][selectedPlane][selectedPlaneKeys[0]]);
    setSelectedFrame(Number(selectedPlaneKeys[0]));
    setSelectedSerieId(selectedSerieId);
    setSerieId(seriesIds[selectedSerieId]);
    SetFrame(Number(selectedPlaneKeys[0]));
    const measurementId = measurementService.getMeasurementIdByLabelandFrameNumber(
      selectedPlane,
      selectedPlaneKeys[0],
      seriesIds[selectedSerieId]
    );
    onClick({ uid: measurementId, isActive: false });
    onImageScrollbarChange(Number(selectedPlaneKeys[0]) - 1, activeViewportIndex);
  };

  const handleGotoPage = (page: number) => {
    const imageIndex = keysSelectedPlane[page - 1] ? Number(keysSelectedPlane[page - 1]) - 1 : 0;
    setPlaneInfo(
      frameByPlane[seriesIds[selectedSerieId]][planes[startPlane]][keysSelectedPlane[page - 1]]
    );
    setSelectedFrame(Number(keysSelectedPlane[page - 1]));
    SetFrame(Number(keysSelectedPlane[page - 1]));

    setPaginationState(prev => ({
      pagination: {
        current: page,
        last: keysSelectedPlane?.length,
      },
    }));
    const measurementId = measurementService.getMeasurementIdByLabelandFrameNumber(
      planes[startPlane],
      keysSelectedPlane[page - 1],
      seriesIds[selectedSerieId]
    );
    setSelectedSerieId(selectedSerieId);
    setSerieId(seriesIds[selectedSerieId]);
    onClick({ uid: measurementId, isActive: false });
    setPage(page);
    onImageScrollbarChange(imageIndex, activeViewportIndex);
  };
  const handleChangeSerieId = (index: number) => {
    setSelectedSerieId(index);
    setSerieId(seriesIds[index]);

    const selectedSeriesId = seriesIds[index];
    const seriesFrames = frameByPlane[selectedSeriesId];

    const planeKeys = Object.keys(seriesFrames).filter(
      plane =>
        Object.keys(seriesFrames[plane])?.length > 0 &&
        ['cephalic', 'abdominal', 'afpocket', 'femur'].includes(plane)
    );

    setPlanes(planeKeys);

    const updatedKeysByPlane = planeKeys.reduce((acc, plane) => {
      const keys = Object.keys(seriesFrames[plane]);
      if (keys?.length > 0) {
        acc[plane] = keys;
      }
      return acc;
    }, {});

    setKeysByPlane(updatedKeysByPlane);

    // Set default plane and frame for the new series
    const defaultPlane = planeKeys[startPlane] || planeKeys[0];
    setSelectedPlane(defaultPlane);
    setPlane(defaultPlane);

    const selectedPlaneKeys = updatedKeysByPlane[defaultPlane] || [];
    if (selectedPlaneKeys.length > 0) {
      const defaultFrame = Number(selectedPlaneKeys[0]);
      const measurementId = measurementService.getMeasurementIdByLabelandFrameNumber(
        defaultPlane,
        selectedPlaneKeys[0],
        selectedSeriesId
      );
      onClick({ uid: measurementId, isActive: false });
      onImageScrollbarChange(defaultFrame - 1, activeViewportIndex);
      measurementService.jumpToMeasurement(activeViewportIndex, measurementId);
      setSelectedFrame(defaultFrame);
      SetFrame(defaultFrame);

      const frameInfo = seriesFrames[defaultPlane][selectedPlaneKeys[0]];
      setPlaneInfo(frameInfo);

      setPaginationState({
        pagination: {
          current: 1,
          last: selectedPlaneKeys.length,
        },
      });
    }
  };

  if (isLoading) {
    return <div className="text-center text-gray-500">Loading data...</div>;
  }

  if (isError) {
    return <div className="text-center text-red-500">error</div>;
  }

  return (
    <div className="w-full">
      {planes.length <= 0 && selectedKey != null ? (
        <div className="mt-12 flex w-full flex-col items-center justify-center text-center text-gray-500">
          <PiDesktopThin
            size={90}
            className="mb-2 rounded-full bg-blue-200 p-2 text-white"
          />
          <span className="text-center text-sm font-semibold text-[#1F384C]">
            No plan detected.
          </span>
          <span className="text-xs text-gray-400">
            No plan detected. Please create or select a plan to proceed.
          </span>
        </div>
      ) : (
        <>
          <div>
            {seriesIds.length > 1 && (
              <div className="mb-3 flex justify-center space-x-2">
                {
                  <>
                    {seriesIds.map((seriesId, index) => (
                      <div
                        key={index}
                        className={classNames(
                          'group flex cursor-pointer rounded transition duration-300 ',
                          {
                            'border-primary-light overflow-hidden': selectedSerieId === index,
                          }
                        )}
                        role="button"
                      >
                        <div
                          className={classNames(
                            'w-6 rounded py-1 text-center text-base transition duration-300',
                            {
                              'bg-blue-600 text-white hover:bg-blue-500': selectedSerieId === index,
                              'border border-blue-300 bg-white text-blue-600 hover:bg-blue-100':
                                selectedSerieId !== index,
                            }
                          )}
                          onClick={() => handleChangeSerieId(index)}
                        >
                          {index + 1}
                        </div>
                      </div>
                    ))}
                  </>
                }
              </div>
            )}
            <Slider
              slides={planes}
              visibleItemsNumber={1}
              start={startPlane}
              setStart={changeStartPlane}
            >
              {slide => (
                <div className="flex flex-col items-center">
                  <img
                    src={imageMapping[slide]}
                    alt={slide}
                    className="h-24 w-24"
                    draggable="true"
                  />
                  <span className="mt-2 text-lg font-bold text-[#1F384C]">
                    {slide.charAt(0).toUpperCase() + slide.slice(1)}
                  </span>
                </div>
              )}
            </Slider>
          </div>
          <div className="mt-2">
            <FramePagination
              current={paginationState.pagination.current}
              last={paginationState.pagination.last}
              gotoPage={handleGotoPage}
              keysSelectedPlane={keysSelectedPlane}
            />
          </div>
          <div>
            {['abdominal', 'afpocket', 'femur', 'cephalic'].includes(selectedPlane) && (
              <PlaneInformation
                data={data}
                planeInfo={PlaneInfo}
                selectedPlane={selectedPlane as 'abdominal' | 'cephalic' | 'femur' | 'afpocket'}
                selectedFrame={selectedFrame}
                drawingMode={drawingMode}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default MeasurementDeepEchoMode;
