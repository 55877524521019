import { CopyFilled, CopyOutlined, UserOutlined } from '@ant-design/icons';
import { Modal, Space } from 'antd';
import React, { useState } from 'react';
import Action from '../../Components/Action/action.component';
import NewPatient from '../../Components/Action/new.patient';
import './actions.section.css';
// import NewExam2 from '../../Components/Action/new.exam';
import RealTime from '../../Components/RealTime/real.time';
import NewExam from '../../Components/NewExam/NewExam';

const actions = [
  {
    title: 'New Case',
    description: 'Start a new case from existing exam sources',
  },
  {
    title: 'New Patient',
    description: 'Create a new patient folder into the record',
  },
  {
    title: 'New Source',
    description: 'Connect new imagery sources with the platform',
  },
  {
    title: 'Realtime',
    description: 'Start your Realtime Inferencing',
  },
];

const Actions: React.FC = () => {
  return (
    <div className="h-full w-full">
      <h5 className="h-[40px] p-2 text-[1rem] font-medium text-[#1F384C]">Actions</h5>
      <div className="flex h-[calc(100%-40px)] w-full items-center justify-center">
        <NewExam
          {...actions[0]}
          page={'home'}
        />
        <NewPatient
          {...actions[1]}
          page={'home'}
        />
        {/* <Action {...actions[2]} /> */}
        <RealTime {...actions[3]} />
      </div>
    </div>
  );
};

export default Actions;
