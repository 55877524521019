import React from 'react';

interface HeaderProps {
  modalTabs: any;
  activeModalTab: any;
  setActiveModalTab: any;
  setUploadType: any;
  len: any;
}
function Header({ modalTabs, activeModalTab, setActiveModalTab, setUploadType, len }: HeaderProps) {
  return (
    <div>
      <div className="mb-5 mt-5 flex w-full">
        {modalTabs.map((tab, i) => {
          const isActive = tab.title === activeModalTab;
          return (
            <a
              key={i}
              className={`inline-flex w-full cursor-pointer items-center justify-center border-b-2 py-3 text-sm
                font-medium leading-none tracking-wider sm:px-6
                 ${
                   isActive
                     ? 'rounded-t border-b-2 border-[#005dd4] bg-gray-100 text-[#005dd4]'
                     : `border-gray-200 hover:bg-gray-50 hover:text-gray-900
                     ${len > 0 ? 'cursor-not-allowed opacity-50' : ' cursor-pointer opacity-100'}`
                 }`}
              onClick={() => {
                if (len == 0) {
                  setActiveModalTab(tab.title);
                  setUploadType(tab.title.toLowerCase());
                }
              }}
            >
              {tab.title}
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default Header;
