import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Avatar, Badge, Button, Form, Input, Modal, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useMe, usePasswordReset, useSingleUser } from '../../../helpers/api/users/users';

const roles = [
  {
    role_type: 'audit_roles',
    permissions: [
      {
        name: 'CAN_ACCESS_SENSITIVE_DATA',
        description:
          'Allows access to sensitive data such as personally identifiable information (PII), financial records, or other confidential information.',
      },
      {
        name: 'CAN_MANAGE_AUDIT_ROLES',
        description:
          'Grants the ability to create, modify, and delete audit roles within the system, defining permissions for users conducting audits.',
      },
      {
        name: 'CAN_PERFORM_AUDITS',
        description:
          'Enables users to perform audits, reviewing and assessing compliance with established standards, policies, and procedures.',
      },
      {
        name: 'CAN_SET_AUDIT_SCHEDULES',
        description:
          'Provides the capability to define and manage schedules for conducting audits, ensuring timely and regular assessments of organizational processes.',
      },
    ],
  },
  {
    role_type: 'user_roles',
    permissions: [
      {
        name: 'CAN_ACTIVATE_USER',
        description:
          'Allows activation of user accounts, enabling users to log in and access system functionalities.',
      },
      {
        name: 'CAN_DEACTIVATE_USER',
        description:
          'Grants the ability to deactivate user accounts, preventing users from accessing the system and its resources.',
      },
      {
        name: 'CAN_INVITE_USER',
        description:
          'Enables users to send invitations to others, allowing them to join the system and create their accounts.',
      },
      {
        name: 'CAN_KICK_USER',
        description:
          'Provides the capability to remove or log out other users from the system, terminating their current session.',
      },
      {
        name: 'CAN_MANAGE_USER_ROLES',
        description:
          'Allows management of user roles, including assigning, modifying, and revoking permissions for different roles.',
      },
      {
        name: 'CAN_MANAGE_USERS',
        description:
          'Grants the ability to manage user accounts, including creation, modification, and deletion of user profiles.',
      },
    ],
  },
  {
    role_type: 'generic_roles',
    permissions: [
      {
        name: 'CAN_CREATE_PATIENT',
        description:
          'Allows creation of patient records within the system, entering relevant information such as demographics, medical history, and treatments.',
      },
      {
        name: 'CAN_MANAGE_GENERIC_ROLES',
        description:
          'Grants the ability to manage generic roles, including creation, modification, and deletion of role definitions within the system.',
      },
      {
        name: 'CAN_MANAGE_PATIENTS',
        description:
          'Provides the capability to manage patient records, including viewing, editing, and deleting patient information.',
      },
    ],
  },
  {
    role_type: 'tech_support_roles',
    permissions: [
      {
        name: 'CAN_HANDLE_DATA_BACKUPS',
        description:
          'Allows handling of data backups, including creation, restoration, and management of backup files and procedures.',
      },
      {
        name: 'CAN_MANAGE_NETWORK_SETTINGS',
        description:
          'Grants the ability to manage network settings, including configuration, monitoring, and troubleshooting of network infrastructure.',
      },
      {
        name: 'CAN_MANAGE_TECH_SUPPORT_ROLES',
        description:
          'Enables management of tech support roles, including assignment, modification, and deletion of role permissions within the support team.',
      },
      {
        name: 'CAN_RESOLVE_TECHNICAL_ISSUES',
        description:
          'Provides the capability to diagnose and resolve technical issues reported by users or detected through system monitoring.',
      },
    ],
  },
  {
    role_type: 'billing_roles',
    permissions: [
      {
        name: 'CAN_GENERATE_FINANCIAL_REPORTS',
        description:
          'Allows generation of financial reports, summarizing billing, revenue, and other financial data for analysis and decision-making.',
      },
      {
        name: 'CAN_MANAGE_BILLING',
        description:
          'Grants the ability to manage billing processes, including invoicing, payment processing, and billing adjustments.',
      },
      {
        name: 'CAN_MANAGE_BILLING_ROLES',
        description:
          'Enables management of billing roles, including assignment, modification, and deletion of role permissions within the billing department.',
      },
      {
        name: 'CAN_PROCESS_PAYMENTS',
        description:
          'Provides the capability to process payments from customers or clients, ensuring accurate and timely transactions.',
      },
      {
        name: 'CAN_VIEW_PAYMENT_HISTORY',
        description:
          'Allows viewing of payment history, including past transactions, payment statuses, and related details.',
      },
    ],
  },
  {
    role_type: 'system_roles',
    permissions: [
      {
        name: 'CAN_MANAGE_SETTINGS',
        description:
          'Grants the ability to manage system settings, including configuration options, preferences, and administrative settings.',
      },
      {
        name: 'CAN_MANAGE_SYSTEM_ROLES',
        description:
          'Enables management of system roles, including creation, modification, and deletion of administrative roles within the system.',
      },
      {
        name: 'CAN_UPDATE_SYSTEM_CONFIGURATION',
        description:
          'Allows updating of system configuration settings, such as database configurations, integration settings, or system-wide parameters.',
      },
      {
        name: 'CAN_VIEW_SYSTEM_LOGS',
        description:
          'Provides access to view system logs, including records of system activities, errors, and security-related events.',
      },
    ],
  },
];

const style = {
  fontFamily: 'Outfit, sans-serif',
};

const ProfilePage = () => {
  const [active, setActive] = useState<undefined | string>('Profile');

  const [form] = Form.useForm();

  const params = useParams();

  const profile = useSingleUser(params.uid);

  const me = useMe();

  const passwordReset = usePasswordReset();

  const [tabs, setTabs] = useState([
    {
      name: 'Profile',
      icon: 'ri-user-line',
      active: true,
    },
    {
      name: 'Teams',
      icon: 'ri-group-line',
      active: false,
    },
    {
      name: 'Roles',
      icon: 'ri-shield-user-line',
      active: false,
    },
  ]);

  useEffect(() => {
    if (profile.data?.data?.uid === me.data?.data?.uid) {
      setTabs(prevTabs => {
        if (!prevTabs.some(tab => tab.name === 'Settings')) {
          return [
            ...prevTabs,
            {
              name: 'Settings',
              icon: 'ri-settings-3-line',
              active: false,
            },
          ];
        }

        return prevTabs;
      });
    }
  }, [profile.data?.data?.uid, me.data?.data?.uid]);

  const options = [
    { value: 'all', label: 'ALL' },
    ...roles.map(roletype => ({
      value: roletype.role_type,
      label: roletype.role_type.toUpperCase(),
    })),
  ];
  const setActiveTab = (name: string) => {
    setActive(name);
    setTabs(prev =>
      prev.map(item =>
        item.name === name ? { ...item, active: true } : { ...item, active: false }
      )
    );
  };

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  if (profile.isLoading) {
    return <></>;
  }

  if (profile.isError) {
    return <>not found</>;
  }

  return (
    <div
      className="flex justify-center overflow-y-scroll"
      style={style}
    >
      <Modal
        style={style}
        centered
        open={open}
        title={
          <div className="flex items-center">
            {' '}
            <span className="mr-2 text-center text-xl font-bold text-gray-800">
              Patient Management
            </span>{' '}
            <span className="mx-2 rounded border border-green-500 px-2 py-0.5 text-xs uppercase text-green-500">
              medical_role
            </span>
          </div>
        }
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="mb-4 flex items-center justify-between px-2">
          <span className="text-lg font-semibold text-gray-800">Assignees</span>
          <Avatar.Group size={40}>
            <Tooltip
              className="cursor-pointer"
              title={profile.data?.data?.name}
              placement="top"
            >
              <Avatar
                style={{ backgroundColor: '#87d068' }}
                src={
                  <img
                    src={profile.data?.data?.avatar}
                    alt="avatar"
                  />
                }
              />
            </Tooltip>
            <Tooltip
              className="cursor-pointer"
              title="User"
              placement="top"
            >
              <Avatar style={{ backgroundColor: '#87d068' }} />
            </Tooltip>
            <Tooltip
              className="cursor-pointer"
              title={profile.data?.data?.name}
              placement="top"
            >
              <Avatar
                style={{ backgroundColor: '#87d068' }}
                src={
                  <img
                    src={profile.data?.data?.avatar}
                    alt="avatar"
                  />
                }
              />
            </Tooltip>
            <Tooltip
              className="cursor-pointer"
              title="User"
              placement="top"
            >
              <Avatar style={{ backgroundColor: '#87d068' }} />
            </Tooltip>
            <Avatar
              style={{ fontSize: '0.875rem' }}
              className="cursor-default bg-gray-700 text-xs text-white"
            >
              +13
            </Avatar>
          </Avatar.Group>
        </div>
        <div className="w-full rounded-lg border border-2 border-gray-200">
          <div className="flex items-center justify-between border-b-2 border-gray-200 bg-gray-100 p-2 text-lg text-gray-800">
            <div>
              <span className="text-lg font-bold text-gray-700">Permissions</span>
            </div>
          </div>

          <div className="grid grid-flow-row-dense grid-cols-2 gap-8 p-4">
            {[1, 2, 3, 4, 5].map(i => (
              <div
                key={i}
                className="flex flex-col"
              >
                <span className="text-lg font-semibold text-gray-700">Permission Name {i}</span>
                <span className="text-base font-medium text-gray-500">
                  Lorem epsum grfhbgu reuh fiaezj eriuhv ieaf yqzeh
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <div className="w-3/4 rounded-lg border border-2 border-gray-200 p-6">
        <div className="mb-2 flex flex-col justify-center">
          <div className="mb-4 flex items-center justify-between border-b-2 border-gray-200 pb-6">
            <div className="flex items-center justify-start gap-2">
              <div className="group relative overflow-hidden rounded-full">
                <Avatar
                  size={80}
                  src={
                    <img
                      src={profile.data?.data?.avatar}
                      alt="avatar"
                    />
                  }
                />
                <div className="absolute inset-0 flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[rgba(0,0,0,0.5)] opacity-0 transition duration-150 ease-in-out group-hover:opacity-100">
                  <i className="ri-image-edit-line text-xl text-white"></i>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-2xl font-semibold text-gray-700">
                  {profile.data?.data?.name}
                </span>
                <span className="text-lg font-medium text-gray-500">
                  {profile.data?.data?.is_superuser && <Badge count={'ADMIN'} />}
                </span>
              </div>
            </div>
            <div className="flex cursor-pointer gap-1 text-lg">
              {!profile.data?.data?.is_active && <Badge count={'INACTIVE'} />}
              {/* <span className="text-blue-600">Edit</span>
              <i className="ri-edit-line text-blue-600"></i> */}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <ul
              id="type-tab"
              className="-mb-px flex flex-wrap pl-8 text-center text-lg font-medium text-gray-500"
            >
              {tabs.map((tab, index) => (
                <>
                  {tab.active ? (
                    <li
                      className="mr-3"
                      key={index}
                    >
                      <span
                        onClick={() => {
                          setActiveTab(tab.name);
                        }}
                        className="group inline-flex cursor-pointer items-center justify-center border-b-2 border-blue-600 p-2 text-blue-600 transition duration-150 ease-in-out"
                        aria-current="page"
                      >
                        <i className={`${tab.icon} mr-1 flex h-4 w-4 items-center text-lg`}></i>
                        {tab.name}
                      </span>
                    </li>
                  ) : (
                    <li
                      className="mr-3"
                      key={index}
                    >
                      <span
                        onClick={() => {
                          setActiveTab(tab.name);
                        }}
                        className="group inline-flex cursor-pointer items-center justify-center border-b-2 border-transparent p-2 text-gray-700 transition duration-150 ease-in-out hover:text-gray-600"
                      >
                        <i className={`${tab.icon} mr-1 flex h-4 w-4 items-center text-lg`}></i>
                        {tab.name}
                      </span>
                    </li>
                  )}
                </>
              ))}
            </ul>
            {/* {active === "Roles" ? (
              <Select
                defaultValue={{ value: "all", label: "ALL" }}
                className="w-60 pr-8"
                options={options}
                styles={customStyles}
              />
            ) : (
              ""
            )} */}
          </div>
          {active === 'Roles' ? (
            <div className="mt-4 flex flex-col gap-4">
              <div className="w-full rounded-lg border border-2 border-gray-200">
                <div className="flex items-center justify-between border-b-2 border-gray-200 bg-gray-100 p-2 text-lg text-gray-800">
                  <div>
                    <span className="text-lg font-bold text-gray-700">User Manager</span>
                    <span className="mx-2 rounded border border-green-500 px-2 py-0.5 text-xs uppercase text-green-500">
                      user_role
                    </span>
                    <span className="cursor-default rounded bg-purple-200 px-2 py-0.5 text-xs uppercase text-purple-600">
                      user managers team
                    </span>
                  </div>
                  <div className="cursor-pointer text-sm uppercase text-red-400">
                    Kick from team
                    <i className="ri-door-open-line ml-1 text-base"></i>
                  </div>
                </div>

                <div className="grid grid-flow-row-dense grid-cols-2 gap-8 p-4 lg:grid-cols-3">
                  {[1, 2, 3, 4, 5].map(i => (
                    <div
                      key={i}
                      className="flex flex-col"
                    >
                      <span className="text-lg font-semibold text-gray-700">
                        Permission Name {i}
                      </span>
                      <span className="text-base font-medium text-gray-500">
                        Lorem epsum grfhbgu reuh fiaezj eriuhv ieaf yqzeh
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full rounded-lg border border-2 border-gray-200">
                <div className="flex items-center justify-between border-b-2 border-gray-200 bg-gray-100 p-2 text-lg text-gray-800">
                  <div>
                    <span className="text-lg font-bold text-gray-700">Audit Specialist</span>
                    <span className="mx-2 rounded border border-green-500 px-2 py-0.5 text-xs uppercase text-green-500">
                      audit_role
                    </span>
                  </div>
                  <div className="cursor-pointer text-sm uppercase text-red-400">
                    Remove<i className="ri-eraser-line ml-1 text-base"></i>
                  </div>
                </div>

                <div className="grid grid-flow-row-dense grid-cols-2 gap-8 p-4 md:grid-cols-3">
                  {[1, 2, 3, 4, 5].map(i => (
                    // <div key={i}>0{i}</div>

                    <div
                      key={i}
                      className="flex flex-col"
                    >
                      <span className="text-lg font-semibold text-gray-700">
                        Permission Name {i}
                      </span>
                      <span className="text-base font-medium text-gray-500">
                        Lorem epsum grfhbgu reuh fiaezj eriuhv ieaf yqzeh
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {active === 'Teams' ? (
            <div className="mt-4 flex flex-col gap-4">
              <div className="w-full rounded-lg border border-2 border-gray-200">
                <div className="flex items-center justify-between border-b-2 border-gray-200 bg-gray-100 p-2 text-lg text-gray-800">
                  <div className="flex items-center gap-2">
                    <span className="text-lg font-bold text-gray-700">Doctors</span>
                    <span className="rounded bg-purple-200 px-2 py-0.5 text-xs uppercase text-purple-600">
                      17 members
                    </span>
                  </div>
                  <div className="cursor-pointer text-sm uppercase text-red-400">
                    Kick from team
                    <i className="ri-door-open-line ml-1 text-base"></i>
                  </div>
                </div>

                <div className="grid grid-flow-row-dense grid-cols-2 gap-8 p-4 lg:grid-cols-3">
                  <div
                    onClick={showModal}
                    className="flex cursor-pointer flex-col"
                  >
                    <div className="flex items-start gap-2">
                      <span className="text-lg font-semibold text-gray-700">
                        Patient Management
                      </span>
                      <span className="cursor-pointer rounded bg-green-200 px-2 py-0.5 text-xs uppercase text-green-600">
                        medical_role
                      </span>
                    </div>
                    <span className="text-base font-medium text-gray-500">
                      Lorem epsum grfhbgu reuh fiaezj eriuhv ieaf yqzeh
                    </span>
                  </div>
                  <div
                    onClick={showModal}
                    className="flex cursor-pointer flex-col"
                  >
                    <div className="flex items-start gap-2">
                      <span className="text-lg font-semibold text-gray-700">
                        Reports Management
                      </span>
                      <span className="cursor-pointer rounded bg-green-200 px-2 py-0.5 text-xs uppercase text-green-600">
                        medical_role
                      </span>
                    </div>
                    <span className="text-base font-medium text-gray-500">
                      Lorem epsum grfhbgu reuh fiaezj eriuhv ieaf yqzeh
                    </span>
                  </div>
                  <div
                    onClick={showModal}
                    className="flex cursor-pointer  flex-col"
                  >
                    <div className="flex items-start gap-2">
                      <span className="text-lg font-semibold text-gray-700">Audit Management</span>
                      <span className="cursor-pointer rounded bg-red-200 px-2 py-0.5 text-xs uppercase text-red-600">
                        audit_role
                      </span>
                    </div>
                    <span className="text-base font-medium text-gray-500">
                      Lorem epsum grfhbgu reuh fiaezj eriuhv ieaf yqzeh
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {active === 'Profile' ? (
            <div className="mt-4 flex flex-col gap-4">
              <div className="mb-4 flex flex-col gap-2">
                <blockquote className="text-lg font-semibold italic text-gray-900">
                  <i className="ri-double-quotes-r text-[3rem] text-gray-400"></i>
                  <p>
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tincidunt
                    volutpat velit vel dapibus. Curabitur feugiat nibh velit, ut dapibus dui
                    suscipit ut. In pretium ligula non quam rutrum, nec viverra ipsum varius. Nunc a
                    dui odio. Vivamus nec luctus ante, nec sodales lacus. Aenean scelerisque sit
                    amet dolor et pretium."
                  </p>
                </blockquote>
              </div>
              <div className="w-full rounded-lg border border-2 border-gray-200">
                <div className="flex items-center justify-between border-b-2 border-gray-200 bg-gray-100 p-2 text-lg text-gray-800">
                  <div>
                    <span className="text-lg font-bold text-gray-700">Contact Information</span>
                  </div>
                </div>
                <div className="grid grid-flow-row-dense grid-cols-2 gap-8 p-4 lg:grid-cols-3">
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold text-gray-700">
                      <i className="ri-phone-line mr-2" />
                      Contact Number
                    </span>
                    <span className="text-base font-medium text-gray-500">06 97 16 05 30</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold text-gray-700">
                      <i className="ri-mail-line mr-2" />
                      Email
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      {profile.data?.data?.email}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold text-gray-700">
                      <i className="ri-map-pin-line mr-2" />
                      Address
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      123 Avenue Mohammed V, Quartier des Habous, Casablanca 20000, Morocco
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full rounded-lg border border-2 border-gray-200">
                <div className="flex items-center justify-between border-b-2 border-gray-200 bg-gray-100 p-2 text-lg text-gray-800">
                  <div>
                    <span className="text-lg font-bold text-gray-700">Schedule & Availability</span>
                  </div>
                </div>
                <div className="grid grid-flow-row-dense grid-cols-2 gap-8 p-4 lg:grid-cols-3">
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold text-gray-700">
                      <i className="ri-calendar-line mr-2" />
                      Office Hours
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      Mon-Fri: 9:00 AM - 5:00 PM
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold text-gray-700">
                      <i className="ri-time-line mr-2" />
                      Availability
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      Available for consultations by appointment
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold text-gray-700">
                      <i className="ri-building-line mr-2" />
                      Location
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      -Office: 5th Floor, Building B
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      -Clinic: 2nd Floor, Block A
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <Form
                form={form}
                layout="vertical"
                onFinish={values => {
                  const formData = new FormData();
                  formData.append('new_password', values.password);
                  passwordReset.mutate(formData);
                  form.resetFields();
                }}
              >
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true, message: 'Please input password!' }]}
                >
                  <Input.Password placeholder="Enter password" />
                </Form.Item>
                <Form.Item
                  name="repeat_password"
                  label="Repeat password"
                  dependencies={['password']} // This ensures that validation runs when 'password' changes
                  rules={[
                    { required: true, message: 'Please repeat password!' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Repeat password" />
                </Form.Item>
                <Form.Item>
                  <Button
                    color="danger"
                    htmlType="submit"
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
