import React, { useState } from 'react';
import { Button, Modal, Select } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { usePaginatedUsers } from '../../../helpers/api/users/users';
import { PaginationState } from '@tanstack/react-table';
import {
  useGrantPermission,
  usePermissionsUserPatient,
  useRevokePermission,
} from '../../../helpers/api/patient/patients';

const ManageAccess: React.FC = ({ patient_id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const grantPermission = useGrantPermission();
  const revokePermission = useRevokePermission();

  const [selectedUser, setSelectedUser] = useState(null);

  const users = usePaginatedUsers(
    pagination.pageIndex + 1,
    pagination.pageSize,
    {},
    { refetchInterval: 60000 }
  );

  const userPermissions = usePermissionsUserPatient(patient_id, selectedUser ? selectedUser : 0);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        className=""
        onClick={showModal}
      >
        <li className="cursor-pointer text-orange-500">
          <span className="text-primary d-inline-block">
            <i className="ri-lock-fill fs-16"></i>
          </span>
        </li>
      </button>

      <Modal
        okButtonProps={{ disabled: true }}
        onCancel={handleCancel}
        title="Access Management"
        open={isModalOpen}
      >
        {users.isLoading ? (
          'loading'
        ) : (
          <>
            {' '}
            <Select
              onChange={value => {
                setSelectedUser(value);
              }}
              centered
              showSearch
              style={{ width: '100%' }}
              placeholder="Search to Select"
              optionFilterProp="label"
              options={users.data?.items
                ?.filter(user => user.roles[0] === 'doctor')
                .map(user => {
                  return { value: user.uid, label: user.name };
                })}
            />
            {selectedUser && (
              <ul className="mt-2 space-y-4">
                <li className="flex items-center justify-between">
                  <span className="font-medium">OWNER</span>
                  <div className="space-x-2">
                    {userPermissions.data?.includes('*') ? (
                      <button
                        className="rounded-md bg-red-500 px-3 py-1 text-white hover:bg-red-600"
                        onClick={() => {
                          revokePermission.mutate({
                            patient_id,
                            user_uid: selectedUser,
                            permission: 'owner',
                          });
                        }}
                      >
                        Revoke
                      </button>
                    ) : (
                      <button
                        className="rounded-md bg-green-500 px-3 py-1 text-white hover:bg-green-600"
                        onClick={() => {
                          grantPermission.mutate({
                            patient_id,
                            user_uid: selectedUser,
                            permission: 'owner',
                          });
                        }}
                      >
                        Grant
                      </button>
                    )}
                  </div>
                </li>
                <li className="flex items-center justify-between">
                  <span className="font-medium">CAN MANAGE</span>
                  <div className="space-x-2">
                    {userPermissions.data?.includes('*') ? (
                      <button className="cursor-default rounded-md bg-green-500 px-3 py-1 text-white">
                        Inherited
                      </button>
                    ) : (
                      <>
                        {userPermissions.data?.includes('*') ||
                        userPermissions.data?.includes('can_manage') ? (
                          <button
                            className="rounded-md bg-red-500 px-3 py-1 text-white hover:bg-red-600"
                            onClick={() => {
                              revokePermission.mutate({
                                patient_id,
                                user_uid: selectedUser,
                                permission: 'can_manage',
                              });
                            }}
                          >
                            Revoke
                          </button>
                        ) : (
                          <button
                            className="rounded-md bg-green-500 px-3 py-1 text-white hover:bg-green-600"
                            onClick={() => {
                              grantPermission.mutate({
                                patient_id,
                                user_uid: selectedUser,
                                permission: 'can_manage',
                              });
                            }}
                          >
                            Grant
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </li>
                <li className="flex items-center justify-between">
                  <span className="font-medium">CAN ACCESS</span>
                  <div className="space-x-2">
                    {userPermissions.data?.includes('*') ||
                    userPermissions.data?.includes('can_manage') ? (
                      <button className="cursor-default rounded-md bg-green-500 px-3 py-1 text-white">
                        Inherited
                      </button>
                    ) : (
                      <>
                        {userPermissions.data?.includes('*') ||
                        userPermissions.data?.includes('can_access') ? (
                          <button
                            className="rounded-md bg-red-500 px-3 py-1 text-white hover:bg-red-600"
                            onClick={() => {
                              revokePermission.mutate({
                                patient_id,
                                user_uid: selectedUser,
                                permission: 'can_access',
                              });
                            }}
                          >
                            Revoke
                          </button>
                        ) : (
                          <button
                            className="rounded-md bg-green-500 px-3 py-1 text-white hover:bg-red-600"
                            onClick={() => {
                              grantPermission.mutate({
                                patient_id,
                                user_uid: selectedUser,
                                permission: 'can_access',
                              });
                            }}
                          >
                            Grant
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </li>
              </ul>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default ManageAccess;
