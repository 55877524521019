import cn from 'classnames';
import React from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { RiCloseLine } from 'react-icons/ri';

export default function FilterButton({
  filterName = '',
  isActive = false,
  value = '',
  Icon = null,
  isClearable = false,
  onClear = () => {},
  isDisabled = false,
  isLoading = false,
  ...props
}) {
  return (
    <div
      onClick={e => {
        if (isDisabled || isLoading) {
          e && e?.stopPropagation();
        }
      }}
      title={value}
      className={cn(
        'flex w-full items-center justify-between space-x-3 rounded-md border-[#005DD4] py-1.5 pl-1.5 pr-2',
        'transform cursor-pointer border duration-300',
        {
          ' text-[#005DD4]': isActive && !isDisabled,
          'hover:border-border-[#005DD4] border-[#005DD4] bg-gray-50 hover:bg-white':
            !isActive && !isDisabled,
          'cursor-not-allowed border-[#005DD4] bg-gray-100': isDisabled,
        }
      )}
      {...props}
    >
      <div className="flex items-center space-x-1.5 overflow-hidden text-sm">
        {isClearable && (
          <div
            title="Delete filter"
            className="text-system-gray-500 rounded-lg hover:text-red-500"
          >
            <RiCloseLine
              onClick={() => onClear()}
              size={18}
              className="flex-shrink-0 cursor-pointer"
              color="#005DD4"
            />
          </div>
        )}
        <span className={`${isActive ? 'text-[#005DD4]' : 'text-[#005DD4]'}`}>{Icon}</span>
        {isLoading ? (
          <p className="font-medium first-letter:uppercase">loading...</p>
        ) : value ? (
          <div className="flex items-center space-x-1">
            {filterName ? (
              <span
                title={filterName}
                className={`font-semibold first-letter:uppercase ${
                  isActive ? 'text-[#005DD4]' : 'text-[#005DD4]'
                }`}
              >
                {filterName}:
              </span>
            ) : null}
            <span className="line-clamp-1 font-medium text-[#005DD4]">{value}</span>
          </div>
        ) : (
          <p
            className="font-medium first-letter:uppercase"
            title={filterName}
          >
            {filterName}
          </p>
        )}
      </div>

      <BsChevronDown
        size={16}
        color="#005DD4"
        className="flex-shrink-0 text-[#005DD4]"
      />
    </div>
  );
}
