import { apiClient } from '../../apiHelper';
import { useEdit, usePaginated, useSingleFetch } from '../react-query';
import { usersQueryKeys } from './users-query-keys';

export function useSingleUser(uid, config = {}) {
  const getUserFn = async () => {
    const response = await apiClient.get(`users/${uid}`);
    return response;
  };

  return useSingleFetch(getUserFn, usersQueryKeys.detail(uid), uid, config);
}

export function useMe(config = {}) {
  const getMeFn = async () => {
    const response = await apiClient.get(`users/me`);
    return response;
  };

  return useSingleFetch(getMeFn, usersQueryKeys.detail('me'), 'me', config);
}

export function usePaginatedUsers(page, itemsPerPage, filters = {}, config = {}) {
  const getPaginatedUsersFn = async (p = page, f = filters) => {
    const filtersQuery = Object.keys(f)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(f[key])}`)
      .join('&');
    const url = `users/?page=${p}&items_per_page=${itemsPerPage}${
      filtersQuery ? `&${filtersQuery}` : ''
    }`;

    const response = await apiClient.get(url);
    return response.data;
  };

  return usePaginated(getPaginatedUsersFn, usersQueryKeys.pagination(page), page, config);
}

export function usePasswordRecovery() {
  const passwordRecoveryFn = async uid => {
    const response = await apiClient.post(`users/recovery/${uid}`);
    return response;
  };

  return useEdit(
    passwordRecoveryFn,
    usersQueryKeys.all,
    'Error while performing password recovery',
    'Password recovery link sent successfully'
  );
}

export function usePasswordReset() {
  const passwordResetFn = async data => {
    const response = await apiClient.post(`users/reset_password`, data);
    return response;
  };

  return useEdit(
    passwordResetFn,
    usersQueryKeys.all,
    'Error while performing password reset',
    'Password reset successfully'
  );
}

export function useUserPasswordReset() {
  const passwordResetFn = async data => {
    console.log('wiwiw', data);
    const response = await apiClient.post(`users/reset_password/${data.uid}`, data.form);
    return response;
  };

  return useEdit(
    passwordResetFn,
    usersQueryKeys.all,
    'Error while performing password reset',
    'Password reset successfully'
  );
}

export function useChangeRoleUser() {
  const changeRoleUserFn = async data => {
    const response = await apiClient.post(`users/roles/${data.uid}/${data.role}`);
    return response;
  };

  return useEdit(
    changeRoleUserFn,
    usersQueryKeys.all,
    'Error while updating role of User',
    'Role updated successfully'
  );
}

export function useActivateUser() {
  const activateUserFn = async uid => {
    const response = await apiClient.post(`users/activate/${uid}`);
    return response;
  };

  return useEdit(
    activateUserFn,
    usersQueryKeys.all,
    'Error while activating User',
    'User activated successfully'
  );
}

export function useDeactivateUser() {
  const deactivateUserFn = async uid => {
    const response = await apiClient.post(`users/deactivate/${uid}`);
    return response;
  };

  return useEdit(
    deactivateUserFn,
    usersQueryKeys.all,
    'Error while activating User',
    'User activated successfully'
  );
}
