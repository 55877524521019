import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import Modal from '../../../../Components/modal';
import { SlCheck } from 'react-icons/sl';
import { TiDeleteOutline } from 'react-icons/ti';

function SelectView({ selectedPlan, setSelectedPlansModal, tag, globalState, setGlobalState }) {
  const [bool, setBool] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedViewData, setSelectedViewData] = useState(null);

  const chunkArray = (array: any[], size: number) => {
    const result = [];
    for (let i = 0; i < array?.length; i += size) {
      result.push(array?.slice(i, i + size));
    }
    return result;
  };

  const groupedPlanes = chunkArray(globalState?.planes[tag]?.predictionHistory || [], 3);

  const handlePrev = () => {
    setCurrentIndex(prev => (prev > 0 ? prev - 1 : groupedPlanes.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex(prev => (prev < groupedPlanes.length - 1 ? prev + 1 : 0));
  };

  useEffect(() => {
    setBool(selectedPlan !== '');
  }, [selectedPlan]);

  const handleSelectView = () => {
    const selectedData = globalState?.planes?.[tag]?.predictionHistory?.[currentIndex];
    setSelectedViewData(selectedData);
    console.log('Selected View Data:', selectedData);
    setGlobalState(prev => ({
      ...prev,
      planes: {
        ...prev.planes,
        [tag]: {
          ...prev.planes[tag],
          selectedViewData: selectedData, // Store in globalState
        },
      },
    }));
    setSelectedPlansModal('');
  };

  return (
    <Modal
      isOpen={bool}
      onClose={() => {
        setSelectedPlansModal('');
      }}
      size={'medium'}
      className="bg-[#F2F8FF]"
    >
      <Modal.Content>
        <div className="h-full w-full p-6">
          <div className="text-xl font-semibold text-[#1F384C]">{selectedPlan}</div>
          <div className="mt-4 h-full">
            <div className="flex h-full w-full space-x-4">
              <div className="flex w-2/3 flex-col space-y-4 pr-4">
                <div className="relative flex h-[66%] w-full cursor-pointer items-center justify-center place-self-center rounded-2xl border-2 border-[#a6abc8]">
                  <img
                    src={
                      globalState?.planes?.[tag]?.predictionHistory?.[currentIndex]
                        ?.imagePredictedPlane || globalState?.planes?.[tag]?.imgReport
                    }
                    className={`h-full w-full rounded-2xl`}
                    alt={tag}
                  />
                  <span className="absolute top-2 left-2 w-2/3 font-semibold text-white">
                    {`View ${currentIndex + 1}`}
                  </span>
                </div>

                <div className="relative flex w-full items-center justify-center rounded-lg">
                  {/* Left Arrow */}
                  <button
                    className="absolute left-0 z-10 rounded-full bg-gray-800 p-2 text-white shadow-lg hover:bg-gray-700"
                    onClick={() => {
                      // Update the visible set only when currentIndex exits the current group of 3
                      setCurrentIndex(prev => {
                        const newIndex =
                          prev > 0
                            ? prev - 1
                            : globalState?.planes[tag]?.predictionHistory.length - 1;
                        if (newIndex % 3 === 2) {
                          return newIndex;
                        } // Move to previous visible set
                        return newIndex;
                      });
                    }}
                  >
                    <FaChevronLeft size={20} />
                  </button>

                  {/* Images */}
                  <div className="flex items-center space-x-4 overflow-hidden">
                    {globalState?.planes[tag]?.predictionHistory
                      ?.slice(
                        Math.floor(currentIndex / 3) * 3, // Start of the visible set
                        Math.floor(currentIndex / 3) * 3 + 3 // End of the visible set
                      )
                      .map((frame, index) => {
                        const isHighlighted =
                          Math.floor(currentIndex / 3) * 3 + index === currentIndex;

                        return (
                          <div
                            key={frame.imagePredictedPlane}
                            className={`rounded-lg transition-all duration-500 ease-in-out ${
                              isHighlighted ? 'scale-100 opacity-100' : 'scale-90 opacity-50'
                            }`}
                          >
                            <img
                              src={frame.imagePredictedPlane}
                              alt={frame.lastDetectedPlan}
                              className="h-24 w-36 rounded-lg object-cover shadow-md"
                            />
                            <p className="mt-2 text-center text-gray-700">
                              View {Math.floor(currentIndex / 3) * 3 + index + 1}
                            </p>
                          </div>
                        );
                      })}
                  </div>

                  {/* Right Arrow */}
                  <button
                    className="absolute right-0 z-10 rounded-full bg-gray-800 p-2 text-white shadow-lg hover:bg-gray-700"
                    onClick={() => {
                      // Update the visible set only when currentIndex exits the current group of 3
                      setCurrentIndex(prev => {
                        const newIndex =
                          (prev + 1) % globalState?.planes[tag]?.predictionHistory.length;
                        if (newIndex % 3 === 0) {
                          return newIndex;
                        } // Move to next visible set
                        return newIndex;
                      });
                    }}
                  >
                    <FaChevronRight size={20} />
                  </button>
                </div>
              </div>

              {/* Right Section */}
              <div className="flex w-1/3 flex-col">
                <div className="text-xl font-medium text-[#2886C7]">Quality Criteria</div>
                {globalState?.planes?.[tag]?.predictionHistory?.[currentIndex]?.quality_criteria
                  ?.length > 0 ? (
                  <div className="h-[250px]">
                    {globalState?.planes?.[tag]?.predictionHistory?.[
                      currentIndex
                    ]?.quality_criteria.map((item, i) => (
                      <div
                        key={i}
                        className="mt-2 flex items-center justify-between 
            border-b border-white py-0.5 text-lg text-[#686C82]"
                      >
                        <div className="flex w-full justify-start">{item?.title}</div>
                        <div
                          className={`flex w-full justify-end ${item?.bool ? 'text-[#32CD32]' : 'text-[#FF4747]'}`}
                        >
                          {item?.bool ? (
                            <SlCheck
                              size={14}
                              className="mr-0.5"
                            />
                          ) : (
                            <TiDeleteOutline size={18} />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex h-[250px] w-full items-center justify-center text-[#686C82]">
                    No quality criteria
                  </div>
                )}
                <button
                  className="w-full cursor-pointer rounded-md border border-[#2886C7] py-1 px-5 font-semibold text-[#2886C7] hover:bg-[#2886C7] hover:text-white"
                  onClick={handleSelectView}
                >
                  Select View For Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default SelectView;
