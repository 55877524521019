import React, { useState, useRef, useEffect } from 'react';
import '../../style/index.css';

function Dropdown({ items, isOpen, setIsOpen, globalState, setGlobalState }) {
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Close dropdown if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleItemClick = item => {
    const pixelSpacingAlreadyExistsForMachineType = localStorage.getItem(item?.acronyme) !== null;

    if (!pixelSpacingAlreadyExistsForMachineType && items) {
      // Remove all items from localStorage except the current selected item
      items?.forEach(machineItem => {
        if (machineItem.acronyme !== item.acronyme) {
          localStorage.removeItem(machineItem.acronyme);
        }
      });
    }

    setGlobalState(prevState => ({
      ...prevState,
      selectedItem: item?.label,
      machineType: item?.acronyme,
      configMachine: item,
    }));
    setIsOpen(false); // Close the dropdown after selection
  };

  const filteredItems = items?.filter(item => item?.label?.toLowerCase()?.includes(searchTerm));

  return (
    <div className="flex w-full items-center justify-center">
      <div
        className="group relative w-full"
        ref={dropdownMenuRef}
      >
        <button
          id="dropdown-button"
          className="inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm focus:border-[#2886c7] focus:outline-none focus:ring-0"
          onClick={toggleDropdown}
        >
          <span className="mr-2">{globalState?.selectedItem}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2 -mr-1 h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {isOpen && (
          <div
            id="dropdown-menu"
            className="custom-scrollbar absolute right-0 z-50 mt-2 max-h-60 w-full space-y-1 overflow-y-auto rounded-md bg-white p-1 shadow-lg ring-1 ring-gray-500 ring-opacity-5"
          >
            <input
              id="search-input"
              className="block w-full rounded-md border border-gray-300 px-4 py-2  text-sm text-gray-800 placeholder:text-sm focus:outline-none"
              type="text"
              placeholder="Search items"
              autoComplete="off"
              value={searchTerm}
              onChange={handleSearch}
            />
            {filteredItems?.map(item => (
              <div
                key={item?.label}
                onClick={() => {
                  handleItemClick(item);
                }}
                className="block cursor-pointer rounded-md px-4  py-2 text-sm text-gray-700 hover:bg-gray-100 active:bg-blue-100"
              >
                {item?.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Dropdown;
