import React, { useEffect, useState } from 'react';
import filtersMeta from './filtersMeta.js';

import { MODULE_TYPES } from '@ohif/core';
import isEqual from 'lodash.isequal';
import { useLocation, useNavigate } from 'react-router';

import { utils } from '@ohif/core';
import classNames from 'classnames';
import moment from 'moment';
import qs from 'query-string';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { extensionManager } from '../../../../App';
import useDebounce from '../../../../hooks/useDebounce.js';
import useSearchParams from '../../../../hooks/useSearchParams.js';
import { useAppConfig } from '../../../../state/appConfig';
import {
  Button,
  ButtonGroup,
  EmptyStudies,
  StudyListExpandedRow,
  StudyListFilter,
  StudyListTable,
  TooltipClipboard,
  Typography,
} from '../../../OHIF_UI/index.js';
import { deleteStudy } from '../../../store/actions.js';
import { Icon } from '@ohif/ui';
const _ = require('lodash');
// import AuthContext from '@state/AuthProvider';

const { sortBySeriesDate } = utils;

const seriesInStudiesMap = new Map();

const STUDIES_LIMIT = 17;

const Exams: React.FC = () => {
  const [appConfig] = useAppConfig();
  const location = useLocation();
  const navigate = useNavigate();

  const [expandedRows, setExpandedRows] = useState([]);
  const [studiesWithSeriesData, setStudiesWithSeriesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState({
    studies: [],
    total: 0,
    resultsPerPage: 25,
    pageNumber: 1,
    location: 'Not a valid location, causes first load to occur',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const { studyDeleted } = useSelector(state => ({
    studyDeleted: state.Study.studyDeleted,
  }));

  let dataSourceName = new URLSearchParams(location.search).get('datasources');
  const dataPath = dataSourceName ? `/${dataSourceName}` : '';

  if (!dataSourceName && window.config.defaultDataSourceName) {
    dataSourceName = window.config.defaultDataSourceName;
  } else if (!dataSourceName) {
    // Gets the first defined datasource with the right name
    // Mostly for historical reasons - new configs should use the defaultDataSourceName
    const dataSourceModules = extensionManager.modules[MODULE_TYPES.DATA_SOURCE];
    // TODO: Good usecase for flatmap?
    const webApiDataSources = dataSourceModules.reduce((acc, curr) => {
      const mods = [];
      curr.module.forEach(mod => {
        if (mod.type === 'webApi') {
          mods.push(mod);
        }
      });
      return acc.concat(mods);
    }, []);
    dataSourceName = webApiDataSources
      .map(ds => ds.name)
      .find(it => extensionManager.getDataSources(it)?.[0] !== undefined);
  }
  const dataSource = extensionManager.getDataSources(dataSourceName)?.[0];

  // Query for series information
  useEffect(() => {
    const fetchSeries = async (studyInstanceUid: string) => {
      try {
        const series = await dataSource.query.series.search(studyInstanceUid);
        seriesInStudiesMap.set(studyInstanceUid, sortBySeriesDate(series));
        setStudiesWithSeriesData([...studiesWithSeriesData, studyInstanceUid]);
      } catch (ex) {
        // TODO: UI Notification Service
        console.warn(ex);
      }
    };

    // TODO: WHY WOULD YOU USE AN INDEX OF 1?!
    // Note: expanded rows index begins at 1
    for (let z = 0; z < expandedRows.length; z++) {
      const expandedRowIndex = expandedRows[z] - 1;
      const studyInstanceUid = sortedStudies[expandedRowIndex]?.studyInstanceUid;

      if (studiesWithSeriesData.includes(studyInstanceUid)) {
        continue;
      }

      fetchSeries(studyInstanceUid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRows, data.studies]);

  useEffect(() => {
    let queryFilterValues = _getQueryFilterValues(location.search);

    async function getData() {
      // todo: enhance the logic for this code : start
      queryFilterValues = queryFilterValues.accessionNumber
        ? {
            ...queryFilterValues,
            accessionNumber: `*${queryFilterValues.accessionNumber}*`,
          }
        : queryFilterValues;

      queryFilterValues = queryFilterValues.studyDescription
        ? {
            ...queryFilterValues,
            studyDescription: `*${queryFilterValues.studyDescription}*`,
          }
        : queryFilterValues;

      queryFilterValues = queryFilterValues.patientId
        ? {
            ...queryFilterValues,
            patientId: `*${queryFilterValues.patientId}*`,
          }
        : queryFilterValues;

      queryFilterValues = queryFilterValues.patientName
        ? {
            ...queryFilterValues,
            patientName: `*${queryFilterValues.patientName}*`,
          }
        : queryFilterValues;
      // todo: enhance the logic for this code : end

      setIsLoading(true);
      const studies = await dataSource.query.studies.search({
        ...queryFilterValues,
        fuzzymatching: true,
        offset: (currentPage - 1) * STUDIES_LIMIT,
        limit: STUDIES_LIMIT,
        // accessToken: auth.accessToken,
      });

      // const response = await axios(
      //   `${url}/dcm4chee-arc/aets/DCM4CHEE/rs/studies/count`
      // );

      // const totalCount = response.data.count;
      // setTotalCount(totalCount);

      // const pageCount = Math.ceil(totalCount / STUDIES_LIMIT);
      // setTotalPages(pageCount);

      setData({
        studies: studies || [],
        total: studies.length,
        resultsPerPage: queryFilterValues.resultsPerPage,
        pageNumber: queryFilterValues.pageNumber,
        location,
      });

      setIsLoading(false);
    }

    getData();
  }, [currentPage, location, studyDeleted]);

  const dispatch = useDispatch();

  const numOfStudies = data.total;
  const hasStudies = numOfStudies > 0;

  const sortedStudies = data.studies;

  const tableDataSource = sortedStudies.map((study, key) => {
    const rowKey = key + 1;
    const isExpanded = expandedRows.some(k => k === rowKey);
    const {
      studyInstanceUid,
      accession,
      modalities,
      instances,
      description,
      mrn,
      patientName,
      date,
      time,
    } = study;
    const studyDate =
      date &&
      moment(date, ['YYYYMMDD', 'YYYY.MM.DD'], true).isValid() &&
      moment(date, ['YYYYMMDD', 'YYYY.MM.DD']).format('MMM-DD-YYYY');
    const studyTime =
      time &&
      moment(time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).isValid() &&
      moment(time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).format('hh:mm A');

    return {
      row: [
        {
          key: 'patientName',
          content: patientName ? (
            <TooltipClipboard>{patientName}</TooltipClipboard>
          ) : (
            <span className="text-gray-700">(Empty)</span>
          ),
          gridCol: 4,
        },
        {
          key: 'mrn',
          content: <TooltipClipboard>{mrn}</TooltipClipboard>,
          gridCol: 3,
        },
        {
          key: 'studyDate',
          content: (
            // <>
            //   {studyDate && <span className="mr-4">{studyDate}</span>}
            //   {studyTime && <span>{studyTime}</span>}
            // </>
            <>
              <TooltipClipboard>
                {studyDate} {studyTime}
              </TooltipClipboard>
            </>
          ),
          // title: `${studyDate || ''} ${studyTime || ''}`,
          gridCol: 5,
        },
        {
          key: 'description',
          content: <TooltipClipboard>{description}</TooltipClipboard>,
          gridCol: 4,
        },
        {
          key: 'modality',
          // content: modalities,
          content: <TooltipClipboard>{modalities}</TooltipClipboard>,
          // title: modalities,
          gridCol: 3,
        },
        {
          key: 'accession',
          content: <TooltipClipboard>{accession}</TooltipClipboard>,
          gridCol: 3,
        },
        {
          key: 'instances',
          content: (
            // <>
            //   <Icon
            //     name="group-layers"
            //     className={classNames('inline-flex mr-2 w-4', {
            //       'text-primary-active': isExpanded,
            //       'text-secondary-light': !isExpanded,
            //     })}
            //   />
            //   {instances}
            // </>
            <>
              <Icon
                name="group-layers"
                className={classNames('mr-2 inline-flex w-4', {
                  'text-blue-900': isExpanded,
                  'text-blue-500': !isExpanded,
                })}
              />
              <TooltipClipboard>{instances}</TooltipClipboard>
            </>
          ),
          // title: (instances || 0).toString(),
          gridCol: 4,
        },
      ],

      expandedContent: (
        <StudyListExpandedRow
          seriesTableColumns={{
            description: 'Description',
            seriesNumber: 'Series',
            modality: 'Modality',
            instances: 'Instances',
          }}
          seriesTableDataSource={
            seriesInStudiesMap.has(studyInstanceUid)
              ? seriesInStudiesMap.get(studyInstanceUid).map(s => {
                  return {
                    description: s.description || '(empty)',
                    seriesNumber: s.seriesNumber ?? '',
                    modality: s.modality || '',
                    instances: s.numSeriesInstances || '',
                  };
                })
              : []
          }
        >
          {appConfig.modes.map((mode, i) => {
            const isFirst = i === 0;
            if (!isFirst) {
              return null;
            }

            const isValidMode = mode.isValidMode({ modalities });
            // TODO: Modes need a default/target route? We mostly support a single one for now.
            // We should also be using the route path, but currently are not
            // mode.routeName
            // mode.routes[x].path
            // Don't specify default data source, and it should just be picked up... (this may not currently be the case)
            // How do we know which params to pass? Today, it's just StudyInstanceUIDs
            return (
              <>
                <Link
                  key={i}
                  to={`${dataPath ? '../../' : ''}${mode.routeName}${
                    dataPath || ''
                  }?StudyInstanceUIDs=${studyInstanceUid}`}
                  // to={`${mode.routeName}/dicomweb?StudyInstanceUIDs=${studyInstanceUid}`}
                >
                  <Button
                    rounded="full"
                    variant={isValidMode ? 'contained' : 'disabled'}
                    disabled={!isValidMode}
                    endIcon={<Icon name="launch-arrow" />} // launch-arrow | launch-info
                    className={classNames('bg-blue-500 font-medium', {
                      'ml-2': !isFirst,
                    })}
                    onClick={() => {}}
                    children={undefined}
                    startIcon={undefined}
                    name={undefined}
                    // style={{ backgroundColor: '#1677FF'}}
                  >
                    {/* {`Modes:${mode.displayName}`} */}
                    {`Viewer`}
                  </Button>
                </Link>
                <Button
                  rounded="full"
                  className={classNames('bg-blue-500 font-medium')}
                  onClick={() => {
                    dispatch(deleteStudy(studyInstanceUid));
                  }}
                  startIcon={undefined}
                  name={undefined}
                >
                  {`Delete`}
                </Button>
              </>
            );
          })}
        </StudyListExpandedRow>
      ),
      onClickRow: () =>
        setExpandedRows(s => (isExpanded ? s.filter(n => rowKey !== n) : [...s, rowKey])),
      isExpanded,
    };
  });

  // ~ Filters
  const searchParams = useSearchParams();
  const queryFilterValues = _getQueryFilterValues(searchParams);
  const [filterValues, _setFilterValues] = useState({
    ...defaultFilterValues,
    ...queryFilterValues,
  });

  const debouncedFilterValues = useDebounce(filterValues, 200);
  const { resultsPerPage, pageNumber, sortBy, sortDirection } = filterValues;

  const setFilterValues = val => {
    if (filterValues.pageNumber === val.pageNumber) {
      val.pageNumber = 1;
    }
    _setFilterValues(val);
    setExpandedRows([]);
  };

  // Sync URL query parameters with filters
  useEffect(() => {
    if (!debouncedFilterValues) {
      return;
    }
    const queryString = {};
    Object.keys(defaultFilterValues).forEach(key => {
      const defaultValue = defaultFilterValues[key];
      const currValue = debouncedFilterValues[key];

      // TODO: nesting/recursion?
      if (key === 'studyDate') {
        if (currValue.startDate && defaultValue.startDate !== currValue.startDate) {
          queryString.startDate = currValue.startDate;
        }
        if (currValue.endDate && defaultValue.endDate !== currValue.endDate) {
          queryString.endDate = currValue.endDate;
        }
      } else if (key === 'modalities' && currValue.length) {
        queryString.modalities = currValue.join(',');
      } else if (currValue !== defaultValue) {
        queryString[key] = currValue;
      }
    });

    const search = qs.stringify(queryString, {
      skipNull: true,
      skipEmptyString: true,
    });

    // navigate({
    //   pathname: '/exams',
    //   search: search ? `?${search}` : undefined,
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterValues]);

  /*
   * The default sort value keep the filters synchronized with runtime conditional sorting
   * Only applied if no other sorting is specified and there are less than 101 studies
   */

  const canSort = numOfStudies < STUDIES_LIMIT;
  const shouldUseDefaultSort = sortBy === '' || !sortBy;
  const sortModifier = sortDirection === 'descending' ? 1 : -1;
  const defaultSortValues =
    shouldUseDefaultSort && canSort ? { sortBy: 'studyDate', sortDirection: 'ascending' } : {};

  const studies = data.studies;

  if (canSort) {
    studies.sort((s1, s2) => {
      if (shouldUseDefaultSort) {
        const ascendingSortModifier = -1;
        return _sortStringDates(s1, s2, ascendingSortModifier);
      }

      const s1Prop = s1[sortBy];
      const s2Prop = s2[sortBy];

      if (typeof s1Prop === 'string' && typeof s2Prop === 'string') {
        return s1Prop.localeCompare(s2Prop) * sortModifier;
      } else if (typeof s1Prop === 'number' && typeof s2Prop === 'number') {
        return (s1Prop > s2Prop ? 1 : -1) * sortModifier;
      } else if (!s1Prop && s2Prop) {
        return -1 * sortModifier;
      } else if (!s2Prop && s1Prop) {
        return 1 * sortModifier;
      } else if (sortBy === 'studyDate') {
        return _sortStringDates(s1, s2, sortModifier);
      }

      return 0;
    });
  }

  const isFiltering = (filterValues, defaultFilterValues) => {
    const diff = _.omitBy(filterValues, (value, key) => _.isEqual(value, defaultFilterValues[key]));

    return !isEqual(filterValues, defaultFilterValues);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const navigateToPage = page => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div
      className=" flex h-screen flex-col "
      style={{
        height: '100%',
        backgroundColor: '#FCFCFC',
        padding: '2px 4px',
        borderRadius: '8px',
      }}
    >
      <div
        className="ohif-scrollbar overflow-y-auto"
        style={{
          borderRadius: '8px',
          height: '100%',
          backgroundColor: 'white',
        }}
      >
        <div
          className="sticky -top-0 z-10"
          style={{ borderBottom: '2px solid #5DA3FD' }}
        >
          <StudyListFilter
            numOfStudies={totalCount}
            filtersMeta={filtersMeta}
            filterValues={{ ...filterValues, ...defaultSortValues }}
            onChange={setFilterValues}
            clearFilters={() => setFilterValues(defaultFilterValues)}
            isFiltering={isFiltering(filterValues, defaultFilterValues)}
            examsCount={totalCount}
          />
        </div>
        {hasStudies ? (
          <div className="bg-white">
            <StudyListTable
              tableDataSource={tableDataSource}
              numOfStudies={numOfStudies}
              filtersMeta={filtersMeta}
            />

            <div
              className="sticky bottom-0 bg-white py-2  text-black"
              style={{ borderTop: '2px solid #5DA3FD' }}
            >
              <div className=" relative px-8">
                <div className="flex justify-end">
                  <div className="text-black">
                    <div className="flex items-center">
                      <Typography className="mr-4 text-black opacity-60">
                        Page {currentPage}
                      </Typography>
                      <ButtonGroup>
                        <Button
                          size="initial"
                          className={classNames(
                            'py-2 px-2 text-black',
                            currentPage === 1 ? null : 'bg-indigo-200'
                          )}
                          color="translucent"
                          border="primary"
                          variant="outlined"
                          onClick={() => navigateToPage(1)}
                          disabled={currentPage === 1}
                        >
                          {`<<`}
                        </Button>
                        <Button
                          size="initial"
                          className={classNames(
                            'py-2 px-2 text-black',
                            currentPage === 1 ? null : 'bg-indigo-200'
                          )}
                          color="translucent"
                          border="primary"
                          variant="outlined"
                          onClick={handlePrevPage}
                          disabled={currentPage === 1}
                        >
                          Prev
                        </Button>
                        <Button
                          size="initial"
                          className={classNames(
                            'py-2 px-2 text-black',
                            currentPage === totalPages ? null : 'bg-indigo-200'
                          )}
                          color="translucent"
                          border="primary"
                          variant="outlined"
                          onClick={handleNextPage}
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </Button>
                        <Button
                          size="initial"
                          className={classNames(
                            'py-2 px-2 text-black',
                            currentPage === totalPages ? null : 'bg-indigo-200'
                          )}
                          color="translucent"
                          border="primary"
                          variant="outlined"
                          onClick={() => navigateToPage(totalPages)}
                          disabled={currentPage === totalPages}
                        >
                          {`>>`}
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className=" flex flex-col items-center justify-center pt-48 ">
            {appConfig.showLoadingIndicator && isLoading ? (
              <div className="h-[220px] w-[180px]">Loading...</div>
            ) : (
              <EmptyStudies />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Exams;

const defaultFilterValues = {
  patientName: '',
  mrn: '',
  studyDate: {
    startDate: null,
    endDate: null,
  },
  description: '',
  modalities: [],
  accession: '',
  sortBy: '',
  sortDirection: 'none',
  pageNumber: 1,
  resultsPerPage: 25,
  datasources: '',
};

function _getQueryFilterValues(query) {
  query = new URLSearchParams(query);

  const pageNumber = _tryParseInt(query.get('pageNumber'), 1);
  const resultsPerPage = _tryParseInt(query.get('resultsPerPage'), 25);

  const queryFilterValues = {
    // DCM
    patientId: query.get('mrn'),
    patientName: query.get('patientName'),
    studyDescription: query.get('description'),
    modalitiesInStudy: query.get('modalities') && query.get('modalities').split(','),
    accessionNumber: query.get('accession'),
    //
    startDate: query.get('startDate'),
    endDate: query.get('endDate'),
    page: _tryParseInt(query.get('page'), undefined),
    pageNumber,
    resultsPerPage,
    // Rarely supported server-side
    sortBy: query.get('sortBy'),
    sortDirection: query.get('sortDirection'),
  };

  // patientName: good
  // studyDescription: good
  // accessionNumber: good

  // Delete null/undefined keys
  Object.keys(queryFilterValues).forEach(
    key => queryFilterValues[key] == null && delete queryFilterValues[key]
  );

  return queryFilterValues;

  function _tryParseInt(str, defaultValue) {
    let retValue = defaultValue;
    if (str !== null) {
      if (str.length > 0) {
        if (!isNaN(str)) {
          retValue = parseInt(str);
        }
      }
    }
    return retValue;
  }
}

function _getQueryFilterValues2(params) {
  const queryFilterValues = {
    patientName: params.get('patientname'),
    mrn: params.get('mrn'),
    studyDate: {
      startDate: params.get('startdate') || null,
      endDate: params.get('enddate') || null,
    },
    description: params.get('description'),
    modalities: params.get('modalities') ? params.get('modalities').split(',') : [],
    accession: params.get('accession'),
    sortBy: params.get('sortby'),
    sortDirection: params.get('sortdirection'),
    pageNumber: _tryParseInt(params.get('pagenumber'), undefined),
    resultsPerPage: _tryParseInt(params.get('resultsperpage'), undefined),
    datasources: params.get('datasources'),
  };

  // Delete null/undefined keys
  Object.keys(queryFilterValues).forEach(
    key => queryFilterValues[key] == null && delete queryFilterValues[key]
  );

  return queryFilterValues;
}

function _tryParseInt(str, defaultValue) {
  let retValue = defaultValue;
  if (str && str.length > 0) {
    if (!isNaN(str)) {
      retValue = parseInt(str);
    }
  }
  return retValue;
}

function _sortStringDates(s1, s2, sortModifier) {
  // TODO: Delimiters are non-standard. Should we support them?
  const s1Date = moment(s1.date, ['YYYYMMDD', 'YYYY.MM.DD'], true);
  const s2Date = moment(s2.date, ['YYYYMMDD', 'YYYY.MM.DD'], true);

  if (s1Date.isValid() && s2Date.isValid()) {
    return (s1Date.toISOString() > s2Date.toISOString() ? 1 : -1) * sortModifier;
  } else if (s1Date.isValid()) {
    return sortModifier;
  } else if (s2Date.isValid()) {
    return -1 * sortModifier;
  }
}
