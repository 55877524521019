import React, { useEffect, useState } from 'react';
import Modal from '../../../Components/modal';
import { MdDownload } from 'react-icons/md';
import { ImPrinter } from 'react-icons/im';
import { IoIosShareAlt } from 'react-icons/io';
import reportLogo from '../../../../../assets/logoReportDeep.png';
import reportbrain from '../../../../../assets/brainReportPlan.png';
import reportfemur from '../../../../../assets/FemoralReportPlan.png';
import reportabdo from '../../../../../assets/abdominalReportPlan.png';
import reportafpocket from '../../../../../assets/afpocketReportPlan.png';
import QRCode from 'react-qr-code';
import { SlCheck } from 'react-icons/sl';
import ReportCard from './report-card';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { LuLoader2 } from 'react-icons/lu';

function GenerateReportModal(globalState) {
  const [isLoading, setIsLoading] = useState(false); // Add loading stat
  const [imgFemur, setImgFemur] = useState(null);
  const [imgAbdo, setImgAbdo] = useState(null);
  const [imgCephalic, setImgCephalic] = useState(null);
  const [imgAfPocke, setImgAfPocket] = useState(null);
  const [qrSrc, setQrSrc] = useState('');
  console.log('just for test for report comp', globalState);
  useEffect(() => {
    if (globalState?.globalState?.planes?.['femur']?.imgReport) {
      globalState?.globalState?.planes?.['femur']?.imgReport
        .then(imgReport => {
          setImgFemur(imgReport); // Set the resolved value as the src
        })
        .catch(error => {
          console.error('Error fetching imgReport:', error);
        });
    } else {
      setImgFemur(null);
      console.log('imgReport does not exist.');
    }
    if (globalState?.globalState?.planes?.['abdominal']?.imgReport) {
      globalState?.globalState?.planes?.['abdominal']?.imgReport
        .then(imgReport => {
          setImgAbdo(imgReport); // Set the resolved value as the src
        })
        .catch(error => {
          console.error('Error fetching imgReport:', error);
        });
    } else {
      setImgAbdo(null);
      console.log('imgReport does not exist.');
    }
    if (globalState?.globalState?.planes?.['cephalic']?.imgReport) {
      globalState?.globalState?.planes?.['cephalic']?.imgReport
        .then(imgReport => {
          setImgCephalic(imgReport); // Set the resolved value as the src
        })
        .catch(error => {
          console.error('Error fetching imgReport:', error);
        });
    } else {
      setImgCephalic(null);
      console.log('imgReport does not exist.');
    }
    if (globalState?.globalState?.planes?.['af_pocket']?.imgReport) {
      globalState?.globalState?.planes?.['af_pocket']?.imgReport
        .then(imgReport => {
          setImgAfPocket(imgReport); // Set the resolved value as the src
        })
        .catch(error => {
          console.error('Error fetching imgReport:', error);
        });
    } else {
      setImgAfPocket(null);
      console.log('imgReport does not exist.');
    }
  }, [globalState?.globalState?.openGR]);
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-GB').replace(/\//g, '-');
  const plans = [
    {
      image: imgFemur,
      title: 'Femur Plan',
      quality_score: globalState?.globalState?.planes?.['femur']?.scoreOrigin
        ? globalState?.globalState?.planes?.['femur']?.scoreOrigin
        : 0,
      quality_criteria: [
        {
          title: 'Both femur ends visible',
          bool: globalState?.globalState?.planes?.femur?.qualityCriteria
            ?.QUAL_VAL__BOTH_EXTREMITIES_VISIBLE
            ? true
            : false,
        },
        {
          title: 'Angle to the horizontal < 45°',
          bool: globalState?.globalState?.planes?.femur?.qualityCriteria?.QUAL_VAL__FEMURAL_ANGLE
            ? true
            : false,
        },
        {
          title: 'Femur occupies > 50% of the image',
          bool: globalState?.globalState?.planes?.femur?.qualityCriteria?.QUAL_VAL__ZOOM
            ? true
            : false,
        },
      ],
    },
    {
      image: imgCephalic,
      title: 'Cephalic Plan',
      quality_score: globalState?.planes?.['cephalic']?.scoreOrigin || 0,
      quality_criteria: [
        {
          title: 'Thalami visible',
          bool: globalState?.globalState?.planes?.cephalic?.qualityCriteria
            ?.QUAL_FLAG__cephal_thalamus
            ? true
            : false,
        },
        {
          title: 'Cavum septum pellucidum visible',
          bool: globalState?.globalState?.planes?.cephalic?.qualityCriteria
            ?.QUAL_FLAG_cavum_septi_pellucidi
            ? true
            : false,
        },
        {
          title: 'Cerebellum not visible',
          bool: globalState?.globalState?.planes?.cephalic?.qualityCriteria
            ?.QUAL_FLAG_Cerebellum_not_visble
            ? true
            : false,
        },
        {
          title: 'Head occupies > 50% of the image',
          bool: globalState?.globalState?.planes?.cephalic?.qualityCriteria?.QUAL_VAL__ZOOM
            ? true
            : false,
        },
      ],
    },
    {
      image: imgAbdo,
      title: 'Abdominal Plan',
      quality_score: globalState?.globalState?.planes?.['abdominal']?.scoreOrigin
        ? globalState?.globalState?.planes?.['abdominal']?.scoreOrigin
        : 0,
      quality_criteria: [
        {
          title: 'Stomach bubble visible',
          bool: globalState?.globalState?.planes?.abdominal?.qualityCriteria
            ?.QUAL_FLAG__abdo_stomach_bubble
            ? true
            : false,
        },
        {
          title: 'Portal sinus visible',
          bool: globalState?.globalState?.planes?.abdominal?.qualityCriteria
            ?.QUAL_FLAG__abdo_portal_sinus
            ? true
            : false,
        },
        {
          title: 'Kidneys not visible',
          bool: globalState?.globalState?.planes?.abdominal?.qualityCriteria
            ?.QUAL_FLAG__abdo_fetal_kidneys_not_visible
            ? true
            : false,
        },
        {
          title: 'Abdomen occupies > 50% of the image',
          bool: globalState?.globalState?.planes?.abdominal?.qualityCriteria?.QUAL_VAL__ZOOM
            ? true
            : false,
        },
      ],
    },
    {
      image: imgAfPocke,
      title: 'AF-Pocket Plan',
      quality_score:
        globalState?.globalState?.planes?.['af_pocket']?.score == -1
          ? 0
          : globalState?.globalState?.planes?.['af_pocket']?.score,
      quality_criteria: [],
    },
    // {
    //   image: imgFemur,
    //   title: 'Plan A',
    //   quality_score: globalState?.planes?.['planA']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgCephalic,
    //   title: 'Plan B',
    //   quality_score: globalState?.planes?.['planB']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgAbdo,
    //   title: 'Plan C',
    //   quality_score: globalState?.planes?.['planC']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgAfPocke,
    //   title: 'Plan D',
    //   quality_score: globalState?.planes?.['planD']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgFemur,
    //   title: 'Plan E',
    //   quality_score: globalState?.planes?.['planE']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgCephalic,
    //   title: 'Plan F',
    //   quality_score: globalState?.planes?.['planF']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgAbdo,
    //   title: 'Plan G',
    //   quality_score: globalState?.planes?.['planG']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgAfPocke,
    //   title: 'Plan H',
    //   quality_score: globalState?.planes?.['planH']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgFemur,
    //   title: 'Plan I',
    //   quality_score: globalState?.planes?.['planI']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgCephalic,
    //   title: 'Plan J',
    //   quality_score: globalState?.planes?.['planJ']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgAbdo,
    //   title: 'Plan K',
    //   quality_score: globalState?.planes?.['planK']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgAfPocke,
    //   title: 'Plan L',
    //   quality_score: globalState?.planes?.['planL']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgFemur,
    //   title: 'Plan M',
    //   quality_score: globalState?.planes?.['planM']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgCephalic,
    //   title: 'Plan N',
    //   quality_score: globalState?.planes?.['planN']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgAbdo,
    //   title: 'Plan O',
    //   quality_score: globalState?.planes?.['planO']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgAfPocke,
    //   title: 'Plan P',
    //   quality_score: globalState?.planes?.['planP']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgFemur,
    //   title: 'Plan Q',
    //   quality_score: globalState?.planes?.['planQ']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgCephalic,
    //   title: 'Plan R',
    //   quality_score: globalState?.planes?.['planR']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgAbdo,
    //   title: 'Plan S',
    //   quality_score: globalState?.planes?.['planS']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgAfPocke,
    //   title: 'Plan T',
    //   quality_score: globalState?.planes?.['planT']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgFemur,
    //   title: 'Plan U',
    //   quality_score: globalState?.planes?.['planU']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgCephalic,
    //   title: 'Plan V',
    //   quality_score: globalState?.planes?.['planV']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgAbdo,
    //   title: 'Plan W',
    //   quality_score: globalState?.planes?.['planW']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgAfPocke,
    //   title: 'Plan X',
    //   quality_score: globalState?.planes?.['planX']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgFemur,
    //   title: 'Plan Y',
    //   quality_score: globalState?.planes?.['planY']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
    // {
    //   image: imgCephalic,
    //   title: 'Plan Z',
    //   quality_score: globalState?.planes?.['planZ']?.scoreOrigin || 0,
    //   quality_criteria: [],
    // },
  ];

  const handleCancel = () => {
    globalState?.setGlobalState(prevState => ({
      ...prevState,
      openGR: !globalState?.globalState?.openGR,
    }));
  };
  useEffect(() => {
    setTimeout(() => {
      const capture = document.querySelector('.actual-receipt');
      const scale = 3;

      html2canvas(capture, {
        scale: scale,
        useCORS: true,
      })
        .then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const doc = new jsPDF({
            orientation: window.innerWidth > 1280 ? 'landscape' : 'portrait',
            unit: 'in',
            format: [24, 12],
            pageSize: 'A4',
          });

          const componentWidth = doc.internal.pageSize.getWidth();
          const componentHeight = doc.internal.pageSize.getHeight();

          doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);

          // Use print option from jsPDF
          setQrSrc(doc.output('bloburl').toString());
        })
        .catch(error => {
          setIsLoading(false); // Stop loading if an error occurs
          console.error('Error generating PDF for print:', error);
        });
    }, 100);
  }, [globalState?.globalState?.openGR]);
  // const downloadPDF = () => {
  //   setIsLoading(true); // Set loading to true when PDF generation starts

  //   // Use setTimeout to delay the start of the PDF generation
  //   setTimeout(() => {
  //     const capture = document.querySelector('.actual-receipt');
  //     const scale = 3;

  //     html2canvas(capture, {
  //       scale: scale,
  //       useCORS: true,
  //     })
  //       .then(canvas => {
  //         const orientation = window.innerWidth > 1280 ? 'landscape' : 'portrait';
  //         const imgData = canvas.toDataURL('img/png');
  //         const doc = new jsPDF({
  //           orientation: orientation,
  //           unit: 'in',
  //           format: orientation == 'landscape' ? [24, 12] : [15, 12],
  //           pageSize: 'A4',
  //         });
  //         const componentWidth = doc.internal.pageSize.getWidth();
  //         const componentHeight = doc.internal.pageSize.getHeight();
  //         doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
  //         doc.save(`report_test.pdf`);
  //         setIsLoading(false); // Set loading to false when PDF generation ends
  //       })
  //       .catch(error => {
  //         setIsLoading(false); // Set loading to false even if there's an error
  //         console.error('Error generating PDF:', error);
  //       });
  //   }, 100); // A small delay, such as 100ms, to allow the UI to update
  // };

  // Function to print the PDF
  const downloadPDF = () => {
    setIsLoading(true); // Set loading to true when PDF generation starts

    // Use setTimeout to delay the start of the PDF generation
    setTimeout(() => {
      const capture = document.querySelector('.actual-receipt');
      const scale = 3;

      html2canvas(capture, {
        scale: scale,
        useCORS: true,
      })
        .then(canvas => {
          const orientation = window.innerWidth > 1280 ? 'landscape' : 'portrait';
          const imgData = canvas.toDataURL('img/png');
          const doc = new jsPDF({
            orientation: orientation,
            unit: 'in',
            format: orientation === 'landscape' ? [24, 12] : [15, 12],
            pageSize: 'A4',
          });

          const pageWidth = doc.internal.pageSize.getWidth();
          const pageHeight = doc.internal.pageSize.getHeight();
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;

          // Calculate the ratio to scale the image to fit in the PDF page
          const scaleRatio = pageWidth / canvasWidth;
          const scaledHeight = canvasHeight * scaleRatio;

          let yOffset = 0; // Track where the content has been drawn

          // Function to add a portion of the canvas
          const addCanvasPart = yOffset => {
            const croppedCanvas = document.createElement('canvas');
            croppedCanvas.width = canvasWidth;
            croppedCanvas.height = pageHeight / scaleRatio;

            const ctx = croppedCanvas.getContext('2d');
            ctx.drawImage(
              canvas,
              0,
              yOffset / scaleRatio, // Start at the correct y position
              canvasWidth,
              pageHeight / scaleRatio, // Slice the part that fits on the page
              0,
              0,
              canvasWidth,
              pageHeight / scaleRatio // Place it in the new canvas
            );

            return croppedCanvas.toDataURL('image/png');
          };

          // Loop through the content and add pages
          while (yOffset < scaledHeight) {
            const remainingHeight = scaledHeight - yOffset;

            // If the content fits on the current page, add it
            if (remainingHeight <= pageHeight) {
              const imagePart = addCanvasPart(yOffset);
              doc.addImage(imagePart, 'PNG', 0, 0, pageWidth, remainingHeight);
              yOffset = scaledHeight;
            } else {
              // Otherwise, add a page with the part that fits
              const imagePart = addCanvasPart(yOffset);
              doc.addImage(imagePart, 'PNG', 0, 0, pageWidth, pageHeight);
              yOffset += pageHeight;

              // Add a new page for the remaining content
              doc.addPage();
            }
          }

          doc.save(`report_test.pdf`);
          setIsLoading(false); // Set loading to false when PDF generation ends
        })
        .catch(error => {
          setIsLoading(false); // Set loading to false even if there's an error
          console.error('Error generating PDF:', error);
        });
    }, 100); // A small delay, such as 100ms, to allow the UI to update
  };

  const printPDF = () => {
    setIsLoading(true); // Optional: show loading during print preparation

    setTimeout(() => {
      const capture = document.querySelector('.actual-receipt');
      const scale = 3;

      html2canvas(capture, {
        scale: scale,
        useCORS: true,
      })
        .then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const doc = new jsPDF({
            orientation: window.innerWidth > 1280 ? 'landscape' : 'portrait',
            unit: 'in',
            format: [24, 12],
            pageSize: 'A4',
          });

          const componentWidth = doc.internal.pageSize.getWidth();
          const componentHeight = doc.internal.pageSize.getHeight();

          doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);

          // Use print option from jsPDF
          window.open(doc.output('bloburl'), '_blank'); // Open PDF in a new tab for printing

          setIsLoading(false); // Stop loading after PDF is prepared
        })
        .catch(error => {
          setIsLoading(false); // Stop loading if an error occurs
          console.error('Error generating PDF for print:', error);
        });
    }, 100);
  };

  console.log('globalState test', globalState?.globalState?.openGR);
  return (
    <div className="">
      <Modal
        isOpen={globalState?.globalState?.openGR}
        onClose={() => {
          handleCancel();
        }}
        size="fullScreen"
        overlayClassName="py-10"
        className="bg-white"
      >
        <Modal.Header
          title={
            <div className="flex w-full justify-between">
              <div className="flex w-full justify-start">Real Time Report</div>
              <div className="flex w-full justify-end space-x-3">
                <div
                  onClick={() => {
                    downloadPDF();
                  }}
                  className="cursor-pointer hover:text-[#8c8989]"
                >
                  {isLoading ? (
                    <LuLoader2 className="animate-spin" /> // Display loader icon with spinning animation
                  ) : (
                    <MdDownload />
                  )}
                </div>
                <div
                  onClick={printPDF}
                  className="cursor-pointer hover:text-[#8c8989]"
                >
                  <ImPrinter />
                </div>
                <div>
                  <IoIosShareAlt
                    onClick={() => {
                      handleCancel();
                    }}
                    className="cursor-pointer hover:text-[#8c8989]"
                  />
                </div>
              </div>
            </div>
          }
          onClose={() => {
            handleCancel();
          }}
          displayClose={false}
        />
        <Modal.Content>
          <div className="actual-receipt">
            <div className="flex w-full items-center border-t border-b border-[#A6ABC8] bg-white p-3 px-5">
              <div className="flex-col font-semibold">
                <div className="w-44 text-2xl text-[#000000]">Fetal Biometry</div>
                <div className="text-md text-[#2886C7]">Ultrasound Report</div>
              </div>
              <div className="flex h-16 w-full items-center justify-center">
                <img
                  src={reportLogo}
                  className="w-52 lg:w-72"
                />
              </div>
              <div className="w-fit">
                <div className="flex w-full">
                  <div className="flex w-[199.88px] flex-col items-end justify-center space-y-1">
                    <div className="text-sm font-semibold text-[#1F384C]">
                      <span className="text-[#000000]">Practicionaer name : </span>
                      Dr. {globalState?.globalState?.doctorName}
                    </div>
                    <div className="text-sm font-semibold text-[#1F384C]">
                      <span className="text-[#000000]">Machine Type: </span>
                      {globalState?.globalState?.machineType}
                    </div>
                    <div className="text-sm font-semibold text-[#1F384C]">
                      <span className="text-[#000000]">Exam date : </span>
                      {formattedDate}
                    </div>
                  </div>
                  <div className="flex w-[101.93px] flex-col items-center justify-center">
                    <div className="mb-1 text-xs font-bold">Access full exam</div>
                    <QRCode
                      className=""
                      title="Deepecho"
                      value={qrSrc}
                      bgColor={'white'}
                      fgColor={'black'}
                      size={50}
                    />
                    <h4 className=" text-center text-sm">27534897</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white px-5 py-8">
              <div className="grid w-full grid-cols-1 gap-3 xl:grid-cols-2">
                {plans.map((item, index) => (
                  <ReportCard
                    key={index}
                    {...item}
                  />
                ))}
              </div>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
}
export default GenerateReportModal;
