import React, { useEffect, useMemo, useState } from 'react';
import PatientGeneralInfo from './patient.general.info';
import PatientMedicalBackground from './patient.medical.background';
import PatientExamDocument from './patient.exam.document';
import PatientContact from './patient.contact';
import { CloseOutlined } from '@ant-design/icons';
import {
  MedicalPlan,
  Patient,
  Relationship,
} from '../../Sections/PatientsList/patientsList.section.custom';
import { Switch } from '../Switch/switch';
import { useDispatch, useSelector } from 'react-redux';
import { getPatients, updatePatient } from '../../../store/actions';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { postNewPatient, updateExistingPatient } from '../../../helpers/backendHelper';
import { Menstrual } from '../../interfaces';
import { useAddPatient } from '../../../helpers/api/patient/patient.api';
import Modal from '../modal';
import PatientForm from './patient.form';
import { toast } from 'react-toastify';
interface ErrorDetail {
  type: string; // Type of error (e.g., 'value_error')
  loc: (string | number)[]; // Location of the error, you may want to use loc[1] for the field name
  msg: string; // The error message
  input?: string; // Input field name (if applicable)
}

export interface errorPatient {
  input_name: string;
  type: string;
  message: string;
  status: boolean;
}

interface PatientModalProps {
  closeNewPatientModal: () => void;
  edit: boolean;
  Patient: Patient;
  setNewPatient: any;
  setPatientEdit?: any;
  page?: number;
  itemsPerPage?: number;
  location?: any;
  isOpen?: any;
  setNewPatientModalOpen?: any;
}

function PatientModal({
  closeNewPatientModal,
  edit,
  Patient,
  setNewPatient,
  setPatientEdit,
  page,
  itemsPerPage,
  location,
  isOpen,
  setNewPatientModalOpen,
}: PatientModalProps) {
  const [condition, setCondition] = useState<undefined | number>();
  const [error, setError] = useState<Map<string, errorPatient>>();
  const [MenstrualData, setMenstrualData] = useState<Menstrual[]>([]);
  const mutationAdd = useAddPatient((oldData, newData) => [...oldData, newData]);
  // const mutationAdd: UseMutationResult<AxiosResponse<any>, AxiosError, Patient> = useMutation({
  //     mutationFn: postNewPatient,
  //     onError: (error: AxiosError) => {
  //         const errorMessages = new Map<string, errorPatient>();
  //         console.log("Error response:", error);

  //         // Check if error.response exists
  //         if (error.response) {
  //             // Cast response data to the defined ErrorResponse interface
  //             const errorData = error.response.data as ErrorResponse;

  //             // Check if errors property exists in the response
  //             if (errorData.errors) {
  //                 errorData.errors.forEach((err) => {
  //                     // Assuming loc[1] contains the input field name
  //                     const fieldName = err.loc[1] as string;

  //                     const errorDetail: errorPatient = {
  //                         input_name: fieldName,
  //                         type: err.type || 'validation',
  //                         message: err.msg || 'An error occurred', // Use msg for the error message
  //                         status: true,
  //                     };
  //                     errorMessages.set(fieldName, errorDetail);
  //                 });
  //             } else {
  //                 // If there are no specific field errors, handle general error messages
  //                 const generalErrorDetail: errorPatient = {
  //                     input_name: 'general',
  //                     type: 'general',
  //                     message: 'An unknown error occurred.',
  //                     status: true,
  //                 };
  //                 errorMessages.set('general', generalErrorDetail);
  //             }
  //         } else {
  //             // Handle case when error.response is undefined (e.g., network error)
  //             const networkErrorDetail: errorPatient = {
  //                 input_name: 'network',
  //                 type: 'network',
  //                 message: 'Network error. Please try again later.',
  //                 status: true,
  //             };
  //             errorMessages.set('network', networkErrorDetail);
  //         }

  //         setError(errorMessages);
  //     },
  // });

  console.log('mutationAdd :', mutationAdd);
  const { profile } = useSelector(state => ({
    profile: state.Profile,
  }));
  const { patientsState } = useSelector(state => ({
    patientsState: state.Patient,
  }));
  const selectedProfile = useMemo(() => profile, [profile]);
  const dispatch = useDispatch();

  const addPatientMutation = useMutation<any, any, any, unknown>({
    mutationFn: ({ orgId, data }: { orgId: string; data: Patient }) =>
      addNewPatient({ orgId, data }),
    onSuccess: response => {
      if (response) {
        toast.success('Patient was added successfully!');
        if (location == 'home') {
          dispatch(getPatients({ filter_options: {} }));
        } else {
          dispatch(
            getPatients({
              filter_options: {
                ...patientsState.filtered_conditions,
                page: page,
                items_per_page: itemsPerPage,
              },
            })
          );
        }
        closeNewPatientModal();
      }
    },
    onError: error => {
      const formatedError = new Map<string, errorPatient>(
        error.map(item => [
          item.loc[1],
          {
            input_name: item.loc[1],
            type: item.type,
            message: item.msg,
            status: true,
          },
        ])
      );
      setError(formatedError);
    },
  });
  const updatePatientMutation = useMutation<any, any, any, unknown>({
    mutationFn: ({ data }: { data: Patient }) => update({ data }),
    onSuccess: response => {
      if (edit && setPatientEdit !== undefined) {
        setPatientEdit(Patient);
      }
      toast.success('Patient was updated successfully!');
      if (response) {
        dispatch(
          getPatients({
            filter_options: {
              ...patientsState.filtered_conditions,
              page: page,
              items_per_page: itemsPerPage,
            },
          })
        );
        closeNewPatientModal();
      }
    },
    onError: error => {
      console.log(error);
      const formatedError = new Map<string, errorPatient>(
        error.map(item => [
          item.loc[1],
          {
            input_name: item.loc[1],
            type: item.type,
            message: item.msg,
            status: true,
          },
        ])
      );
      setError(formatedError);
    },
  });
  const handleNewPatientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('i m here?');
    const { name, value, checked } = e.target;
    console.log('i m patient = ', Patient);
    setNewPatient(prevPatient => ({ ...prevPatient, [name]: value, watchlist: checked }));
  };
  const handleNewPatientBirthDate = (date: moment.Moment | null, dateString: string) => {
    console.log('asssss : ', date);
    if (date) {
      const formattedDate = date.format('YYYY-MM-DD');
      setNewPatient(prevPatient => ({ ...prevPatient, birth_date: formattedDate }));
    } else {
      setNewPatient(prevPatient => ({ ...prevPatient, birth_date: '' }));
    }
  };
  const handleSelectChange = (value: MedicalPlan) => {
    setNewPatient(prevPatient => ({ ...prevPatient, medical_plan: MedicalPlan[value] }));
  };
  const handleSelectRelationChange = (value: Relationship) => {
    setNewPatient(prevPatient => ({
      ...prevPatient,
      emergency_contact_relationship: Relationship[value],
    }));
  };

  const addNewPatient = async ({ orgId, data }: { orgId: string; data: Patient }) => {
    return mutationAdd.mutateAsync(data);
  };
  const update = async ({ data }: { data: Patient }) => {
    console.log('i m here', data);
    const keepKeys = [
      'id',
      'patient_id',
      'patient_name',
      'email',
      'phone_number',
      'birth_date',
      'adress',
      'policy_number',
      'insurance_provider',
      'patient_last_name',
      'patient_middle_name',
      'emergency_details',
    ];
    const filteredData = Object.fromEntries(
      Object.entries(Patient).filter(([key]) => keepKeys.includes(key))
    );
    return updateExistingPatient(filteredData);
  };
  function HandleChangeComponents() {
    if (edit) {
      updatePatientMutation.mutate({ data: Patient });
    } else {
      addPatientMutation.mutate({
        orgId: selectedProfile?.currentOrganization?.id,
        data: Patient,
      });
      // addNewPatient({ orgId: selectedProfile?.currentOrganization?.id, data: Patient });
    }
  }
  console.log("helo helà",isOpen)
  function HandleConditionSwitch() {
    if (condition >= 1) {
      if (condition === 1) {
        setCondition(undefined);
      } else {
        setCondition(condition - 1);
      }
    }
  }
  const handlemsgError = msg => {
    return msg.replace(/^Value error, /, '');
  };
  useEffect(() => {
    error?.forEach((value, key) => {
      if (value?.status) {
        toast.error(handlemsgError(value.message));
      }
    });
  }, [error]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        closeNewPatientModal();
      }}
      size={'medium'}
      overlayClassName="top-0 right-0 left-0"
      className="bg-[#F2F8FF]"
    >
      <Modal.Header
        title={
          <div className="flex w-full justify-between">
            <div className="flex w-full justify-start text-lg font-semibold">
              {edit ? 'Edit Patient' : 'Add New Patient'}
            </div>
          </div>
        }
        onClose={() => {
          closeNewPatientModal();
        }}
        displayClose={true}
      />
      <Modal.Content>
        <PatientForm
          handleNewPatientChange={handleNewPatientChange}
          newPatient={Patient}
          condition={condition}
          edit={edit}
          error={error}
          handleNewPatientBirthDate={handleNewPatientBirthDate}
          handleSelectChange={handleSelectChange}
          HandleChangeComponents={HandleChangeComponents}
        />
        
      </Modal.Content>
    </Modal>
  );
}

export default PatientModal;
