// export const GET_REPORT = 'GET_REPORT';
export const GET_STUDY = 'GET_STUDY';
export const ADD_STUDY = 'ADD_STUDY';
export const PREDICT = 'PREDICT';
export const GET_STUDIES = 'GET_STUDIES';
export const UPDATE_NEEDS_RELOAD = 'UPDATE_NEEDS_RELOAD';
export const API_SUCCESS = 'API_SUCCESS';
export const API_ERROR = 'API_ERROR';
// export const DELETE_REPORT = 'DELETE_REPORT';
export const DELETE_STUDY = 'DELETE_STUDY';
export const RESET_STUDY_STATE = 'RESET_STUDY_STATE';
export const ARCHIVE_STUDY = 'ARCHIVE_STUDY';
export const UNARCHIVE_STUDY = 'UNARCHIVE_STUDY';
