import React, { useEffect, useRef, useState } from 'react';
import { useGlobalState } from '../../../state/RealtimeGlobalContextProvider';
import { getUser } from '../../../helpers/apiHelper';
import { FaPrint, FaShare } from 'react-icons/fa6';
import Card from './components/card';
import { MdFiberManualRecord } from 'react-icons/md';
import { LuLoader2 } from 'react-icons/lu';
import { ImEnlarge2 } from 'react-icons/im';
import { PiInfo } from 'react-icons/pi';
import { TfiLayers } from 'react-icons/tfi';
import RatingBar from './components/rating-bar';
import VideoModal from './components/video-full-screen';
import imgPlans from '../../assets/viewPlan.png';
import abdoPlans from '../../assets/iconsPlans/Fetal_Abdominal_Standard_Plane.png';
import MultiSelectDropdown from './components/mulit-select-dropdown';
import ProgressBar from './components/progress-bar';
import { GoCheckCircle } from 'react-icons/go';
import CircularTimer from './components/circular-timer';
import { TiDeleteOutline } from 'react-icons/ti';
import ButtonWithCursorMovement from './components/invisible-button-click/button-with-cursor-movement';
import GenerateReportModal from './components/report';
import PixelSpacingModal from './components/pixel-spacing';
import DevicesModal from './components/devices-modal';
import Fetal_Abdominal_Standard_Plane from '../../assets/iconsPlans/Fetal_Abdominal_Standard_Plane.png';
import View_of_Genitalia from '../../assets/iconsPlans/View_of_Genitalia.png';
import View_of_Upper_Limb from '../../assets/iconsPlans/View_of_Upper_Limb.png';
import View_of_Lower_Limb_ from '../../assets/iconsPlans/View_of_Lower_Limb_.png';
import Axial_View_of_Kidneys from '../../assets/iconsPlans/Axial_View_of_Kidneys.png';
import Axial_View_of_Fetal_Bladder from '../../assets/iconsPlans/Axial_View_of_Fetal_Bladder.png';
import Coronal_View_of_Upper_Lip_Nose_Nostrils from '../../assets/iconsPlans/Coronal_View_of_Upper_Lip_Nose_Nostrils.png';
import Femur_Plane from '../../assets/iconsPlans/Femur_Plane.png';
import Sagittal_View_of_Cervix_with_Placenta from '../../assets/iconsPlans/Sagittal_View_of_Cervix_with_Placenta.png';
import Sagittal_View_for_CRL_and_NC_Measurements from '../../assets/iconsPlans/Sagittal_View_for_CRL_and_NC_Measurements.png';
import Transcerebellar_View from '../../assets/iconsPlans/Transcerebellar_View.png';
import Transventricular_View from '../../assets/iconsPlans/Transventricular_View.png';
import Transthalamic_View from '../../assets/iconsPlans/Transthalamic_View.png';
import Sagittal_View_of_the_Entire_Spine from '../../assets/iconsPlans/Sagittal_View_of_the_Entire_Spine.png';
import Coronal_View_of_the_Spine from '../../assets/iconsPlans/Coronal_View_of_the_Spine.png';
import Sagittal_View_of_the_Brain from '../../assets/iconsPlans/Sagittal_View_of_the_Brain.png';
import Four_Chamber_View_of_the_Heart from '../../assets/iconsPlans/Four_Chamber_View_of_the_Heart.png';
import LVOT from '../../assets/iconsPlans/LVOT.png';
import RVOT from '../../assets/iconsPlans/RVOT.png';
import Three_Vessel_View from '../../assets/iconsPlans/Three_Vessel_View.png';
import Three_Vessel_View_Trachea from '../../assets/iconsPlans/Three_Vessel_View_Trachea.png';
import Sagittal_View_of_the_Face from '../../assets/iconsPlans/Sagittal_View_of_the_Face.png';
import StepperComponent from './components/stepper-component';
import SelectView from './components/select-view';
export enum MediaType {
  USER_MEDIA = 'UserMedia',
  DISPLAY_MEDIA = 'DisplayMedia',
}

const mediaTypeMap = new Map([
  ['Share', MediaType.DISPLAY_MEDIA],
  ['USB', MediaType.USER_MEDIA],
]);

//354 640 854 1280 1920
//280 360 480 720 1080

// 1920 x 1080 (1080p Full HD)
// 1280 x 720  (720p HD)
// 854 x 480   (480p)
// 640 x 360   (360p)

const VIDEO_DIMENSIONS = {
  width: 1280,
  height: 720,
  aspectRatio: 16 / 9,
} as const;
// const DISPLAY_DIMENSIONS = {
//   width: 1280,
//   height: 720 / (VIDEO_DIMENSIONS.aspectRatio) // This will be ~562.5
// } as const;
const DISPLAY_DIMENSIONS = {
  width: VIDEO_DIMENSIONS.width,
  height: Math.round(VIDEO_DIMENSIONS.width / VIDEO_DIMENSIONS.aspectRatio), // Calculate dynamically
};
// const containerStyle = {
//   width: `${DISPLAY_DIMENSIONS.width}px`,
//   height: `${DISPLAY_DIMENSIONS.height}px`,
//   position: 'relative', // Ensures videos are positioned within the container
//   overflow: 'hidden', // Prevents layout overflow
// };
const RealTimeInferencingUDP: React.FC = () => {
  const { globalState, setGlobalState } = useGlobalState();
  const localVideoRef = useRef(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: DISPLAY_DIMENSIONS.width,
    height: DISPLAY_DIMENSIONS.height,
  });
  const [videoStream, setVideoStream] = useState(null);
  const [videoMaskStream, setVideoMaskStream] = useState(null);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [deviceMediaType, setDeviceMediaType] = useState(MediaType.DISPLAY_MEDIA);
  const user = getUser();
  const canvasRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [bool, setBool] = useState(true);
  const pcRef = useRef(null);
  const dataChannelRef = useRef(null);
  const [isInferencing, setIsInferencing] = useState(false);
  const [useDataChannel] = useState(true);
  const [videoTransform] = useState('rotate');
  const webrtcVideoRef = useRef<HTMLVideoElement>(null);
  const [selectedPlansModal, setSelectedPlansModal] = useState('');
  const [selectedPlansTag, setSelectedPlansTag] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const plans = [
    {
      label: 'Four Chamber View of the Heart',
      imgPlan: globalState?.planes?.['Four_Chamber_View_of_the_Heart']?.imgReport
        ? globalState?.planes?.['Four_Chamber_View_of_the_Heart']?.imgReport
        : Four_Chamber_View_of_the_Heart,
      tag: 'Four_Chamber_View_of_the_Heart',
      quality_score:
        globalState?.planes?.['Four_Chamber_View_of_the_Heart']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['Four_Chamber_View_of_the_Heart']?.selectedViewData
          ?.quality_criteria || [],
      isInReport: globalState?.planes?.['Four_Chamber_View_of_the_Heart']?.selectedViewData
        ? true
        : false,
      selectedImageReport: globalState?.planes?.['Four_Chamber_View_of_the_Heart']?.selectedViewData
        ?.imagePredictedPlane
        ? globalState?.planes?.['Four_Chamber_View_of_the_Heart']?.selectedViewData
            ?.imagePredictedPlane
        : Four_Chamber_View_of_the_Heart,
    },
    {
      label: 'LVOT (Left Ventricle Outflow Tract) View',
      imgPlan: globalState?.planes?.['LVOT']?.imgReport
        ? globalState?.planes?.['LVOT']?.imgReport
        : LVOT,
      tag: 'LVOT',
      quality_score: globalState?.planes?.['LVOT']?.selectedViewData?.score || 0,
      quality_criteria: globalState?.planes?.['LVOT']?.selectedViewData?.quality_criteria || [],
      isInReport: globalState?.planes?.['LVOT']?.selectedViewData ? true : false,
      selectedImageReport: globalState?.planes?.['LVOT']?.selectedViewData?.imagePredictedPlane
        ? globalState?.planes?.['LVOT']?.selectedViewData?.imagePredictedPlane
        : LVOT,
    },
    {
      label: 'RVOT (Right Ventricle Outflow Tract) View',
      imgPlan: globalState?.planes?.['RVOT']?.imgReport
        ? globalState?.planes?.['RVOT']?.imgReport
        : RVOT,
      tag: 'RVOT',
      quality_score: globalState?.planes?.['RVOT']?.selectedViewData?.score || 0,
      quality_criteria: globalState?.planes?.['RVOT']?.selectedViewData?.quality_criteria || [],
      isInReport: globalState?.planes?.['RVOT']?.selectedViewData ? true : false,
      selectedImageReport: globalState?.planes?.['RVOT']?.selectedViewData?.imagePredictedPlane
        ? globalState?.planes?.['RVOT']?.selectedViewData?.imagePredictedPlane
        : RVOT,
    },
    {
      label: 'Vessel View',
      imgPlan: globalState?.planes?.['Three_Vessel_View']?.imgReport
        ? globalState?.planes?.['Three_Vessel_View']?.imgReport
        : Three_Vessel_View,
      tag: 'Three_Vessel_View',
      quality_score: globalState?.planes?.['Three_Vessel_View']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['Three_Vessel_View']?.selectedViewData?.quality_criteria || [],
      isInReport: globalState?.planes?.['Three_Vessel_View']?.selectedViewData ? true : false,
      selectedImageReport: globalState?.planes?.['Three_Vessel_View']?.selectedViewData
        ?.imagePredictedPlane
        ? globalState?.planes?.['Three_Vessel_View']?.selectedViewData?.imagePredictedPlane
        : Three_Vessel_View,
    },
    {
      label: 'Vessel View & Trachea',
      imgPlan: globalState?.planes?.['Three_Vessel_View_Trachea']?.imgReport
        ? globalState?.planes?.['Three_Vessel_View_Trachea']?.imgReport
        : Three_Vessel_View_Trachea,
      tag: 'Three_Vessel_View_Trachea',
      quality_score:
        globalState?.planes?.['Three_Vessel_View_Trachea']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['Three_Vessel_View_Trachea']?.selectedViewData?.quality_criteria ||
        [],
      isInReport: globalState?.planes?.['Three_Vessel_View_Trachea']?.selectedViewData
        ? true
        : false,
      selectedImageReport: globalState?.planes?.['Three_Vessel_View_Trachea']?.selectedViewData
        ?.imagePredictedPlane
        ? globalState?.planes?.['Three_Vessel_View_Trachea']?.selectedViewData?.imagePredictedPlane
        : Three_Vessel_View_Trachea,
    },
    {
      label: 'Sagittal View of the Face',
      imgPlan: globalState?.planes?.['Sagittal_View_of_the_Face']?.imgReport
        ? globalState?.planes?.['Sagittal_View_of_the_Face']?.imgReport
        : Sagittal_View_of_the_Face,
      tag: 'Sagittal_View_of_the_Face',
      quality_score:
        globalState?.planes?.['Sagittal_View_of_the_Face']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['Sagittal_View_of_the_Face']?.selectedViewData?.quality_criteria ||
        [],
      isInReport: globalState?.planes?.['Sagittal_View_of_the_Face']?.selectedViewData
        ? true
        : false,
      selectedImageReport: globalState?.planes?.['Sagittal_View_of_the_Face']?.selectedViewData
        ?.imagePredictedPlane
        ? globalState?.planes?.['Sagittal_View_of_the_Face']?.selectedViewData?.imagePredictedPlane
        : Sagittal_View_of_the_Face,
    },
    {
      label: 'Sagittal View of the Brain',
      imgPlan: globalState?.planes?.['Sagittal_View_of_the_Brain']?.imgReport
        ? globalState?.planes?.['Sagittal_View_of_the_Brain']?.imgReport
        : Sagittal_View_of_the_Brain,
      tag: 'Sagittal_View_of_the_Brain',
      quality_score:
        globalState?.planes?.['Sagittal_View_of_the_Brain']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['Sagittal_View_of_the_Brain']?.selectedViewData?.quality_criteria ||
        [],
      isInReport: globalState?.planes?.['Sagittal_View_of_the_Brain']?.selectedViewData
        ? true
        : false,
      selectedImageReport: globalState?.planes?.['Sagittal_View_of_the_Brain']?.selectedViewData
        ?.imagePredictedPlane
        ? globalState?.planes?.['Sagittal_View_of_the_Brain']?.selectedViewData?.imagePredictedPlane
        : Sagittal_View_of_the_Brain,
    },
    {
      label: 'Transcerebellar View',
      imgPlan: globalState?.planes?.['Transcerebellar_View']?.imgReport
        ? globalState?.planes?.['Transcerebellar_View']?.imgReport
        : Transcerebellar_View,
      tag: 'Transcerebellar_View',
      quality_score: globalState?.planes?.['Transcerebellar_View']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['Transcerebellar_View']?.selectedViewData?.quality_criteria || [],
      isInReport: globalState?.planes?.['Transcerebellar_View']?.selectedViewData ? true : false,
      selectedImageReport: globalState?.planes?.['Transcerebellar_View']?.selectedViewData
        ?.imagePredictedPlane
        ? globalState?.planes?.['Transcerebellar_View']?.selectedViewData?.imagePredictedPlane
        : Transcerebellar_View,
    },
    {
      label: 'Transventricular View',
      imgPlan: globalState?.planes?.['cephalic']?.imgReport
        ? globalState?.planes?.['cephalic']?.imgReport
        : Transventricular_View,
      tag: 'cephalic',
      quality_score: globalState?.planes?.['cephalic']?.selectedViewData?.score || 0,
      quality_criteria: globalState?.planes?.['cephalic']?.selectedViewData?.quality_criteria || [],
      isInReport: globalState?.planes?.['cephalic']?.selectedViewData ? true : false,
      selectedImageReport: globalState?.planes?.['cephalic']?.selectedViewData?.imagePredictedPlane
        ? globalState?.planes?.['cephalic']?.selectedViewData?.imagePredictedPlane
        : Transventricular_View,
    },
    {
      label: 'Transthalamic View',
      imgPlan: globalState?.planes?.['cephalic']?.imgReport
        ? globalState?.planes?.['cephalic']?.imgReport
        : Transthalamic_View,
      tag: 'cephalic',
      quality_score: globalState?.planes?.['cephalic']?.selectedViewData?.score || 0,
      quality_criteria: globalState?.planes?.['cephalic']?.selectedViewData?.quality_criteria || [],
      isInReport: globalState?.planes?.['cephalic']?.selectedViewData ? true : false,
      selectedImageReport: globalState?.planes?.['cephalic']?.selectedViewData?.imagePredictedPlane
        ? globalState?.planes?.['cephalic']?.selectedViewData?.imagePredictedPlane
        : Transthalamic_View,
    },
    {
      label: 'Sagittal View of the Entire Spine',
      imgPlan: globalState?.planes?.['Sagittal_View_of_the_Entire_Spine']?.imgReport
        ? globalState?.planes?.['Sagittal_View_of_the_Entire_Spine']?.imgReport
        : Sagittal_View_of_the_Entire_Spine,
      tag: 'Sagittal_View_of_the_Entire_Spine',
      quality_score:
        globalState?.planes?.['Sagittal_View_of_the_Entire_Spine']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['Sagittal_View_of_the_Entire_Spine']?.selectedViewData
          ?.quality_criteria || [],
      isInReport: globalState?.planes?.['Sagittal_View_of_the_Entire_Spine']?.selectedViewData
        ? true
        : false,
      selectedImageReport: globalState?.planes?.['Sagittal_View_of_the_Entire_Spine']
        ?.selectedViewData?.imagePredictedPlane
        ? globalState?.planes?.['Sagittal_View_of_the_Entire_Spine']?.selectedViewData
            ?.imagePredictedPlane
        : Sagittal_View_of_the_Entire_Spine,
    },
    {
      label: 'Coronal View of the Spine',
      imgPlan: globalState?.planes?.['Coronal_View_of_the_Spine']?.imgReport
        ? globalState?.planes?.['Coronal_View_of_the_Spine']?.imgReport
        : Coronal_View_of_the_Spine,
      tag: 'Coronal_View_of_the_Spine',
      quality_score:
        globalState?.planes?.['Coronal_View_of_the_Spine']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['Coronal_View_of_the_Spine']?.selectedViewData?.quality_criteria ||
        [],
      isInReport: globalState?.planes?.['Coronal_View_of_the_Spine']?.selectedViewData
        ? true
        : false,
      selectedImageReport: globalState?.planes?.['Coronal_View_of_the_Spine']?.selectedViewData
        ?.imagePredictedPlane
        ? globalState?.planes?.['Coronal_View_of_the_Spine']?.selectedViewData?.imagePredictedPlane
        : Coronal_View_of_the_Spine,
    },
    {
      label: 'Fetal Abdominal Standard Plane',
      imgPlan: globalState?.planes?.['abdominal']?.imgReport
        ? globalState?.planes?.['abdominal']?.imgReport
        : Fetal_Abdominal_Standard_Plane,
      tag: 'abdominal',
      quality_score: globalState?.planes?.['abdominal']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['abdominal']?.selectedViewData?.quality_criteria || [],
      isInReport: globalState?.planes?.['abdominal']?.selectedViewData ? true : false,
      selectedImageReport: globalState?.planes?.['abdominal']?.selectedViewData?.imagePredictedPlane
        ? globalState?.planes?.['abdominal']?.selectedViewData?.imagePredictedPlane
        : Fetal_Abdominal_Standard_Plane,
    },
    {
      label: 'View of Genitalia',
      imgPlan: globalState?.planes?.['View_of_Genitalia']?.imgReport
        ? globalState?.planes?.['View_of_Genitalia']?.imgReport
        : View_of_Genitalia,
      tag: 'View_of_Genitalia',
      quality_score: globalState?.planes?.['View_of_Genitalia']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['View_of_Genitalia']?.selectedViewData?.quality_criteria || [],
      isInReport: globalState?.planes?.['View_of_Genitalia']?.selectedViewData ? true : false,
      selectedImageReport: globalState?.planes?.['View_of_Genitalia']?.selectedViewData
        ?.imagePredictedPlane
        ? globalState?.planes?.['View_of_Genitalia']?.selectedViewData?.imagePredictedPlane
        : View_of_Genitalia,
    },
    {
      label: 'View of Upper Limb',
      imgPlan: globalState?.planes?.['View_of_Upper_Limb']?.imgReport
        ? globalState?.planes?.['View_of_Upper_Limb']?.imgReport
        : View_of_Upper_Limb,
      tag: 'View_of_Upper_Limb',
      quality_score: globalState?.planes?.['View_of_Upper_Limb']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['View_of_Upper_Limb']?.selectedViewData?.quality_criteria || [],
      isInReport: globalState?.planes?.['View_of_Upper_Limb']?.selectedViewData ? true : false,
      selectedImageReport: globalState?.planes?.['View_of_Upper_Limb']?.selectedViewData
        ?.imagePredictedPlane
        ? globalState?.planes?.['View_of_Upper_Limb']?.selectedViewData?.imagePredictedPlane
        : View_of_Upper_Limb,
    },
    {
      label: 'View of Lower Limb',
      imgPlan: globalState?.planes?.['View_of_Lower_Limb_']?.imgReport
        ? globalState?.planes?.['View_of_Lower_Limb_']?.imgReport
        : View_of_Lower_Limb_,
      tag: 'View_of_Lower_Limb_',
      quality_score: globalState?.planes?.['View_of_Lower_Limb_']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['View_of_Lower_Limb_']?.selectedViewData?.quality_criteria || [],
      isInReport: globalState?.planes?.['View_of_Lower_Limb_']?.selectedViewData ? true : false,
      selectedImageReport: globalState?.planes?.['View_of_Lower_Limb_']?.selectedViewData
        ?.imagePredictedPlane
        ? globalState?.planes?.['View_of_Lower_Limb_']?.selectedViewData?.imagePredictedPlane
        : View_of_Lower_Limb_,
    },
    {
      label: 'Axial View of Kidneys',
      imgPlan: globalState?.planes?.['Axial_View_of_Kidneys']?.imgReport
        ? globalState?.planes?.['Axial_View_of_Kidneys']?.imgReport
        : Axial_View_of_Kidneys,
      tag: 'Axial_View_of_Kidneys',
      quality_score: globalState?.planes?.['Axial_View_of_Kidneys']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['Axial_View_of_Kidneys']?.selectedViewData?.quality_criteria || [],
      isInReport: globalState?.planes?.['Axial_View_of_Kidneys']?.selectedViewData ? true : false,
      selectedImageReport: globalState?.planes?.['Axial_View_of_Kidneys']?.selectedViewData
        ?.imagePredictedPlane
        ? globalState?.planes?.['Axial_View_of_Kidneys']?.selectedViewData?.imagePredictedPlane
        : Axial_View_of_Kidneys,
    },
    {
      label: 'Axial View of Fetal Bladder',
      imgPlan: globalState?.planes?.['Axial_View_of_Fetal_Bladder']?.imgReport
        ? globalState?.planes?.['Axial_View_of_Fetal_Bladder']?.imgReport
        : Axial_View_of_Fetal_Bladder,
      tag: 'Axial_View_of_Fetal_Bladder',
      quality_score:
        globalState?.planes?.['Axial_View_of_Fetal_Bladder']?.selectedViewData?.score || 0,
      quality_criteria:
        globalState?.planes?.['Axial_View_of_Fetal_Bladder']?.selectedViewData?.quality_criteria ||
        [],
      isInReport: globalState?.planes?.['Axial_View_of_Fetal_Bladder']?.selectedViewData
        ? true
        : false,
      selectedImageReport: globalState?.planes?.['Axial_View_of_Fetal_Bladder']?.selectedViewData
        ?.imagePredictedPlane
        ? globalState?.planes?.['Axial_View_of_Fetal_Bladder']?.selectedViewData
            ?.imagePredictedPlane
        : Axial_View_of_Fetal_Bladder,
    },
    {
      label: 'Coronal View of Upper Lip, Nose & Nostrils',
      imgPlan: globalState?.planes?.['Coronal_View_of_Upper_Lip_Nose_Nostrils']?.imgReport
        ? globalState?.planes?.['Coronal_View_of_Upper_Lip_Nose_Nostrils']?.imgReport
        : Coronal_View_of_Upper_Lip_Nose_Nostrils,
      tag: 'Coronal_View_of_Upper_Lip_Nose_Nostrils',
      quality_score:
        globalState?.planes?.['Coronal_View_of_Upper_Lip_Nose_Nostrils']?.selectedViewData?.score ||
        0,
      quality_criteria:
        globalState?.planes?.['Coronal_View_of_Upper_Lip_Nose_Nostrils']?.selectedViewData
          ?.quality_criteria || [],
      isInReport: globalState?.planes?.['Coronal_View_of_Upper_Lip_Nose_Nostrils']?.selectedViewData
        ? true
        : false,
      selectedImageReport: globalState?.planes?.['Coronal_View_of_Upper_Lip_Nose_Nostrils']
        ?.selectedViewData?.imagePredictedPlane
        ? globalState?.planes?.['Coronal_View_of_Upper_Lip_Nose_Nostrils']?.selectedViewData
            ?.imagePredictedPlane
        : Coronal_View_of_Upper_Lip_Nose_Nostrils,
    },
    {
      label: 'Femur Plane',
      imgPlan: globalState?.planes?.['femur']?.imgReport
        ? globalState?.planes?.['femur']?.imgReport
        : Femur_Plane,
      tag: 'femur',
      quality_score: globalState?.planes?.['femur']?.selectedViewData?.score || 0,
      quality_criteria: globalState?.planes?.['femur']?.selectedViewData?.quality_criteria || [],
      isInReport: globalState?.planes?.['femur']?.selectedViewData ? true : false,
      selectedImageReport: globalState?.planes?.['femur']?.selectedViewData?.imagePredictedPlane
        ? globalState?.planes?.['femur']?.selectedViewData?.imagePredictedPlane
        : Femur_Plane,
    },
    {
      label: 'Sagittal View for CRL and NC Measurements',
      imgPlan: globalState?.planes?.['Sagittal_View_for_CRL_and_NC_Measurements']?.imgReport
        ? globalState?.planes?.['Sagittal_View_for_CRL_and_NC_Measurements']?.imgReport
        : Sagittal_View_for_CRL_and_NC_Measurements,
      tag: 'Sagittal_View_for_CRL_and_NC_Measurements',
      quality_score:
        globalState?.planes?.['Sagittal_View_for_CRL_and_NC_Measurements']?.selectedViewData
          ?.score || 0,
      quality_criteria:
        globalState?.planes?.['Sagittal_View_for_CRL_and_NC_Measurements']?.selectedViewData
          ?.quality_criteria || [],
      isInReport: globalState?.planes?.['Sagittal_View_for_CRL_and_NC_Measurements']
        ?.selectedViewData
        ? true
        : false,
      selectedImageReport: globalState?.planes?.['Sagittal_View_for_CRL_and_NC_Measurements']
        ?.selectedViewData?.imagePredictedPlane
        ? globalState?.planes?.['Sagittal_View_for_CRL_and_NC_Measurements']?.selectedViewData
            ?.imagePredictedPlane
        : Sagittal_View_for_CRL_and_NC_Measurements,
    },
    {
      label: 'Sagittal View of Cervix with Placenta',
      imgPlan: globalState?.planes?.['Sagittal_View_of_Cervix_with_Placenta']?.imgReport
        ? globalState?.planes?.['Sagittal_View_of_Cervix_with_Placenta']?.imgReport
        : Sagittal_View_of_Cervix_with_Placenta,
      tag: 'Sagittal_View_of_Cervix_with_Placenta',
      quality_score:
        globalState?.planes?.['Sagittal_View_of_Cervix_with_Placenta']?.selectedViewData?.score ||
        0,
      quality_criteria:
        globalState?.planes?.['Sagittal_View_of_Cervix_with_Placenta']?.selectedViewData
          ?.quality_criteria || [],
      isInReport: globalState?.planes?.['Sagittal_View_of_Cervix_with_Placenta']?.selectedViewData
        ? true
        : false,
      selectedImageReport: globalState?.planes?.['Sagittal_View_of_Cervix_with_Placenta']
        ?.selectedViewData?.imagePredictedPlane
        ? globalState?.planes?.['Sagittal_View_of_Cervix_with_Placenta']?.selectedViewData
            ?.imagePredictedPlane
        : Sagittal_View_of_Cervix_with_Placenta,
    },
  ];
  const createPeerConnection = () => {
    const config = {
      iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
    };
    const pc = new RTCPeerConnection(config);

    pc.ontrack = event => {
      if (event.track.kind === 'video' && webrtcVideoRef.current) {
        webrtcVideoRef.current.srcObject = event.streams[0];
        console.log('webrtcVideoRef.current', webrtcVideoRef.current);
        setVideoMaskStream(event.streams[0]);
      }
    };

    return pc;
  };
  const modifySDP = desc => {
    const videoBandwidth = '\r\nb=AS:2000'; // Add 2 Mbps bandwidth for video
    desc.sdp = desc.sdp.replace(/a=mid:video\r\n/g, `a=mid:video${videoBandwidth}\r\n`);
    return desc;
  };
  const negotiate = () => {
    pcRef.current
      .createOffer()
      .then(offer => pcRef.current.setLocalDescription(offer))
      .then(() => {
        return new Promise<void>(resolve => {
          if (pcRef.current.iceGatheringState === 'complete') {
            resolve();
          } else {
            const checkState = () => {
              if (pcRef.current.iceGatheringState === 'complete') {
                pcRef.current.removeEventListener('icegatheringstatechange', checkState);
                resolve();
              }
            };
            pcRef.current.addEventListener('icegatheringstatechange', checkState);
          }
        });
      })
      .then(() => {
        const offer = pcRef.current.localDescription;
        return fetch('http://localhost:8000/api/v1/offer', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            sdp: offer.sdp,
            type: offer.type,
            video_transform: videoTransform,
            client_id: user?.profile?.sub,
          }),
        });
      })
      .then(response => response.json())
      .then(data => {
        return pcRef.current.setRemoteDescription(new RTCSessionDescription(data));
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (!globalState.isVideoFrozen) {
      console.log('================ Start Inferencing');

      // Start WebRTC connection
      pcRef.current = createPeerConnection();

      if (useDataChannel) {
        const dataChannel = pcRef.current.createDataChannel('chat');
        dataChannel.onopen = () => console.log('========== Data channel open');
        dataChannel.onmessage = event => console.log('========== Message received:', event.data);
        dataChannelRef.current = dataChannel;
      }

      if (videoStream) {
        const videoTrack = videoStream.getVideoTracks()[0];
        console.log('videoTrack :', videoTrack);
        const applyConstraints = async track => {
          const constraints = {
            width: DISPLAY_DIMENSIONS.width,
            height: DISPLAY_DIMENSIONS.height,
            frameRate: 30,
          };

          try {
            await track.applyConstraints(constraints);
            console.log('Applied constraints:', track.getConstraints());
            console.log('Updated video settings:', track.getSettings());
          } catch (error) {
            console.error('Error applying constraints:', error);
          }
        };
        // Get the video track settings
        const { width, height } = videoTrack.getSettings();
        console.log('Video settings2:', { width, height });
        videoStream.getTracks().forEach(track => {
          if (track.kind === 'video') {
            applyConstraints(track);
            const settings = track.getSettings();
            const { width, height } = settings;
            console.log('Video track dimensions0:', settings);
            console.log('Video track dimensions0:', { width, height });
          }
          pcRef.current.addTrack(track, videoStream);
        });
      }
      setIsInferencing(true);
      negotiate();
    } else {
      console.log('================ Stop Inferencing');

      // Clean up WebRTC connection
      if (dataChannelRef.current) {
        dataChannelRef.current.close();
      }

      if (pcRef.current) {
        // pcRef.current.getSenders().forEach((sender) => {
        //   if (sender.track) {
        //     sender.track.stop();
        //   }
        // });
        pcRef.current.close();
        pcRef.current = null;
      }

      // Ensure the original video feed is still displayed
      if (videoStream && videoRef.current) {
        videoRef.current.srcObject = videoStream;
      }

      setIsInferencing(false);
    }

    // Cleanup function
    return () => {
      if (pcRef.current) {
        pcRef.current.close();
        pcRef.current = null;
      }
    };
  }, [globalState.isVideoFrozen]);

  const API = process.env.BACKEND_URL.replace(/\/api\/v1$/, '');

  useEffect(() => {
    const getMediaStream = async constraints => {
      // Stop existing tracks before creating new stream
      if (videoStream) {
        videoStream.getTracks().forEach(track => track.stop());
      }

      try {
        let stream;
        setIsVideoLoading(true);

        if (globalState.optionValueDevice === 'Share') {
          const constraints = {
            video: {
              width: { ideal: VIDEO_DIMENSIONS.width },
              height: { ideal: VIDEO_DIMENSIONS.height },
              aspectRatio: VIDEO_DIMENSIONS.aspectRatio,
            },
          };
          stream = await navigator.mediaDevices.getDisplayMedia(constraints);
          // Set video to frozen state immediately when sharing screen
          setGlobalState(prevState => ({
            ...prevState,
            isVideoFrozen: true,
          }));
        } else if (globalState.optionValueDevice === 'USB') {
          stream = await navigator.mediaDevices.getUserMedia(constraints);
        }
        const resizeStream = (stream, width, height) => {
          const videoElement = document.createElement('video');
          videoElement.srcObject = stream;
          videoElement.play();

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');

          const outputStream = canvas.captureStream();
          videoElement.addEventListener('play', () => {
            const draw = () => {
              if (!videoElement.paused && !videoElement.ended) {
                ctx.drawImage(videoElement, 0, 0, width, height);
                requestAnimationFrame(draw);
              }
            };
            draw();
          });

          return outputStream;
        };
        if (videoRef.current && stream) {
          const resizedStream = resizeStream(
            stream,
            DISPLAY_DIMENSIONS.width,
            DISPLAY_DIMENSIONS.height
          );
          videoRef.current.srcObject = resizedStream;
          setVideoStream(resizedStream);
          setIsVideoLoading(false);

          // Auto-play the video
          const playPromise = videoRef.current.play();
          if (playPromise !== undefined) {
            playPromise.catch(error => {
              console.error('Error playing video:', error);
            });
          }

          const videoTrack = stream.getVideoTracks()[0];
          const { width, height } = videoTrack.getSettings();
          console.log('Video settings:', { width, height });
        }
      } catch (error) {
        setIsVideoLoading(false);
        console.error('Error accessing the video stream: ', error);
      }
    };

    if (navigator.mediaDevices && globalState.deviceId !== '') {
      const constraints = {
        video: {
          width: { ideal: VIDEO_DIMENSIONS.width },
          height: { ideal: VIDEO_DIMENSIONS.height },
          aspectRatio: VIDEO_DIMENSIONS.aspectRatio,
        },
      };

      getMediaStream(constraints);
    }

    // Cleanup function
    return () => {
      if (videoStream) {
        videoStream.getTracks().forEach(track => track.stop());
      }
    };
  }, [globalState.deviceId, globalState.optionValueDevice]);

  const mediaTypeSwitcher = () => {
    mediaTypeMap.forEach((mediaType, option) => {
      if (globalState.optionValueDevice === option) {
        setDeviceMediaType(mediaType);
      }
    });
  };

  const handleVideoClick = () => {
    setIsChecked(!isChecked);
    setGlobalState(prevState => ({
      ...prevState,
      isVideoFrozen: !prevState.isVideoFrozen,
    }));
  };

  useEffect(() => {
    if (!globalState.isVideoFrozen) {
      console.log('================ Start Inferencing');
    } else {
      console.log('================ Stop Inferencing');
    }
  }, [globalState.isVideoFrozen]);
  useEffect(() => {
    if (isInferencing) {
      setIsVideoLoading(true); // Start loading
      const timeout = setTimeout(() => setIsVideoLoading(false), 1200); // Simulate loading time
      return () => clearTimeout(timeout); // Cleanup timeout
    } else {
      setIsVideoLoading(false);
    }
  }, [isInferencing]);
  const handleChange = e => {
    handleVideoClick();

    // toggleVideoFreeze();
  };
  const listOfSelectedFilter = [
    {
      key: 'Cephalic',
      list: [
        {
          title: 'Thalami visible',
          bool: globalState?.planes?.cephalic?.qualityCriteria?.QUAL_FLAG__cephal_thalamus
            ? true
            : false,
        },
        {
          title: 'Cavum septum pellucidum visible',
          bool: globalState?.planes?.cephalic?.qualityCriteria?.QUAL_FLAG_cavum_septi_pellucidi
            ? true
            : false,
        },
        {
          title: 'Cerebellum not visible',
          bool: globalState?.planes?.cephalic?.qualityCriteria?.QUAL_FLAG_Cerebellum_not_visble
            ? true
            : false,
        },
        {
          title: 'Zoom > 50%',
          bool: globalState?.planes?.cephalic?.qualityCriteria?.QUAL_VAL__ZOOM ? true : false,
        },
      ],
    },
    {
      key: 'Femur',
      list: [
        {
          title: 'Both femur ends visible',
          bool: globalState?.planes?.femur?.qualityCriteria?.QUAL_VAL__BOTH_EXTREMITIES_VISIBLE
            ? true
            : false,
        },
        {
          title: 'Angle to the horizontal < 45°',
          bool: globalState?.planes?.femur?.qualityCriteria?.QUAL_VAL__FEMURAL_ANGLE ? true : false,
        },
        {
          title: 'Zoom > 50%',
          bool: globalState?.planes?.femur?.qualityCriteria?.QUAL_VAL__ZOOM ? true : false,
        },
      ],
    },
    {
      key: 'Abdominal',
      list: [
        {
          title: 'Stomach bubble visible',
          bool: globalState?.planes?.abdominal?.qualityCriteria?.QUAL_FLAG__abdo_stomach_bubble
            ? true
            : false,
        },
        {
          title: 'Portal sinus visible',
          bool: globalState?.planes?.abdominal?.qualityCriteria?.QUAL_FLAG__abdo_portal_sinus
            ? true
            : false,
        },
        {
          title: 'Kidneys not visible',
          bool: globalState?.planes?.abdominal?.qualityCriteria
            ?.QUAL_FLAG__abdo_fetal_kidneys_not_visible
            ? true
            : false,
        },
        {
          title: 'Zoom > 50%',
          bool: globalState?.planes?.abdominal?.qualityCriteria?.QUAL_VAL__ZOOM ? true : false,
        },
      ],
    },
    {
      key: 'Plan A',
      list: [
        { title: 'Criteria 1 for Plan A', bool: false },
        { title: 'Criteria 2 for Plan A', bool: false },
        { title: 'Criteria 3 for Plan A', bool: false },
        { title: 'Zoom > 50% for Plan A', bool: false },
      ],
    },
    {
      key: 'Plan B',
      list: [
        { title: 'Criteria 1 for Plan B', bool: false },
        { title: 'Criteria 2 for Plan B', bool: false },
        { title: 'Criteria 3 for Plan B', bool: false },
        { title: 'Zoom > 50% for Plan B', bool: false },
      ],
    },
    {
      key: 'Plan C',
      list: [
        { title: 'Criteria 1 for Plan C', bool: false },
        { title: 'Criteria 2 for Plan C', bool: false },
        { title: 'Criteria 3 for Plan C', bool: false },
        { title: 'Zoom > 50% for Plan C', bool: false },
      ],
    },
    {
      key: 'Plan D',
      list: [
        { title: 'Criteria 1 for Plan D', bool: false },
        { title: 'Criteria 2 for Plan D', bool: false },
        { title: 'Criteria 3 for Plan D', bool: false },
        { title: 'Zoom > 50% for Plan D', bool: false },
      ],
    },
    {
      key: 'Plan E',
      list: [
        { title: 'Criteria 1 for Plan E', bool: false },
        { title: 'Criteria 2 for Plan E', bool: false },
        { title: 'Criteria 3 for Plan E', bool: false },
        { title: 'Zoom > 50% for Plan E', bool: false },
      ],
    },
    {
      key: 'Plan F',
      list: [
        { title: 'Criteria 1 for Plan F', bool: false },
        { title: 'Criteria 2 for Plan F', bool: false },
        { title: 'Criteria 3 for Plan F', bool: false },
        { title: 'Zoom > 50% for Plan F', bool: false },
      ],
    },
    {
      key: 'Plan G',
      list: [
        { title: 'Criteria 1 for Plan G', bool: false },
        { title: 'Criteria 2 for Plan G', bool: false },
        { title: 'Criteria 3 for Plan G', bool: false },
        { title: 'Zoom > 50% for Plan G', bool: false },
      ],
    },
    {
      key: 'Plan H',
      list: [
        { title: 'Criteria 1 for Plan H', bool: false },
        { title: 'Criteria 2 for Plan H', bool: false },
        { title: 'Criteria 3 for Plan H', bool: false },
        { title: 'Zoom > 50% for Plan H', bool: false },
      ],
    },
    {
      key: 'Plan I',
      list: [
        { title: 'Criteria 1 for Plan I', bool: false },
        { title: 'Criteria 2 for Plan I', bool: false },
        { title: 'Criteria 3 for Plan I', bool: false },
        { title: 'Zoom > 50% for Plan I', bool: false },
      ],
    },
    {
      key: 'Plan J',
      list: [
        { title: 'Criteria 1 for Plan J', bool: false },
        { title: 'Criteria 2 for Plan J', bool: false },
        { title: 'Criteria 3 for Plan J', bool: false },
        { title: 'Zoom > 50% for Plan J', bool: false },
      ],
    },
    {
      key: 'Plan K',
      list: [
        { title: 'Criteria 1 for Plan K', bool: false },
        { title: 'Criteria 2 for Plan K', bool: false },
        { title: 'Criteria 3 for Plan K', bool: false },
        { title: 'Zoom > 50% for Plan K', bool: false },
      ],
    },
    {
      key: 'Plan L',
      list: [
        { title: 'Criteria 1 for Plan L', bool: false },
        { title: 'Criteria 2 for Plan L', bool: false },
        { title: 'Criteria 3 for Plan L', bool: false },
        { title: 'Zoom > 50% for Plan L', bool: false },
      ],
    },
    {
      key: 'Plan M',
      list: [
        { title: 'Criteria 1 for Plan M', bool: false },
        { title: 'Criteria 2 for Plan M', bool: false },
        { title: 'Criteria 3 for Plan M', bool: false },
        { title: 'Zoom > 50% for Plan M', bool: false },
      ],
    },
    {
      key: 'Plan N',
      list: [
        { title: 'Criteria 1 for Plan N', bool: false },
        { title: 'Criteria 2 for Plan N', bool: false },
        { title: 'Criteria 3 for Plan N', bool: false },
        { title: 'Zoom > 50% for Plan N', bool: false },
      ],
    },
    {
      key: 'Plan O',
      list: [
        { title: 'Criteria 1 for Plan O', bool: false },
        { title: 'Criteria 2 for Plan O', bool: false },
        { title: 'Criteria 3 for Plan O', bool: false },
        { title: 'Zoom > 50% for Plan O', bool: false },
      ],
    },
    {
      key: 'Plan P',
      list: [
        { title: 'Criteria 1 for Plan P', bool: false },
        { title: 'Criteria 2 for Plan P', bool: false },
        { title: 'Criteria 3 for Plan P', bool: false },
        { title: 'Zoom > 50% for Plan P', bool: false },
      ],
    },
    {
      key: 'Plan Q',
      list: [
        { title: 'Criteria 1 for Plan Q', bool: false },
        { title: 'Criteria 2 for Plan Q', bool: false },
        { title: 'Criteria 3 for Plan Q', bool: false },
        { title: 'Zoom > 50% for Plan Q', bool: false },
      ],
    },
    {
      key: 'Plan R',
      list: [
        { title: 'Criteria 1 for Plan R', bool: false },
        { title: 'Criteria 2 for Plan R', bool: false },
        { title: 'Criteria 3 for Plan R', bool: false },
        { title: 'Zoom > 50% for Plan R', bool: false },
      ],
    },
  ];

  const selectedListFilter = listOfSelectedFilter.find(filter => filter.key === 'Abdominal');
  useEffect(() => {
    console.log('globalStateeeee', globalState);
  }, [globalState]);
  const isDataAvailable = globalState?.planes && globalState?.planes[globalState?.lastDetectedPlan];

  const predictionHistory =
    globalState?.planes[globalState?.lastDetectedPlan]?.predictionHistory.slice(0, -1).slice(-4) ||
    [];

  // Calculate placeholders for skeleton
  const skeletonCount = Math.max(4 - predictionHistory.length, 0);
  const [divHeight, setDivHeight] = useState('60%');

  const calculateHeight = () => {
    // Example: Subtracting some pixels or using available height
    const availableHeight = window.innerHeight;
    const calculatedHeight = availableHeight; // 60% of the viewport height
    setDivHeight(`${calculatedHeight}px`);
  };

  useEffect(() => {
    calculateHeight(); // Initial calculation
    window.addEventListener('resize', calculateHeight); // Update on resize

    return () => {
      window.removeEventListener('resize', calculateHeight); // Cleanup
    };
  }, []);

  const countPlanesWithFrame = planes => {
    let count = 0;

    for (const planeKey in planes) {
      if (planes[planeKey]?.frame) {
        count++;
      }
    }

    return count;
  };

  const planesWithFrameCount = countPlanesWithFrame(globalState.planes);
  const isButtonDisabled = !Object.keys(globalState?.planes).some(
    tag => globalState?.planes[tag]?.selectedViewData
  );
  return (
    <div className={`relative mx-auto flex h-full flex-col bg-[#fcfcfc]`}>
      {/* {globalState.isVideoFrozen &&
      !isVideoLoading &&
      !localStorage.getItem(`${globalState.machineType}`) && (
        <PixelSpacingModal
          globalState={globalState}
          setGlobalState={setGlobalState}
          videoRef={videoRef}
          canvasDimensions={canvasDimensions}
          canvasRef={canvasRef}
        />
      )} */}
      <div className="relative flex h-full w-full justify-between">
        <GenerateReportModal
          globalState={globalState}
          setGlobalState={setGlobalState}
          plans={plans}
        />
        <div
          className={`${globalState.deviceId === '' || globalState?.selectedItem === 'Select your machine' ? '' : 'relative'} flex h-full w-full flex-1 items-center justify-center bg-[#fcfcfc]`}
        >
          <div
            className={` flex h-full ${globalState.openPanel ? 'w-[100%]' : 'w-[94%]'} items-center justify-center bg-[#fcfcfc] text-black`}
          >
            <div className="relative flex h-full w-full items-center justify-center">
              <VideoModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                videoStream={videoStream}
                viseaRtcStream={videoMaskStream}
                displayDimensions={DISPLAY_DIMENSIONS}
                handleVideoClick={handleVideoClick}
                isInferencing={isInferencing}
                globalState={globalState}
                handleChange={handleChange}
                isChecked={isChecked}
                imgPlans={imgPlans}
              />
              <StepperComponent
                mediaTypeSwitcher={mediaTypeSwitcher}
                globalState={globalState}
                setGlobalState={setGlobalState}
                api={API}
                token={user?.access_token}
                videoRef={videoRef}
                bool={bool}
                setBool={setBool}
              />
              <SelectView
                selectedPlan={selectedPlansModal}
                setSelectedPlansModal={setSelectedPlansModal}
                tag={selectedPlansTag}
                globalState={globalState}
                setGlobalState={setGlobalState}
              />
              <>
                <div className="flex h-full w-full flex-col space-y-2 p-2">
                  <div className="mt-2 flex w-full justify-between">
                    {/* <div className="inline-flex w-fit cursor-pointer items-center justify-center space-x-3 rounded-lg border  border-[#2886c7] px-6 py-1 text-sm font-bold text-[#2886c7] hover:opacity-70">
                              <span>Report</span>
                              <div className="">
                                <TbReportAnalytics size={20} />
                              </div>
                            </div> */}
                    {/* <div className="w-full text-xl font-bold">Real-time Report</div> */}
                    <div className="flex w-full items-center justify-start text-2xl font-semibold text-[#1F384C]">
                      Realtime Examination
                    </div>

                    <div className="flex w-full items-center justify-center space-x-2">
                      <div className="opacity-45 inline-flex w-fit cursor-not-allowed items-center justify-center space-x-5 rounded-md border  border-[#2886c7] px-6 py-1 font-semibold text-[#2886c7]">
                        <span>Share</span>
                        <FaShare size={18} />
                      </div>
                      <div
                        className={`${isButtonDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer hover:opacity-70'} inline-flex w-fit items-center justify-center 
                      space-x-6 rounded-md border  border-[#2886c7] px-6 py-1 font-semibold text-[#2886c7] 
                      `}
                        onClick={() => {
                          if (isButtonDisabled == false) {
                            setGlobalState(prevState => ({
                              ...prevState,
                              openGR: !globalState.openGR,
                            }));
                          }
                        }}
                      >
                        <span>Print</span>
                        <FaPrint size={18} />
                      </div>
                    </div>
                    <div className="flex w-full items-center justify-end">
                      {globalState?.isExamEnded == false ? (
                        <button
                          className="rounded-md border border-[#FF4747] px-6 py-1 font-semibold text-[#FF4747] hover:opacity-50"
                          onClick={() => {
                            setGlobalState(prevState => ({
                              ...prevState,
                              isExamEnded: true,
                              isVideoFrozen: true,
                            }));
                          }}
                        >
                          End Session
                        </button>
                      ) : (
                        <>
                          {globalState?.isReportFinish == false ? (
                            <button
                              className="rounded-md border border-[#2886c7] px-6 py-1 font-semibold text-[#2886c7] hover:opacity-50"
                              onClick={() => {
                                setGlobalState(prevState => ({
                                  ...prevState,
                                  isReportFinish: true,
                                }));
                              }}
                            >
                              Finish Report
                            </button>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {globalState?.isExamEnded == false && (
                    <div className="flex w-full space-x-4">
                      <Card className="w-full">
                        <div className="flex h-full w-full space-x-5">
                          <div className="w-[57%]">
                            <div className="flex w-[100%] items-center justify-between">
                              <div className="flex w-full items-center space-x-4 text-xl font-bold">
                                <div>Real-time</div>
                                {!globalState.isVideoFrozen && isVideoLoading == false && (
                                  <MdFiberManualRecord
                                    size={18}
                                    className="mt-1 text-red-700"
                                  />
                                )}
                              </div>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  checked={isChecked}
                                  onChange={handleChange}
                                />
                                <div className="slider"></div>
                              </label>
                            </div>
                            <div className="relative flex h-[90%] w-[100%] items-center justify-center">
                              <div
                                className={`${isVideoLoading ? 'visible absolute top-0 z-10 flex h-[calc(100%-2%)] w-[calc(100%-5%)] items-center justify-center bg-black/50' : 'hidden'}`}
                              >
                                <div className="inline-flex items-center space-x-2 text-white">
                                  <LuLoader2
                                    className="animate-spin"
                                    size={18}
                                  />
                                  {/* <span>Please wait</span> */}
                                </div>
                              </div>
                              <div
                                className={`relative h-fit w-fit ${isVideoLoading ? 'hidden' : 'visible'}`}
                              >
                                <div
                                  className="absolute top-3 right-2 z-20 cursor-pointer text-white"
                                  onClick={() => setModalOpen(true)}
                                >
                                  <ImEnlarge2 size={18} />
                                </div>
                                <ButtonWithCursorMovement
                                  begin={globalState.isVideoFrozen}
                                  setBegin={handleVideoClick}
                                  deviceId={globalState.deviceId}
                                />

                                <video
                                  ref={videoRef}
                                  autoPlay
                                  playsInline
                                  width={VIDEO_DIMENSIONS.width}
                                  height={VIDEO_DIMENSIONS.height}
                                  onClick={handleVideoClick}
                                  style={{
                                    filter: globalState.isVideoFrozen ? 'blur(10px)' : 'none',
                                    cursor: 'pointer',
                                    display: !isInferencing ? 'block' : 'none',
                                  }}
                                />

                                {/* WebRTC video element - shows when inferencing */}
                                <video
                                  ref={webrtcVideoRef}
                                  autoPlay
                                  playsInline
                                  width={VIDEO_DIMENSIONS.width}
                                  height={VIDEO_DIMENSIONS.height}
                                  onClick={handleVideoClick}
                                  style={{
                                    cursor: 'pointer',
                                    display: isInferencing ? 'block' : 'none',
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-9 flex w-[43%] space-x-2">
                            <div className="flex items-center justify-start">
                              <RatingBar
                                align={'V'}
                                globalState={globalState}
                              />
                            </div>
                            <div className="flex w-full flex-col items-center justify-center space-y-2 text-sm font-medium text-[#686c82]">
                              <div className="inline-flex w-full space-x-2">
                                <PiInfo
                                  size={22}
                                  className="text-[#2886c7]"
                                />
                                <span>Ensure proper gel application for clear imaging</span>
                              </div>
                              <div className="inline-flex w-full space-x-2">
                                <PiInfo
                                  size={26}
                                  className="text-[#2886c7]"
                                />
                                <span>Ensure steady probe movement to capture accurate scans.</span>
                              </div>
                              <div className="inline-flex w-full space-x-2">
                                <PiInfo
                                  size={22}
                                  className="text-[#2886c7]"
                                />
                                <span>Adjust depth and focus for optimal image clarity.</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                      <Card className="w-full">
                        <div className="flex h-full flex-col">
                          <div className="flex w-full items-center space-x-5">
                            <div className="flex w-full items-center space-x-4 text-xl font-bold">
                              Last Detected Plan
                            </div>
                          </div>
                          <div className="flex h-full w-full items-center space-x-4">
                            {/* Conditionally render skeleton loader or actual content */}
                            {isDataAvailable ? (
                              <>
                                {/* Actual content */}
                                <div className="relative h-full w-full">
                                  <img
                                    src={`${globalState?.planes[globalState?.lastDetectedPlan]?.imgReport}`}
                                    alt="Main Report"
                                    className="h-full w-full rounded-2xl border-2 border-[#a6abc8]"
                                  />
                                  <span className="absolute top-2 left-2 font-semibold text-white">
                                    {globalState?.lastDetectedPlan}
                                  </span>
                                  <span className="absolute top-2 right-2 font-semibold text-white">
                                    {globalState?.planes[globalState?.lastDetectedPlan]?.score
                                      ? Number(
                                          globalState?.planes[globalState?.lastDetectedPlan]?.score
                                        ).toFixed(2) + ' %'
                                      : ''}
                                  </span>
                                </div>

                                <div className="grid h-full w-full grid-cols-2 gap-2 text-xs">
                                  {/* Render actual data */}
                                  {predictionHistory.map((item, index) => (
                                    <div
                                      className="relative aspect-video h-full w-full"
                                      key={index}
                                    >
                                      <img
                                        src={`${item?.imagePredictedPlane}`}
                                        alt={`Prediction ${index}`}
                                        className="h-full w-full rounded-2xl border-2 border-[#a6abc8]"
                                      />
                                      <span className="absolute top-2 left-2 w-1/2 font-semibold text-white">
                                        {item?.lastDetectedPlan}
                                      </span>
                                      <span className="absolute top-2 right-2 font-semibold text-white">
                                        {item?.score ? Number(item?.score).toFixed(2) + ' %' : ''}
                                      </span>
                                    </div>
                                  ))}

                                  {/* Render skeletons for missing items */}
                                  {[...Array(skeletonCount)].map((_, index) => (
                                    <div
                                      className="relative aspect-video h-full w-full animate-pulse"
                                      key={`skeleton-${index}`}
                                    >
                                      <div className="h-full w-full rounded-2xl border-2 border-gray-400 bg-gray-300"></div>
                                      <span className="absolute top-2 left-2 h-4 w-1/2 rounded bg-gray-400"></span>
                                      <span className="absolute top-2 right-2 h-4 w-10 rounded bg-gray-400"></span>
                                    </div>
                                  ))}
                                </div>
                              </>
                            ) : (
                              <>
                                {/* Skeleton loader for the entire layout */}
                                <div className="relative h-full w-full animate-pulse">
                                  <div className="h-full w-full rounded-2xl  bg-[#EEF5FF]"></div>
                                  <span className="absolute top-2 left-2 h-4 w-16 rounded bg-gray-400"></span>
                                  <span className="absolute top-2 right-2 h-4 w-10 rounded bg-gray-400"></span>
                                </div>

                                <div className="grid h-full w-full grid-cols-2 gap-2 text-xs">
                                  {[...Array(4)].map((_, index) => (
                                    <div
                                      className="relative aspect-video h-full w-full animate-pulse"
                                      key={index}
                                    >
                                      <div className="h-full w-full rounded-2xl bg-[#EEF5FF]"></div>
                                      <span className="absolute top-2 left-2 h-4 w-1/2 rounded bg-gray-400"></span>
                                      <span className="absolute top-2 right-2 h-4 w-10 rounded bg-gray-400"></span>
                                    </div>
                                  ))}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}

                  <div
                    className={`flex ${globalState?.isExamEnded ? 'h-[90%] xl:h-[92%]' : 'h-[56%]'} w-full`}
                  >
                    <Card className="w-full">
                      <div className="flex h-full w-full flex-col space-y-2">
                        <div className="flex w-full items-center justify-between space-x-4 text-xl font-bold">
                          <MultiSelectDropdown
                            items={plans}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            globalState={globalState}
                            setGlobalState={setGlobalState}
                          />
                          <ProgressBar
                            approvedPlans={planesWithFrameCount}
                            totalPlans={22}
                          />
                        </div>
                        <div className="custom-scrollbar overflow-y-auto pr-1">
                          <div
                            className={`grid grid-cols-4 place-items-center content-center gap-2 xl:grid-cols-6`}
                          >
                            {plans
                              .slice() // Create a copy of the array to avoid mutating the original `plans`
                              .sort((a, b) => {
                                const aHasHistory =
                                  !!globalState?.planes?.[a?.tag]?.predictionHistory;
                                const bHasHistory =
                                  !!globalState?.planes?.[b?.tag]?.predictionHistory;
                                // Items with `predictionHistory` come first
                                return bHasHistory - aHasHistory;
                              })
                              .map((plan, index) => (
                                <div
                                  className={`${
                                    globalState?.planes?.[plan?.tag]?.predictionHistory &&
                                    !globalState?.isReportFinish
                                      ? 'cursor-pointer'
                                      : 'cursor-not-allowed'
                                  } relative flex h-full items-center justify-center place-self-center rounded-2xl border-2 border-[#a6abc8]`}
                                  key={index}
                                  onClick={() => {
                                    if (
                                      globalState?.planes?.[plan?.tag]?.predictionHistory &&
                                      !globalState?.isReportFinish
                                    ) {
                                      setSelectedPlansModal(plan?.label);
                                      setSelectedPlansTag(plan?.tag);
                                    }
                                  }}
                                >
                                  <img
                                    src={plan?.imgPlan}
                                    className={`${
                                      plan.imgPlan === globalState?.planes?.[plan?.tag]?.imgReport
                                        ? 'h-full w-full rounded-2xl'
                                        : 'mt-4 h-1/2 w-1/2 py-4'
                                    }`}
                                    alt={plan?.label}
                                  />
                                  <span
                                    className={`absolute top-2 left-2 w-2/3 font-semibold ${
                                      plan.imgPlan === globalState?.planes?.[plan?.tag]?.imgReport
                                        ? 'text-white'
                                        : 'text-gray-700'
                                    }`}
                                  >
                                    {plan?.label}
                                  </span>
                                  {globalState?.planes?.[plan?.tag]?.predictionHistory && (
                                    <>
                                      <TfiLayers
                                        className={`absolute top-2 right-2 ${
                                          plan.imgPlan ===
                                          globalState?.planes?.[plan?.tag]?.imgReport
                                            ? 'text-white'
                                            : 'text-gray-700'
                                        }`}
                                        size={24}
                                      />
                                      <span
                                        className={`absolute top-2.5 right-5 text-xs font-bold ${
                                          plan.imgPlan ===
                                          globalState?.planes?.[plan?.tag]?.imgReport
                                            ? 'text-white'
                                            : 'text-gray-700'
                                        }`}
                                      >
                                        {
                                          globalState?.planes?.[plan?.tag]?.predictionHistory
                                            ?.length
                                        }
                                      </span>
                                    </>
                                  )}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>

                {globalState?.isStart && globalState?.encounterError && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="rounded-lg bg-white p-6 shadow-lg">
                      <h2 className="mb-4 text-2xl font-bold text-red-600">Connection Error</h2>
                      <p className="text-gray-700">
                        An error occurred while connecting to the socket server. Please try again.
                      </p>
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealTimeInferencingUDP;
