import React, { useEffect, useState } from 'react';
import ReportTable from '../../Components/ReportTable/report.table';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../interfaces';
import { getReports } from '../../../store/actions';
import Report from '../../../../../../ui/src/components/MeasurementTable/Components/Report/report.page';
import { Modal } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import LoaderPage from '../../Components/Loader/loader.page';

/*
interface Report {
  id: number;
  study_id: string;
  date: string;
  report_content: JSON;
  created_at: string;
  updated_at: string | null;
}
*/

const items = [
  {
    report_id: 'RPT001',
    study_instance_id: '1.3.6.1.4.1.5962.1.1.0.0.0.1194731235.1361.0.1',
    creation_date: '2024-04-11',
    patient_name: 'John Doe',
    doctor_name: 'Dr. Jane Doe',
  },
  {
    report_id: 'RPT002',
    study_instance_id: '1.3.6.1.4.1.5962.1.1.0.0.0.1194731235.1362.0.1',
    creation_date: '2024-04-11',
    patient_name: 'John Doe',
    doctor_name: 'Dr. Jane Doe',
  },
  {
    report_id: 'RPT003',
    study_instance_id: '1.3.6.1.4.1.5962.1.1.0.0.0.1194731235.1363.0.1',
    creation_date: '2024-04-11',
    patient_name: 'John Doe',
    doctor_name: 'Dr. Jane Doe',
  },
  {
    report_id: 'RPT004',
    study_instance_id: '1.3.6.1.4.1.5962.1.1.0.0.0.1194731235.1364.0.1',
    creation_date: '2024-04-11',
    patient_name: 'John Doe',
    doctor_name: 'Dr. Jane Doe',
  },
];

const Reports: React.FC = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [archived, setArchived] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [StudyUID, setStudyUID] = useState<string>('');
  const { report }: { report: any } = useSelector((state: State) => ({
    report: state.Report,
  }));
  const [search, setSearch] = useState('');
  const searchReport = (search: string) => {
    const dateFormatRegex = /^(\d{4}-\d{2}-\d{2}|\d{3}\/\d{2}-\d{2})$/;
    if (dateFormatRegex.test(search)) {
      dispatch(
        getReports({
          filter_options: {},
        })
      );
      // setPage(1);
    } else {
      // ask about the default values for the page and items_per_page
      dispatch(
        getReports({
          filter_options: {
            patient_name__contains: search,
            page: 1,
            // items_per_page: itemsPerPage
          },
        })
      );
      // setPage(1);
    }
  };
  const debouncedSearchBackend = useDebouncedCallback(searchReport, 500, {
    maxWait: 2000,
    leading: true,
    trailing: false,
  });
  useEffect(() => {
    if (search.length > 0) {
      debouncedSearchBackend(search);
    } else {
      dispatch(
        getReports({
          filter_options: {
            page: 1,
          },
        })
      );
    }
    return () => {
      debouncedSearchBackend.cancel();
    };
  }, [search, debouncedSearchBackend]);
  console.log('report ======', report);
  useEffect(() => {
    dispatch(getReports());
  }, []);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const openReport = (StudyUID: string, completed: boolean) => {
    if (completed === false) {
      return;
    }
    setStudyUID(StudyUID);
    setIsModalOpen(true);
  };
  return (
    <div className="mx-auto h-full w-full overflow-auto p-8">
      <h1 className="h-[40px] text-[18px] font-medium text-[#1F384C]">Report Records</h1>
      <div className=" h-[calc(100%-40px)]">
        <div className="flex h-[60px] w-full items-center justify-between bg-white py-4 px-2">
          <div className="flex w-1/2">
            <button className="flex h-8 items-center justify-center rounded border border-[#91B9ED] bg-[#ffffff]  px-4 py-2 text-[24px] font-bold text-white">
              <div className="flex items-center justify-center space-x-2">
                <img
                  src="/icons/filter.svg"
                  className="h-6"
                />
                <span className="text-[16px] font-medium text-[#25213B]">Filter</span>
              </div>
            </button>
            <div className="ml-4 mr-4 flex h-8 items-center justify-center rounded bg-[#EEF5FF]">
              <img
                src="/icons/search.svg"
                className="ml-2 mr-8 h-4 w-4"
              />
              <input
                type="text"
                className="w-[420px] border border-transparent bg-transparent text-[#2B5170] placeholder-[#2B5170]"
                placeholder="Search report Records by Patient Name"
                onChange={e => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="h-[calc(100%-60px)] w-full">
          <div className="h-full overflow-auto">
            <ReportTable
              report={report}
              openReport={openReport}
              page={page}
              itemsPerPage={itemsPerPage}
              setPage={setPage}
              setItemsPerPage={setItemsPerPage}
            />
            {report?.reports.length === 0 && !report?.loading && (
              <div className="inset-20 flex h-44 w-full flex-col items-center justify-center rounded-lg border text-xl text-gray-700 shadow-[rgba(0,0,0,0.5)_0px_5px_14px_1px]">
                <p>No report found</p>
              </div>
            )}
          </div>
          {report?.loading === true && report?.reports.length === 0 && <LoaderPage />}
          <Modal
            title="Report"
            open={isModalOpen}
            onCancel={handleCancel}
            width={1600}
            // style={{ top: 4 }}
            footer={null}
            centered={true}
          >
            <Report
              StudyUID={StudyUID}
              ohifViewer={false}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Reports;
