import React, { useEffect, useRef, useState } from 'react';
import dicomParser from 'dicom-parser';

import { ConfigProvider, DatePicker, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addStudy, getPatients, resetState } from '../../../store/actions';
import newSource from '../../../../assets/newSource.png';
import locale from 'antd/es/date-picker/locale/en_US';
import _, { update } from 'lodash';
import dayjs from 'dayjs';
// import { APIClient } from './apiHelper';
import { APIClient } from '../../../helpers/apiHelper';
import { useAuth } from 'react-oidc-context';
// import * as url from './urlHelper';
import * as url from '../../../helpers/urlHelper';
import { FaEye, FaFolder } from 'react-icons/fa';
import { PiFilesFill } from 'react-icons/pi';
import { FaXmark } from 'react-icons/fa6';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import { MdInfoOutline } from 'react-icons/md';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router';
import { Select, Space } from 'antd';

// import FileLoaderService from 'platform/viewer/src/routes/Local/fileLoaderService';
import FileLoaderService from '../../../../../../../platform/viewer/src/routes/Local/fileLoaderService';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { LuUpload } from 'react-icons/lu';
import { toast } from 'react-toastify';
export const api = new APIClient();

interface Props {
  title?: string;
  description?: string;
  page?: string;
  fullPatientName?: string;
  fullPatientId?: string;
}

const NewExam: React.FC<Props> = ({
  title,
  description,
  fullPatientName,
  fullPatientId,
  page,
}: Props) => {
  const [uploadType, setUploadType] = useState<string>('import files');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedStudies, setSelectedStudies] = useState([]);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRefMore = useRef<HTMLInputElement>(null);
  const [globalPatientId, setGlobalPatientId] = useState('');
  const [useGlobalPatient, setUseGlobalPatient] = useState(false);
  const [newGlobalPatient, setNewGlobalPatient] = useState('tawachi');
  const [createDate, setCreateDate] = useState<string>(null);
  const [modalHeight, setModalHeight] = useState(0);
  const [componentDisabled, setComponentDisabled] = useState<boolean>(true);
  const modalTabs = [{ title: 'Import Files' }, { title: 'Import a Folder' }];
  const [activeModalTab, setActiveModalTab] = useState('Import Files');
  const auth = useAuth();
  const [isUpdate, setIsUpdate] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { study: studySagaState } = useSelector(state => ({
    study: state.Study,
  }));

  const { patientState } = useSelector(state => ({
    patientState: state.Patient,
  }));

  const ErrorNotify = str => toast.error(str);
  useEffect(() => {
    dispatch(
      getPatients({
        // this is not good
        filter_options: { page: 1, items_per_page: 1000 },
      })
    );
    return () => {
      dispatch(resetState());
    };
  }, [dispatch, isUpdate]);
  useEffect(() => {
    const calculateModalHeight = () => {
      const screenHeight = window.innerHeight;
      setModalHeight(screenHeight * 0.8); // Set modal height to 80% of the screen height
    };

    calculateModalHeight(); // Initial calculation
    window.addEventListener('resize', calculateModalHeight); // Recalculate on window resize

    return () => {
      window.removeEventListener('resize', calculateModalHeight); // Clean up event listener
    };
  }, []);

  const handleDragOver = e => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    setIsDragOver(false);
  };

  // const handleDrop = async e => {
  //   e.preventDefault();
  //   setIsDragOver(false);

  //   const items = e.dataTransfer.items;
  //   const fileList = [];

  //   for (let i = 0; i < items.length; i++) {
  //     const item = items[i].webkitGetAsEntry();
  //     if (item) {
  //       if (item.isFile) {
  //         // Handle individual files
  //         const file = items[i].getAsFile();
  //         fileList.push(file);
  //       } else if (item.isDirectory) {
  //         // Handle directories
  //         console.log('Directory:', item);
  //         if (uploadType == 'Import Files') {
  //           ErrorNotify('lololo')
  //           return;
  //         }
  //         // You can recursively traverse directories if needed
  //         await traverseDirectory(item, fileList);
  //       }
  //     }
  //   }

  //   // Process the files after traversing the directory
  //   await handleFiles(fileList);
  // };

  const handleDrop = async e => {
    e.preventDefault();
    setIsDragOver(false);

    const items = e.dataTransfer.items;
    const fileList = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry();
      if (item) {
        if (item.isFile) {
          if (uploadType !== 'import files') {
            ErrorNotify('Please upload folders only in this section.');
            return;
          }
          const file = items[i].getAsFile();
          fileList.push(file);
        } else if (item.isDirectory) {
          if (uploadType === 'import files') {
            ErrorNotify('Please upload files only in this section.');
            return;
          }
          await traverseDirectory(item, fileList);
        }
      }
    }

    // Process the files after traversing the directory
    await handleFiles(fileList);
  };

  async function traverseDirectory(directoryEntry, fileList) {
    const directoryReader = directoryEntry.createReader();
    return new Promise<void>((resolve, reject) => {
      directoryReader.readEntries(async entries => {
        for (let i = 0; i < entries.length; i++) {
          const entry = entries[i];
          if (entry.isFile) {
            // Handle files inside the directory
            const file = await getFileFromEntry(entry);
            fileList.push(file);
          } else if (entry.isDirectory) {
            // Recursively traverse subdirectories
            await traverseDirectory(entry, fileList);
          }
        }
        resolve();
      }, reject);
    });
  }

  function getFileFromEntry(fileEntry) {
    return new Promise((resolve, reject) => {
      fileEntry.file(resolve, reject);
    });
  }
  // const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.preventDefault();

  //   // Access the dropped items
  //   const items = event.dataTransfer.items;

  //   // Loop through each dropped item
  //   for (let i = 0; i < items.length; i++) {
  //     const item = items[i];

  //     // Check if the dropped item is a file or a folder
  //     if (item.kind === 'file') {
  //       const file = item.getAsFile();
  //       // Do something with the file...
  //       console.log('Dropped file:', file);
  //     } else if (item.kind === 'directory') {
  //       // Handle dropped directory
  //       const directory = item.webkitGetAsEntry();
  //       // Do something with the directory...
  //       console.log('Dropped directory:', directory);
  //       const directoryEntry = directory as FileSystemDirectoryEntry;
  //       const dirReader = directoryEntry.createReader();
  //       console.log('test:', dirReader);
  //     }
  //   }
  // };

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };
  const handleFileSelectMore = () => {
    fileInputRefMore.current.click();
  };

  const handleFileInputChange = e => {
    const files = e.target.files;
    console.log('files :: ' + JSON.stringify(files));
    handleFiles(files);
  };

  const handleGlobalPatientChange = e => {
    setGlobalPatientId(e.target.value);
  };

  const handleFiles = async files => {
    const fileList = [];
    const validModalities = new Set(['US', 'SR']);
    let invalidFileNotified = false;

    const notifyInvalidFileOnce = () => {
      if (!invalidFileNotified) {
        ErrorNotify('Only US or SR modality files are supported. Upload failed.');
        invalidFileNotified = true;
      }
    };

    const extractDicomData = async file => {
      try {
        const fileLoaderService = new FileLoaderService(file);
        const imageId = fileLoaderService.addFile(file);
        const image = await fileLoaderService.loadFile(file, imageId);
        return await fileLoaderService.getDataset(image, imageId);
      } catch (error) {
        notifyInvalidFileOnce();
        return null;
      }
    };

    const processFile = async (file, index, dicomData) => {
      const {
        StudyInstanceUID,
        SeriesInstanceUID,
        PatientName,
        PatientID,
        Modality,
        StudyDescription,
      } = dicomData;
      const modality = Modality;

      if (!validModalities.has(modality)) {
        notifyInvalidFileOnce();
        return;
      }

      try {
        const arrayBuffer = await file.arrayBuffer();
        const byteArray = new Uint8Array(arrayBuffer);
        dicomParser.parseDicom(byteArray);

        fileList.push({
          index,
          data: file,
          fileName: file.name,
          size: file.size,
          modality,
          existingPatient: true,
          originalPatientName: PatientName?.Alphabetic || '',
          originalPatientID: PatientID || '',
          newPatientName: '',
          studyInstanceUID: StudyInstanceUID,
          seriesInstanceUID: SeriesInstanceUID,
          studyDescription: StudyDescription || '',
          uploadStatus: 'Not Uploaded Yet',
        });
      } catch (error) {
        console.error('Error reading DICOM file:', error);
        fileList.push({ index, fileName: file.name, error });
      }
    };

    for (let i = 0; i < files?.length; i++) {
      const file = files[i];
      const dicomData = await extractDicomData(file);
      if (dicomData) {
        await processFile(file, i, dicomData);
      }
    }

    setSelectedFiles(prevFiles => [...prevFiles, ...fileList]);

    const studies = fileList.reduce((acc, file) => {
      const { studyInstanceUID } = file;
      if (!acc[studyInstanceUID]) {
        acc[studyInstanceUID] = {
          studyInstanceUID,
          seriesInstanceUID: file.seriesInstanceUID,
          originalPatientName: file.originalPatientName,
          originalPatientID: file.originalPatientID,
          existingPatient: false,
          modality: file.modality,
          description: file.studyDescription,
          create_date: null,
          error: '',
          files: [],
        };
      }
      acc[studyInstanceUID].files.push(file);
      return acc;
    }, {});

    setSelectedStudies(prevStudies => [...prevStudies, ...Object.values(studies)]);
  };

  const onFormLayoutChange = ({ disabled }: { disabled: boolean }) => {
    setComponentDisabled(disabled);
  };

  const showModal = () => {
    setOpen(true);
  };

  const toggleExistingPatient = studyInstanceUID => {
    setSelectedStudies(prevStudies =>
      prevStudies.map(study =>
        study.studyInstanceUID === studyInstanceUID
          ? { ...study, existingPatient: !study.existingPatient }
          : study
      )
    );
  };

  const updateFileUploadStatus = (studyInstanceUID, fileIndex, status) => {
    setSelectedStudies(prevStudies =>
      prevStudies.map(study => {
        if (study.studyInstanceUID === studyInstanceUID) {
          const updatedFiles = study.files.map((file, index) => {
            if (index === fileIndex) {
              return { ...file, uploadStatus: status };
            }
            return file;
          });
          return { ...study, files: updatedFiles };
        }
        return study;
      })
    );
  };

  const handleOk = async () => {
    for (const study of selectedStudies) {
      const {
        selectedExistingPatientId,
        newPatientName,
        studyInstanceUID,
        files,
        existingPatient,
        modality,
        description,
        create_date,
      } = study;

      await Promise.all(
        files.map(async (file, index) => {
          updateFileUploadStatus(studyInstanceUID, index, 'Uploading...');

          const data = new FormData();
          console.log('file', file.data, file.fileName);
          data.append('file', file.data, file.fileName);
          data.append('patientName', newPatientName || file.originalPatientName);
          data.append(
            'patientID',
            fullPatientId
              ? fullPatientId
              : existingPatient
                ? selectedExistingPatientId
                  ? selectedExistingPatientId
                  : '-1'
                : '-1'
          );
          data.append('doctorName', auth?.user?.profile?.name);
          data.append('modality', modality ? modality : null);
          data.append('studyDescription', description ? description : null);
          data.append('creation_date', create_date);
          // if (createDate) {
          //   data.append('creation_date', createDate);
          // } else {
          //   data.append('creation_date', null);
          // }
          // if (description) {
          //   data.append('studyDescription', description);
          // } else {
          //   data.append('studyDescription', null);
          // }
          try {
            const response = await api.post(
              `${url.ADD_STUDY_2}?patient_id=${
                fullPatientId
                  ? fullPatientId
                  : existingPatient
                    ? selectedExistingPatientId
                      ? selectedExistingPatientId
                      : '-1'
                    : '-1'
              }`,
              data
            );
            updateFileUploadStatus(studyInstanceUID, index, 'success');
          } catch (error) {
            updateFileUploadStatus(studyInstanceUID, index, `faillure:${error}`);
            setConfirmLoading(false);
          }
        })
      );
    }
    setIsUpdate(!isUpdate);
    setModalText('The modal will be closed after 4 seconds');
  };

  const handleCancel = () => {
    setSelectedStudies([]);
    setSelectedFiles([]);
    setOpen(false);
  };
  const allUploaded =
    selectedStudies?.length > 0
      ? selectedStudies.every(item => item.files.every(file => file.uploadStatus === 'success'))
      : false;
  const renderUploadInput = () => {
    if (uploadType === 'import files') {
      return (
        <input
          type="file"
          className="hidden"
          ref={fileInputRef}
          onChange={handleFileInputChange}
          multiple
          // accept=".dcm"
        />
      );
    } else if (uploadType === 'import a folder') {
      return (
        <input
          type="file"
          className="hidden"
          ref={fileInputRef}
          onChange={handleFileInputChange}
          // Use type assertion for webkitdirectory
          // @ts-ignore
          webkitdirectory=""
          // @ts-ignore
          mozdirectory=""
        />
      );
    }
  };
  const renderUploadInputMore = () => {
    if (uploadType === 'import files') {
      return (
        <input
          type="file"
          className="hidden"
          ref={fileInputRefMore}
          onChange={handleFileInputChange}
          multiple
          // accept=".dcm"
        />
      );
    } else if (uploadType === 'import a folder') {
      return (
        <input
          type="file"
          className="hidden"
          ref={fileInputRefMore}
          onChange={handleFileInputChange}
          // Use type assertion for webkitdirectory
          // @ts-ignore
          webkitdirectory=""
          // @ts-ignore
          mozdirectory=""
        />
      );
    }
  };

  const handleUploadTypeChange = e => {
    setUploadType(e.target.value);
  };

  const groupFilesByStudyInstanceUID = files => {
    return files.reduce((acc, file) => {
      const { studyInstanceUID } = file;
      if (!acc[studyInstanceUID]) {
        acc[studyInstanceUID] = [];
      }
      acc[studyInstanceUID].push(file);
      return acc;
    }, {});
  };

  const prepareFilesForDisplay = files => {
    const groupedFiles = groupFilesByStudyInstanceUID(files);
    return Object.entries(groupedFiles).map(([studyInstanceUID, files]) => ({
      studyInstanceUID,
      files,
    }));
  };
  const handleCreationDate = (date: moment.Moment | null, dateString: string) => {
    setCreateDate(dateString);
  };
  const getValue = study => {
    if (fullPatientName) {
      return fullPatientName;
    }
    if (study?.newPatientName !== undefined) {
      return study.newPatientName;
    }
    if (study?.originalPatientName !== undefined) {
      return study.originalPatientName;
    }
    return '';
  };
  return (
    <div className="flex h-full items-center justify-center">
      {page == 'home' ? (
        <div className="flex cursor-pointer flex-col items-center justify-center ">
          <h5 className="text-base font-normal text-[#21426D]">{title}</h5>
          <div className="">
            <img
              src={newSource}
              alt="newExam"
              onClick={showModal}
            />
          </div>
          <div className="flex items-center justify-center px-6 text-center text-xs">
            {description}
          </div>
        </div>
      ) : page == 'patientDetails' ? (
        <button
          className="flex cursor-pointer items-center justify-center  space-x-2 rounded-md bg-[#005DD4] px-1 py-[2px] text-white"
          onClick={showModal}
        >
          <span>New Case</span>
          <AiOutlinePlusCircle size={16} />
        </button>
      ) : page == 'examPage' ? (
        <button
          onClick={showModal}
          className="flex items-center justify-center space-x-2 rounded border border-[#005DD4] px-2 py-1 font-semibold text-[#005DD4]  hover:bg-[#015DDD] hover:text-white"
        >
          <IoMdAddCircleOutline size={15} />
          <div>Add new exam</div>
        </button>
      ) : (
        <></>
      )}

      <Modal
        title={'Add new exams'}
        open={open}
        onCancel={handleCancel}
        width={null}
        className="h-2/3 w-2/3"
        confirmLoading={confirmLoading}
        centered={true}
        footer={
          <div className="flex justify-between">
            <div className="relative mb-2 flex h-8 space-x-2">
              <button
                disabled={selectedFiles?.length === 0}
                className={`${selectedFiles?.length === 0 ? 'cursor-not-allowed opacity-50' : ' cursor-pointer text-white opacity-100 hover:bg-red-700 hover:text-white'} inline-flex items-center justify-center space-x-2 rounded-md border border-red-700
                 px-2 py-1  text-red-700 transition duration-150 ease-in-out `}
                onClick={() => {
                  setSelectedStudies([]);
                  setSelectedFiles([]);
                }}
              >
                <MdDelete size={14} />
                <div>Clear All</div>
              </button>
              <div
                className={`${
                  selectedFiles?.length === 0
                    ? 'cursor-not-allowed opacity-50'
                    : ' cursor-pointer text-white opacity-100 hover:bg-[#005dd4] hover:text-white'
                } inline-flex items-center  justify-center space-x-2 rounded-md border border-[#005dd4]
                 py-1 pr-2  text-[#005dd4] transition duration-150 ease-in-out `}
                onClick={selectedFiles?.length === 0 ? null : handleFileSelectMore}
              >
                {renderUploadInputMore()}
                <LuUpload size={14} />
                <div>Upload</div>
                {/* Add your upload icon or text */}
              </div>
            </div>
            <div className="flex items-center justify-center">
              Total Files : {selectedFiles?.length}
            </div>{' '}
            {/* Custom footer text */}
            {allUploaded && location.pathname != '/exams' ? (
              <div>
                <button
                  disabled={selectedFiles?.length === 0}
                  className={`${selectedFiles?.length === 0 ? 'cursor-not-allowed opacity-50' : ' cursor-pointer text-white opacity-100'}
                rounded-lg bg-[#005dd4] px-3 py-1.5 transition duration-150 ease-in-out hover:bg-blue-600
                 disabled:bg-gray-300 disabled:hover:bg-gray-300`}
                  onClick={() => {
                    navigate(`/exams`, {
                      replace: true,
                    });
                  }}
                >
                  Go to exam list
                </button>
              </div>
            ) : (
              <div className="space-x-3">
                <button
                  className={`rounded-lg border px-3 py-1 hover:border-[#005dd4] hover:text-[#005dd4]`}
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  disabled={selectedFiles?.length === 0}
                  className={`${selectedFiles?.length === 0 ? 'cursor-not-allowed opacity-50' : ' cursor-pointer text-white opacity-100'}
                rounded-lg bg-[#005dd4] px-3 py-1 transition duration-150 ease-in-out hover:bg-blue-600
                 disabled:bg-gray-300 disabled:hover:bg-gray-300`}
                  onClick={handleOk}
                >
                  OK
                </button>{' '}
              </div>
            )}
          </div>
        }
        maskStyle={
          {
            backdropFilter: 'blur(var(--blur-backdrop))',
            '--blur-backdrop': '10px', // Adjust the blur radius to match the custom class
          } as any
        }
        // style={{ width: 'auto !important' }}
      >
        <div>
          <div className="mb-5 mt-5 flex w-full">
            {modalTabs.map((tab, i) => {
              const isActive = tab.title === activeModalTab;
              return (
                <a
                  key={i}
                  className={`inline-flex w-full cursor-pointer items-center justify-center border-b-2 py-3 text-sm
                      font-medium leading-none tracking-wider sm:px-6
                       ${
                         isActive
                           ? 'rounded-t border-b-2 border-[#005dd4] bg-gray-100 text-[#005dd4]'
                           : `border-gray-200 hover:bg-gray-50 hover:text-gray-900
                           ${selectedFiles?.length > 0 ? 'cursor-not-allowed opacity-50' : ' cursor-pointer opacity-100'}`
                       }`}
                  onClick={() => {
                    if (selectedFiles?.length == 0) {
                      setActiveModalTab(tab.title);
                      setUploadType(tab.title.toLowerCase());
                    }
                  }}
                >
                  {tab.title}
                </a>
              );
            })}
          </div>

          {/* <div className="mb-4 inline-flex w-full cursor-pointer items-center justify-center text-lg">
            <div className="w-full border-b-2 border-[#91B9ED] text-center">Files</div>
            <div className="w-full border-b-2 border-[#91B9ED] text-center">Folder</div>
          </div> */}
          {/* <label>
            <input
              type="radio"
              name="uploadType"
              value="files"
              checked={uploadType === 'files'}
              onChange={handleUploadTypeChange}
            />
            Files
          </label>
          <label>
            <input
              type="radio"
              name="uploadType"
              value="folder"
              checked={uploadType === 'folder'}
              onChange={handleUploadTypeChange}
            />
            Folder
          </label> */}
        </div>
        {selectedFiles?.length > 1 && (
          <div>
            {/* <label>
                <input
                  type="checkbox"
                  checked={useGlobalPatient}
                  onChange={e => setUseGlobalPatient(e.target.checked)}
                />
                Assign all files to the same patient
              </label> */}
            {useGlobalPatient && (
              <select
                value={globalPatientId}
                onChange={handleGlobalPatientChange}
                // ... additional props
              >
                <option value="">Choose patient</option>
                {patientState.patients.map(patient => (
                  <option
                    key={patient.id}
                    value={patient.id}
                  >
                    {patient.patient_name}
                  </option>
                ))}
              </select>
            )}
          </div>
        )}
        {selectedStudies?.length === 0 ? (
          <div
            className={`flex h-[420px] w-full cursor-pointer items-center justify-center rounded-md border-2 border-dashed border-gray-400 p-4 ${isDragOver ? 'bg-gray-100' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleFileSelect}
          >
            {renderUploadInput()}
            <div className="flex w-full flex-col items-center justify-center text-center text-[#005dd4] hover:text-blue-600">
              <div className="flex w-full items-center justify-center">
                {uploadType == 'import files' ? <PiFilesFill size={40} /> : <FaFolder size={40} />}
              </div>
              <p className="mt-1 w-full text-sm">
                {isDragOver
                  ? 'Drop your files here'
                  : uploadType == 'import files'
                    ? 'Drag and drop your files here or click to select files'
                    : 'Drag and drop your Folder here or click to select folder'}
              </p>
            </div>
          </div>
        ) : (
          <div className="overflow-hidden">
            <div className="h-[420px] overflow-y-scroll">
              {selectedStudies.map((study, index) => (
                <div
                  key={study.studyInstanceUID}
                  className="mx-3 mb-2 flex items-center justify-between overflow-hidden
                   bg-white shadow sm:rounded-lg"
                >
                  {/* Render study-level info */}
                  <div className="flex w-full flex-col px-2 py-2">
                    <div className="w-full rounded-md border-l-[3px] border-[#005dd4] bg-[#c7daf365] py-3 px-3">
                      <div className="inline-flex items-center justify-center space-x-2 text-[#005dd4]">
                        <MdInfoOutline size={15} />
                        <span className="text-md font-semibold">Global file informations</span>
                      </div>
                      <div className="ml-6">
                        <div className="">
                          <strong className="text-sm">
                            Number of files :{' '}
                            <span className="font-normal">{study?.files?.length}</span>
                          </strong>
                        </div>
                        <div>
                          <p
                            className="cursor-pointer text-sm"
                            title={`${study.studyInstanceUID}`}
                          >
                            {/* <strong>Study Instance UID From The Dicom :</strong>{' '} */}
                            <strong>UID :</strong>{' '}
                            {study.studyInstanceUID ? study.studyInstanceUID : 'None'}
                          </p>
                        </div>
                        <div className="mt-1 space-y-1">
                          {/* <p className='text-sm'><strong>Series Instance UID From The Dicom:</strong> {study.seriesInstanceUID}</p>*/}
                          <p className="text-sm">
                            <strong>Patient name from the dicom :</strong>{' '}
                            {study.originalPatientName ? study.originalPatientName : 'None'}
                          </p>
                          <p className="text-sm">
                            <strong>Patient ID from the dicom :</strong>{' '}
                            {study.originalPatientID ? study.originalPatientID : 'None'}
                          </p>
                        </div>
                      </div>
                    </div>

                    <strong className="mt-1 ml-4 text-sm">Files names : </strong>
                    <div className="scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 mx-4 mt-1 flex cursor-pointer space-x-2 overflow-x-scroll pb-2">
                      {study.files.map((file, fileIndex) => (
                        <div
                          className="inline-flex w-fit space-x-5 rounded-md border px-1.5 py-1 shadow-sm"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          <div className="text-sm font-medium leading-6 text-gray-900">
                            {`${file.fileName.substring(0, 4)}${!file.error ? ` (${file.modality})` : ''}`}
                          </div>
                          <div
                            className={`rounded-md border-2 py-0.5 px-2 text-sm font-medium shadow-inner ${
                              file.uploadStatus === 'Not Uploaded Yet'
                                ? 'border-gray-300 bg-gray-300'
                                : file.uploadStatus === 'success'
                                  ? 'border-green-600 bg-green-600 text-white'
                                  : file.uploadStatus === 'Uploading...'
                                    ? 'border-blue-700 bg-blue-700 text-white'
                                    : 'border-red-600 bg-red-600 text-white'
                            }`}
                          >
                            {file.uploadStatus}
                          </div>
                        </div>
                      ))}
                    </div>
                    {fullPatientName ? (
                      'Patient Name'
                    ) : (
                      <div
                        className="mt-4 ml-4 inline-flex w-[200px] cursor-pointer items-center space-x-3 text-sm font-bold text-black "
                        onClick={() => toggleExistingPatient(study.studyInstanceUID)}
                      >
                        <div>
                          {study.existingPatient
                            ? `  Create new patient`
                            : `  Choose an existent patient`}{' '}
                        </div>

                        <HiOutlineSwitchHorizontal size={15} />
                      </div>
                    )}

                    <div className="mx-4 mb-4 mt-1 inline-flex space-x-4">
                      {/* Use the study-level existingPatient property */}
                      {!study.existingPatient ? (
                        <input
                          className="border-1 w-full rounded-md border border-gray-400 px-2 py-1 outline-none focus:border-0
                          focus:outline-none focus:ring-1 focus:ring-[#4096ff]
                          "
                          type="text"
                          placeholder="patient name"
                          disabled={fullPatientName && true}
                          value={
                            // fullPatientName
                            //   ? fullPatientName
                            //   : study?.newPatientName
                            //     ? study?.newPatientName
                            //     : study?.originalPatientName
                            getValue(study)
                          }
                          onChange={e => {
                            const newPatientName = e.target.value;
                            setSelectedStudies(prevStudies =>
                              prevStudies.map(study1 =>
                                study1.studyInstanceUID === study.studyInstanceUID
                                  ? { ...study1, newPatientName: newPatientName } // Update the originalPatientName property
                                  : study1
                              )
                            );
                          }}
                        />
                      ) : (
                        <Select
                          defaultValue="choose patient"
                          className="w-full"
                          onChange={value => {
                            setSelectedStudies(prevStudies =>
                              prevStudies.map(study1 =>
                                study1.studyInstanceUID === study.studyInstanceUID
                                  ? {
                                      ...study1,
                                      selectedExistingPatientId: value,
                                    } // Update the selectedExistingPatientId for the matching study
                                  : study1
                              )
                            );
                          }}
                          options={patientState.patients.map(patient => ({
                            value: patient.id,
                            label: patient.patient_name,
                          }))}
                        />
                      )}

                      <div className="w-full">
                        <ConfigProvider locale={locale}>
                          <DatePicker
                            placeholder="Creation Date (yy-mm-dd)"
                            className="main-input-void mt-0 w-full  rounded-md bg-white px-3 py-1 text-sm placeholder-opacity-50"
                            maxDate={dayjs()}
                            onChange={(date: moment.Moment | null, dateString: string) => {
                              study.create_date = dateString;
                              console.log('study', study);
                              setSelectedStudies(prev =>
                                prev.map(prevStudy => {
                                  if (prevStudy.studyInstanceUID === study.studyInstanceUID) {
                                    return {
                                      ...prevStudy,
                                      create_date: dateString,
                                    };
                                  }
                                  return prevStudy;
                                })
                              );
                            }}
                            id="create_date"
                            name="create_date"
                          />
                        </ConfigProvider>
                      </div>
                      {/* {study?.error && <span className='bg-red-500'>{study.error}</span>} */}
                    </div>
                    <div className="mt-4 flex items-center justify-end border-t pt-2">
                      {study?.files.every(file => file.uploadStatus === 'success') ? (
                        <button
                          onClick={() => {
                            navigate(`/exams/viewer?StudyInstanceUIDs=${study?.studyInstanceUID}`, {
                              replace: true,
                            });
                          }}
                          className="rounded-md border border-blue-700
                      px-2 py-1 text-blue-700 transition duration-150 ease-in-out hover:bg-blue-700 hover:text-white"
                        >
                          <div className="inline-flex items-center justify-center space-x-2">
                            <FaEye size={13} /> <div>View</div>
                          </div>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setSelectedStudies(prev =>
                              prev.filter(
                                prevStudy => prevStudy.studyInstanceUID !== study.studyInstanceUID
                              )
                            );
                            setSelectedFiles(prev =>
                              prev.filter(
                                prevStudy => prevStudy.studyInstanceUID !== study.studyInstanceUID
                              )
                            );
                          }}
                          className="rounded-md border border-red-700
                        px-2 py-1 text-red-700 transition duration-150 ease-in-out hover:bg-red-700 hover:text-white"
                        >
                          <div className="inline-flex items-center justify-center space-x-2">
                            <MdDelete size={14} /> <div>Delete</div>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default NewExam;
