var primaryColor = 'primary';
export var sizeClassnamesMap = {
  small: 'text-xs px-3 py-2',
  medium: 'text-sm px-5 py-2.5',
  large: 'text-lg px-6 py-3.5',
};
export var sizeOnlyIconClassnamesMap = {
  small: 'p-2',
  medium: 'p-2.5',
  large: 'p-3.5',
};
export var shapeClassnamesMap = {
  rectangle: 'rounded-sm',
  rounded: 'rounded-lg',
  circle: 'rounded-full',
};
export var disabledButtonClassnames =
  'border border-transparent bg-gray-100 text-gray-400 cursor-not-allowed';
export var buttonIntentStyleClassnamesMap = {
  primary: {
    solid: 'bg-primary-600 hover:bg-primary-600 text-white border border-transparent shadow',
    soft: 'bg-'
      .concat(primaryColor, '-50 hover:bg-')
      .concat(primaryColor, '-100 text-')
      .concat(primaryColor, '-600 hover:text-')
      .concat(primaryColor, '-700 border border-transparent'),
    outline: 'text-'
      .concat(primaryColor, '-600 hover:text-')
      .concat(primaryColor, '-700 border border-')
      .concat(primaryColor, '-600'),
    ghost: 'text-'
      .concat(primaryColor, '-600 hover:text-')
      .concat(primaryColor, '-700 border border-transparent'),
  },
  secondary: {
    solid:
      'bg-gray-100 hover:bg-gray-200 text-gray-600 hover:text-gray-700 border border-transparent',
    soft: 'bg-gray-50 hover:bg-gray-100 text-gray-600 hover:text-gray-700 border border-transparent',
    outline: 'text-gray-600 hover:text-gray-700 border border-gray-600',
    ghost: 'text-gray-600 hover:text-gray-700 border border-transparent',
  },
  tertiary: {
    solid: 'bg-indigo-600 hover:bg-indigo-700 text-white border border-transparent shadow',
    soft: 'bg-indigo-50 hover:bg-indigo-100 text-indigo-600 hover:text-indigo-700 border border-transparent',
    outline: 'text-indigo-600 hover:text-indigo-700 border border-indigo-600',
    ghost: 'text-indigo-600 hover:text-indigo-700 border border-transparent',
  },
  white: {
    solid: 'bg-white hover:bg-'
      .concat(primaryColor, '-gray-50 text-')
      .concat(primaryColor, '-600 hover:text-')
      .concat(primaryColor, '-700 border border-transparent'),
    soft: 'bg-'
      .concat(primaryColor, '-gray-50 hover:bg-')
      .concat(primaryColor, '-gray-100 text-')
      .concat(primaryColor, '-600 hover:text-')
      .concat(primaryColor, '-700 border border-transparent'),
    outline: 'bg-white text-'
      .concat(primaryColor, '-600 hover:text-')
      .concat(primaryColor, '-700 border border-')
      .concat(primaryColor, '-600'),
    ghost: 'bg-white text-'
      .concat(primaryColor, '-600 hover:text-')
      .concat(primaryColor, '-700 border border-transparent'),
  },
  dark: {
    solid: 'bg-gray-600 hover:bg-gray-700 text-white border border-transparent shadow',
    soft: 'bg-gray-50 hover:bg-gray-100 text-gray-600 hover:text-gray-700 border border-transparent',
    outline:
      'bg-gray-50 hover:bg-gray-100 text-gray-600 hover:text-gray-700 border border-gray-600',
    ghost: 'text-gray-600 hover:text-gray-700 border border-transparent',
  },
  danger: {
    solid: 'bg-red-600 hover:bg-red-700 text-white border border-transparent shadow',
    soft: 'bg-red-50 hover:bg-red-100 text-red-600 hover:text-red-700 border border-transparent',
    outline: 'text-red-600 hover:text-red-700 border border-red-600',
    ghost: 'text-red-600 hover:text-red-700 border border-transparent',
  },
  success: {
    solid: 'bg-success-600 hover:bg-success-700 text-white border border-transparent shadow',
    soft: 'bg-success-50 hover:bg-success-100 text-success-600 hover:text-success-700 border border-transparent',
    outline: 'text-success-600 hover:text-success-700 border border-success-600',
    ghost: 'text-success-600 hover:text-success-700 border border-transparent',
  },
  warning: {
    solid: 'bg-yellow-500 hover:bg-yellow-600 text-white border border-transparent shadow',
    soft: 'bg-yellow-50 hover:bg-yellow-100 text-yellow-500 hover:text-yellow-600 border border-transparent',
    outline: 'text-yellow-500 hover:text-yellow-600 border border-yellow-500',
    ghost: 'text-yellow-500 hover:text-yellow-600 border border-transparent',
  },
};
