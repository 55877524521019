import React, { useEffect, useState } from 'react';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import deepEqual from 'deep-equal';
import { resetCriteria2, resetReportObjValues } from './resetUtils';
import { reportAttrsVals, reportAttrsVals_Orig } from './objects';
import { Spin } from 'antd';

import {
  handleACChange_cent,
  handleACChange_ga,
  handleACChange_val,
  handleAFPChange_curr,
  handleAFPChange_prev,
  handleAgeChange,
  handleBPDChange_cent,
  handleBPDChange_ga,
  handleBPDChange_val,
  handleCommentsChange,
  handleDOBChange,
  handleEFAChange_cent,
  handleEFAChange_curr,
  handleEFAChange_prev,
  handleEGAChange_cent,
  handleEGAChange_curr,
  handleEGAChange_prev,
  handleExamChange,
  handleFLChange_cent,
  handleFLChange_ga,
  handleFLChange_val,
  handleGAChange,
  handleGRSChange_curr,
  handleGRSChange_prev,
  handleHCChange_cent,
  handleHCChange_ga,
  handleHCChange_val,
  handleLMPChange,
  handleMSChange,
  handleRHPChange,
  handleSDPChange_cent,
  handleSDPChange_ga,
  handleSDPChange_val,
  onChangeSwitchFCFR_cri1,
  onChangeSwitchFCFR_cri2,
  onChangeSwitchFCFR_cri3,
  onChangeSwitchFCFR_cri4,
  onChangeSwitchFCFR_cri5,
  onChangeSwitchFCFR_cri6,
  onChangeSwitchFCSR_cri1,
  onChangeSwitchFCSR_cri2,
  onChangeSwitchFCSR_cri3,
  onChangeSwitchFCSR_cri4,
  onChangeSwitchFCSR_cri5,
  onChangeSwitchFCSR_cri6,
  onChangeSwitchSCFR_cri1,
  onChangeSwitchSCFR_cri2,
  onChangeSwitchSCFR_cri3,
  onChangeSwitchSCFR_cri4,
  onChangeSwitchSCFR_cri5,
  onChangeSwitchSCFR_cri6,
  onChangeSwitchSCSR_cri1,
  onChangeSwitchSCSR_cri2,
  onChangeSwitchSCSR_cri3,
  onChangeSwitchSCSR_cri4,
  onChangeSwitchSCSR_cri5,
  onChangeSwitchSCSR_cri6,
} from './changeInputsUtils';
import { useNavigate } from 'react-router';
import ActionsBar from './actionsBar';
import ReportHeader from './ReportHeader';
import ReportPlane from './reportPlane';
import GeneralInfo from './generalInfo.tsx';
import ExaminationResults from './examinationResults';
import ExaminationDetails from './examinationDetails';
// import { getReport } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import reportSRJSON from './report.json';
import { getReport, resetReportState } from '../../../../../../viewer/src/Deepecho/store/actions';
// import { getStudyById } from '../../../../viewer/src/Deepecho/helpers/backendHelper';
import {
  finishReport,
  getStudyById,
} from '../../../../../../viewer/src/Deepecho/helpers/backendHelper';
// import { useGetGlobalReportByStudyId } from 'platform/viewer/src/Deepecho/helpers/api/report/report.api';
import { useGetGlobalReportByStudyId } from '../../../../../../viewer/src/Deepecho/helpers/api/report/report.api';
const url = process.env.BACKEND_URL;
interface ReportProps {
  StudyUID: string;
  ohifViewer: boolean;
}
const Report: React.FC<ReportProps> = ({ StudyUID, ohifViewer }) => {
  // let StudyUID = new URLSearchParams(location.search).get('StudyInstanceUIDs');

  const { isLoading, data, error } = useGetGlobalReportByStudyId(StudyUID);
  console.log('inside report', data, isLoading, error);
  const [study, setStudy] = useState<any>();
  const [examData, setExamData] = useState(reportAttrsVals);
  const [reportDetails, setReportDetails] = useState({});
  const [reportDetailsOriginal, setReportDetailsOriginal] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [resetActive, setResetActive] = useState(false);
  const [loader, setLoader] = useState(false);
  const [finished, setFinished] = useState(data?.report?.is_completed);
  const [loadingFinished, setLoadingFinished] = useState(false);

  // Plan 1
  const [selectedFrame1, setSelectedFrame1] = useState('');
  const [selectedFrame1ID, setSelectedFrame1ID] = useState(0);
  const [selectedFramePlan1, setSelectedFramePlan1] = useState({});

  // Plan 2
  const [selectedFrame2, setSelectedFrame2] = useState('');
  const [selectedFrame2ID, setSelectedFrame2ID] = useState(0);
  const [selectedFramePlan2, setSelectedFramePlan2] = useState({});

  // Plan 2
  const [selectedFrame3, setSelectedFrame3] = useState('');
  const [selectedFrame3ID, setSelectedFrame3ID] = useState(0);
  const [selectedFramePlan3, setSelectedFramePlan3] = useState({});
  // Plan 3
  const [selectedFrame4, setSelectedFrame4] = useState('');
  const [selectedFrame4ID, setSelectedFrame4ID] = useState(0);
  const [selectedFramePlan4, setSelectedFramePlan4] = useState({});

  const [report_content, setReport_content] = useState();

  const handleSwitchChange = () => {
    setEditMode(!editMode);
  };

  // const downloadPDF = () => {
  //   const capture = document.querySelector('.actual-receipt');
  //   setLoader(true);
  //   html2canvas(capture).then(canvas => {
  //     const imgData = canvas.toDataURL('img/png');
  //     const doc = new jsPDF({
  //       orientation: 'landscape',
  //       unit: 'in',
  //       format: [24, 12],
  //       pageSize: 'A3',
  //     });
  //     const componentWidth = doc.internal.pageSize.getWidth();
  //     const componentHeight = doc.internal.pageSize.getHeight();
  //     doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
  //     setLoader(false);
  //     doc.save('receipt.pdf');
  //   });
  // };

  const downloadPDF = () => {
    const capture = document.querySelector('.actual-receipt');
    setLoader(true);

    const scale = 3;

    html2canvas(capture, {
      scale: scale,
      useCORS: true,
    }).then(canvas => {
      const orientation = window.innerWidth > 1280 ? 'landscape' : 'portrait';

      const imgData = canvas.toDataURL('img/png');
      const doc = new jsPDF({
        orientation: orientation,
        unit: 'in',
        format: [24, 12],
        pageSize: 'A4',
      });
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save(`report_${study?.patient_patient_name}.pdf`);
    });
  };

  const saveReport = () => {
    // fetch(`${url}/api/reports/create`, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },

    //   body: JSON.stringify({
    //     date: 'lundi',
    //     doctor: null,
    //     patient: null,
    //     fields: [
    //       {
    //         fieldName: 'examID',
    //         fieldValue: '946846898',
    //       },
    //       {
    //         fieldName: 'patientID',
    //         fieldValue: '948594859',
    //       },
    //     ],
    //   }),
    //   method: 'POST',
    // })
    //   .then(res => {
    //     navigate({
    //       pathname: '/deepecho/reports',
    //     });
    //   })
    //   .catch(err => console.error(err));
    setLoadingFinished(true);
    finishReport(StudyUID)
      .then(res => {
        setFinished(true);
        setLoadingFinished(false);
        console.log('Report finished', res);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    if (!deepEqual(examData, reportAttrsVals_Orig)) {
      setResetActive(true);
    }
  }, [examData]);

  useEffect(() => {
    setReportDetails(data);
  }, [data]);

  const cephalicCriterias = ['QUAL_VAL__ZOOM', 'QUAL_FLAG__cephal_thalamus', 'STD_PLANE__cephalic'];
  // cephalic:
  // plan showning Thalimus  (conditioned by QUAL_FLAG__cephal_thalamus)
  // Plan showing cavum septum pellucidi ( STD_PLANE__cephalic === true)
  // Cerebellum not visible => false (STD_PLANE__cephalic === true)

  const abdoCriterias = [
    'QUAL_VAL__ZOOM',
    'QUAL_FLAG__abdo_stomach_bubble',
    'QUAL_FLAG__abdo_portal_sinus',
    'STD_PLANE__abdominal',
  ];

  // abdo
  // QUAL_FLAG__abdo_stomach_bubble: true, //abdo_stomach_bubble visible
  // QUAL_FLAG__abdo_portal_sinus: true, // abdo_portal_sinus visible
  // fetal kidney not visible ==> (STD_PLANE__abdominal === true )

  const afPocketCriterias = ['QUAL_VAL__ZOOM', 'STD_PLANE__af'];

  // afpocket
  //       placanta not visible              => STD_PLANE__af === true  // to remove
  //       umbilical not visible             => STD_PLANE__af === true

  const femurCriterias = ['QUAL_VAL__FEMURAL_ANGLE', 'STD_PLANE__femur', 'QUAL_VAL__ZOOM'];
  // femur
  // less than 45 to horizontal (QUAL_VAL__FEMURAL_ANGLE < 45)
  // fetal spine not showing (STD_PLANE__femur === true)

  return (
    <>
      {reportDetails != undefined &&
      reportDetails != null &&
      isLoading == false &&
      Object.keys(reportDetails).length !== 0 ? (
        <>
          <ActionsBar
            saveReport={saveReport}
            resetReportObjValues={resetReportObjValues}
            setExamData={setExamData}
            examData={examData}
            setResetActive={setResetActive}
            resetActive={resetActive}
            downloadPDF={downloadPDF}
            editMode={editMode}
            finishMode={data?.report?.is_completed}
            finished={finished}
            ohifViewer={ohifViewer}
            loadingFinished={loadingFinished}
            handleSwitchChange={handleSwitchChange}
          />
          <div className="actual-receipt grid grid-cols-12 grid-rows-6 border-4 border-gray-500 bg-white">
            <ReportHeader study={study} />
            <div className="col-span-12 row-span-5 mt-3 xl:col-span-7">
              <div
                className="grid grid-cols-2 grid-rows-2 text-center"
                style={{
                  height: '-webkit-fill-available',
                }}
              >
                <ReportPlane
                  qualityCriterias={femurCriterias}
                  image={reportDetails?.images?.femur}
                  setExamData={setExamData}
                  examData={examData}
                  plan="femur"
                  frame={reportDetails?.keys_info?.femur?.frame}
                  selectedFrameID={selectedFrame1ID}
                  editMode={editMode}
                  reportDetails={data}
                  selectedFrame={selectedFrame1}
                  setSelectedFrameID={setSelectedFrame1ID}
                  setSelectedFrame={setSelectedFrame1}
                  setSelectedFramePlan={setSelectedFramePlan1}
                  onChangeSwitch_cri1={onChangeSwitchFCFR_cri1}
                  resetCriteria2={resetCriteria2}
                  onChangeSwitch_cri2={onChangeSwitchFCFR_cri2}
                  onChangeSwitch_cri3={onChangeSwitchFCFR_cri3}
                  onChangeSwitch_cri4={onChangeSwitchFCFR_cri4}
                  onChangeSwitch_cri6={onChangeSwitchFCFR_cri6}
                  onChangeSwitch_cri5={onChangeSwitchFCFR_cri5}
                />

                <ReportPlane
                  qualityCriterias={abdoCriterias}
                  setExamData={setExamData}
                  image={reportDetails?.images?.abdominal}
                  examData={examData}
                  plan="abdominal"
                  frame={reportDetails?.keys_info?.abdominal?.frame}
                  selectedFrameID={selectedFrame2ID}
                  editMode={editMode}
                  reportDetails={data}
                  selectedFrame={selectedFrame2}
                  setSelectedFrameID={setSelectedFrame2ID}
                  setSelectedFrame={setSelectedFrame2}
                  setSelectedFramePlan={setSelectedFramePlan2}
                  onChangeSwitch_cri1={onChangeSwitchSCFR_cri1}
                  resetCriteria2={resetCriteria2}
                  onChangeSwitch_cri2={onChangeSwitchSCFR_cri2}
                  onChangeSwitch_cri3={onChangeSwitchSCFR_cri3}
                  onChangeSwitch_cri4={onChangeSwitchSCFR_cri4}
                  onChangeSwitch_cri6={onChangeSwitchSCFR_cri6}
                  onChangeSwitch_cri5={onChangeSwitchSCFR_cri5}
                />

                <ReportPlane
                  qualityCriterias={afPocketCriterias}
                  setExamData={setExamData}
                  image={reportDetails?.images?.afpocket}
                  examData={examData}
                  plan="afpocket"
                  frame={reportDetails?.keys_info?.afpocket?.frame}
                  selectedFrameID={selectedFrame3ID}
                  editMode={editMode}
                  reportDetails={data}
                  selectedFrame={selectedFrame3}
                  setSelectedFrameID={setSelectedFrame3ID}
                  setSelectedFrame={setSelectedFrame3}
                  setSelectedFramePlan={setSelectedFramePlan3}
                  onChangeSwitch_cri1={onChangeSwitchFCSR_cri1}
                  resetCriteria2={resetCriteria2}
                  onChangeSwitch_cri2={onChangeSwitchFCSR_cri2}
                  onChangeSwitch_cri3={onChangeSwitchFCSR_cri3}
                  onChangeSwitch_cri4={onChangeSwitchFCSR_cri4}
                  onChangeSwitch_cri6={onChangeSwitchFCSR_cri6}
                  onChangeSwitch_cri5={onChangeSwitchFCSR_cri5}
                />

                <ReportPlane
                  qualityCriterias={cephalicCriterias}
                  setExamData={setExamData}
                  image={reportDetails?.images?.cephalic}
                  examData={examData}
                  plan="cephalic"
                  frame={reportDetails?.keys_info?.cephalic?.frame}
                  selectedFrameID={selectedFrame4ID}
                  editMode={editMode}
                  reportDetails={data}
                  selectedFrame={selectedFrame4}
                  setSelectedFrameID={setSelectedFrame4ID}
                  setSelectedFrame={setSelectedFrame4}
                  setSelectedFramePlan={setSelectedFramePlan4}
                  onChangeSwitch_cri1={onChangeSwitchSCSR_cri1}
                  resetCriteria2={resetCriteria2}
                  onChangeSwitch_cri2={onChangeSwitchSCSR_cri2}
                  onChangeSwitch_cri3={onChangeSwitchSCSR_cri3}
                  onChangeSwitch_cri4={onChangeSwitchSCSR_cri4}
                  onChangeSwitch_cri6={onChangeSwitchSCSR_cri6}
                  onChangeSwitch_cri5={onChangeSwitchSCSR_cri5}
                />
              </div>
            </div>
            <div className="col-span-12 row-span-5 mt-3 xl:col-span-5">
              <div
                style={{ height: '100%' }}
                className="flex flex-col space-y-4"
              >
                <GeneralInfo
                  editMode={editMode}
                  examData={examData}
                  study={study}
                  handleDOBChange={handleDOBChange}
                  setExamData={setExamData}
                  handleAgeChange={handleAgeChange}
                  handleLMPChange={handleLMPChange}
                  handleGAChange={handleGAChange}
                  handleExamChange={handleExamChange}
                  handleMSChange={handleMSChange}
                  handleRHPChange={handleRHPChange}
                />

                <ExaminationResults
                  reportDetails={{}}
                  selectedFrame1ID={selectedFrame1ID}
                  selectedFrame2ID={selectedFrame2ID}
                  selectedFrame3ID={selectedFrame3ID}
                  selectedFrame4ID={selectedFrame4ID}
                  editMode={editMode}
                  examData={examData}
                  setExamData={setExamData}
                  handleEGAChange_curr={handleEGAChange_curr}
                  handleEGAChange_prev={handleEGAChange_prev}
                  handleEGAChange_cent={handleEGAChange_cent}
                  handleEFAChange_curr={handleEFAChange_curr}
                  handleEFAChange_prev={handleEFAChange_prev}
                  handleEFAChange_cent={handleEFAChange_cent}
                  handleGRSChange_curr={handleGRSChange_curr}
                  handleGRSChange_prev={handleGRSChange_prev}
                  handleAFPChange_curr={handleAFPChange_curr}
                  handleAFPChange_prev={handleAFPChange_prev}
                  handleCommentsChange={handleCommentsChange}
                />

                {selectedFramePlan3 && (
                  <ExaminationDetails
                    reportDetails={data}
                    selectedFrame1ID={selectedFrame1ID}
                    selectedFrame2ID={selectedFrame2ID}
                    selectedFrame3ID={selectedFrame3ID}
                    selectedFrame4ID={selectedFrame4ID}
                    editMode={editMode}
                    examData={examData}
                    setExamData={setExamData}
                    handleBPDChange_val={handleBPDChange_val}
                    handleBPDChange_ga={handleBPDChange_ga}
                    handleBPDChange_cent={handleBPDChange_cent}
                    handleHCChange_val={handleHCChange_val}
                    handleHCChange_ga={handleHCChange_ga}
                    handleHCChange_cent={handleHCChange_cent}
                    handleFLChange_val={handleFLChange_val}
                    handleFLChange_ga={handleFLChange_ga}
                    handleFLChange_cent={handleFLChange_cent}
                    handleACChange_val={handleACChange_val}
                    handleACChange_ga={handleACChange_ga}
                    handleACChange_cent={handleACChange_cent}
                    handleSDPChange_val={handleSDPChange_val}
                    handleSDPChange_ga={handleSDPChange_ga}
                    handleSDPChange_cent={handleSDPChange_cent}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex h-96 items-center justify-center">
          <div className="mx-auto align-middle">
            <Spin className="h-18" />
          </div>
        </div>
      )}
    </>
  );
};

export default Report;
