import { apiClient } from '../../apiHelper';
import { useCreate, useEdit, usePaginated, useSingleFetch } from '../react-query';
import { patientsQueryKeys } from './patients-query-keys';

export function usePaginatedPatients(page, itemsPerPage, filters = {}, config = {}) {
  const getPaginatedPatientsFn = async (p = page, f = filters) => {
    const filtersQuery = Object.keys(f)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(f[key])}`)
      .join('&');
    const url = `patients?page=${p}&items_per_page=${itemsPerPage}${
      filtersQuery ? `&${filtersQuery}` : ''
    }`;

    const response = await apiClient.get(url);
    return response.data;
  };

  return usePaginated(getPaginatedPatientsFn, patientsQueryKeys.pagination(page), page, config);
}

export function useSinglePatient(patient_id, config = {}) {
  if (patient_id === -1) {
    return;
  }
  const getPatientFn = async () => {
    const response = await apiClient.get(`patients/${patient_id}`);
    return response;
  };

  return useSingleFetch(getPatientFn, patientsQueryKeys.detail(patient_id), patient_id, config);
}

export function usePermissionsUserPatient(patient_id, user_uid, config = {}) {
  const getPatientFn = async () => {
    const response = await apiClient.get(`patients/${patient_id}/get_permissions/${user_uid}`);
    return response;
  };

  return useSingleFetch(
    getPatientFn,
    patientsQueryKeys.permissions(patient_id, user_uid),
    null,
    config
  );
}

export function useCreatePatient() {
  const createPatientFn = async data => {
    const response = await apiClient.post(`patients`, data);
    return response;
  };

  return useCreate(
    createPatientFn,
    patientsQueryKeys.all,
    'Error while creating patient',
    'Patient created successfully'
  );
}

export function useEditPatient() {
  const editPatientFn = async data => {
    const { patient_id, values } = data;
    const response = await apiClient.put(`patients/${patient_id}`, values);
    return response;
  };

  return useEdit(
    editPatientFn,
    patientsQueryKeys.all,
    'Error while Editing patient',
    'Patient edited successfully'
  );
}

export function useGrantPermission() {
  const grantPermissionFn = async data => {
    const { patient_id, user_uid, permission } = data;
    const response = await apiClient.post(
      `patients/${patient_id}/grant_permission/${user_uid}/${permission}`
    );
    return response;
  };

  return useEdit(
    grantPermissionFn,
    patientsQueryKeys.all,
    'Error while granting Permission',
    'Permission granted successfully'
  );
}

export function useRevokePermission() {
  const revokePermissionFn = async data => {
    const { patient_id, user_uid, permission } = data;

    const response = await apiClient.post(
      `patients/${patient_id}/revoke_permission/${user_uid}/${permission}`
    );
    return response;
  };

  return useEdit(
    revokePermissionFn,
    patientsQueryKeys.all,
    'Error while revokeing Permission',
    'Permission revokeed successfully'
  );
}

export function useArchivePatient() {
  const archivePatientFn = async patient_id => {
    const response = await apiClient.post(`patients/archive/${patient_id}`);
    return response;
  };

  return useEdit(
    archivePatientFn,
    patientsQueryKeys.all,
    'Error while archiving patient',
    'Patient archived successfully'
  );
}
export function useUnarchivePatient() {
  const unarchivePatientFn = async patient_id => {
    const response = await apiClient.post(`patients/unarchive/${patient_id}`);
    return response;
  };

  return useEdit(
    unarchivePatientFn,
    patientsQueryKeys.all,
    'Error while unarchiving patient',
    'Patient unarchived successfully'
  );
}
