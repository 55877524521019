export const patientsQueryKeys = {
  all: ['patients'],
  details: () => [...patientsQueryKeys.all, 'detail'],
  detail: id => [...patientsQueryKeys.details(), id],
  pagination: page => [...patientsQueryKeys.all, 'pagination', page],
  infinite: () => [...patientsQueryKeys.all, 'infinite'],
  permissions: (patient_id, user_uuid) => [
    ...patientsQueryKeys.all,
    'permissions',
    patient_id,
    user_uuid,
  ],
};
