import React from 'react';
import { Patient } from '../../Sections/PatientsList/patientsList.section.custom';

interface SelectedPatientInfoProps {
  isModalOpen: boolean;
  closeModal: any;
  selectedPatient: Patient;
}

function SelectedPatientInfo({
  isModalOpen,
  closeModal,
  selectedPatient,
}: SelectedPatientInfoProps) {
  return (
    <div className={`fixed inset-0 z-10 overflow-y-auto ${isModalOpen ? 'block' : 'hidden'}`}>
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={closeModal}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="mt-3 text-center sm:mt-5">
              <div className="mt-2">
                <dl className="grid grid-cols-2 gap-2">
                  {Object.entries(selectedPatient).map(([key, value]) => (
                    <div
                      key={key}
                      className="py-2"
                    >
                      <dt className="text-sm font-medium text-gray-500">{key}</dt>
                      <dd className="text-lg">{value}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-gray-300 bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mr-2 sm:w-auto sm:text-sm"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectedPatientInfo;
