import React, { useMemo } from 'react';
import { LuUpload } from 'react-icons/lu';
import { MdDelete } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router';
import { DicomDataStructure, DicomUploadStatus, StudySerieUploadInfo } from '../../interfaces';

interface FooterProps {
  handleFileSelectMore: any;
  renderUploadInputMore: any;
  handleCancel: any;
  handleOk: any;
  setDicomDataStructure: any;
  setProcessedSOPInstanceUIDs: any;
  len: number;
  setStudySerieUploadInfo?: React.Dispatch<React.SetStateAction<StudySerieUploadInfo[]>>;
  dicomDataStructure?: DicomDataStructure;
}
function Footer({
  handleFileSelectMore,
  renderUploadInputMore,
  handleCancel,
  handleOk,
  setDicomDataStructure,
  setProcessedSOPInstanceUIDs,
  setStudySerieUploadInfo,
  len,
  dicomDataStructure,
}: FooterProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const areAllUploadsSuccessful = useMemo(() => {
    if (!dicomDataStructure || dicomDataStructure.length === 0) {
      return false;
    }

    return dicomDataStructure.every(
      study =>
        study.upload_status === DicomUploadStatus.Successful &&
        study.series.every(
          series =>
            series.upload_status === DicomUploadStatus.Successful &&
            series.instances.every(
              instance => instance.upload_status === DicomUploadStatus.Successful
            )
        )
    );
  }, [dicomDataStructure]);

  return (
    <div className="flex justify-between">
      <div className="relative mb-2 flex h-8 space-x-2">
        <button
          disabled={len === 0}
          className={`${len === 0 ? 'cursor-not-allowed opacity-50' : ' cursor-pointer text-white opacity-100 hover:bg-red-700 hover:text-white'} inline-flex items-center justify-center space-x-2 rounded-md border border-red-700
                 px-2 py-1  text-red-700 transition duration-150 ease-in-out `}
          onClick={() => {
            setDicomDataStructure([]);
            setProcessedSOPInstanceUIDs(new Set<string>());
            setStudySerieUploadInfo([]);
          }}
        >
          <MdDelete size={14} />
          <div>Clear All</div>
        </button>
        <div
          className={`${
            len === 0
              ? 'cursor-not-allowed opacity-50'
              : ' cursor-pointer text-white opacity-100 hover:bg-[#005dd4] hover:text-white'
          } inline-flex items-center  justify-center space-x-2 rounded-md border border-[#005dd4]
                 py-1 pr-2  text-[#005dd4] transition duration-150 ease-in-out `}
          onClick={len === 0 ? null : handleFileSelectMore}
        >
          {renderUploadInputMore()}
          <LuUpload size={14} />
          <div>Upload</div>
        </div>
      </div>
      <div className="flex items-center justify-center">Total Files : {len}</div>

      {areAllUploadsSuccessful && location.pathname !== '/exams' ? (
        <div>
          <button
            disabled={!areAllUploadsSuccessful}
            className={`${
              areAllUploadsSuccessful
                ? 'cursor-pointer text-white opacity-100'
                : 'cursor-not-allowed opacity-50'
            }
                            rounded-lg bg-[#005dd4] px-3 py-1.5 transition duration-150 ease-in-out hover:bg-blue-600
                            disabled:bg-gray-300 disabled:hover:bg-gray-300`}
            onClick={() => {
              navigate(`/exams`, {
                replace: true,
              });
            }}
          >
            Go to exam list
          </button>
        </div>
      ) : (
        <div className="space-x-3">
          <button
            className={`rounded-lg border px-3 py-1 hover:border-[#005dd4] hover:text-[#005dd4]`}
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            disabled={len === 0}
            className={`${len === 0 ? 'cursor-not-allowed opacity-50' : ' cursor-pointer text-white opacity-100'}
                    rounded-lg bg-[#005dd4] px-3 py-1 transition duration-150 ease-in-out hover:bg-blue-600
                    disabled:bg-gray-300 disabled:hover:bg-gray-300`}
            onClick={handleOk}
          >
            OK
          </button>
        </div>
      )}
    </div>
  );
}

export default Footer;

// import React, { useMemo } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { MdDelete } from 'react-icons/md';
// import { LuUpload } from 'react-icons/lu';
// import { DicomDataStructure, DicomUploadStatus, StudySerieUploadInfo } from '../../interfaces';

// interface FooterProps {
//     handleFileSelectMore: any;
//     renderUploadInputMore: any;
//     handleCancel: any;
//     handleOk: any;
//     setDicomDataStructure: React.Dispatch<React.SetStateAction<DicomDataStructure>>;
//     setProcessedSOPInstanceUIDs: React.Dispatch<React.SetStateAction<Set<string>>>;
//     len: number;
//     setStudySerieUploadInfo?: React.Dispatch<React.SetStateAction<StudySerieUploadInfo[]>>;
//     dicomDataStructure?: DicomDataStructure;
// }

// function Footer({
//     handleFileSelectMore,
//     renderUploadInputMore,
//     handleCancel,
//     handleOk,
//     setDicomDataStructure,
//     setProcessedSOPInstanceUIDs,
//     setStudySerieUploadInfo,
//     len,
//     dicomDataStructure
// }: FooterProps)  {
//     const navigate = useNavigate();
//     const location = useLocation();

//     const areAllUploadsSuccessful = useMemo(() => {
//         if (!dicomDataStructure || dicomDataStructure.length === 0) return false;

//         return dicomDataStructure.every(study =>
//             study.upload_status === DicomUploadStatus.Successful &&
//             study.series.every(series =>
//                 series.upload_status === DicomUploadStatus.Successful &&
//                 series.instances.every(instance =>
//                     instance.upload_status === DicomUploadStatus.Successful
//                 )
//             )
//         );
//     }, [dicomDataStructure]);

//     return (
//         <div className="flex justify-between">
//             <div className="relative mb-2 flex h-8 space-x-2">
//                 <button
//                     disabled={len === 0}
//                     className={`${len === 0 ? 'cursor-not-allowed opacity-50' : ' cursor-pointer text-white opacity-100 hover:bg-red-700 hover:text-white'} inline-flex items-center justify-center space-x-2 rounded-md border border-red-700
//                  px-2 py-1  text-red-700 transition duration-150 ease-in-out `}
//                     onClick={() => {
//                         setDicomDataStructure([]);
//                         setProcessedSOPInstanceUIDs(new Set<string>());
//                         setStudySerieUploadInfo([]);
//                     }}
//                 >
//                     <MdDelete size={14} />
//                     <div>Clear All</div>
//                 </button>
//                 <div
//                     className={`${len === 0
//                             ? 'cursor-not-allowed opacity-50'
//                             : ' cursor-pointer text-white opacity-100 hover:bg-[#005dd4] hover:text-white'
//                         } inline-flex items-center  justify-center space-x-2 rounded-md border border-[#005dd4]
//                  pr-2 py-1  text-[#005dd4] transition duration-150 ease-in-out `}
//                     onClick={len === 0 ? null : handleFileSelectMore}
//                 >
//                     {renderUploadInputMore()}
//                     <LuUpload size={14} />
//                     <div>Upload</div>
//                 </div>
//             </div>
//             <div className="flex items-center justify-center">
//                 Total Files : {len}
//             </div>

//             <div className="space-x-3">
//                 {areAllUploadsSuccessful && (
//                     <button
//                         className={`rounded-lg bg-[#005dd4] px-3 py-1.5 text-white transition duration-150 ease-in-out hover:bg-blue-600`}
//                         onClick={() => {
//                             navigate(`/exams`, {
//                                 replace: true,
//                             });
//                         }}
//                     >
//                         Go to exam list
//                     </button>
//                 )}
//                 <button
//                     className={`rounded-lg border px-3 py-1 hover:border-[#005dd4] hover:text-[#005dd4]`}
//                     onClick={handleCancel}
//                 >
//                     Cancel
//                 </button>
//                 <button
//                     disabled={len === 0}
//                     className={`${len === 0 ? 'cursor-not-allowed opacity-50' : ' cursor-pointer text-white opacity-100'}
//                 rounded-lg bg-[#005dd4] px-3 py-1 transition duration-150 ease-in-out hover:bg-blue-600
//                  disabled:bg-gray-300 disabled:hover:bg-gray-300`}
//                     onClick={handleOk}
//                 >
//                     OK
//                 </button>
//             </div>
//         </div>
//     );
// }

// export default Footer;
