import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker } from 'antd';
const { differenceInDays, addMonths } = require('date-fns');
const moment = require('moment');
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

const GeneralInfo: React.FC = props => {
  function calculateGestationalAge(lmpDate) {
    try {
      const lmp = new Date(lmpDate);

      let gestationalAge;

      gestationalAge = differenceInDays(Date.now(), lmp);

      // return format(edd, 'MMMM dd, yyyy');
      if (gestationalAge < 0) {
        return 'Error';
      } else {
        const rest = gestationalAge % 7;
        gestationalAge = gestationalAge / 7;

        return `${Math.floor(gestationalAge)}w${rest}d`;
      }
    } catch (error) {
      return 'Error';
    }
  }

  const timestamp = Date.now();
  const currentDate = new Date(timestamp);
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}/${month}/${day}`;

  const [LMP, SetLMP] = useState(formattedDate);
  const [GA, SetGA] = useState('23');

  useEffect(() => {
    const edd = calculateGestationalAge(LMP);

    SetGA(edd);
  }, [LMP]);

  return (
    <div
      style={{ height: '25%' }}
      className="grid grid-cols-8 grid-rows-6 border-b-2  border-black px-2 text-sm"
    >
      <div className=" col-span-8 row-span-1">
        <h2 className="text-lg font-bold underline">General Information</h2>
      </div>

      <div className=" col-span-4 row-span-1 flex-initial justify-between">
        <span className="font-bold">DOB:</span>

        <span>12/12/2001</span>
      </div>
      <div className=" col-span-4 row-span-1  ">
        <span className="font-bold">Age:</span>

        <span>23</span>
      </div>
      <div className=" col-span-4 row-span-1  ">
        <span className="font-bold">LMP:</span>

        <>
          <DatePicker
            size="small"
            defaultValue={dayjs(formattedDate, dateFormat)}
            format={dateFormat}
            onChange={e => {
              SetLMP(e.$d);
            }}
          />
        </>
      </div>
      <div className=" col-span-4 row-span-1  ">
        <span className="font-bold">GA from LMP:</span>

        <span>{GA}</span>
      </div>
      <div className=" col-span-4 row-span-1  ">
        <span className="font-bold">Exam:</span>

        <span>Secont Trimester</span>
      </div>
      <div className=" col-span-4 row-span-1  ">
        <span className="font-bold">Medical status:</span>

        <span>Followed Patient</span>
      </div>
      <div className=" col-span-8 row-span-1  ">
        <span className="font-bold">Reference hospital or Practioner</span> <span>MFM Clinic</span>
      </div>
    </div>
  );
};

export default GeneralInfo;
