import { all, fork } from 'redux-saga/effects';

import studySaga from './study/saga';
import reportSaga from './report/saga';
import ProfileSaga from './profile/saga';
import SR from './sr/saga';
import PatientSaga from './patient/saga';

export default function* rootSaga() {
  yield all([fork(studySaga), fork(reportSaga), fork(ProfileSaga), fork(SR), fork(PatientSaga)]);
}
