import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { Icon, Tooltip, Typography } from '../';

const ThumbnailNoImage = ({
  displaySetInstanceUID,
  description,
  seriesDate,
  seriesNumber,
  serieInstanceUID,
  modality,
  modalityTooltip,
  onClick,
  onDoubleClick,
  canReject,
  onReject,
  dragData,
  isActive,
  index,
}) => {
  const [collectedProps, drag, dragPreview] = useDrag({
    type: 'displayset',
    item: { ...dragData },
    canDrag: function (monitor) {
      return Object.keys(dragData).length !== 0;
    },
  });

  return (
    <div
      className={classnames('cursor-pointer')}
      id={`thumbnail-${displaySetInstanceUID}`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      role="button"
      tabIndex="0"
      data-cy={`study-browser-thumbnail-no-image`}
    >
      <div ref={drag}>
        <div className="flex flex-1 flex-col">
          <div className="mb-2 flex flex-1 flex-row items-center">
            <div
              className={`rounded-md border border-[#21426D] px-2 text-sm hover:bg-[#21426d] hover:text-white ${isActive ? 'bg-[#21426d] text-white' : 'bg-white text-[#21426d]'}`}
            >
              {index}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ThumbnailNoImage.propTypes = {
  displaySetInstanceUID: PropTypes.string.isRequired,
  /**
   * Data the thumbnail should expose to a receiving drop target. Use a matching
   * `dragData.type` to identify which targets can receive this draggable item.
   * If this is not set, drag-n-drop will be disabled for this thumbnail.
   *
   * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
   */
  dragData: PropTypes.shape({
    /** Must match the "type" a dropTarget expects */
    type: PropTypes.string.isRequired,
  }),
  description: PropTypes.string.isRequired,
  modality: PropTypes.string.isRequired,
  /* Tooltip message to display when modality text is hovered */
  modalityTooltip: PropTypes.string.isRequired,
  seriesDate: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default ThumbnailNoImage;
