var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
        return t;
      };
    return __assign.apply(this, arguments);
  };
var __rest =
  (this && this.__rest) ||
  function (s, e) {
    var t = {};
    for (var p in s)
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === 'function')
      for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
          t[p[i]] = s[p[i]];
      }
    return t;
  };
import React from 'react';
import {
  disabledButtonClassnames,
  buttonIntentStyleClassnamesMap,
  sizeClassnamesMap,
  sizeOnlyIconClassnamesMap,
  shapeClassnamesMap,
} from './constants';
import cn from 'classnames';
import { Spinner } from '../spinner';
export var Button = function (_a) {
  var type = _a.type,
    _b = _a.intent,
    intent = _b === void 0 ? 'primary' : _b,
    _c = _a.style,
    style = _c === void 0 ? 'solid' : _c,
    _d = _a.size,
    size = _d === void 0 ? 'medium' : _d,
    _e = _a.shape,
    shape = _e === void 0 ? 'rounded' : _e,
    _f = _a.isFullWidth,
    isFullWidth = _f === void 0 ? false : _f,
    _g = _a.isDisabled,
    isDisabled = _g === void 0 ? false : _g,
    _h = _a.isLoading,
    isLoading = _h === void 0 ? false : _h,
    leftIcon = _a.leftIcon,
    rightIcon = _a.rightIcon,
    _j = _a.href,
    href = _j === void 0 ? '' : _j,
    children = _a.children,
    _k = _a.onClick,
    onClick = _k === void 0 ? function (_) {} : _k,
    props = __rest(_a, [
      'type',
      'intent',
      'style',
      'size',
      'shape',
      'isFullWidth',
      'isDisabled',
      'isLoading',
      'leftIcon',
      'rightIcon',
      'href',
      'children',
      'onClick',
    ]);
  var spinnerColor = style === 'solid' && intent !== 'secondary' ? 'white' : 'inherit';
  var Element = href ? 'a' : 'button';
  return React.createElement(
    Element,
    __assign(
      {
        type: type,
        href: !isDisabled ? href : undefined,
        onClick: function (e) {
          e.persist();
          if (!isDisabled && !isLoading) {
            onClick(e);
          }
        },
        disabled: isDisabled,
      },
      props,
      {
        className: cn(
          'relative font-medium inline-flex items-center justify-center outline-none focus:outline-none transition-color duration-200',
          isFullWidth && 'w-full',
          children ? sizeClassnamesMap[size] : sizeOnlyIconClassnamesMap[size],
          shapeClassnamesMap[shape],
          isDisabled ? disabledButtonClassnames : buttonIntentStyleClassnamesMap[intent][style],
          (props === null || props === void 0 ? void 0 : props.className) &&
            (props === null || props === void 0 ? void 0 : props.className)
        ),
      }
    ),
    isLoading &&
      React.createElement(
        'span',
        { className: 'absolute inset-0 flex items-center justify-center' },
        React.createElement(Spinner, { color: spinnerColor, size: size })
      ),
    React.createElement(
      'span',
      { className: cn('flex items-center justify-content', isLoading && 'opacity-0') },
      leftIcon && React.createElement('span', { className: children ? 'mr-3' : '' }, leftIcon),
      children,
      rightIcon && React.createElement('span', { className: children ? 'ml-3' : '' }, rightIcon)
    )
  );
};
export default Button;
