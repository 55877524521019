import React from 'react';
import cn from 'classnames';
import { HiX } from 'react-icons/hi';

interface ModalPropsI {
  isOpen: boolean;
  onClose: () => any;
  children?: React.ReactNode;
  size?: 'small' | 'medium' | 'large' | 'bodySize' | 'extraLarge' | 'fullScreen';
  className?: string;
  overlayClassName?: string;
  closeFromOutside?: boolean;
  canClose?: boolean; // New prop to control if the modal can be closed
}

const sizeClassNameMap = {
  small: 'w-[481px] h-[479px] mx-auto',
  medium: 'w-[851px] h-[560px] mx-auto',
  large: 'w-[1024px] mx-auto',
  extraLarge: 'w-[1536px] mx-auto',
  bodySize: 'w-[76rem] mx-auto',
  // fullScreen: "w-full mx-12 h-fit mt-[356rem] xl:mt-[166rem]",
  fullScreen: 'w-fit mx-12 h-fit',
};

export default function Modal({
  isOpen = false,
  onClose = () => {},
  children,
  size = 'large',
  className = '',
  overlayClassName = '',
  closeFromOutside = true,
  canClose = true, // Default value is true, allowing closure unless specified otherwise
}: ModalPropsI) {
  if (!isOpen) {
    return null;
  } // Modal is not open, do not render

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (closeFromOutside && e.target === e.currentTarget && canClose) {
      onClose(); // Close modal when clicking outside, only if allowed by `canClose`
    }
  };

  return (
    <div
      className={cn(
        `custom-scrollbar absolute z-40 h-full w-full overflow-y-auto bg-white  bg-opacity-25 backdrop-blur ${size == 'fullScreen' ? '' : 'flex items-center justify-center xl:overflow-hidden'}`,
        overlayClassName
      )}
      onClick={handleOutsideClick} // Handle click outside modal content
    >
      <div
        className={cn(
          className,
          'rounded-md border border-[#F2F8FF] bg-[#F2F8FF] shadow-xl',
          sizeClassNameMap[size]
        )}
      >
        {children}
      </div>
    </div>
  );
}

Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;

function ModalHeader({
  title,
  onClose = () => {},
  displayClose = false,
  canClose = true, // Allow closure unless specified otherwise
}) {
  const handleClose = () => {
    if (canClose) {
      onClose();
    } // Only close if `canClose` is true
  };

  return (
    <div className="flex items-center justify-between border-b border-gray-100 bg-gray-100 bg-opacity-20 px-5 py-3">
      <h4 className="line-clamp-1 flex w-full items-center text-lg font-medium text-[#1F384C]">
        {title}
      </h4>
      {displayClose && (
        <button
          className="text-gray-400 hover:text-gray-600 focus:outline-none"
          onClick={handleClose} // Apply the condition before closing
        >
          <div className="ml-auto inline-flex transform items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 duration-500 hover:bg-gray-200 hover:text-gray-900">
            <HiX size={18} />
          </div>
        </button>
      )}
    </div>
  );
}

function ModalContent({ children }) {
  return <div className="h-full w-full">{children}</div>;
}

function ModalFooter({ children }) {
  return <div className="border-t border-gray-100 bg-[#F2F8FF] px-5 py-3">{children}</div>;
}
