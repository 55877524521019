import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { NavBar, Svg, Icon, IconButton, Dropdown } from '../';

function Header({
  children,
  menuOptions,
  isReturnEnabled,
  onClickReturnButton,
  isSticky,
  WhiteLabeling,
  ...props
}): ReactNode {
  const { t } = useTranslation('Header');

  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };
  return (
    // <NavBar
    //   className="border-b-1 justify-between border-[#f7fafe] shadow-md"
    //   isSticky={isSticky}
    // >
    //   <div className="flex flex-1 justify-between">
    //     <div className="flex items-center">
    //       {/* <div
    //         className={classNames(
    //           'inline-flex items-center mr-3',
    //           isReturnEnabled && 'cursor-pointer'
    //         )}
    //         onClick={onClickReturn}
    //       >
    //         {isReturnEnabled && (
    //           <Icon name="chevron-left" className="w-8 text-primary-active" />
    //         )}

    //       </div> */}
    //     </div>
    //     <div className="flex items-center">{children}</div>
    //     <div className="flex items-center">
    //       {/* <span className="mr-3 text-lg text-common-light">
    //         {t('INVESTIGATIONAL USE ONLY')}
    //       </span> */}
    //       <Dropdown
    //         id="options"
    //         showDropdownIcon={false}
    //         list={menuOptions}
    //       >
    //         <IconButton
    //           id={'options-settings-icon'}
    //           variant="text"
    //           color="inherit"
    //           size="initial"
    //           className="text-[#2d84c7]"
    //         >
    //           <Icon name="settings" />
    //         </IconButton>
    //         <IconButton
    //           id={'options-chevron-down-icon'}
    //           variant="text"
    //           color="inherit"
    //           size="initial"
    //           className="text-[#2d84c7]"
    //         >
    //           <Icon name="chevron-down" />
    //         </IconButton>
    //       </Dropdown>
    //     </div>
    //   </div>
    // </NavBar>

    <div className="z-40 m-2 flex w-full justify-center rounded-md bg-white py-2 drop-shadow-lg">
      <div className="flex items-center">{children}</div>
    </div>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
};

Header.defaultProps = {
  isReturnEnabled: true,
  isSticky: false,
};

export default Header;
