const { UPDATE_SR_REPORT, CLEAN_SR_REPORT } = require('./actionType');

export const update_sr_report = data => {
  console.log(data);
  return {
    type: UPDATE_SR_REPORT,
    payload: data,
  };
};

export const clean_sr_report = payload => {
  return {
    type: CLEAN_SR_REPORT,
    payload,
  };
};
