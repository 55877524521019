import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import Icon from '../Icon';

const ContextMenu = ({ items, ...props }) => {
  if (!items) {
    console.warn('No items for context menu');
    return null;
  }
  return (
    <div
      data-cy="context-menu"
      className="relative z-50 block w-48 rounded bg-white"
      onContextMenu={e => e.preventDefault()}
    >
      {items.map((item, index) => (
        <div
          key={index}
          data-cy="context-menu-item"
          onClick={() => item.action(item, props)}
          style={{ justifyContent: 'space-between' }}
          className="flex cursor-pointer items-center border-b border-blue-200 px-4 py-3 text-blue-500 transition duration-300 last:border-b-0 hover:bg-blue-300"
        >
          <Typography color="blue">{item.label}</Typography>
          {item.iconRight && (
            <Icon
              name={item.iconRight}
              className="inline"
            />
          )}
        </div>
      ))}
    </div>
  );
};

ContextMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default ContextMenu;
