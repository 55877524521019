import React from 'react';
import { FaEye } from 'react-icons/fa6';
import femurImgS from '../../../../assets/femur.png';
import abdoImgS from '../../../../../../assets/abdoicon.png';
import afImgS from '../../../../assets/AF-pocket.png';
import faceImgS from '../../../../assets/brain.png';

function DetectedPlans({ globalState, handleFilterClick, setPlanSelected, setIsOpen }) {
  const staticPlanKeys = ['Cephalic', 'Femur', 'Abdominal'];
  const imageMap = {
    Cephalic: faceImgS,
    Femur: femurImgS,
    Abdominal: abdoImgS,
    'AF Pocket': afImgS,
    // 'Plan A': faceImgS,
    // 'Plan B': femurImgS,
    // 'Plan C': abdoImgS,
    // 'Plan D': afImgS,
    // 'Plan E': faceImgS,
    // 'Plan F': abdoImgS,
    // 'Plan G': femurImgS,
    // 'Plan H': afImgS,
    // 'Plan I': faceImgS,
    // 'Plan J': femurImgS,
    // 'Plan K': abdoImgS,
    // 'Plan L': afImgS,
    // 'Plan M': faceImgS,
    // 'Plan N': femurImgS,
    // 'Plan O': abdoImgS,
    // 'Plan P': afImgS,
    // 'Plan Q': faceImgS,
    // 'Plan R': femurImgS,
  };
  const generateDynamicPlanKeys = () => {
    const dynamicPlanKeys = [];
    const totalDynamicPlans = 18; // Plan A to Plan R
    for (let i = 0; i < totalDynamicPlans; i++) {
      const planKey = `Plan ${String.fromCharCode(65 + i)}`;
      dynamicPlanKeys.push(planKey);
    }
    return dynamicPlanKeys;
  };
  // const items = [...staticPlanKeys, ...generateDynamicPlanKeys()];
  const items = [...staticPlanKeys];

  return (
    <div className="custom-scrollbar max-h-44 overflow-y-auto">
      {items.map((planKey, index) => {
        const plan = planKey.toLowerCase();
        const scoreOrigin = globalState.planes[plan]?.score || 0;
        const imgSrc = imageMap[planKey] || null;

        return (
          <React.Fragment key={planKey}>
            <div
              className={`${
                globalState?.selectedFilter === planKey
                  ? 'border-[#2886c7]'
                  : 'border-[#2885c700] hover:border-[#a8d1f1]'
              } mb-5 flex w-full cursor-pointer items-center justify-between rounded-md border-2 p-1`}
              onClick={() => handleFilterClick(planKey)}
            >
              <div className="inline-flex items-center space-x-1">
                <img
                  src={imgSrc}
                  className="h-4 w-4 xl:h-4 xl:w-4"
                />
                <label
                  htmlFor={`${planKey}-checkbox`}
                  className="ms-2 cursor-pointer text-sm font-medium text-[#1F384C] xl:text-sm"
                >
                  {planKey}
                </label>
              </div>

              <div className="inline-flex items-center justify-center space-x-1">
                <span className="w-[32.53px] text-center text-xs">
                  {scoreOrigin >= 0 ? `${scoreOrigin.toFixed(1)} %` : '0 %'}
                </span>
                <span
                  className={`${
                    scoreOrigin > 0 ? 'bg-[#32CD32]' : 'bg-[#FF4747] bg-opacity-40'
                  } rounded-full  px-3 pb-[1px] text-xs text-white`}
                  title={scoreOrigin > 0 ? 'Detected' : 'Missing'}
                >
                  {scoreOrigin > 0 ? 'D' : 'M'}
                </span>

                {scoreOrigin > 0 ? (
                  <FaEye
                    size={15}
                    className="text-[#A6ABC8] opacity-100"
                    onClick={() => {
                      if (globalState.frames?.length > 0) {
                        setPlanSelected(planKey.toLowerCase());
                        setIsOpen(true);
                      }
                    }}
                  />
                ) : (
                  <FaEye
                    size={15}
                    className="text-[#A6ABC8] opacity-30"
                  />
                )}
              </div>
            </div>
            {planKey === 'Abdominal' && globalState?.planes?.af_pocket && (
              <div className="mb-5 flex w-full items-center justify-between rounded-md p-1">
                <div className="inline-flex items-center space-x-1">
                  <img
                    src={afImgS}
                    className="h-4 w-4 xl:h-4 xl:w-4"
                  />
                  <span className="ms-2 text-sm font-medium text-[#1F384C] xl:text-sm">
                    AF Pocket
                  </span>
                </div>

                <div className="inline-flex items-center justify-center space-x-1">
                  <span className="w-[32.53px] text-center text-xs">
                    {globalState.planes.af_pocket.score >= 0
                      ? `${globalState.planes.af_pocket.score.toFixed(1)} %`
                      : '0 %'}
                  </span>
                  <span
                    className={`${globalState.planes.af_pocket.score > 0 ? 'bg-[#32CD32]' : 'bg-[#FF4747] bg-opacity-40'} rounded-full px-3 pb-[1px] text-xs text-white`}
                    title={globalState.planes.af_pocket.score > 0 ? 'Detected' : 'Missing'}
                  >
                    {globalState.planes.af_pocket.score > 0 ? 'D' : 'M'}
                  </span>

                  {globalState.planes.af_pocket.score > 0 ? (
                    <FaEye
                      size={15}
                      className="text-[#A6ABC8] opacity-100"
                      onClick={() => {
                        if (globalState.frames?.length > 0) {
                          setPlanSelected('af_pocket');
                          setIsOpen(true);
                        }
                      }}
                    />
                  ) : (
                    <FaEye
                      size={15}
                      className="text-[#A6ABC8] opacity-30"
                    />
                  )}
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default DetectedPlans;
