import { Modal, Switch, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import './framesSlider.css';

import { APIClient } from '../../../helpers/apiHelper';
import axios from 'axios';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import checkImage from './assets/check.jpg';
import unCheckImage from './assets/uncheck.jpg';

const api = new APIClient();

const FramesSlider: React.FC = props => {
  const {
    report,
    handleCancel,
    selectedFrame,
    setSelectedFrame,
    setSelectedFrameID,
    resetCriteria2,
    examData,
    setExamData,
    editMode,
    qualityCriterias,
    selectedFrameID,
    onChangeSwitch_cri1,
    plan,
  } = props;

  const backend_url = process.env.BACKEND_URL;
  const [imageData, setImageData] = useState(null);
  const [current, setCurrent] = useState(0);
  // const [selectedFrameWado, setSelectedFrameWado] = useState('');

  const frames = report?.planes[plan]?.frames;
  const length = frames?.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? length - 1 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? 0 : current - 1);
  };

  if (!Array.isArray(frames) || frames.length <= 0) {
    return null;
  }

  const fetchImage = imageURL => {
    try {
      axios
        .get(imageURL, {
          responseType: 'blob',
        })
        .then(response => {
          const blob = new Blob([response], {
            type: 'image/jpeg',
          });
          const objectURL = URL.createObjectURL(blob);
          console.log(typeof response);

          setImageData(objectURL);
        })
        .catch(error => {
          console.error(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // fetchImage(
    //   selectedFrameWado
    //     ? selectedFrameWado
    //     : `${backend_url}/api/reports/getFrame?studyId=${report.study_id}&seriesId=${report.series_id}&objectId=${report.planes[plan].frames[0].frameId}`
    // );
    fetchImage(
      `${backend_url}/api/reports/getFrame?studyId=${report.study_id}&seriesId=${report.series_id}&objectId=${report.planes[plan].frames[current]?.frameId}`
    );
  }, [current]);

  return (
    <>
      <div className="mb-4 grid grid-cols-3 ">
        <span className="col-span-1 pl-2 text-left text-sm">
          <span className="font-bold">Detected plane</span>:{report?.planes[plan]?.key}
        </span>
        <span className="col-span-1 text-center text-sm">
          {current + 1}/{length} Frame
        </span>
        <span className="col-span-1 pr-2 text-right text-sm">
          <span className="font-bold">Quality score</span>:
          {!report?.planes[plan]?.frames[current]?.tags['QUAL_VAL__SCORE']
            ? 'N/A'
            : (
                Number(report?.planes[plan]?.frames[current]?.tags['QUAL_VAL__SCORE']) * 100
              ).toFixed(2) + '%'}
        </span>
      </div>
      <section className="slider">
        {report?.planes[plan]?.frames?.map((frame, index) => {
          // const wadoFrame = `${dcm_url}/dcm4chee-arc/aets/DCM4CHEE/wado?requestType=WADO&studyUID=${report.study_id}&seriesUID=${report.series_id}&objectUID=${frame.frameId}&contentType=image/jpeg&frameNumber=1`;
          const wadoFrame = `${backend_url}/api/reports/getFrame?studyId=${report.study_id}&seriesId=${report.series_id}&objectId=${frame.frameId}`;

          return (
            <div>
              <FaArrowAltCircleLeft
                className="left-arrow bg-gray-400"
                onClick={e => prevSlide()}
              />
              <FaArrowAltCircleRight
                className="right-arrow bg-gray-400"
                onClick={e => nextSlide()}
              />
              <div
                className={index === current ? 'frame active' : 'frame'}
                key={index}
              >
                {index === current && imageData && (
                  <>
                    <img
                      src={imageData}
                      onClick={() => {
                        setSelectedFrame(wadoFrame);
                        setSelectedFrameID(index);
                        handleCancel();
                      }}
                      alt="Test"
                      style={{
                        height: '600px',
                        width: '2000px',
                        border: '1px solid gray',
                        margin: '0 auto',
                        cursor: 'pointer',
                      }}
                      crossOrigin="anonymous"
                    />

                    <div className="mt-4 grid grid-cols-2 grid-rows-3 text-xs">
                      {qualityCriterias.includes('QUAL_FLAG__cephal_thalamus') && (
                        <div className="col-row-2 col-span-1 flex border-b-2 px-1  pl-4 text-left">
                          Plan showing thalami
                          {editMode ? (
                            <Switch
                              className={`my-auto ml-auto bg-red-500`}
                              defaultChecked={qualityCriterias.QUAL_VAL__ZOOM ? true : false}
                              size="small"
                              onChange={checked =>
                                onChangeSwitch_cri1(checked, setExamData, examData)
                              }
                            />
                          ) : (
                            <img
                              src={
                                report?.planes[plan]?.frames[current]?.tags[
                                  'QUAL_FLAG__cephal_thalamus'
                                ] === 'true'
                                  ? checkImage
                                  : unCheckImage
                              }
                              className="my-auto ml-auto h-3"
                            />
                          )}
                        </div>
                      )}
                      {qualityCriterias.includes('STD_PLANE__cephalic') && (
                        <div className="col-row-2 col-span-1 flex border-b-2 px-1  pl-4 text-left">
                          <span>Plan showing cavum septum pellucidi</span>
                          <Tooltip
                            className="my-auto"
                            color="blue"
                            title="Reset the original value"
                          >
                            <span
                              onClick={() => resetCriteria2(setExamData, examData)}
                              className="cursor-pointer text-xs"
                            >
                              🔄
                            </span>
                          </Tooltip>
                          {editMode ? (
                            <Switch
                              className=" my-auto ml-auto bg-red-500"
                              defaultChecked={
                                examData.section2.firstColFirstRow.criterias.criteria2.status ===
                                'success'
                                  ? true
                                  : false
                              }
                              size="small"
                              id=""
                              onChange={checked =>
                                onChangeSwitch_cri1(checked, setExamData, examData)
                              }
                            />
                          ) : (
                            <img
                              src={
                                report?.planes[plan]?.frames[current]?.tags[
                                  'STD_PLANE__cephalic'
                                ] === 'true'
                                  ? checkImage
                                  : unCheckImage
                              }
                              className="my-auto ml-auto h-3"
                            />
                          )}
                        </div>
                      )}

                      {qualityCriterias.includes('STD_PLANE__cephalic') && (
                        <div className="col-row-2 col-span-1 flex border-b-2 px-1  pl-4 text-left">
                          Cerebellum not visible{' '}
                          {editMode ? (
                            <Switch
                              className=" my-auto ml-auto bg-red-500"
                              defaultChecked={
                                examData.section2.firstColFirstRow.criterias.criteria3.status ===
                                'success'
                                  ? true
                                  : false
                              }
                              size="small"
                              id=""
                              onChange={checked =>
                                onChangeSwitch_cri1(checked, setExamData, examData)
                              }
                            />
                          ) : (
                            <img
                              src={
                                report?.planes[plan]?.frames[current]?.tags['QUAL_VAL__SCORE'] ===
                                'true'
                                  ? checkImage
                                  : unCheckImage
                              }
                              className="my-auto ml-auto h-3"
                            />
                          )}
                        </div>
                      )}

                      {qualityCriterias.includes('QUAL_FLAG__abdo_stomach_bubble') && (
                        <div className="col-row-2 col-span-1 flex border-b-2 px-1  pl-4 text-left">
                          abdo_stomach_bubble visible{' '}
                          {editMode ? (
                            <Switch
                              className=" my-auto ml-auto bg-red-500"
                              defaultChecked={
                                examData.section2.firstColFirstRow.criterias.criteria3.status ===
                                'success'
                                  ? true
                                  : false
                              }
                              size="small"
                              id=""
                              onChange={checked =>
                                onChangeSwitch_cri1(checked, setExamData, examData)
                              }
                            />
                          ) : (
                            <img
                              src={
                                report?.planes[plan]?.frames[current]?.tags[
                                  'QUAL_FLAG__abdo_stomach_bubble'
                                ] === 'true'
                                  ? checkImage
                                  : unCheckImage
                              }
                              className="my-auto ml-auto h-3"
                            />
                          )}
                        </div>
                      )}

                      {qualityCriterias.includes('QUAL_FLAG__abdo_portal_sinus') && (
                        <div className="col-row-2 col-span-1 flex border-b-2 px-1  pl-4 text-left">
                          abdo_portal_sinus visible{' '}
                          {editMode ? (
                            <Switch
                              className=" my-auto ml-auto bg-red-500"
                              defaultChecked={
                                examData.section2.firstColFirstRow.criterias.criteria3.status ===
                                'success'
                                  ? true
                                  : false
                              }
                              size="small"
                              id=""
                              onChange={checked =>
                                onChangeSwitch_cri1(checked, setExamData, examData)
                              }
                            />
                          ) : (
                            <img
                              src={
                                report?.planes[plan]?.frames[current]?.tags[
                                  'QUAL_FLAG__abdo_portal_sinus'
                                ] === 'true'
                                  ? checkImage
                                  : unCheckImage
                              }
                              className="my-auto ml-auto h-3"
                            />
                          )}
                        </div>
                      )}

                      {qualityCriterias.includes('STD_PLANE__abdominal') && (
                        <div className="col-row-2 col-span-1 flex border-b-2 px-1  pl-4 text-left">
                          fetal kidney not visible{' '}
                          {editMode ? (
                            <Switch
                              className=" my-auto ml-auto bg-red-500"
                              defaultChecked={
                                examData.section2.firstColFirstRow.criterias.criteria3.status ===
                                'success'
                                  ? true
                                  : false
                              }
                              size="small"
                              id=""
                              onChange={checked =>
                                onChangeSwitch_cri1(checked, setExamData, examData)
                              }
                            />
                          ) : (
                            <img
                              src={
                                report?.planes[plan]?.frames[current]?.tags[
                                  'STD_PLANE__abdominal'
                                ] === 'true'
                                  ? checkImage
                                  : unCheckImage
                              }
                              className="my-auto ml-auto h-3"
                            />
                          )}
                        </div>
                      )}

                      {qualityCriterias.includes('STD_PLANE__af') && (
                        <div className="col-row-2 col-span-1 flex border-b-2 px-1  pl-4 text-left">
                          placanta not visible{' '}
                          {editMode ? (
                            <Switch
                              className=" my-auto ml-auto bg-red-500"
                              defaultChecked={
                                examData.section2.firstColFirstRow.criterias.criteria3.status ===
                                'success'
                                  ? true
                                  : false
                              }
                              size="small"
                              id=""
                              onChange={checked =>
                                onChangeSwitch_cri1(checked, setExamData, examData)
                              }
                            />
                          ) : (
                            <img
                              src={
                                report?.planes[plan]?.frames[current]?.tags['STD_PLANE__af'] ===
                                'true'
                                  ? checkImage
                                  : unCheckImage
                              }
                              className="my-auto ml-auto h-3"
                            />
                          )}
                        </div>
                      )}

                      {qualityCriterias.includes('STD_PLANE__af') && (
                        <div className="col-row-2 col-span-1 flex border-b-2 px-1  pl-4 text-left">
                          umbilical not visible{' '}
                          {editMode ? (
                            <Switch
                              className=" my-auto ml-auto bg-red-500"
                              defaultChecked={
                                examData.section2.firstColFirstRow.criterias.criteria3.status ===
                                'success'
                                  ? true
                                  : false
                              }
                              size="small"
                              id=""
                              onChange={checked =>
                                onChangeSwitch_cri1(checked, setExamData, examData)
                              }
                            />
                          ) : (
                            <img
                              src={
                                report?.planes[plan]?.frames[current]?.tags['STD_PLANE__af'] ===
                                'true'
                                  ? checkImage
                                  : unCheckImage
                              }
                              className="my-auto ml-auto h-3"
                            />
                          )}
                        </div>
                      )}

                      {qualityCriterias.includes('QUAL_VAL__FEMURAL_ANGLE') && (
                        <div className="col-row-2 col-span-1 flex border-b-2 px-1  pl-4 text-left">
                          less than 45 to horizontal{' '}
                          {editMode ? (
                            <Switch
                              className=" my-auto ml-auto bg-red-500"
                              defaultChecked={
                                examData.section2.firstColFirstRow.criterias.criteria3.status ===
                                'success'
                                  ? true
                                  : false
                              }
                              size="small"
                              id=""
                              onChange={checked =>
                                onChangeSwitch_cri1(checked, setExamData, examData)
                              }
                            />
                          ) : (
                            <img
                              src={
                                report?.planes[plan]?.frames[current]?.tags[
                                  'QUAL_VAL__FEMURAL_ANGLE'
                                ] === 'true'
                                  ? checkImage
                                  : unCheckImage
                              }
                              className="my-auto ml-auto h-3"
                            />
                          )}
                        </div>
                      )}

                      {qualityCriterias.includes('STD_PLANE__femur') && (
                        <div className="col-row-2 col-span-1 flex border-b-2 px-1  pl-4 text-left">
                          fetal spine not showing{' '}
                          {editMode ? (
                            <Switch
                              className=" my-auto ml-auto bg-red-500"
                              defaultChecked={
                                examData.section2.firstColFirstRow.criterias.criteria3.status ===
                                'success'
                                  ? true
                                  : false
                              }
                              size="small"
                              id=""
                              onChange={checked =>
                                onChangeSwitch_cri1(checked, setExamData, examData)
                              }
                            />
                          ) : (
                            <img
                              src={
                                report?.planes[plan]?.frames[current]?.tags['STD_PLANE__femur'] ===
                                'true'
                                  ? checkImage
                                  : unCheckImage
                              }
                              className="my-auto ml-auto h-3"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
};

export default FramesSlider;
