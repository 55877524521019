import React from 'react';

type ProgressBarProps = {
  approvedPlans: number;
  totalPlans: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ approvedPlans, totalPlans }) => {
  const percentage = (approvedPlans / totalPlans) * 100;

  return (
    <div className="w-1/6">
      <div className="mb-1 text-xs font-medium text-gray-600">Approved plans</div>
      <div className="h-1 w-full rounded-full bg-gray-200">
        <div
          className="h-1 rounded-full bg-blue-500"
          style={{ width: `${percentage}%` }}
        />
      </div>
      <div className="mt-1 text-xs text-gray-500">
        {approvedPlans} / {totalPlans}
      </div>
    </div>
  );
};

export default ProgressBar;
