import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import {
  getStudies as getStudiesApi,
  addStudy as addStudyApi,
  predictStudy as predictStudyApi,
  // getReport as getReportApi,
  getStudy as getStudyApi,
  // deleteReport as deleteReportApi,
  deleteStudy as deleteStudyApi,
  archiveStudyById,
  unarchiveStudyById,
} from '../../helpers/backendHelper';
import { studyApiError, studyApiSuccess } from './action';
import {
  ADD_STUDY,
  // DELETE_REPORT,
  DELETE_STUDY,
  // GET_REPORT,
  GET_STUDIES,
  GET_STUDY,
  PREDICT,
  RESET_STUDY_STATE,
  ARCHIVE_STUDY,
  UNARCHIVE_STUDY,
} from './actionType';

function* getStudies({ payload: { data } }) {
  console.log(data);
  try {
    const response = yield call(getStudiesApi, data.filter_options);
    yield put(studyApiSuccess(GET_STUDIES, response));
  } catch (error) {
    yield put(studyApiError(GET_STUDIES, error));
  }
}

function* predictStudy(payload) {
  try {
    const response = yield call(predictStudyApi, payload);
    yield put(studyApiSuccess(PREDICT, response));
  } catch (error) {
    yield put(studyApiError(PREDICT, error));
  }
}

function* addStudy({ payload: data }) {
  try {
    // ask abdou about this
    const response = yield call(addStudyApi, data.organization_id, data.study);
    yield put(studyApiSuccess(ADD_STUDY, response));
  } catch (error) {
    yield put(studyApiError(ADD_STUDY, error));
  }
}

// function* deleteReport({ payload: studyId }) {
//   try {
//     const response = yield call(deleteReportApi, studyId);
//     console.log(response);
//     yield put(studyApiSuccess(DELETE_REPORT, response));
//   } catch (error) {
//     console.log(error);
//     yield put(studyApiError(DELETE_REPORT, error));
//   }
// }

function* deleteStudy({ payload: studyId }) {
  try {
    const response = yield call(deleteStudyApi, studyId);
    console.log(response);
    yield put(studyApiSuccess(DELETE_STUDY, response));
  } catch (error) {
    console.log(error);
    yield put(studyApiError(DELETE_STUDY, error));
  }
}

// function* getReport({ payload }) {
//   try {
//     const response = yield call(getReportApi, payload);
//     yield put(studyApiSuccess(GET_REPORT, response));
//   } catch (error) {
//     yield put(studyApiError(GET_REPORT, error));
//   }
// }

function* getStudy({ payload }) {
  try {
    const response = yield call(getStudyApi, payload);
    yield put(studyApiSuccess(GET_STUDY, response));
  } catch (error) {
    yield put(studyApiError(GET_STUDY, error));
  }
}

function* archiveStudy({ payload }) {
  try {
    // use forEach instead of for loop
    for (let i = 0; i < payload.items.length; i++) {
      if (payload.items[i].isChecked === true) {
        const response = yield call(archiveStudyById, payload.items[i].study_instance_uid);
      }
    }
    const response = yield call(getStudiesApi, payload.filter_options);
    try {
      yield put(studyApiSuccess(GET_STUDIES, response));
    } catch (error) {
      yield put(studyApiError(GET_STUDIES, error));
    }
  } catch (error) {
    yield put(studyApiError(ARCHIVE_STUDY, error));
  }
}

function* unarchiveStudy({ payload }) {
  console.log('unarchive study');
  console.log(payload);
  try {
    for (let i = 0; i < payload.items.length; i++) {
      if (payload.items[i].isChecked === true) {
        const response = yield call(unarchiveStudyById, payload.items[i].study_instance_uid);
      }
    }
    const response = yield call(getStudiesApi, payload.filter_options);
    try {
      yield put(studyApiSuccess(GET_STUDIES, response));
    } catch (error) {
      yield put(studyApiError(GET_STUDIES, error));
    }
  } catch (error) {
    yield put(studyApiError(UNARCHIVE_STUDY, error));
  }
}

function* resetStudyState() {
  try {
    yield put(studyApiSuccess(RESET_STUDY_STATE, {}));
    console.log('reset state 2');
  } catch (error) {
    yield put(studyApiError(RESET_STUDY_STATE, error));
  }
}
export function* watchStudies() {
  yield takeEvery(GET_STUDIES, getStudies);
  yield takeEvery(ADD_STUDY, addStudy);
  yield takeEvery(PREDICT, predictStudy);
  // yield takeEvery(GET_REPORT, getReport);
  yield takeEvery(GET_STUDY, getStudy);
  // yield takeEvery(DELETE_REPORT, deleteReport);
  yield takeEvery(DELETE_STUDY, deleteStudy);
  yield takeEvery(RESET_STUDY_STATE, resetStudyState);
  yield takeEvery(ARCHIVE_STUDY, archiveStudy);
  yield takeEvery(UNARCHIVE_STUDY, unarchiveStudy);
}

function* studySaga() {
  yield all([fork(watchStudies)]);
}

export default studySaga;
