import React, { useEffect, useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FolderOpenOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { archivePatient, getPatients, unarchivePatient } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { hasPermissionforPatient } from './helpers';
import { Patient } from '../../Sections/PatientsList/patientsList.section.custom';
import ManageAccess from './ManageAccess';
interface PatientTableProps {
  patientsState: any;
  openModal: any;
  openNewPatientModalEdit: any;
  deletePatientById: any;
  page: number;
  archived: boolean;
  itemsPerPage: number;
  setPage: any;
  setItemsPerPage: any;
}

function PatientTable({
  patientsState,
  openModal,
  openNewPatientModalEdit,
  deletePatientById,
  page,
  itemsPerPage,
  setItemsPerPage,
  setPage,
  archived,
}: PatientTableProps) {
  const [row, setRow] = useState(itemsPerPage);
  const [patients, setPatients] = useState([]);
  const [deleteselected, setDeleteSelected] = useState(false);
  const [data, setData] = useState<Patient[]>([]);
  const [isArchived, setIsArchived] = useState(false);
  const { profile } = useSelector(state => ({
    profile: state.Profile,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    setPatients(patientsState.patients);
  }, [patientsState.patients]);
  function formatBirthDate(birthDate: any) {
    const options: any = { month: 'long', day: 'numeric', year: 'numeric' };
    return new Date(birthDate).toLocaleDateString('en-US', options);
  }
  function calculateAge(birthDate: any) {
    const today = new Date();
    const birthDateObj = new Date(birthDate);

    let age = today.getFullYear() - birthDateObj.getFullYear();
    if (
      today.getMonth() < birthDateObj.getMonth() ||
      (today.getMonth() === birthDateObj.getMonth() && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }

    return age;
  }
  function handleArchive(items: Patient) {
    dispatch(
      archivePatient({
        items: [{ ...items, isChecked: true }],
        filter_options: {
          ...patientsState?.filtered_conditions,
          page: page,
          items_per_page: itemsPerPage,
        },
      })
    );
  }
  function handleUnArchive(items: Patient) {
    dispatch(
      unarchivePatient({
        items: [{ ...items, isChecked: true }],
        filter_options: {
          ...patientsState?.filtered_conditions,
          page: page,
          items_per_page: itemsPerPage,
        },
      })
    );
  }
  const ArchiveCheckedPatients = () => {
    if (archived === false) {
      dispatch(
        archivePatient({
          items: patients,
          filter_options: {
            ...patientsState?.filtered_conditions,
            page: page,
            items_per_page: itemsPerPage,
          },
        })
      );
    } else {
      dispatch(
        unarchivePatient({
          items: patients,
          filter_options: {
            ...patientsState?.filtered_conditions,
            page: page,
            items_per_page: itemsPerPage,
          },
        })
      );
    }
    setDeleteSelected(false);
  };
  function HandlePrevPage() {
    dispatch(
      getPatients({
        filter_options: {
          ...patientsState.filtered_conditions,
          page: page - 1,
          items_per_page: itemsPerPage,
        },
      })
    );
    setPage(prev => prev - 1);
  }
  function handleNextNext() {
    dispatch(
      getPatients({
        filter_options: {
          ...patientsState.filtered_conditions,
          page: page + 1,
          items_per_page: itemsPerPage,
        },
      })
    );
    setPage(prev => prev + 1);
  }
  function HandleRowChange(e: any) {
    dispatch(
      getPatients({
        filter_options: {
          ...patientsState.filtered_conditions,
          page: 1,
          items_per_page: e.target.value,
        },
      })
    );
    setPage(1);
    setItemsPerPage(e.target.value);
  }
  const handleCheckBoxChange = (e: any) => {
    const { id, checked } = e.target;
    setDeleteSelected(true);
    console.log('hehehe', id, checked);
    let checkedData = [];
    if (id === 'allselect') {
      checkedData = patients.map((item: any) => {
        return { ...item, isChecked: checked };
      });
      setPatients(checkedData);
    } else {
      checkedData = patients.map((item: any) => {
        if (item.id.toString() === id) {
          return { ...item, isChecked: checked };
        }
        return item;
      });
      setPatients(checkedData);
    }
    checkedData.filter((item: any) => item.isChecked).length !== 0
      ? setDeleteSelected(true)
      : setDeleteSelected(false);
  };
  const deleteCheckedPatiens = () => {
    patients.map((item: any) => {
      if (item.isChecked) {
        deletePatientById(item.id);
      }
    });
    setDeleteSelected(false);
  };
  useEffect(() => {
    console.log('see y', patients);
    console.log('see y', !patients.some(item => item.isChecked !== true));
  }, [patients]);
  return (
    <>
      <table
        className={`block w-full border-collapse rounded-lg shadow-xl ${patientsState?.patients.length === 0 ? '' : 'h-full'}`}
      >
        <thead className="relative block h-[56px] w-full bg-[#EEF5FF] text-center text-[#000000]">
          <tr className="flex h-full w-full items-center justify-between border border-[#C7DBF5] text-[12px] font-semibold text-[#2B5170]">
            <th className="pl-4">
              <div className="flex cursor-pointer">
                {deleteselected ? (
                  <button
                    className="text-[14px] font-semibold text-[#005DD4]"
                    onClick={ArchiveCheckedPatients}
                  >
                    <FolderOpenOutlined />
                  </button>
                ) : (
                  <input
                    type="checkbox"
                    className="h-6"
                    id="allselect"
                    checked={
                      patients.length !== 0 && !patients.some(item => item.isChecked !== true)
                    }
                    onChange={handleCheckBoxChange}
                  />
                )}
              </div>
            </th>
            <th className="block grow basis-full">FIRST NAME</th>
            <th className="block grow basis-full">LAST NAME</th>
            <th className="block grow basis-full">DATE OF BIRTH</th>
            {/* <th className="basis-full grow block">HEALTHCARE PROVIDER</th> */}
            {/* <th className="basis-full grow block">LAST VISIT</th>
                        <th className="basis-full grow block">CURRENT LMP</th> */}
            <th className="block grow basis-full">Email</th>
            <th className="block grow basis-full">Doctor</th>
            <th className="block grow basis-full">INSURANCE PROVIDER</th>
            <th className="block grow basis-full">ACTIONS</th>
          </tr>
        </thead>
        <tbody
          className={`custom-scrollbar relative block max-h-[calc(100%-96px)] w-full overflow-y-auto text-center`}
        >
          {patients?.map(patient => (
            <tr
              key={patient?.id}
              className="flex  w-full items-center justify-between border-b border-[#C7DBF5] hover:bg-gray-100 "
            >
              <td className="custom-letter-spacing py-3 pl-4 ">
                {/* <img src="/icons/check.svg" className="h-6" /> */}
                <input
                  type="checkbox"
                  className="h-6"
                  id={patient.id}
                  checked={patient?.isChecked || false}
                  onChange={handleCheckBoxChange}
                />
              </td>
              <td className="custom-letter-spacing block grow basis-full text-[14px] font-medium text-[#25213B] ">
                <Link to={`/patients/${patient.patient_id}`}>{patient.patient_name}</Link>
              </td>
              <td className="custom-letter-spacing block grow basis-full text-[14px] font-medium text-[#25213B] ">
                {patient.patient_last_name}
              </td>
              <td className="custom-letter-spacing block grow basis-full">
                <span className="text-[14px] font-medium text-[#25213B]">
                  {formatBirthDate(patient.birth_date)}
                </span>{' '}
                <br></br>
                <span className="text-[12px] text-[#25213B]">
                  {calculateAge(patient.birth_date)} years old
                </span>
              </td>
              {/* <td className="basis-full grow block custom-letter-spacing text-[14px] font-medium text-[#25213B] ">
                                TBD
                            </td> */}
              <td className="custom-letter-spacing block grow basis-full text-[#25213B] ">
                {patient.email}
              </td>
              <td className="custom-letter-spacing block grow basis-full text-[#25213B] ">
                {patient?.owner?.username}
              </td>
              <td className="custom-letter-spacing block grow basis-full text-[#25213B] ">
                {patient.insurance_provider}
              </td>
              <td className="custom-letter-spacing block grow basis-full ">
                {patient?.archived === false ? (
                  <>
                    <button
                      className=" w-10"
                      onClick={() => openModal(patient)}
                    >
                      <EyeOutlined />
                    </button>
                    <button
                      className=" w-10"
                      onClick={() => openNewPatientModalEdit(patient)}
                    >
                      <EditOutlined />
                    </button>
                    <button
                      className=" w-10"
                      onClick={() => handleArchive(patient)}
                    >
                      <FolderOpenOutlined />
                    </button>
                    {hasPermissionforPatient('can_manage', patient.permissions) && (
                      <ManageAccess patient_id={patient.id} />
                    )}
                  </>
                ) : (
                  <button
                    className="w-10"
                    onClick={() => handleUnArchive(patient)}
                  >
                    <FolderOpenOutlined />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot
          className={`block h-[40px] w-full bg-[#EEF5FF] ${patientsState?.patients.length === 0 ? 'hidden' : ''}`}
        >
          <tr className="flex h-full w-full justify-between border">
            <td
              colSpan={7}
              className="flex w-full space-x-4 px-4"
            >
              <div className="inline-flex w-full flex-row items-center justify-end space-x-4 text-sm font-semibold text-[#2B5170]">
                <div>
                  Rows per page:
                  <select
                    name="row"
                    id="row"
                    onChange={HandleRowChange}
                    className="bg-[#EEF5FF]"
                  >
                    <option value={row}>{row}</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                  </select>
                </div>
                <div>
                  {patientsState.totalCount > 0 &&
                    `${(page - 1) * itemsPerPage + 1}-${Math.min(itemsPerPage * page, patientsState.totalCount)} of ${patientsState.totalCount}`}
                </div>

                <div className="space-x-4">
                  <button
                    className="text-[14px] font-semibold text-[#005DD4]"
                    onClick={HandlePrevPage}
                    disabled={page > 1 ? false : true}
                  >
                    <LeftOutlined />
                  </button>
                  <button
                    className="text-[14px] font-semibold text-[#005DD4]"
                    onClick={handleNextNext}
                    disabled={!patientsState.hasMore}
                  >
                    <RightOutlined />
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}

export default PatientTable;
