import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

const ProtectedRoutes = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated) {
      // If not authenticated, redirect to login
      auth.signinRedirect({ redirect_uri: window.location.href });
    } else if (auth.isAuthenticated && process.env.DEPLOYMENT_TARGET === 'Morocco') {
      navigate('/realtime-inferencing');
    }
  }, [auth.isLoading, auth.isAuthenticated, navigate]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && !auth.isAuthenticated) {
        // If the tab becomes visible and the user is not authenticated, redirect to login
        auth.signinRedirect({ redirect_uri: window.location.href });
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [auth]);

  if (auth.isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white">
        <div className="h-32 w-32 animate-spin rounded-full border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!auth.isAuthenticated) {
    return null; // Don't render anything while redirecting
  }

  return children;
};

export default ProtectedRoutes;
