import React from 'react';

function ErrorPage({ msg }: { msg: string }) {
  return (
    <div className="flex  h-full">
      <div className="relative mx-6 my-2 h-full w-full">
        <div className="mt-1 text-center text-[#005DD4] md:mt-8">
          <h1 className="my-5 text-4xl font-bold">Oops!</h1>
          <h1 className="mt-10 text-3xl font-bold">{msg}</h1>{' '}
        </div>
        <div className="absolute top-1/2 left-1/2 flex h-[60%] w-full -translate-x-1/2 -translate-y-1/2 transform items-center justify-center text-[#005DD4] md:h-[600px]">
          <div className="text-[10rem] font-thin md:text-[19rem]">X</div>
          <div className="text-[10rem] font-thin md:text-[19rem]">X</div>
          {/* <div className='rounded-t-full rounded-b-full bg-white mr-2'></div> */}
          <div className="text-[10rem] font-thin md:text-[19rem]">X</div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
