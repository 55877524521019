import { EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { List } from 'antd';
import React from 'react';
import NewExam from '../NewExam/NewExam';
const actions = [
  {
    title: 'New Case',
    description: 'Start a new case from existing exam sources',
  },
];
const data = [
  {
    doctor: 'Dr. Kebbour',
    hospital: 'John Brahim Hospital',
    date: '2021-10-10',
    Exam: 'Q1 Exam',
    type: 'Regular Consultation',
  },
  {
    doctor: 'Dr. Kebbour',
    hospital: 'John Brahim Hospital',
    date: '2021-10-10',
    Exam: 'Q1 Exam',
    type: 'Regular Consultation',
  },
  {
    doctor: 'Dr. Kebbour',
    hospital: 'John Brahim Hospital',
    date: '2021-10-10',
    Exam: 'Q1 Exam',
    type: 'Regular Consultation',
  },
  {
    doctor: 'Dr. Kebbour',
    hospital: 'John Brahim Hospital',
    date: '2021-10-10',
    Exam: 'Q1 Exam',
    type: 'Regular Consultation',
  },
  {
    doctor: 'Dr. Kebbour',
    hospital: 'John Brahim Hospital',
    date: '2021-10-10',
    Exam: 'Other',
    type: 'Late FGR',
  },
  {
    doctor: 'Dr. Kebbour',
    hospital: 'John Brahim Hospital',
    date: '2021-10-10',
    Exam: 'Other',
    type: 'Late FGR',
  },
  {
    doctor: 'Dr. Kebbour',
    hospital: 'John Brahim Hospital',
    date: '2021-10-10',
    Exam: 'Other',
    type: 'Late FGR',
  },
  {
    doctor: 'Dr. Kebbour',
    hospital: 'John Brahim Hospital',
    date: '2021-10-10',
    Exam: 'Other',
    type: 'Late FGR',
  },
  {
    doctor: 'Dr. Kebbour',
    hospital: 'John Brahim Hospital',
    date: '2021-10-10',
    Exam: 'Other',
    type: 'Late FGR',
  },
  {
    doctor: 'Dr. Kebbour',
    hospital: 'John Brahim Hospital',
    date: '2021-10-10',
    Exam: 'Other',
    type: 'Late FGR',
  },
];
interface PatientExamsProps {
  fullName?: string;
  PatientId?: string;
}
function PatientExams({ fullName, PatientId }: PatientExamsProps) {
  return (
    <div className="h-full w-full space-y-3 p-3 text-[#1F384C]">
      <div className="flex justify-between">
        <span className="h-7 text-[1rem] ">Patient Exams History</span>
        <div className="text-[#005DD4]">
          <NewExam
            {...actions[0]}
            page={'patientDetails'}
            fullPatientName={fullName}
            fullPatientId={PatientId}
          />
        </div>
      </div>
      <div className="flex h-[calc(100%-28px)] flex-col justify-between space-y-3">
        <div className="flex h-[calc(100%-48px)] w-full flex-col space-y-2 overflow-x-scroll">
          {data.map((item, index) => (
            <div
              key={index}
              className="h-9 w-full  text-sm"
              style={{ width: '100%' }}
            >
              <div className="flex w-full flex-row items-start justify-between gap-2">
                <div className="flex w-1/4 flex-col">
                  <span className="text-[14px] font-[500]">{item.doctor}</span>
                  <span className="text-xs font-[400]">{item.hospital}</span>
                </div>

                <div className="flex w-1/4 flex-col items-start">
                  <span className="text-[14px] font-[500]">{item.date}</span>
                  <span className="text-xs font-[400]">2 months ago</span>
                </div>

                <div className="flex w-1/4 flex-col items-start">
                  <span className="text-[14px] font-[500]">{item.Exam}</span>
                  <span className="text-xs font-[400]">{item.type}</span>
                </div>
                <div className=" flex w-1/4 items-end justify-end">
                  <EyeOutlined className=" text-2xl" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PatientExams;
