import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { getUser } from 'platform/viewer/src/Deepecho/helpers/apiHelper';
import { APIClient, apiClient } from '../../../../../helpers/apiHelper';
const EditableTable = ({ api, token }) => {
  const [rows, setRows] = useState([]);
  const [editIdx, setEditIdx] = useState(-1);
  const [isChanged, setIsChanged] = useState(false);
  const [newRow, setNewRow] = useState({
    label: '',
    acronyme: '',
    machine_width: '',
    machine_height: '',
    output_width: '',
    output_height: '',
    crop_x: '',
    crop_y: '',
    crop_width: '',
    crop_height: '',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const totalPages = Math.ceil(rows.length / rowsPerPage);
  const apiClient = new APIClient();
  const apiUrl = api; // Update with your API endpoint
  console.log('api test', apiUrl);
  const fetchUltrasoundMachines = async () => {
    try {
      console.log('===================== mmm');

      const machines = await apiClient.getUltrasoundMachines();
      console.log('===================== mmm');

      setRows(machines.data);
    } catch (error) {
      console.error('Failed to fetch ultrasound machines:', error);
    }
  };
  useEffect(() => {
    fetchUltrasoundMachines();
  }, [isChanged]);

  // const handleChange = (e, index, key) => {
  //   console.log("index ::",index)
  //   console.log("key",key)
  //   const { value } = e.target;
  //   console.log("value",value)
  //   const updatedRows = [...rows];
  //   console.log("value",updatedRows)
  //   updatedRows[index] = { ...updatedRows[index], [key]: value };
  //   console.log("value",updatedRows)
  //   setRows(updatedRows);
  // };
  const handleChange = (e, index, key) => {
    const { value } = e.target;
    const updatedRows = [...rows];
    updatedRows[(currentPage - 1) * rowsPerPage + index] = {
      ...updatedRows[(currentPage - 1) * rowsPerPage + index],
      [key]: value,
    };
    setRows(updatedRows);
  };

  const handleNewRowChange = (e, key) => {
    setNewRow({
      ...newRow,
      [key]: e.target.value,
    });
  };
  const addNewMachine = async machineData => {
    try {
      const addedMachine = await apiClient.addUltrasoundMachine(machineData);
      setRows([...rows, addedMachine]);
      setNewRow({
        label: '',
        acronyme: '',
        machine_width: '',
        machine_height: '',
        output_width: '',
        output_height: '',
        crop_x: '',
        crop_y: '',
        crop_width: '',
        crop_height: '',
      });
      setIsChanged(!isChanged);
      // Handle the added machine data as needed
    } catch (error) {
      // Handle any errors that occurred during the post
      console.error('Failed to add ultrasound machine:', error);
    }
  };
  // Add new row using axios POST request
  const handleAddRow = () => {
    addNewMachine(newRow);
  };
  const updateMachine = async (machineData, index) => {
    try {
      const updatedMachine = await apiClient.updateUltrasoundMachine(machineData);
      const updatedRows = [...rows];
      updatedRows[index] = updatedMachine;
      setRows(updatedRows);
      setEditIdx(-1);
      setIsChanged(!isChanged);
      // Handle the updated machine data as needed
    } catch (error) {
      // Handle any errors that occurred during the put
      console.error('Failed to update ultrasound machine:', error);
    }
  };
  // Save edited row using axios PUT request
  // const handleSave = (index) => {
  //   const updatedRow = rows[index];
  //   updateMachine(updatedRow,index);
  // };
  const handleSave = index => {
    const updatedRow = rows[(currentPage - 1) * rowsPerPage + index];
    updateMachine(updatedRow, index);
  };

  // Delete row using axios DELETE request

  const handleEdit = index => {
    setEditIdx(index);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const currentRows = rows.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <div>
      <div className="mb-4">
        <h2 className="mb-2 text-lg font-semibold">Add New Machine</h2>
        <input
          value={newRow.label}
          onChange={e => handleNewRowChange(e, 'label')}
          placeholder="Label"
          className="mr-2 border p-2"
        />
        <input
          value={newRow.acronyme}
          onChange={e => handleNewRowChange(e, 'acronyme')}
          placeholder="Acronym"
          className="mr-2 border p-2"
        />
        <input
          type="number"
          value={newRow.machine_width}
          onChange={e => handleNewRowChange(e, 'machine_width')}
          placeholder="Machine Width"
          className="mr-2 border p-2"
        />
        <input
          type="number"
          value={newRow.machine_height}
          onChange={e => handleNewRowChange(e, 'machine_height')}
          placeholder="Machine Height"
          className="mr-2 border p-2"
        />
        <input
          type="number"
          value={newRow.output_width}
          onChange={e => handleNewRowChange(e, 'output_width')}
          placeholder="Output Width"
          className="mr-2 border p-2"
        />
        <input
          type="number"
          value={newRow.output_height}
          onChange={e => handleNewRowChange(e, 'output_height')}
          placeholder="Output Height"
          className="mr-2 border p-2"
        />
        <input
          type="number"
          value={newRow.crop_x}
          onChange={e => handleNewRowChange(e, 'crop_x')}
          placeholder="Crop X"
          className="mr-2 border p-2"
        />
        <input
          type="number"
          value={newRow.crop_y}
          onChange={e => handleNewRowChange(e, 'crop_y')}
          placeholder="Crop Y"
          className="mr-2 border p-2"
        />
        <input
          type="number"
          value={newRow.crop_width}
          onChange={e => handleNewRowChange(e, 'crop_width')}
          placeholder="Crop Width"
          className="mr-2 border p-2"
        />
        <input
          type="number"
          value={newRow.crop_height}
          onChange={e => handleNewRowChange(e, 'crop_height')}
          placeholder="Crop Height"
          className="mr-2 border p-2"
        />
        <button
          onClick={handleAddRow}
          className="rounded bg-blue-500 p-2 text-white"
        >
          Add Machine
        </button>
      </div>
      <div className="custom-scrollbar overflow-y-auto">
        <table className="w-full border-collapse border border-gray-400">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">Label</th>
              <th className="border border-gray-300 p-2">Acronym</th>
              <th className="border border-gray-300 p-2">Machine Width</th>
              <th className="border border-gray-300 p-2">Machine Height</th>
              <th className="border border-gray-300 p-2">Output Width</th>
              <th className="border border-gray-300 p-2">Output Height</th>
              <th className="border border-gray-300 p-2">Crop X</th>
              <th className="border border-gray-300 p-2">Crop Y</th>
              <th className="border border-gray-300 p-2">Crop Width</th>
              <th className="border border-gray-300 p-2">Crop Height</th>
              <th className="border border-gray-300 p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((row, index) => (
              <tr key={index}>
                {editIdx === index ? (
                  <>
                    <td className="border border-gray-300 p-2">
                      <input
                        value={row?.label}
                        onChange={e => handleChange(e, index, 'label')}
                        className="border p-1"
                      />
                    </td>
                    <td className="border border-gray-300 p-2">
                      <input
                        value={row?.acronyme}
                        onChange={e => handleChange(e, index, 'acronyme')}
                        className="border p-1"
                      />
                    </td>
                    <td className="border border-gray-300 p-2">
                      <input
                        type="number"
                        value={row?.machine_width}
                        onChange={e => handleChange(e, index, 'machine_width')}
                        className="border p-1"
                      />
                    </td>
                    <td className="border border-gray-300 p-2">
                      <input
                        type="number"
                        value={row?.machine_height}
                        onChange={e => handleChange(e, index, 'machine_height')}
                        className="border p-1"
                      />
                    </td>
                    <td className="border border-gray-300 p-2">
                      <input
                        type="number"
                        value={row?.output_width}
                        onChange={e => handleChange(e, index, 'output_width')}
                        className="border p-1"
                      />
                    </td>
                    <td className="border border-gray-300 p-2">
                      <input
                        type="number"
                        value={row?.output_height}
                        onChange={e => handleChange(e, index, 'output_height')}
                        className="border p-1"
                      />
                    </td>
                    <td className="border border-gray-300 p-2">
                      <input
                        type="number"
                        value={row?.crop_x}
                        onChange={e => handleChange(e, index, 'crop_x')}
                        className="border p-1"
                      />
                    </td>
                    <td className="border border-gray-300 p-2">
                      <input
                        type="number"
                        value={row?.crop_y}
                        onChange={e => handleChange(e, index, 'crop_y')}
                        className="border p-1"
                      />
                    </td>
                    <td className="border border-gray-300 p-2">
                      <input
                        type="number"
                        value={row?.crop_width}
                        onChange={e => handleChange(e, index, 'crop_width')}
                        className="border p-1"
                      />
                    </td>
                    <td className="border border-gray-300 p-2">
                      <input
                        type="number"
                        value={row?.crop_height}
                        onChange={e => handleChange(e, index, 'crop_height')}
                        className="border p-1"
                      />
                    </td>
                    <td className="border border-gray-300 p-2">
                      <button
                        onClick={() => handleSave(index)}
                        className="rounded bg-green-500 p-1 text-white"
                      >
                        Save
                      </button>
                    </td>
                  </>
                ) : (
                  <>
                    <td className="border border-gray-300 p-2">{row?.label}</td>
                    <td className="border border-gray-300 p-2">{row?.acronyme}</td>
                    <td className="border border-gray-300 p-2">{row?.machine_width}</td>
                    <td className="border border-gray-300 p-2">{row?.machine_height}</td>
                    <td className="border border-gray-300 p-2">{row?.output_width}</td>
                    <td className="border border-gray-300 p-2">{row?.output_height}</td>
                    <td className="border border-gray-300 p-2">{row?.crop_x}</td>
                    <td className="border border-gray-300 p-2">{row?.crop_y}</td>
                    <td className="border border-gray-300 p-2">{row?.crop_width}</td>
                    <td className="border border-gray-300 p-2">{row?.crop_height}</td>
                    <td className="border border-gray-300 p-2">
                      <button
                        onClick={() => handleEdit(index)}
                        className="mr-2"
                      >
                        <FaEdit />
                      </button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex justify-between">
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          className="rounded bg-gray-300 p-2"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={nextPage}
          disabled={currentPage === totalPages}
          className="rounded bg-gray-300 p-2"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default EditableTable;
