export function decodeRLE(rle, width, height) {
  // Split the input RLE string into parts of letters and numbers
  // console.log("rle :: "+ rle)
  const parts = rle.match(/[A-Z]+|\d+/g);

  // console.log("parts :: "+ parts)
  // Initialize an empty matrix of the specified width and height
  const matrix = Array.from({ length: height }, () => Array(width).fill(null));

  // console.log("matrix :: "+ matrix)
  let currentRow = 0;
  let currentCol = 0;
  for (let i = 0; i < parts.length - 2; i += 2) {
    const char = parts[i];

    const count = parseInt(parts[i + 1], 10);

    for (let j = 0; j < count; j++) {
      matrix[currentRow][currentCol] = char === 'A' ? 0 : 1;

      currentCol++;
      if (currentCol === width) {
        currentCol = 0;
        currentRow++;
      }
    }
  }

  return matrix;
}

export function drawMask(ctx, maskMatrix, color, label) {
  const rows = maskMatrix.length;
  const cols = maskMatrix[0].length;
  console.log('rows ::', rows);
  console.log('cols ::', cols);
  const path = new Path2D();
  let totalX = 0;
  let totalY = 0;
  let count = 0;

  ctx.fillStyle = color;
  ctx.strokeStyle = color;
  ctx.lineWidth = 1;

  // Check if a pixel at position (x, y) is on the outline of the mask
  function isOutlinePixel(x, y) {
    return x < 0 || x >= cols || y < 0 || y >= rows || maskMatrix[y][x] === 0;
  }

  // Iterate over each pixel in the mask matrix
  for (let y = 0; y < rows; y++) {
    for (let x = 0; x < cols; x++) {
      if (maskMatrix[y][x] === 1) {
        // Check if the current pixel is on the outline
        if (
          isOutlinePixel(x - 1, y) ||
          isOutlinePixel(x + 1, y) ||
          isOutlinePixel(x, y - 1) ||
          isOutlinePixel(x, y + 1)
        ) {
          path.rect(x, y, 1, 1);
          totalX += x;
          totalY += y;
          count++;
        }
      }
    }
  }

  ctx.fill(path);
  ctx.stroke(path);

  if (count > 0) {
    // Calculate the centroid of the mask
    const centerX = totalX / count;
    const centerY = totalY / count;

    // Set text properties
    ctx.fillStyle = 'white'; // White color for the text
    ctx.font = '12px Arial'; // Font size and style
    ctx.textAlign = 'center'; // Align text to center

    // Draw the text at the top center of the mask
    ctx.fillText(label, centerX, centerY - 10); // Adjusting the y-coordinate
  }
}

export function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  const a = 0.5;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}
