import React from 'react';

function LoaderPage({
  className = 'w-20 h-20 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin',
}) {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className={className}></div>
    </div>
  );
}

export default LoaderPage;
