import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
// import { APIClient } from 'platform/viewer/src/Deepecho/helpers/apiHelper';

// const api = new APIClient();

const ExaminationResults: React.FC = props => {
  const {
    editMode,
    examData,
    setExamData,
    selectedFrame1ID,
    selectedFrame2ID,
    selectedFrame3ID,
    selectedFrame4ID,
    handleEGAChange_curr,
    handleEGAChange_prev,
    handleEGAChange_cent,
    handleEFAChange_curr,
    handleEFAChange_prev,
    handleEFAChange_cent,
    handleGRSChange_curr,
    handleGRSChange_prev,
    handleAFPChange_curr,
    handleAFPChange_prev,
    handleCommentsChange,
    reportDetails,
  } = props;

  // const planeOneName = "femur"
  // const selectedPlaneOneFrameDetails = reportDetails["femur"][selectedFrame1ID];

  // const planeTwoName = "abdominal"
  // const selectedPlaneTwoFrameDetails = reportDetails["abdominal"][selectedFrame2ID];

  // const planeThreeName = "afpocket"
  // const selectedPlaneThreeFrameDetails = reportDetails["afpocket"][selectedFrame3ID];

  // const planeFourName = "cephalic"

  // const selectedPlaneFourFrameDetails = reportDetails["cephalic"][selectedFrame4ID];

  // const femurExist = Object.keys(reportDetails["femur"]).length > 0
  //     ? true
  //     : false;
  // const abdoExist = Object.keys(reportDetails["abdominal"]).length > 0
  //     ? true
  //     : false;
  // const afExist = Object.keys(reportDetails["afpocket"]).length > 0
  //     ? true
  //     : false;
  // const cephalicExist = Object.keys(reportDetails["cephalic"]).length > 0
  //     ? true
  //     : false;

  // - cephalic: HC, BPD
  // - abdomen: AC
  // - femur: FL
  // - af pocket: SDP

  // const methodChoices = [];
  // femurExist &&
  //   cephalicExist &&
  //   methodChoices.push({
  //     value: 'HC-FL',
  //     label: 'HC-FL',
  //   });
  // abdoExist &&
  //   methodChoices.push({
  //     value: 'AC',
  //     label: 'AC',
  //   });
  // cephalicExist &&
  //   methodChoices.push({
  //     value: 'BPD',
  //     label: 'BPD',
  //   });
  // afExist &&
  //   methodChoices.push({
  //     value: 'FL',
  //     label: 'FL',
  //   });

  // const selectedFramesForAgeCalc = {
  //   [planeOneName]: selectedPlaneOneFrameDetails,
  //   [planeTwoName]: selectedPlaneTwoFrameDetails,
  //   [planeThreeName]: selectedPlaneThreeFrameDetails,
  //   [planeFourName]: selectedPlaneFourFrameDetails,
  //   method: methodChoices[0] && methodChoices[0].value,
  // };

  const url = process.env.BACKEND_URL;

  const [age, setAge] = useState('');
  const [weight, setWeight] = useState('');
  // const [meth, setMeth] = useState(methodChoices[0] && methodChoices[0].value);

  // useEffect(() => {
  //   const calculteGA = async () => {
  //     const age = await api.post(`${url}/api/reports/get_ga`, {
  //       ...selectedFramesForAgeCalc,
  //       method: meth,
  //     });

  //     // let weeks = age / 7;
  //     // let days = age % 7;
  //     // setAge(`${Math.round(weeks)}w${Math.round(days)}d`)
  //     setAge(`${age.toFixed(2)}d`);
  //   };
  //   calculteGA();
  // }, [
  //   selectedFrame1ID,
  //   selectedFrame2ID,
  //   selectedFrame3ID,
  //   selectedFrame4ID,
  //   meth,
  // ]);

  // useEffect(() => {
  //   const calculteFW = async () => {
  //     const weight = await api.post(`${url}/api/reports/get_weight`, {
  //       ...selectedFramesForAgeCalc,
  //     });

  //     setWeight(`${weight.data} grams`);
  //   };
  //   calculteFW();
  // }, [selectedFrame1ID, selectedFrame2ID, selectedFrame3ID, selectedFrame4ID]);

  // const handleChange = (value: string) => {
  //   console.log(`selected ${value}`);
  //   setMeth(value);
  // };

  return (
    <div
      className="border-b-2  border-black px-2 text-sm"
      // style={{ height: '50%' }}
    >
      <h2 className="text-lg font-bold underline">Examination Results</h2>
      <table className="w-full table-auto ">
        <thead>
          <tr>
            <th></th>
            <th className="text-left ">current</th>
            <th className="text-left">centile</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="font-bold">Estimated gestationnel age</td>
            <td>
              <span>{age ? `${age}` : `37w`}</span>

              {/* <Select
                defaultValue={`${methodChoices[0].value}`}
                style={{
                  width: 80,
                  height: 15,
                  marginLeft: '2px',
                  fontSize: "5px"
                }}
                onChange={handleChange}
                options={methodChoices}
                size="small"
              /> */}
            </td>

            <td>
              <span>10th</span>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Estimated fetal weight</td>
            <td>
              <span>{weight ? `${weight}` : `2800g`}</span>
            </td>

            <td>
              <span>3rd</span>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="my-2 mt-6">Physician comments</p>
      <textarea
        value={''}
        onChange={e => handleCommentsChange(e, setExamData, examData)}
        className="w-full bg-gray-300"
        rows={8}
      ></textarea>
    </div>
  );
};

export default ExaminationResults;
