import {
  GET_PATIENTS,
  ADD_PATIENT,
  API_SUCCESS,
  API_ERROR,
  UPDATE_PATIENT,
  DELETE_PATIENT,
  RESET_STATE,
  ARCHIVE_PATIENT,
  UNARCHIVE_PATIENT,
} from './actionType';

const INIT_STATE = {
  patients: [],
  totalCount: 0,
  hasMore: false,
  error: null,
  loading: false,
  success: false,
  filtered_conditions: {},
};

const Patient = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PATIENTS:
      return {
        ...state,
        loading: true,
      };
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PATIENTS:
          return {
            ...state,
            patients: action.payload.data.data,
            totalCount: action.payload.data.total_count,
            hasMore: action.payload.data.has_more,
            filtered_conditions: action.payload.data.filtered_conditions,
            success: true,
            loading: false,
          };
        case ADD_PATIENT:
          return {
            ...state,
            success: true,
            patients: action.payload.data.data,
            totalCount: action.payload.data.total_count,
            hasMore: action.payload.data.has_more,
            loading: false,
          };
        case UPDATE_PATIENT:
          return {
            ...state,
            patients: action.payload.data.data,
            totalCount: action.payload.data.total_count,
            hasMore: action.payload.data.has_more,
            success: true,
            loading: false,
          };
        case DELETE_PATIENT:
          return {
            ...state,
            success: true,
            patients: action.payload.data.data,
            totalCount: action.payload.data.total_count,
            hasMore: action.payload.data.has_more,
            loading: false,
          };
        case RESET_STATE:
          return {
            ...state,
            patients: [],
            totalCount: 0,
            hasMore: false,
            currentPage: 1,
            itemsPerPage: 4,
            error: null,
            loading: false,
            success: false,
          };
        case ARCHIVE_PATIENT:
          return {
            ...state,
            needsReload: true,
            isStudySuccess: true,
          };
        case UNARCHIVE_PATIENT:
          return {
            ...state,
            needsReaload: true,
            isStudySuccess: true,
          };
      }
    case API_ERROR:
      switch (action.payload.actionType) {
        case GET_PATIENTS:
          return {
            ...state,
            error: action.payload.error,
            success: false,
            loading: false,
          };
        case ADD_PATIENT:
          return {
            ...state,
            error: action.payload.error,
            success: false,
            loading: false,
          };
        case UPDATE_PATIENT:
          return {
            ...state,
            error: action.payload.error,
            success: false,
            loading: false,
          };
        case DELETE_PATIENT:
          return {
            ...state,
            error: action.payload.error,
            success: false,
            loading: false,
          };
      }
    default:
      return { ...state };
  }
};
export default Patient;
