import { apiClient } from '../../apiHelper';
import { usePaginated } from '../react-query';
import { patientsQueryKeys } from './patients-query-keys';

export function usePaginatedPatients(page, itemsPerPage, filters = {}, config = {}) {
  const getPaginatedPatientsFn = async (p = page, f = filters) => {
    const prepareFilters = inputFilters => {
      console.log('Input filters:', inputFilters);
      if (!inputFilters) {
        return {};


        
      }
      if (!inputFilters.global__ilike || inputFilters.global__ilike.length === 0) return {};

      const sanitizedFilters = { ...inputFilters };

      if (sanitizedFilters.global__ilike) {
        sanitizedFilters['condition__or'] = {
          patient_name__ilike: `%${sanitizedFilters.global__ilike}%`,
          patient_last_name__ilike: `%${sanitizedFilters.global__ilike}%`,
          email__ilike: `%${sanitizedFilters.global__ilike}%`,
        };

        delete sanitizedFilters.global__ilike;
      }

      return sanitizedFilters;
    };

    const sanitizedFilters = prepareFilters(f);
    const queryString = `page=${p}&items_per_page=${itemsPerPage}&filters=${encodeURIComponent(JSON.stringify(sanitizedFilters))}`;

    const patientUrl = `patients?${queryString}`;

    const response = await apiClient.get(patientUrl);
    return response;
  };

  return usePaginated(
    getPaginatedPatientsFn,
    patientsQueryKeys.pagination(page, filters),
    page,
    config
  );
}
