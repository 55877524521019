import React, { useState, useEffect, useRef } from 'react';
import Modal from '../../../../Components/modal';
import { Icon } from '@ohif/ui';
import { MdArrowBackIosNew } from 'react-icons/md';
import { LuLoader2 } from 'react-icons/lu';
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import { api } from '../../../../../../../../../platform/viewer/src/Deepecho/helpers/backendHelper';

function PixelSpacingModal({
  globalState,
  setGlobalState,
  videoRef,
  handleNext,
  handleBack,
  currentStep,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [detectionStatus, setDetectionStatus] = useState(null);
  const localCanvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    console.log('imageDataUrl1', videoRef);
    const captureFrame = () => {
      console.log('imageDataUrl2');
      if (videoRef.current) {
        // Create a new canvas element dynamically
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        // Set canvas dimensions to video dimensions
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;

        // Draw the current frame from the video onto the canvas
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

        // Convert the canvas content to a data URL (image format)
        const imageDataUrl = canvas.toDataURL('image/png');

        // Set the image URL to the state to display it
        setImageSrc(imageDataUrl);
      }
    };

    if (videoRef.current) {
      // Listen for the video to load its metadata (dimensions)
      console.log('imageDataUrl4');
      videoRef.current.addEventListener('loadedmetadata', captureFrame);

      // Capture a frame if globalState changes (and video is ready)

      captureFrame();
    }
  }, [videoRef, globalState.machineType]);

  const handleGoBack = () => {
    if (currentStep == 2) {
      setGlobalState(prevState => ({
        ...prevState,
        selectedItem: 'Select your machine',
        deviceId: '',
        pixelSpacing: '',
      }));
    }

    setDetectionStatus(null);
  };

  const detectPixelSpacing_simulated = async () => {
    // Simulate API request with delay
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const isSuccess = Math.random() < 0.5; // Randomly determine success with 50% probability
        if (isSuccess) {
          resolve((Math.random() * (0.5 - 0.1) + 0.1).toFixed(4));
        } else {
          reject('Error processing the image');
        }
      }, 2000);
    });
  };

  const detectPixelSpacing = async () => {
    try {
      const canvas = localCanvasRef.current;
      canvas.toBlob(async blob => {
        const formData = new FormData();
        formData.append('image', blob, 'image.png');
        formData.append('machine_type', globalState.machineType);

        const response = await api.post('/pixel_spacing_detection', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // Assuming the response contains the pixel spacing value
        const pixelSpacingDetected = response.data.pixelSpacing;

        return pixelSpacingDetected;
      }, 'image/png');
    } catch (error) {
      throw new Error('Error detecting pixel spacing: ' + error.message);
    }
  };

  const detectPixelSpacing_with_ImageSrc = async imageSrc => {
    try {
      const formData = new FormData();
      formData.append('image', imageSrc, 'image.png');
      formData.append('machine_type', globalState.machineType);

      const response = await api.post('/pixel_spacing_detection', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Assuming the response contains the pixel spacing value
      const pixelSpacingDetected = response.data.pixelSpacing;

      return pixelSpacingDetected;
    } catch (error) {
      throw new Error('Error detecting pixel spacing: ' + error.message);
    }
  };

  const runDetection = async () => {
    try {
      setIsLoading(true);
      const response = await detectPixelSpacing_simulated();

      if (response !== undefined && response !== null) {
        // localStorage.setItem(`${globalState.machineType}`, `${response}`);

        setGlobalState(prevState => ({
          ...prevState,
          pixelSpacing: response,
          PixelSpacingDetectionError: null,
        }));
        setDetectionStatus('success');
        if (currentStep <= 2) {
          handleNext();
        }
      } else {
        throw new Error('No pixel spacing value returned');
      }
    } catch (error) {
      setGlobalState(prevState => ({
        ...prevState,
        pixelSpacing: '',
        PixelSpacingDetectionError: error instanceof Error ? error.message : String(error),
      }));
      setDetectionStatus('error');
      if (currentStep <= 2) {
        handleNext();
      }
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   runDetection();
  // }, [globalState.machineType, setGlobalState, imageSrc]);

  return (
    <div className="relative h-full p-3">
      <div className="w-full text-xl font-medium">Pixel Spacing Auto Detection</div>
      <div className="relative flex w-full items-center justify-center p-5">
        {imageSrc != 'data:,' ? (
          <div className="relative flex flex-col items-center space-y-3">
            <div className="relative flex justify-center">
              <img
                src={imageSrc}
                alt="Captured Frame"
                // width={600}
                // height={600}
                className={`${detectionStatus === 'success' ? 'h-1/2 w-1/2' : 'max-h-full max-w-full'}`}
              />
              {detectionStatus === 'success' && (
                <IoIosCheckmarkCircle
                  className="absolute -bottom-[15px] right-[81px] text-[#32CD32]"
                  size={30}
                />
              )}
            </div>

            {detectionStatus === 'success' && (
              <div className={`flex w-full flex-col items-center justify-center gap-4 py-2`}>
                <div className="flex w-full justify-center space-x-3 text-lg font-semibold">
                  <div className="text-center text-[#404040]">
                    Pixel spacing retrieved successfully
                  </div>
                </div>
                <div className="rounded-md border border-[#A6ABC854] bg-white py-0.5 px-1 text-[#1F384C]">
                  {Number(globalState?.pixelSpacing).toFixed(2)} px
                </div>
              </div>
            )}

            {detectionStatus === 'error' && (
              <div className={`absolute top-0 left-8 right-8 rounded-md bg-white py-2`}>
                <div className="flex w-fit flex-col space-y-0.5 px-2">
                  <div className="flex space-x-2">
                    <IoIosCloseCircle
                      className="text-red-500"
                      size={18}
                    />
                    <div>Failure</div>
                  </div>
                  <div className="text-[#404040]">
                    Failed to get pixel spacing. Please try again
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="item-center flex h-64 w-full flex-col justify-center">
            <div className="loader">
              <Icon name={'deepechoLogo2'} />
            </div>
          </div>
        )}
      </div>
      <div
        className={`${detectionStatus === 'success' ? 'flex flex-col-reverse items-center justify-center gap-5 px-5' : 'absolute bottom-12 justify-between p-5'} flex w-full`}
      >
        <div
          className={`flex w-full  text-[#A6ABC8] hover:text-[#999db3] ${detectionStatus === 'success' ? 'items-center justify-center' : 'justify-start'}`}
        >
          <button
            className={`flex items-center justify-center space-x-2`}
            onClick={() => {
              handleGoBack();
              handleBack();
            }}
          >
            {detectionStatus !== 'success' && <MdArrowBackIosNew />}

            <div>Go Back</div>
          </button>
        </div>

        <div
          className={`flex w-full px-4 ${detectionStatus === 'success' ? 'items-center justify-center' : 'justify-end'}`}
        >
          <button
            className={`rounded-md border border-[#2886c7] bg-[#2886c7] py-1 px-4 text-white hover:bg-white hover:text-[#2886c7] ${isLoading ? 'cursor-not-allowed' : ''}`}
            disabled={isLoading}
            onClick={() => {
              if (detectionStatus === 'success') {
                handleNext();
              } else {
                runDetection();
              }
            }}
          >
            {isLoading ? (
              <div className="inline-flex items-center space-x-2">
                <LuLoader2 className="animate-spin" />
                <span>Please wait</span>
              </div>
            ) : detectionStatus === 'error' ? (
              'Try Again'
            ) : detectionStatus === 'success' ? (
              'Continue'
            ) : (
              'Get Spacing'
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PixelSpacingModal;
