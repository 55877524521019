import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Typography, Select } from '../';
import { useTranslation } from 'react-i18next';

const StudyListPagination = ({ handleNextPage, handlePrevPage, currentPage }) => {
  const { t } = useTranslation('StudyList');

  const navigateToPage = page => {
    const toPage = page < 1 ? 1 : page;
    // onChangePage(toPage);
  };

  return (
    <div className="border-t-2 border-t-gray-500 bg-white py-5 text-black">
      <div className="container relative m-auto px-8">
        <div className="flex justify-end">
          {/* <div className="flex items-center">
            <Select
              id="rows-per-page"
              className="relative mr-3 w-16 border-primary-main"
              options={ranges}
              value={selectedRange}
              isMulti={false}
              isClearable={false}
              isSearchable={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={true}
              onChange={onSelectedRange}
            />
            <Typography className="text-black opacity-60">
              {t('ResultsPerPage')}
            </Typography>
          </div> */}
          <div className="text-black">
            <div className="flex items-center">
              <Typography className="mr-4 text-black opacity-60">
                {t('Page')} {currentPage}
              </Typography>
              <ButtonGroup>
                <Button
                  size="initial"
                  className="px-4 py-2 text-black"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={() => navigateToPage(1)}
                >
                  {`<<`}
                </Button>
                <Button
                  size="initial"
                  className="py-2 px-2 text-black"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={() => handlePrevPage()}
                >
                  {t('Previous')}
                </Button>
                <Button
                  size="initial"
                  className="py-2 px-4 text-black"
                  color="translucent"
                  border="primary"
                  variant="outlined"
                  onClick={() => handleNextPage()}
                >
                  {t('Next')}
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StudyListPagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  onChangePerPage: PropTypes.func.isRequired,
};

export default StudyListPagination;
