import {
  QueryFunctionContext,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { APIClient } from '../apiHelper';
import { AxiosError, AxiosResponse } from 'axios';

export const api = new APIClient();
export type QueryKeyT = [string, object | undefined] | [string] | any;
type Updater<T, S> = (oldData: T | undefined, newData: S) => T;
export interface GetInfinitePagesInterface<T> {
  nextId?: number;
  previousId?: number;
  data: T;
  count: number;
}

export const fetcher = async <T>({
  queryKey,
  pageParam, // to use after for loading more
  signal, // to use after
}: QueryFunctionContext<QueryKeyT>): Promise<any> => {
  const [url, params] = queryKey;
  try {
    const response = await api.get(url, params);
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const useFetch = <T>(
  url: string | null,
  params?: object,
  config?: UseQueryOptions<T, Error, T, QueryKeyT> | any,
  queryKey?: QueryKeyT
) => {
  const context = useQuery<T, Error, T, QueryKeyT>({
    queryKey: [url || '', params, queryKey],
    queryFn: fetcher,
    ...config,
  });

  return context;
};

export const useLoadMore = <T>(url: string | null, params?: object) => {
  const context = useInfiniteQuery<
    GetInfinitePagesInterface<T>,
    Error,
    GetInfinitePagesInterface<T>,
    QueryKeyT
  >({
    queryKey: [url || '', params],
    queryFn: fetcher,
    getNextPageParam: (lastPage, pages) => lastPage.nextId,
    getPreviousPageParam: (lastPage, pages) => lastPage.previousId,
    initialPageParam: null,
  });

  return context;
};

// const useGenericMutation = <T, S>(
//   func: (data: T | S) => Promise<AxiosResponse<S>>,
//   url: string,
//   params?: object,
//   updater?: Updater<T, S>,
//   queryKey?: QueryKeyT
// ) => {
//   const queryClient = useQueryClient();

//   return useMutation<AxiosResponse, AxiosError, T | S>({
//     mutationFn: func,
//     onMutate: async (data) => {
//       const key = queryKey || [url!, params];
//       await queryClient.cancelQueries({ queryKey: key });

//       const previousData = queryClient.getQueryData(key);

//       queryClient.setQueryData<T>(key, (oldData) => {
//         if (updater) {
//           if (Array.isArray(oldData)) {
//             return updater(oldData, data as S);
//           } else {
//             return updater([] as unknown as T, data as S);
//           }
//         } else {
//           return data as T;
//         }
//       });

//       return previousData;
//     },
//     // onSuccess: () => {
//     //   const key = queryKey || [url!, params];
//     //   queryClient.invalidateQueries(key);
//     //   queryClient.refetchQueries(key );
//     // },
//     onError: (err, _, context) => {
//       const key = queryKey || [url!, params];
//       queryClient.setQueryData(key, context);
//     },
//     onSettled: () => {
//       const key = queryKey || [url!, params];
//       queryClient.invalidateQueries(key);
//     },
//   });
// };

const useGenericMutation = <T, S>(
  func: (data: T | S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: Updater<T, S>,
  queryKey?: QueryKeyT,
  onSuccess?: (response: AxiosResponse<S>, data: T | S) => void,
  onError?: (error: any, data: T | S) => void
) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<S>, AxiosError, T | S>({
    mutationFn: func,

    onMutate: async data => {
      const key = queryKey || [url!, params];

      // this line cancel any previous queries with the same key
      await queryClient.cancelQueries({ queryKey: key });

      // Get the previous data
      const previousData = queryClient.getQueryData(key);

      // update the cache with the new data
      queryClient.setQueryData<T>(key, oldData => {
        if (updater) {
          if (Array.isArray(oldData)) {
            return updater(oldData, data as S);
          } else {
            return updater([] as unknown as T, data as S);
          }
        } else {
          return data as T;
        }
      });

      return previousData;
    },
    onSuccess: (response, data) => {
      // add our OnSuccess handler if we have one
      if (onSuccess) {
        onSuccess(response, data);
      }
    },

    onError: (error, data, context) => {
      const key = queryKey || [url!, params];
      // return the cache to the previous state
      queryClient.setQueryData(key, context);
      // add our OnError handler if we have one
      if (onError) {
        onError(error, data);
      }
    },

    onSettled: () => {
      const key = queryKey || [url!, params];
      queryClient.invalidateQueries(key);
    },
  });
};

// export const usePost = <T, S>(
//   url: string,
//   params?: object,
//   updater?: Updater<T, S>
// ) => {
//   const apiPost: (data: T) => Promise<AxiosResponse<S>> = (data) => api.post(url, data);
//   return useGenericMutation<T, S>(
//     apiPost,
//     url,
//     params,
//     updater
//   );
// };

export const usePost = <T, S>(
  url: string,
  params?: object,
  updater?: Updater<T, S>,
  onSuccess?: (response: AxiosResponse<S>, data: T | S) => void,
  onError?: (error: any, data: T | S) => void
) => {
  console.log('usePost', url, params, updater, onSuccess, onError);
  const apiPost: (data: T) => Promise<AxiosResponse<S>> = data => api.post(url, data);

  return useGenericMutation<T, S>(apiPost, url, params, updater, undefined, onSuccess, onError);
};

export const usePut = <T, S>(
  url: string,
  params?: object,
  updater?: Updater<T, S>,
  queryKey?: QueryKeyT
) => {
  const apiPut: (data: T) => Promise<AxiosResponse<S>> = data => api.put(url, data);
  return useGenericMutation<T, S>(apiPut, url, params, updater, queryKey);
};
export const useDelete = <T, S>(url: string, params?: object, updater?: Updater<T, S>) => {
  const apiDelete: (data: T) => Promise<AxiosResponse<S>> = data => api.delete(url, data);
  return useGenericMutation<T, S>(apiDelete, url, params, updater);
};

// export const usePatch = <T, S>(
//   url: string,
//   params?: object,
//   updater?: Updater<T, S>
// ) => {
//   const apiPatch: (data: T) => Promise<AxiosResponse<S>> = (data) => api.patch(url, data);
//   return useGenericMutation<T, S>(
//     apiPatch,
//     url,
//     params,
//     updater
//   );
// }
