import React from 'react';
import { FaArrowTrendDown, FaArrowTrendUp } from 'react-icons/fa6';
import { IconType } from 'react-icons';

interface CardItemsProps {
  title: string;
  icon: IconType;
  totalNumber: number;
  percent: number;
}

const CardItems: React.FC<CardItemsProps> = ({ title, icon: Icon, totalNumber, percent }) => {
  return (
    <div className="flex w-full flex-col rounded-md border border-[#dee2e6] py-5 px-3">
      <div className="flex items-center justify-between text-[#868e96]">
        <div className="text-lg font-semibold">{title}</div>
        <div>
          <Icon size={18} />
        </div>
      </div>
      <div className="mt-5 inline-flex space-x-3">
        <div className="text-2xl font-bold">{totalNumber}</div>
        <div
          className={`inline-flex items-center space-x-2 text-lg font-medium ${percent < 0 ? 'text-[#fa5252]' : 'text-[#19ba89]'} `}
        >
          <div>{Math.abs(percent)}%</div>
          {percent > 0 ? <FaArrowTrendUp /> : <FaArrowTrendDown />}
        </div>
      </div>
      <div className="text-[#868e96]">Compared to previous month</div>
    </div>
  );
};
export default CardItems;
