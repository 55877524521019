import React, { useState } from 'react';
import { MdDelete, MdExpandLess, MdExpandMore } from 'react-icons/md';
import CompactUIDDisplay from './CompactUIDDisplay';
import {
  DicomDataStructure,
  DicomSeries,
  DicomUploadStatus,
  StudySerieUploadInfo,
} from '../../interfaces';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { GiBugleCall } from 'react-icons/gi';
import InstancesErrorTable from './InstancesErrorTable';

interface SeriesTableProps {
  data: DicomSeries[];
  setDicomDataStructure: React.Dispatch<React.SetStateAction<DicomDataStructure>>;
  setProcessedSOPInstanceUIDs: React.Dispatch<React.SetStateAction<Set<string>>>;
  getStatusStyle: (status: string) => { text: string; className: string };
  studySerieUploadInfo?: StudySerieUploadInfo;
}

function SeriesTable({
  data,
  setDicomDataStructure,
  setProcessedSOPInstanceUIDs,
  getStatusStyle,
  studySerieUploadInfo,
}: SeriesTableProps) {
  const [expandedSeriesUIDs, setExpandedSeriesUIDs] = useState<Set<string>>(new Set());

  const toggleSeriesExpansion = (seriesUID: string) => {
    setExpandedSeriesUIDs(prev => {
      const newSet = new Set(prev);
      if (newSet.has(seriesUID)) {
        newSet.delete(seriesUID);
      } else {
        newSet.add(seriesUID);
      }
      return newSet;
    });
  };

  const hasFailedInstances = (series: DicomSeries) => {
    return series.instances.some(instance => instance.upload_status === DicomUploadStatus.Failed);
  };

  const globalStatusSummary = {
    total_files: studySerieUploadInfo?.total_files,
    total_failed: studySerieUploadInfo?.total_failed,
    total_success: studySerieUploadInfo?.total_success,
    total_pending:
      studySerieUploadInfo?.total_files -
      (studySerieUploadInfo.total_success + studySerieUploadInfo.total_failed),
  };

  return (
    <table className="w-full border-collapse rounded-md border border-gray-200 bg-[#F7FAFE] text-sm">
      <thead>
        <tr className="text-left text-[#1F384C]">
          <th className="w-1/6 border border-gray-200 py-1 pl-1">Series UID</th>
          <th className="w-1/6 border border-gray-200 py-1 pl-1">Number of Instances</th>
          <th className="w-1/6 border border-gray-200 py-1 pl-1">Number of Frames</th>
          <th className="w-1/6 border border-gray-200 py-1 pl-1">Modality</th>
          <th className="w-1/6 border border-gray-200 py-1 pl-1">Upload Status</th>
          <th className="w-1/6 border border-gray-200 py-1 pl-1">Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map(series => (
          <React.Fragment key={series.series_instance_uid}>
            <tr className="hover:bg-gray-50">
              <td className="border border-gray-300 py-1 pl-1">
                <CompactUIDDisplay uid={series.series_instance_uid} />
              </td>
              <td className="border border-gray-300 py-1 pl-1">{series.instances.length}</td>
              <td className="border border-gray-300 py-1 pl-1">
                {series.instances.reduce((acc, instance) => acc + (instance.frames || 0), 0)}
              </td>
              <td className="border border-gray-300 py-1 pl-1">
                {series.series_modality || 'N/A'}
              </td>
              <td className="border border-gray-300 py-1 pl-1">
                {getStatusStyle(series?.upload_status || '').text !== 'Uploading' ? (
                  <>
                    <span
                      className={`rounded px-2 py-1 text-xs font-semibold ${getStatusStyle(DicomUploadStatus.Pending || '').className} ${globalStatusSummary.total_pending > 0 ? '' : 'hidden'}`}
                    >
                      {globalStatusSummary.total_pending} Pending
                    </span>
                    <span
                      className={`rounded px-2 py-1 text-xs font-semibold ${getStatusStyle(DicomUploadStatus.Successful || '').className} ${globalStatusSummary.total_success > 0 ? '' : 'hidden'}`}
                    >
                      {globalStatusSummary.total_success} Uploaded
                    </span>
                    <span
                      className={`rounded px-2 py-1 text-xs font-semibold ${getStatusStyle(DicomUploadStatus.Failed || '').className} ${globalStatusSummary.total_failed > 0 ? '' : 'hidden'}`}
                    >
                      {globalStatusSummary.total_failed} Failed
                    </span>
                  </>
                ) : (
                  <span
                    className={`rounded px-2 py-1 text-xs font-semibold ${getStatusStyle(series?.upload_status || '').className}`}
                  >
                    {getStatusStyle(series?.upload_status || '').text}
                  </span>
                )}
              </td>
              <td className="border border-gray-300 py-1 pl-1">
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <MdDelete
                      className="h-5 w-5 text-red-600"
                      onClick={() => {
                        setDicomDataStructure(prevData =>
                          prevData
                            .map(study => ({
                              ...study,
                              series: study.series.filter(
                                seriesItem =>
                                  seriesItem.series_instance_uid !== series.series_instance_uid
                              ),
                            }))
                            .filter(study => study.series.length > 0)
                        );
                        setProcessedSOPInstanceUIDs(prev => {
                          const newSet = new Set(prev);
                          series.instances.forEach(instance => {
                            newSet.delete(instance.sop_instance_uid || '');
                          });
                          return newSet;
                        });
                      }}
                    />
                  </div>
                  <div>
                    {hasFailedInstances(series) && (
                      <button
                        onClick={() => toggleSeriesExpansion(series.series_instance_uid || '')}
                        className="text-gray-600 hover:text-gray-900"
                      >
                        {expandedSeriesUIDs.has(series.series_instance_uid || '') ? (
                          <GiBugleCall className="h-5 w-5 pr-1 text-red-500" />
                        ) : (
                          <GiBugleCall className="h-5 w-5 pr-1 text-red-500" />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </td>
            </tr>
            {expandedSeriesUIDs.has(series.series_instance_uid || '') && (
              <tr>
                <td
                  colSpan={6}
                  className="bg-gray-50 p-2"
                >
                  <InstancesErrorTable
                    series={series}
                    getStatusStyle={getStatusStyle}
                  />
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
}

export default SeriesTable;
