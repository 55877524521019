import React, { useEffect, useRef, useState } from 'react';
import PatientInfoModal from './tempComponents/PatientInfoModal';
import { useGlobalState } from '../../../state/RealtimeGlobalContextProvider';
import ButtonWithCursorMovement from './tempComponents/button-with-cursor-movement';
import PopUp from './tempComponents/device-modal/device-modal';
import SideBar from './tempComponents/right-bar';
import io from 'socket.io-client';
import { decodeRLE, drawMask } from './tempComponents/reusable-functions';
import HeaderContent from './tempComponents/header-content';
import { getJWT, getUser } from '../../../helpers/apiHelper';
import { Modal } from 'antd';
import FooterContent from './tempComponents/footer-content';
import MachineTypePopUp from './tempComponents/machine-type-pop-up';
import GenerateReportModal from './tempComponents/generate-report-modal';
import DevicesModal from './tempComponents/device-modal/device-modal';
import PatientModal from './tempComponents/patient-modal/patient-modal';
import PixelSpacingModal from './tempComponents/pixel-spacing';
import { LuLoader2 } from 'react-icons/lu';
import { CATEGORIES } from './vars';
import { api } from '../../../helpers/backendHelper';
import axios from 'axios';

export enum MediaType {
  USER_MEDIA = 'UserMedia',
  DISPLAY_MEDIA = 'DisplayMedia',
}

const mediaTypeMap = new Map([
  ['Share', MediaType.DISPLAY_MEDIA],
  ['USB', MediaType.USER_MEDIA],
]);

const RealTimeInferencing: React.FC = () => {
  const { globalState, setGlobalState } = useGlobalState();
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [pixelSpacingModalOpen, setPixelSpacingModalOpen] = useState(true);
  const [deviceMediaType, setDeviceMediaType] = useState(MediaType.DISPLAY_MEDIA);
  const [visible, setVisible] = useState(false);

  const [canvasDimensions, setCanvasDimensions] = useState();
  const [latency, setLatency] = useState(null);
  const [examStartTime, setExamStartTime] = useState(null);
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  const [ws, setWs] = useState(null);
  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const sessionIdRef = useRef(null);
  const examinationDurationRef = useRef(0);
  const processedFrames = useRef(new Set());

  const API: string = process.env.BACKEND_URL.replace(/\/api\/v1$/, '');

  useEffect(() => {
    setCanvasDimensions({
      width: globalState?.configMachine?.machine_width,
      height: globalState?.configMachine?.machine_height,
    });
  }, [globalState?.machineType]);

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.width = canvasDimensions.width;
      canvasRef.current.height = canvasDimensions.height;
    }
  }, [canvasRef.current]);

  useEffect(() => {
    const user = getUser();
    const token = user?.access_token;
    setGlobalState(prevState => ({
      ...prevState,
      doctorName: user?.profile.preferred_username,
    }));
    if (globalState.isStart && !globalState.socket) {
      console.log('============================ test API', API);
      const newSocket = io(API, {
        auth: {
          token: `Bearer ${token}`,
        },
        transports: ['websocket'],
      });
      newSocket.on('connect', () => {
        setGlobalState(prevState => ({
          ...prevState,
          socket: newSocket,
          encounterError: false,
        }));
      });

      newSocket.on('disconnect', () => {
        setGlobalState(prevState => ({
          ...prevState,
          socket: null,
        }));
      });

      // Catch connection errors
      newSocket.on('connect_error', error => {
        // Handle the error as needed, e.g., update the state to reflect the error
        setGlobalState(prevState => ({
          ...prevState,
          socket: null,
          encounterError: true,
          error: `Connection Error: ${error.message}`,
        }));
      });

      // Catch any other errors
      newSocket.on('error', error => {
        // Handle the error as needed, e.g., update the state to reflect the error
        setGlobalState(prevState => ({
          ...prevState,
          socket: null,
          encounterError: true,
          error: `Socket Error: ${error.message}`,
        }));
      });
    } else if (!globalState.isStart && globalState.socket) {
      globalState.socket.disconnect();
      setGlobalState(prevState => ({
        ...prevState,
      }));
    } else if (globalState.deviceId == '' && globalState.socket) {
      globalState.socket.disconnect();
      setGlobalState(prevState => ({
        ...prevState,
        socket: null,
        frames: [],
      }));
    }
    return () => {
      if (globalState.socket) {
        globalState.socket.disconnect();
        setGlobalState(prevState => ({
          ...prevState,
          socket: null,
        }));
      }
    };
  }, [
    globalState.isStart,
    globalState.socket,
    globalState.encounterError,
    setGlobalState,
    globalState.deviceId,
    globalState.machineType,
  ]);

  useEffect(() => {
    let frameInterval;
    let startTime;

    const EXAM_DURATION = 60000;

    const handleVideoFrame = () => {
      const currentTime = Date.now();
      const elapsedTime = startTime ? currentTime - startTime : 0;
      const remainingTime = Math.max(0, EXAM_DURATION - elapsedTime);

      // Update the examination duration with remaining seconds
      setGlobalState(prevState => ({
        ...prevState,
        examinationDuration: Math.ceil(remainingTime / 1000),
      }));

      if (remainingTime <= 0) {
        clearInterval(frameInterval);
        // Stop the video and update state when time is up
        if (videoRef.current) {
          videoRef.current.pause();
          setGlobalState(prevState => ({
            ...prevState,
            isStart: false,
            isVideoFrozen: true,
            ratingScore: 0,
            isExamEnded: true,
            examinationDuration: 0, // Timer reached 0
          }));
        }
        setExamStartTime(null);
        return;
      }

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const outputWidth = globalState?.configMachine?.output_width;
      const outputHeight = globalState?.configMachine?.output_height;

      canvas.width = outputWidth;
      canvas.height = outputHeight;

      const cropX = globalState?.configMachine?.crop_x;
      const cropY = globalState?.configMachine?.crop_y;
      const cropWidth = globalState?.configMachine?.crop_width;
      const cropHeight = globalState?.configMachine?.crop_height;

      context.drawImage(
        videoRef.current,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        outputWidth,
        outputHeight
      );
      canvas.toBlob(
        blob => {
          if (blob) {
            const frameId = Date.now();
            globalState.socket.emit('sendFrame', { data: blob, id: frameId });
            console.log('============================ realtime emitted frame', frameId);

            // Update index first before updating frames
            const newIndex = (globalState.index || 0) + 1;
            console.log('New index:', newIndex); // Debug log

            setGlobalState(prevState => ({
              ...prevState,
              index: newIndex,
              frames: [
                ...prevState.frames,
                {
                  id: frameId,
                  blobURL: URL.createObjectURL(blob),
                  response: null,
                },
              ],
            }));
          }
        },
        'image/jpeg',
        1
      );
    };

    const startVideoStreaming = () => {
      startTime = Date.now(); // Set start time when streaming begins
      frameInterval = setInterval(() => {
        if (!videoRef.current.paused && !videoRef.current.ended) {
          handleVideoFrame();
        }
      }, 700);
    };

    const stopVideoStreaming = () => {
      clearInterval(frameInterval);
      startTime = null; // Reset start time
    };

    if (globalState.socket) {
      if (!globalState.isVideoFrozen) {
        startVideoStreaming();
      }
    }

    return () => {
      stopVideoStreaming();
    };
  }, [globalState.isStart, globalState.isVideoFrozen, globalState.socket]);

  useEffect(() => {
    if (!globalState.socket) {
      return;
    }

    // Remove any existing listeners
    globalState.socket.off('processed_frame');

    // Set up single listener for processed frames
    globalState.socket.on('processed_frame', data => {
      // Check if we've already processed this frame
      if (processedFrames.current.has(data.id)) {
        console.log(' Already processed frame:', data.id);
        return;
      }

      console.log('============================ realtime processed_frame', data);
      console.log('============================ real globalState', globalState);

      const now = new Date();

      // Calculate duration since exam start
      if (examStartTime) {
        const duration = now.getTime() - new Date(examStartTime).getTime();
        examinationDurationRef.current = Math.floor(duration / 1000); // Convert to seconds
      }

      processedFrames.current.add(data.id);

      setGlobalState(prevState => ({
        ...prevState,
        frames: prevState.frames.map(frame =>
          frame.id === data.id ? { ...frame, response: data.processed_image } : frame
        ),
        latestResponseFrame: data.id,
        examinationDuration: examinationDurationRef.current,
      }));
    });

    // Set exam start time if not already set
    if (!examStartTime) {
      setExamStartTime(new Date());
    }

    return () => {
      if (globalState.socket) {
        globalState.socket.off('processed_frame');
      }
    };
  }, [globalState.socket, examStartTime]);

  // Add cleanup for processedFrames when component unmounts
  useEffect(() => {
    return () => {
      processedFrames.current.clear();
    };
  }, []);

  function createBlobUrlFromCanvas(blobURL, uniqueReport) {
    console.log('jj test', uniqueReport);
    const offscreenCanvas = document.createElement('canvas');
    const offscreenCtx = offscreenCanvas.getContext('2d');
    offscreenCanvas.width = canvasDimensions.width;
    offscreenCanvas.height = canvasDimensions.height;

    const img = new Image();
    img.src = blobURL;

    return new Promise(resolve => {
      img.onload = function () {
        offscreenCtx.drawImage(img, 0, 0, offscreenCanvas.width, offscreenCanvas.height);

        // Draw the masks on the offscreen canvas
        uniqueReport.forEach(result => {
          const category = CATEGORIES.find(category => category.id === result.label);

          drawMask(
            offscreenCtx,
            decodeRLE(result.mask_realtime, canvasDimensions.width, canvasDimensions.height),
            category.color,
            category.name
          );
        });

        // Convert to Blob and create a Blob URL
        offscreenCanvas.toBlob(blob => {
          if (blob) {
            const blobUrl = URL.createObjectURL(blob);
            resolve(blobUrl);
          } else {
            resolve(null);
          }
        }, 'image/png'); // Change format if needed
      };
    });
  }

  useEffect(() => {
    if (globalState.latestResponseFrame) {
      console.log(
        '============================ realtime latestResponseFrame',
        globalState.latestResponseFrame
      );
      console.log(
        '============================ realtime latest globaleState frames',
        globalState.frames
      );
      console.log(
        '============================ realtime latest globaleState frames',
        globalState.planes
      );

      const latestFrameWithResponse = globalState.frames.find(
        frame => frame.id === globalState.latestResponseFrame
      );

      setLatency(Date.now() - globalState.latestResponseFrame);

      const offscreenCanvas = document.createElement('canvas');
      const offscreenCtx = offscreenCanvas.getContext('2d');
      offscreenCanvas.width = canvasDimensions.width;
      offscreenCanvas.height = canvasDimensions.height;

      const img = new Image();
      img.src = latestFrameWithResponse?.blobURL;
      img.onload = function () {
        offscreenCtx.drawImage(img, 0, 0, offscreenCanvas.width, offscreenCanvas.height);
        offscreenCtx.fillStyle = 'rgba(255, 0, 0, 1)';

        if (latestFrameWithResponse?.response != null) {
          let afPocketScore = 0;
          let afPocketDetected = false;

          const results = latestFrameWithResponse.response?.results;

          if (latestFrameWithResponse.response?.results?.some(obj => obj.label === 12)) {
            const label12Object = latestFrameWithResponse.response?.results.find(
              obj => obj.label === 12
            );

            if (label12Object && 'score' in label12Object) {
              afPocketScore = parseFloat((label12Object.score * 100).toFixed(0));
              afPocketDetected = afPocketScore > 0;
            }
          }

          const qualityResults = latestFrameWithResponse.response?.quality_results;

          if (qualityResults && typeof qualityResults === 'object') {
            let score = 0;
            let planeType = '';
            const qualityCriteria = {};
            let totalCount = 0;
            let structureToDraw = [];
            if ('STD_PLANE__abdominal' in qualityResults) {
              planeType = 'abdominal';
              totalCount = 4;
              if (qualityResults.QUAL_FLAG__abdo_stomach_bubble) {
                score += 25;
                qualityCriteria['QUAL_FLAG__abdo_stomach_bubble'] = true;
                structureToDraw = structureToDraw.concat(results.filter(item => item.label === 6));
              }
              if (qualityResults.QUAL_FLAG__abdo_portal_sinus) {
                score += 25;
                qualityCriteria['QUAL_FLAG__abdo_portal_sinus'] = true;
                structureToDraw = structureToDraw.concat(results.filter(item => item.label === 4));
              }
              if (qualityResults.QUAL_FLAG__abdo_fetal_kidneys_not_visible) {
                score += 25;
                qualityCriteria['QUAL_FLAG__abdo_fetal_kidneys_not_visible'] = true;
                structureToDraw = structureToDraw.concat(results.filter(item => item.label === 7));
              }
              if (qualityResults.QUAL_VAL__ZOOM >= 0.5) {
                score += 25;
                qualityCriteria['QUAL_VAL__ZOOM'] = true;
                structureToDraw = structureToDraw.concat(results.filter(item => item.label === 5));
              }
            } else if ('STD_PLANE__femur' in qualityResults) {
              planeType = 'femur';
              totalCount = 3;
              if (qualityResults.QUAL_VAL__FEMURAL_ANGLE) {
                score += 33.3;
                qualityCriteria['QUAL_VAL__FEMURAL_ANGLE'] = true;
                structureToDraw = structureToDraw.concat(results.filter(item => item.label === 9));
              }
              if (qualityResults.QUAL_VAL__ZOOM >= 0.5) {
                score += 33.3;
                qualityCriteria['QUAL_VAL__ZOOM'] = true;
                structureToDraw = structureToDraw.concat(results.filter(item => item.label === 9));
              }
              if (qualityResults.QUAL_VAL__BOTH_EXTREMITIES_VISIBLE) {
                score += 33.4;
                qualityCriteria['QUAL_VAL__BOTH_EXTREMITIES_VISIBLE'] = true;
                structureToDraw = structureToDraw.concat(results.filter(item => item.label === 10));
              }
            } else if ('STD_PLANE__cephalic' in qualityResults) {
              planeType = 'cephalic';
              totalCount = 4;
              if (qualityResults.QUAL_FLAG__cephal_thalamus) {
                score += 25;
                qualityCriteria['QUAL_FLAG__cephal_thalamus'] = true;
                structureToDraw = structureToDraw.concat(results.filter(item => item.label === 3));
              }
              if (qualityResults.QUAL_FLAG_Cerebellum_not_visble) {
                score += 25;
                qualityCriteria['QUAL_FLAG_Cerebellum_not_visble'] = true;
                structureToDraw = structureToDraw.concat(results.filter(item => item.label === 2));
              }
              if (qualityResults.QUAL_FLAG_cavum_septi_pellucidi) {
                score += 25;
                qualityCriteria['QUAL_FLAG_cavum_septi_pellucidi'] = true;
                structureToDraw = structureToDraw.concat(results.filter(item => item.label === 4));
              }
              if (qualityResults.QUAL_VAL__ZOOM >= 0.5) {
                score += 25;
                qualityCriteria['QUAL_VAL__ZOOM'] = true;
                structureToDraw = structureToDraw.concat(results.filter(item => item.label === 0));
              }
            } else if ('STD_PLANE_af_pocket' in qualityResults) {
              planeType = 'af_pocket';
              structureToDraw = structureToDraw.concat(results.filter(item => item.label === 12));
            }

            if (score > 0 || afPocketDetected) {
              setGlobalState(prevState => {
                const scoreFromQualityCriteria = Object.keys(qualityCriteria).length;

                return {
                  ...prevState,
                  planes: {
                    ...prevState.planes,
                    [planeType]: {
                      ...prevState.planes[planeType],
                      prediction: latestFrameWithResponse.response,
                      frame: latestFrameWithResponse.blobURL,
                      qualityCriteria:
                        (scoreFromQualityCriteria * 100) / totalCount >
                        (prevState.planes[planeType]?.score || 0)
                          ? qualityCriteria
                          : prevState.planes[planeType]?.qualityCriteria || qualityCriteria,
                      score: Math.max(
                        prevState.planes[planeType]?.score || 0,
                        (scoreFromQualityCriteria * 100) / totalCount
                      ),
                      imgReport: createBlobUrlFromCanvas(
                        latestFrameWithResponse.blobURL,
                        structureToDraw
                      ),
                    },
                    af_pocket: {
                      ...prevState.planes.af_pocket,
                      ...(afPocketDetected && {
                        prediction: latestFrameWithResponse.response,
                        frame: latestFrameWithResponse.blobURL,
                        score: Math.max(prevState.planes.af_pocket?.score || 0, afPocketScore),
                        imgReport: createBlobUrlFromCanvas(
                          latestFrameWithResponse.blobURL,
                          structureToDraw
                        ),
                      }),
                    },
                  },
                  ratingScore: Math.max(score, afPocketScore),
                };
              });
            }
          }
        } else {
          setGlobalState(prevState => ({
            ...prevState,
            ratingScore: 0,
          }));
        }
        const results = latestFrameWithResponse?.response?.results;
        if (Array.isArray(results)) {
          results.forEach(result => {
            const category = CATEGORIES.find(category => category.id === result.label);
            if (category) {
              drawMask(
                offscreenCtx,
                decodeRLE(result.mask_realtime, canvasDimensions.width, canvasDimensions.height),
                category.color,
                category.name
              );
            }
          });
        } else {
          console.error('results is not an array:', results);
        }

        const ctx = canvasRef.current.getContext('2d');
        ctx.drawImage(offscreenCanvas, 0, 0);
      };

      setGlobalState(prevState => ({
        ...prevState,
        frames: prevState.frames.filter(frame => frame.id !== globalState.latestResponseFrame),
        latestResponseFrame: null,
        ratingScore: 0,
      }));
    }
  }, [globalState.latestResponseFrame]);

  useEffect(() => {
    const getMediaStream = async constraints => {
      try {
        let stream;

        if (globalState.optionValueDevice === 'Share') {
          stream = await navigator.mediaDevices.getDisplayMedia(constraints);
        } else if (globalState.optionValueDevice === 'USB') {
          stream = await navigator.mediaDevices.getUserMedia(constraints);
        }

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          console.log('stream,', stream);
          setIsVideoLoading(false);
          const videoTrack = stream.getVideoTracks()[0];
          const { width, height } = videoTrack.getSettings();

          if (width * height <= 450000) {
            setCanvasDimensions({ width, height });
          }

          setGlobalState(prevState => ({
            ...prevState,
            isVideoFrozen: !prevState.isVideoFrozen,
          }));
        }
      } catch (error) {
        console.error('Error accessing the video stream: ', error);
      }
    };

    if (navigator.mediaDevices && globalState.deviceId !== '') {
      const constraints = {
        // video: mediaType === MediaType.USER_MEDIA
        //   ? { deviceId: { exact: globalState.deviceId }, width: canvasDimensions.width, height: canvasDimensions.height }
        //   : {},
        video:
          globalState.optionValueDevice === 'USB'
            ? {
                deviceId: { exact: globalState.deviceId },
                width: canvasDimensions.width,
                height: canvasDimensions.height,
              }
            : {},
      };

      getMediaStream(constraints);
    }
  }, [
    globalState.deviceId,
    deviceMediaType,
    globalState.optionValueDevice,
    globalState?.selectedItem,
  ]);

  const toggleVideoFreeze = () => {
    setGlobalState(prevState => ({
      ...prevState,
      isVideoFrozen: !prevState.isVideoFrozen,
    }));
    if (videoRef.current) {
      if (globalState.isVideoFrozen) {
        // Clear session data when starting after timer reached 0 or after exam ended
        if (globalState.isExamEnded || globalState.examinationDuration === 0) {
          setGlobalState(prevState => ({
            ...prevState,
            frames: [],
            planes: {},
            sessionId: null,
            ratingScore: 0,
            isExamEnded: false,
            examinationDuration: 60, // Reset to 1 minute
            error: '',
          }));
          processedFrames.current.clear();
        }
        videoRef.current.play();
        setGlobalState(prevState => ({
          ...prevState,
          isStart: true,
          isExamEnded: false,
          error: '',
        }));
        if (!examStartTime) {
          setExamStartTime(Date.now());
        }
      } else {
        videoRef.current.pause();
        setGlobalState(prevState => ({
          ...prevState,
          isStart: false,
          ratingScore: 0,
          isExamEnded: false,
        }));
        if (examStartTime && globalState.socket) {
          setGlobalState(prevState => ({
            ...prevState,
            isExamEnded: false,
            examinationDuration:
              (prevState.examinationDuration || 0) + (Date.now() - examStartTime),
          }));
          setExamStartTime(null);
        } else if (examStartTime && !globalState.socket) {
          console.warn('Socket not connected. Examination duration not updated.');
          setExamStartTime(null);
        }
      }
    }
  };

  const mediaTypeSwitcher = () => {
    mediaTypeMap.forEach((mediaType, option) => {
      if (globalState.optionValueDevice === option) {
        setDeviceMediaType(mediaType);
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1343) {
        setGlobalState(prevState => ({
          ...prevState,
          openPanel: false,
        }));
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    sessionIdRef.current = globalState.sessionId;
    examinationDurationRef.current = globalState.examinationDuration;

    const saveExaminationStats = async () => {
      if (globalState.isStart && !globalState.examinationDuration) {
        console.log(
          'Condition met: globalState.isStart =',
          globalState.isStart,
          'examinationDuration = 0'
        );

        try {
          // Generate a random session ID
          const randomSessionId =
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15) +
            Date.now().toString(36);

          // Store the random session ID in globalState
          setGlobalState(prevState => ({
            ...prevState,
            sessionId: randomSessionId,
          }));

          sessionIdRef.current = randomSessionId;

          const response = await api.post('/start_examination_session', {
            session_id: randomSessionId,
          });
          console.log('Examination session started:', response.data);
        } catch (error) {
          console.error('Error starting examination session:', error);
        }
      } else if (
        globalState.isExamEnded &&
        globalState.sessionId &&
        globalState.examinationDuration
      ) {
        try {
          const response = await api.post('/save_examination_session_stats', {
            session_id: globalState.sessionId,
            examination_duration: globalState.examinationDuration,
          });
          examinationDurationRef.current = globalState.examinationDuration;

          console.log('Examination session ended:', response.data);
          if (globalState.isDeviceChange) {
            window.location.reload();
          } else {
            setGlobalState(prevState => ({
              ...prevState,
              sessionId: null,
              examinationDuration: 0,
              isExamEnded: false,
            }));
          }
        } catch (error) {
          console.error('Error ending examination session:', error);
        }
      } else if (globalState.isExamEnded && globalState.isDeviceChange) {
        window.location.reload();
      }
    };

    saveExaminationStats();

    // Function to save stats before unload
    const saveStatsBeforeUnload = async event => {
      if (sessionIdRef.current && (examinationDurationRef.current || examStartTime)) {
        event.preventDefault(); // Cancel the event as stated by the standard
        event.returnValue = ''; // Chrome requires returnValue to be set

        try {
          await api.post('/save_examination_session_stats', {
            session_id: sessionIdRef.current,
            examination_duration: examinationDurationRef.current
              ? examinationDurationRef.current
              : Date.now() - examStartTime,
          });
        } catch (error) {
          console.error('Error saving examination stats before unload:', error);
        }
      }
    };

    // Add event listener for beforeunload
    window.addEventListener('beforeunload', saveStatsBeforeUnload);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('beforeunload', saveStatsBeforeUnload);
    };
  }, [globalState.isStart, globalState.isExamEnded, globalState.examinationDuration]);

  return (
    <div
      className={`relative mx-auto flex h-full flex-col bg-[#fcfcfc] ${globalState?.openGR ? '' : 'pt-3'} `}
    >
      {/* <PatientInfoModal globalState={globalState} setGlobalState={setGlobalState} firstName={globalState?.firstName} lastName={globalState?.lastName} patient={[{firstName: "Souad", lastName: "Zaydoun"}]}/> */}
      {/* <PatientModal globalState={globalState} setGlobalState={setGlobalState} firstName={globalState?.firstName} lastName={globalState?.lastName}/> */}
      {/* {
        (
          globalState.isVideoFrozen && !isVideoLoading && !localStorage.getItem(`${globalState.machineType}`)) && (
          <PixelSpacingModal globalState={globalState} setGlobalState={setGlobalState} videoRef={videoRef} canvasDimensions={canvasDimensions} canvasRef={canvasRef}/>
        )
      } */}
      <GenerateReportModal
        globalState={globalState}
        setGlobalState={setGlobalState}
      />
      <div className="relative flex h-full w-full justify-between">
        <div
          className={`${globalState.deviceId === '' || globalState?.selectedItem === 'Select your machine' ? '' : 'relative'} flex h-full w-full flex-1 items-center justify-center bg-[#fcfcfc]`}
          // className={`${globalState.deviceId === ''   ? '' : 'relative'} bg-[#fcfcfc] flex h-full w-full flex-1 items-center justify-center`}
        >
          <div
            className={` flex h-full ${globalState.openPanel ? 'w-[100%]' : 'w-[94%]'} items-center justify-center bg-[#fcfcfc] text-black`}
          >
            <div
              className={`absolute top-0 z-30 ${globalState.openPanel ? 'w-[100%]' : 'w-[94%]'} space-y-2 bg-[#fcfcfc] p-2 ${globalState.deviceId ? '' : 'hidden'}`}
            >
              {globalState.deviceId && (
                <div className="flex w-full justify-between">
                  <div className="w-full">
                    <HeaderContent
                      firstName={globalState?.firstName}
                      lastName={globalState?.lastName}
                      age={globalState?.maternalAge}
                      setGlobalState={setGlobalState}
                      lmp={globalState?.lmp}
                      globalState={globalState}
                      latency={latency}
                    />
                  </div>
                </div>
              )}
            </div>
            <ButtonWithCursorMovement
              visible={visible}
              begin={globalState.isVideoFrozen}
              setBegin={toggleVideoFreeze}
              deviceId={globalState.deviceId}
            />

            {globalState.deviceId === '' || globalState.selectedItem === 'Select your machine' ? (
              <>
                <DevicesModal
                  mediaTypeSwitcher={mediaTypeSwitcher}
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                  inputDevices={globalState.inputDevices}
                  api={API}
                  token={getUser()?.access_token}
                  setDeviceId={value =>
                    setGlobalState(prevState => ({
                      ...prevState,
                      deviceId: value,
                    }))
                  }
                  setInputDevices={value =>
                    setGlobalState(prevState => ({
                      ...prevState,
                      inputDevices: value,
                    }))
                  }
                />
              </>
            ) : (
              <div className="relative flex h-full w-full items-center justify-center">
                <div
                  className={`${isVideoLoading ? "w-[calc(100%-5%)]' visible absolute top-0 z-50 flex h-[calc(100%-2%)] items-center justify-center" : 'hidden'}`}
                >
                  <div className="inline-flex items-center space-x-2">
                    <LuLoader2 className="animate-spin" />
                    <span>Please wait</span>
                  </div>
                </div>
                <>
                  <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    width={canvasDimensions.width}
                    height={canvasDimensions.height}
                    className={`max-h-full ${globalState.openPanel ? 'w-[90%]' : 'w-[94%]'} ${globalState?.isStart ? 'hidden' : 'visible'}`}
                  />
                  <canvas
                    ref={canvasRef}
                    width={canvasDimensions.width}
                    height={canvasDimensions.height}
                    className={`max-h-full max-w-full ${
                      globalState?.isVideoFrozen || !globalState?.isStart ? 'hidden' : 'visible'
                    }`}
                  />
                  {globalState?.isStart && globalState?.encounterError && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                      <div className="rounded-lg bg-white p-6 shadow-lg">
                        <h2 className="mb-4 text-2xl font-bold text-red-600">Connection Error</h2>
                        <p className="text-gray-700">
                          An error occurred while connecting to the socket server. Please try again.
                        </p>
                      </div>
                    </div>
                  )}
                </>
              </div>
            )}
          </div>
        </div>
        {globalState.deviceId !== '' && globalState.selectedItem !== 'Select your machine' && (
          <SideBar frames={globalState?.frames} />
        )}
      </div>
    </div>
  );
};

export default RealTimeInferencing;
