import React from 'react';
import { CiImageOff } from 'react-icons/ci';
import { SlCheck } from 'react-icons/sl';
import { TiDeleteOutline } from 'react-icons/ti';

function ReportCard({
  imgPlan,
  label,
  quality_score,
  quality_criteria,
  isInReport,
  selectedImageReport,
}) {
  console.log('ReportCard props:', { imgPlan, label, quality_score, quality_criteria });
  return (
    <>
      {isInReport && (
        <div className="flex rounded-md border border-[#A6ABC8] bg-[#F7FAFE] p-2 ">
          <div className="h-44 w-64">
            <img
              src={selectedImageReport}
              className="h-44 w-64"
              alt={label}
            />
          </div>

          <div className="flex w-full flex-col space-y-3 text-sm">
            <div className="flex w-full items-center justify-between px-2">
              <div className="flex w-full justify-start text-lg font-semibold text-[#1F384C]">
                {label}
              </div>
              <div className="flex w-full justify-end ">
                <span className="font-bold">{quality_score}% </span> (quality score)
              </div>
            </div>
            <div className="flex h-full flex-col px-2 text-base font-semibold">
              <div className="text-lg text-[#1F384C]">Quality Criteria</div>
              {quality_criteria?.length > 0 ? (
                <>
                  {quality_criteria.map((item, i) => (
                    <div
                      key={i}
                      className="mt-2 flex h-full items-center justify-between 
       border-b border-white py-0.5 text-xs text-[#686C82]"
                    >
                      <div className="flex w-full justify-start">{item?.title}</div>
                      <div
                        className={`flex w-full justify-end ${item?.bool ? 'text-[#32CD32]' : 'text-[#FF4747]'}`}
                      >
                        {item?.bool ? (
                          <SlCheck
                            size={14}
                            className="mr-0.5"
                          />
                        ) : (
                          <TiDeleteOutline size={18} />
                        )}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex h-full w-full items-center justify-center text-[#686C82]">
                  No quality criteria
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ReportCard;
