import axios from 'axios';

import { User } from 'oidc-client-ts';

axios.defaults.baseURL = process.env.BACKEND_URL;

axios.defaults.withCredentials = true;

// axios.defaults.headers.post['Content-Type'] = 'application/json';

const token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null;
if (token) axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

// intercepting to capture errors
axios.interceptors.response.use(
  async function (response) {
    return response.data ? response.data : response;
  },
  async function (error) {
    let message;
    const originalRequest = error.config;
    switch (error?.response?.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      case 404:
        message = 'Sorry! the data you are looking for could not be found';
        break;
      default:
        message = error?.response?.data?.detail || error;
    }
    return Promise.reject(message);
  }
);

const setAuthorization = token => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

function getUser() {
  //test
  const client_id = process.env.CLIENT_ID;
  const authority_url = process.env.AUTHORITY_URL;
  const oidcStorage = localStorage.getItem(`oidc.user:${authority_url}:${client_id}`);

  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

class APIClient {
  get = (url, params) => {
    const user = getUser();
    const token = user?.access_token;
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : '';
      response = axios.get(`${url}?${queryString}`, {
        ...params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      response = axios.get(`${url}`, {
        ...params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return response;
  };
  getImages = async url => {
    const user = getUser(); // Assuming getUser() retrieves user information
    const token = user?.access_token;

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', // Adjust content type as needed
        },
        responseType: 'arraybuffer', // Specify response type as arraybuffer for binary data
      };

      const response = await axios.get(url, config);
      const arrayBufferToBase64 = arrayBuffer => {
        let binary = '';
        const bytes = new Uint8Array(arrayBuffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
      };
      // Convert the arraybuffer response to base64
      const base64String = arrayBufferToBase64(response);
      const imageUrl = `data:image/jpeg;base64,${base64String}`;

      return imageUrl;
    } catch (error) {
      console.error('Error fetching image:', error);
      throw error; // Propagate error to handle it at the caller level
    }
  };
  getUltrasoundMachines = async () => {
    try {
      const machines = await this.get('/ultrasound_machines');
      console.log('Ultrasound Machines:', machines);
      return machines; // Optionally return machines for further processing
    } catch (error) {
      console.error('Error fetching ultrasound machines:', error);
      throw error; // Propagate error if necessary
    }
  };
  addUltrasoundMachine = async newRow => {
    const user = getUser(); // Get user to retrieve the access token
    const token = user?.access_token;

    try {
      const response = await axios.post('/ultrasound_machine', newRow, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the headers
          'Content-Type': 'application/json', // Ensure the content type is set to JSON
        },
      });
      console.log('Ultrasound Machine added successfully:', response.data);
      return response.data; // Return the response data if needed
    } catch (error) {
      console.error('Error adding ultrasound machine:', error);
      throw error; // Propagate the error to be handled at the caller level
    }
  };
  updateUltrasoundMachine = async updatedRow => {
    const user = getUser(); // Get user to retrieve the access token
    const token = user?.access_token;

    try {
      const response = await axios.put(`/ultrasound_machines/${updatedRow.acronyme}`, updatedRow, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the headers
          'Content-Type': 'application/json', // Ensure the content type is set to JSON
        },
      });
      console.log('Ultrasound Machine updated successfully:', response.data);
      return response.data; // Return the response data if needed
    } catch (error) {
      console.error('Error updating ultrasound machine:', error);
      throw error; // Propagate the error to be handled at the caller level
    }
  };
  post = (url, data, config = {}) => {
    const user = getUser();
    const token = user?.access_token;
    return axios.post(url, data, {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    });
  };
  put = (url, data) => {
    const user = getUser();
    const token = user?.access_token;
    return axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  delete = (url, data) => {
    const user = getUser();
    const token = user?.access_token;
    return axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}

const getJWT = () => {
  const jwt = localStorage.getItem('access_token');
  if (!jwt) {
    return null;
  } else {
    return jwt;
  }
};

const apiClient = new APIClient();

export { APIClient, setAuthorization, getJWT, getUser, apiClient };
