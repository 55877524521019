import { useGlobalState } from '../../../../../state/RealtimeGlobalContextProvider';
import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';

const ButtonWithCursorMovement = ({ begin, setBegin, deviceId }) => {
  const [isDragging, setIsDragging] = useState(false);
  return (
    <>
      <button
        className={`${
          deviceId ? 'visible' : 'invisible'
        } aligh-center rounded-[50%]} absolute z-10 flex h-full w-full cursor-pointer justify-center  text-white ${!begin ? '' : 'bg-black/30 backdrop-blur-sm'}`}
        onClick={() => {
          if (!isDragging) {
            setBegin(!begin);
          }
        }}
      ></button>
    </>
  );
};

export default ButtonWithCursorMovement;
