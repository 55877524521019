import { GlobalReportSearchParams } from '../../../UI/interfaces';
import { QueryKeyT, useFetch, usePut } from '../../reactQuery_old/reactQuery';
import * as url from '../../urlHelper';

export const useGetRecentReports = () => useFetch(`${url.GET_ALL_REPORTS}`);
// export const useGetStateReport = (StudyUID: string) => useFetch(`${url.GET_STATE_REPORT}/${StudyUID}`)
// export const useGetStateReport = (StudyUID: string, queryKey?: QueryKeyT) =>
//     useFetch(`${url.GET_STATE_REPORT}/${StudyUID}`, undefined, undefined, queryKey);

export const useUpdateStateReport = (
  queryKey?: QueryKeyT,
  updater?: (oldData: any[], newData: any) => any[]
) => usePut(`${url.UPDATE_STATE_REPORT}`, undefined, updater, queryKey);

export const useGetGlobalReportByStudyId = (StudyUID: string, queryKey?: QueryKeyT) =>
  useFetch(`${url.GET_REPORT}/${StudyUID}`, undefined, { enabled: !!StudyUID }, queryKey);

export const useGetSrReport = (
  globalReportSearchParams: GlobalReportSearchParams,
  queryKey?: QueryKeyT
) => {
  if (globalReportSearchParams?.date?.length === 0) {
    return;
  }
  return useFetch(
    `${url.GET_GLOBAL_REPORT}`,
    globalReportSearchParams,
    { enabled: !!globalReportSearchParams },
    queryKey
  );
};

export const useGetGlobalReport = (StudyUID: string, queryKey?: QueryKeyT) =>
  useFetch(`${url.GET_STATE_REPORT}/${StudyUID}`, undefined, { enabled: !!StudyUID }, queryKey);
