import {
  API_ERROR,
  API_SUCCESS,
  GET_REPORTS,
  RESET_REPORTS_STATE,
  GET_REPORT,
  RESET_REPORT_STATE,
  GET_STATE_REPORT,
} from './actionType';

const INIT_STATE = {
  reports: [],
  report: {},
  stateReport: {},
  error: {},
  totalCount: 0,
  hasMore: false,
  loading: false,
  success: false,
  filtered_conditions: {},
};

const Report = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORTS:
      return {
        ...state,
        loading: true,
      };
    case GET_REPORT:
      return {
        ...state,
        loading: true,
      };
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_REPORTS:
          return {
            ...state,
            reports: action.payload?.data?.data,
            loading: false,
            success: true,
            totalCount: action.payload?.data?.total_count,
            hasMore: action.payload?.data?.has_more,
            filtered_conditions: action.payload?.data?.filtered_conditions,
          };
        case GET_REPORT:
          return {
            ...state,
            report: action.payload.data,
            loading: false,
            success: true,
          };
        case GET_STATE_REPORT:
          return {
            ...state,
            stateReport: action.payload.data,
            loading: false,
            success: true,
          };
        case RESET_REPORTS_STATE:
          return {
            ...state,
            reports: [],
            report: {},
            totalCount: 0,
            hasMore: false,
            error: {},
            filtered_conditions: {},
            loading: false,
            success: false,
          };
        case RESET_REPORT_STATE:
          return {
            ...state,
            report: {},
            error: {},
            loading: false,
            success: false,
          };
        default:
          return { ...state };
      }
    case API_ERROR:
      switch (action.payload.actionType) {
        case GET_REPORTS:
          return {
            ...state,
            error: action.payload.error,
            isReportSuccess: false,
          };

        default:
          return { ...state };
      }

    default:
      return { ...state };
  }
};

export default Report;
