import { Button, DatePicker } from 'antd';
import React, { useState } from 'react';
import locale from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { Patient } from '../../Sections/PatientsList/patientsList.section.custom';
import { toast } from 'react-toastify';

import Input from '../../Input/Input.component';
import { useAddPatient } from '../../../../helpers/api/patient/patient.api';

interface errorPatient {
  input_name: string;
  type: string;
  message: string;
  status: boolean;
}
interface MinimunPatientFormProps {
  handlePatientAddClick: (patient: Patient) => void;
}
function MinimunPatientForm({ handlePatientAddClick }: MinimunPatientFormProps) {
  const [error, setError] = useState<Map<string, errorPatient>>();
  // to change empty string object here
  const [newPatient, setNewPatient] = useState({
    patient_name: '',
    patient_last_name: '',
    birth_date: '',
  });
  // to change any here
  const onSuccess = (response: any, data: any) => {
    if (response) {
      toast.success('Patient added successfully');
      handlePatientAddClick(response);
      setNewPatient({
        patient_name: '',
        patient_last_name: '',
        birth_date: '',
      });
    }
  };
  const onError = (error: any, data: any) => {
    const formatedError = new Map<string, errorPatient>(
      error.map((item: any) => [
        item.loc[1],
        {
          input_name: item.loc[1],
          type: item.type,
          message: item.msg,
          status: true,
        },
      ])
    );
    setError(formatedError);
  };
  const mutationAdd = useAddPatient(
    (oldData, newData) => [...oldData, newData],
    onSuccess,
    onError
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      patient_name: newPatient?.patient_name || '',
      patient_last_name: newPatient?.patient_last_name || '',
      birth_date: newPatient?.birth_date ? dayjs(newPatient.birth_date).format('YYYY-MM-DD') : '',
    },
    validationSchema: Yup.object({
      patient_name: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, 'First Name should be alphabets')
        .required('Please Enter the First Name'),
      patient_last_name: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, 'Last Name should be alphabets')
        .required('Please Enter the Last Name'),
      birth_date: Yup.date()
        .nullable()
        .transform((value, originalValue) =>
          dayjs(originalValue, 'YYYY-MM-DD', true).isValid() ? value : new Date('')
        )
        .typeError('Date of Birth must be in the format YYYY-MM-DD')
        .required('Please Enter a valide date'),
    }),
    onSubmit: values => {},
  });

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    validation.handleSubmit();
  }
  const handleDatePicker = (date: dayjs.Dayjs, dateString: string) => {
    setNewPatient(prevPatient => ({
      ...prevPatient,
      birth_date: typeof dateString === 'string' ? dateString : '',
    }));
    validation.setFieldValue('birth_date', dateString);
  };
  const handlemsgError = msg => {
    return msg.replace(/^Value error, /, '');
  };
  const handleNewPatientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewPatient(prevPatient => ({ ...prevPatient, [name]: value }));
  };
  function HandleClickAdd() {
    mutationAdd.mutateAsync(newPatient);
  }
  return (
    <div className="w-full">
      <form
        onSubmit={handleSubmit}
        className="flex items-center justify-between"
      >
        <div className="my-3 flex w-full items-center justify-between">
          <div className="flex items-center space-x-2 ">
            <div className={`input-container relative [&>input]:border [&>input]:border-red-500`}>
              <label className="absolute left-0 top-0 z-10 ml-1 text-[8px] font-medium text-[#7B7B7B]">
                {error?.get('patient_name') ? (
                  <div className="text-red-500">
                    {handlemsgError(error?.get('patient_name')?.message)}
                  </div>
                ) : validation.errors.patient_name ? (
                  <div className="text-red-500">Please enter a valid name</div>
                ) : newPatient?.patient_name === '' || !newPatient?.patient_name ? (
                  ''
                ) : (
                  'First Name'
                )}
              </label>

              <Input
                type="text"
                placeholder="First Name"
                onChange={handleNewPatientChange}
                value={newPatient?.patient_name}
                onBlur={validation.handleBlur}
                className="h-7"
                id="patientName"
                name="patient_name"
                invalid={validation.errors.patient_name ? true : false}
                inerror={error?.get('patient_name') ? true : false}
              />
            </div>
            <div className="input-container relative">
              {/* <label className="text-[#7B7B7B] text-[8px] font-medium left-0 z-10 ml-1 absolute top-0">
                                {newPatient?.patient_last_name === '' || !newPatient?.patient_last_name ? '' : 'Last Name'}
                                
                            </label> */}
              <label className="absolute left-0 top-0 z-10 ml-1 text-[8px] font-medium text-[#7B7B7B]">
                {error?.get('patient_last_name') ? (
                  <div className="text-red-500">
                    {handlemsgError(error?.get('patient_last_name')?.message)}
                  </div>
                ) : validation.touched.patient_last_name && validation.errors.patient_last_name ? (
                  <div className="text-red-500">Please enter a valid name</div>
                ) : newPatient?.patient_last_name === '' || !newPatient?.patient_last_name ? (
                  ''
                ) : (
                  'Last Name'
                )}
              </label>
              <Input
                placeholder="Last Name"
                type="text"
                className="h-7"
                value={newPatient?.patient_last_name}
                onChange={handleNewPatientChange}
                onBlur={validation.handleBlur}
                invalid={validation.errors.patient_last_name ? true : false}
                id="lastName"
                name="patient_last_name"
                inerror={error?.get('patient_last_name') ? true : false}
              />
            </div>
            <div className="input-container relative">
              <label className="absolute left-0 top-0 z-10 ml-1 text-[8px] font-medium text-[#7B7B7B]">
                {error?.get('birth_date') ? (
                  <div className="text-red-500">Invalid date format</div>
                ) : validation.errors.birth_date ? (
                  <div className="text-red-500">{validation.errors.birth_date}</div>
                ) : newPatient?.birth_date === '' || !newPatient?.birth_date ? (
                  ''
                ) : (
                  'Date of birth'
                )}
              </label>
              <DatePicker
                placeholder="Date of birth"
                value={newPatient?.birth_date ? dayjs(newPatient.birth_date) : null}
                className={`main-input-void mt-0  h-7 w-full rounded-md bg-white px-3 py-2 text-sm placeholder-opacity-50 ${
                  error?.get('birth_date') ||
                  (validation.errors.birth_date && validation.touched.birth_date)
                    ? 'border border-red-500'
                    : ''
                }`}
                onChange={handleDatePicker}
                maxDate={dayjs()}
                id="birth_date"
                name="birth_date"
                locale={locale}
                status={error?.get('birth_date') || validation.errors.birth_date ? 'error' : ''}
              />
            </div>
          </div>
          <button
            className="flex h-7 items-center rounded-md border border-[#005dd4] bg-[#F7FAFE] px-4 py-2 text-[#2886C7]"
            type="submit"
            onClick={HandleClickAdd}
          >
            Add Patient
          </button>
        </div>
      </form>
    </div>
  );
}

export default MinimunPatientForm;
