import React, { useState, useEffect } from 'react';
import { ZoomInOutlined, PercentageOutlined } from '@ant-design/icons';
import MeasurementBlock from '../MeasurementBlock/MeasurementBlock';
import { set } from 'date-fns';

interface PlaneInformationProps {
  planeInfo?: {
    QUAL_VAL__ZOOM?: number;
    QUAL_VAL__ML__SCORE?: number;
    MEAS__BPD?: number;
    MEAS__HC?: number;
    MEAS__SDP?: number;
    MEAS__AC?: number;
    MEAS__FL?: number;
    QUAL_VAL__ANGLE?: number;
    QUAL_VAL__VISIBILITY?: {
      visible?: {
        cephalic_plane_ellipse_segment?: boolean;
        cavum_septum_pellucidum_segment_polygon?: boolean;
        thalamus_segment?: boolean;

        abdominal_biometry_plane_ellipse_segment?: boolean;
        portal_sinus_segment?: boolean;
        stomach_bubble_segment?: boolean;

        femur_extremities_box_segment?: boolean;
      };
      absent?: {
        cerebellum_segment_polygon?: boolean;

        fetal_kidneys_segment_polygon?: boolean;
      };
    };
  };
  selectedPlane?: 'abdominal' | 'cephalic' | 'femur' | 'afpocket';
  selectedFrame?: number;
  data?: any;
  drawingMode: boolean;
}

const PlaneInformation: React.FC<PlaneInformationProps> = ({
  planeInfo,
  selectedPlane,
  selectedFrame,
  data,
  drawingMode,
}) => {
  const [zoom, setZoom] = useState<string>('N/A');
  const [confidenceScore, setConfidenceScore] = useState<string>('N/A');
  const [bpd, setBpd] = useState<string>('N/A');
  const [hc, setHc] = useState<string>('N/A');
  const [sdp, setSdp] = useState<string>('N/A');
  const [ac, setAc] = useState<string>('N/A');
  const [fl, setFl] = useState<string>('N/A');
  const [CerebellumVisible, setCerebellumVisible] = useState<boolean | undefined>(undefined);
  const [cavumSeptumPellucidumVisible, setCavumSeptumPellucidumVisible] = useState<
    boolean | undefined
  >(undefined);
  const [thalamusVisible, setThalamusVisible] = useState<boolean | undefined>(undefined);

  const [portalSinusSegment, setPortalSinusSegment] = useState<boolean | undefined>(undefined);
  const [stomachBubbleSegment, setStomachBubbleSegment] = useState<boolean | undefined>(undefined);
  const [fetalKidneysSegment, setFetalKidneysSegment] = useState<boolean | undefined>(undefined);
  const [femurExtremitiesBoxSegment, setFemurExtremitiesBoxSegment] = useState<boolean | undefined>(
    undefined
  );

  const [angle, setAngle] = useState<string>('N/A');

  // Update state whenever planeInfo or selectedPlane changes
  useEffect(() => {
    if (planeInfo) {
      setZoom(planeInfo?.QUAL_VAL__ZOOM?.toFixed(2) ?? 'N/A');
      setConfidenceScore((planeInfo?.QUAL_VAL__ML__SCORE * 100).toFixed(2) ?? 'N/A');
      setBpd(planeInfo?.MEAS__BPD?.toFixed(2) ?? 'N/A');
      setHc(planeInfo?.MEAS__HC?.toFixed(2) ?? 'N/A');
      setSdp(planeInfo?.MEAS__SDP?.toFixed(2) ?? 'N/A');
      setAc(planeInfo?.MEAS__AC?.toFixed(3) ?? 'N/A');
      setFl(planeInfo?.MEAS__FL?.toFixed(2) ?? 'N/A');
      setAngle(planeInfo?.QUAL_VAL__ANGLE?.toString() ?? 'N/A');
      setCerebellumVisible(planeInfo?.QUAL_VAL__VISIBILITY?.absent?.cerebellum_segment_polygon);

      setCavumSeptumPellucidumVisible(
        planeInfo?.QUAL_VAL__VISIBILITY?.visible?.cavum_septum_pellucidum_segment_polygon
      );
      setThalamusVisible(planeInfo?.QUAL_VAL__VISIBILITY?.visible?.thalamus_segment);

      setPortalSinusSegment(planeInfo?.QUAL_VAL__VISIBILITY?.visible?.portal_sinus_segment);
      setStomachBubbleSegment(planeInfo?.QUAL_VAL__VISIBILITY?.visible?.stomach_bubble_segment);
      setFetalKidneysSegment(
        planeInfo?.QUAL_VAL__VISIBILITY?.absent?.fetal_kidneys_segment_polygon
      );

      setFemurExtremitiesBoxSegment(
        planeInfo?.QUAL_VAL__VISIBILITY?.visible?.femur_extremities_box_segment
      );
    }
  }, [planeInfo, data, selectedPlane, selectedFrame]);
  return (
    <div className="mt-2 flex h-full w-full flex-col justify-between">
      <div className="flex w-full items-center justify-between">
        <span className="text-lg font-bold text-gray-700">Information</span>
        <span className="text-xs font-bold text-gray-800">Frame {selectedFrame ?? 'N/A'}</span>
      </div>
      <div className="flex w-full items-center space-x-2">
        <div className="w-1/2">
          <MeasurementBlock
            value={`${zoom} %`}
            icon={<ZoomInOutlined />}
            head="Zoom"
          />
        </div>
        <div className="w-1/2">
          <MeasurementBlock
            value={`${confidenceScore} %`}
            icon={<PercentageOutlined />}
            head="Confidence Score"
          />
        </div>
      </div>
      {selectedPlane === 'abdominal' && (
        <MeasurementBlock
          label="Abdominal Circumference"
          value={`${ac} cm`}
        />
      )}
      {selectedPlane === 'cephalic' && (
        <>
          <MeasurementBlock
            label="Biparietal Diameter"
            value={`${bpd} cm`}
          />
          <MeasurementBlock
            label="Head Circumference"
            value={`${hc} cm`}
          />
        </>
      )}
      {selectedPlane === 'femur' && (
        <MeasurementBlock
          label="Femoral Length"
          value={`${fl} cm`}
        />
      )}
      {selectedPlane === 'afpocket' && (
        <MeasurementBlock
          label="SDP Value"
          value={`${sdp} cm`}
        />
      )}
      {selectedPlane !== 'afpocket' && (
        <div className="mt-2 flex w-full items-center justify-start">
          <span className="text-lg font-bold text-gray-700">Quality Critera</span>
        </div>
      )}
      {selectedPlane === 'cephalic' && (
        <div>
          <MeasurementBlock
            label="Head >50% of image"
            quality={true}
            value={zoom === 'N/A' ? undefined : Number(zoom) > 50}
          />
          <MeasurementBlock
            label="Cerebellum not visible"
            quality={true}
            value={CerebellumVisible === undefined ? undefined : !CerebellumVisible}
          />
          <MeasurementBlock
            label="Cavum septi pellucidi visible"
            quality={true}
            value={
              cavumSeptumPellucidumVisible == undefined ? undefined : cavumSeptumPellucidumVisible
            }
          />
          <MeasurementBlock
            label="Thalami visible"
            quality={true}
            value={thalamusVisible == undefined ? undefined : thalamusVisible}
          />
        </div>
      )}
      {selectedPlane === 'abdominal' && (
        <div>
          <MeasurementBlock
            label="Abdomen >50% of image"
            quality={true}
            value={zoom === 'N/A' ? undefined : Number(zoom) > 50}
          />
          <MeasurementBlock
            label="Kidneys not visible"
            quality={true}
            value={fetalKidneysSegment === undefined ? undefined : !fetalKidneysSegment}
          />
          <MeasurementBlock
            label="Portal sinus visible"
            quality={true}
            value={portalSinusSegment}
          />
          <MeasurementBlock
            label="Stomach bubble visible"
            quality={true}
            value={stomachBubbleSegment}
          />
        </div>
      )}
      {selectedPlane === 'femur' && (
        <div>
          <MeasurementBlock
            label="Femur >50% of image"
            quality={true}
            value={zoom === 'N/A' ? undefined : Number(zoom) > 50}
            // value={`${ac} cm`}
          />
          <MeasurementBlock
            label="Angle <45°"
            quality={true}
            value={angle === 'N/A' ? undefined : Number(angle) < 45}
          />
          <MeasurementBlock
            label="Extremities visible"
            quality={true}
            value={
              femurExtremitiesBoxSegment === undefined ? undefined : femurExtremitiesBoxSegment
            }
          />
        </div>
      )}
    </div>
  );
};

export default PlaneInformation;
