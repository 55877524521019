import { Patient } from '../UI/Sections/PatientsList/patientsList.section.custom';
import { APIClient } from './apiHelper';

import * as url from './urlHelper';
import axios, { AxiosResponse } from 'axios';
export const api = new APIClient();

export const getStudies = filter_options => api.get(`${url.GET_STUDIES}`, filter_options);
export const addStudy = data => api.post(`${url.ADD_STUDY_2}`, data);
export const predictStudy = ({ payload }) => api.post(`${url.PREDICT}/${payload.StudyUID}`);
export const getReport = payload => api.get(`${url.GET_REPORT}/${payload.StudyUID}`);
export const getStateReport = payload => api.get(`${url.GET_STATE_REPORT}/${payload.StudyUID}`);
export const getStudyById = StudyId => api.get(`${url.GET_STUDIES}/${StudyId}`);
export const getStudyByPatientId = (patient_id, count, page) =>
  api.get(`${url.GET_PATIENT_STUDIES}/${patient_id}?items_per_page=${count}&page=${page}`);
export const deleteReport = data => api.post(url.DELETE_REPORT + '/' + data);
export const deleteStudy = data => api.post(url.DELETE_STUDY + '/' + data);
export const archiveStudyById = data => api.post(url.ARCHIVE_STUDY + '/' + data);
export const unarchiveStudyById = data => api.post(url.UNARCHIVE_STUDY + '/' + data);
export const getReports = filter_options => api.get(url.GET_ALL_REPORTS, filter_options);
export const finishReport = StudyId => api.post(url.FINISH_REPORT + '/' + StudyId);
//test
// Profile
export const getJwtProfile = () => api.get(url.POST_GET_JWT_PROFILE);

// Patient
export const getAllPatients = filter_options => api.get(url.GET_PATIENTS, filter_options);
export const filterPatients = (page, items_per_page, filterCondition) =>
  api.post(url.GET_PATIENTS + `?page=${page}&items_per_page=${items_per_page}`, filterCondition);
// export const postNewPatient = (data) => api.post(url.POST_PATIENT, data);
export const postNewPatient = (data: Patient): Promise<AxiosResponse<any>> => {
  return api.post(url.POST_PATIENT, data);
};
export const updateExistingPatient = data => api.put(url.PUT_PATIENT, data);
// export const deleteExistingPatient = id => api.delete(url.PUT_PATIENT , { data: { id } });
export const deleteExistingPatient = id => api.delete(url.PUT_PATIENT + '/' + id);
export const getPatientById = id => api.get(url.GET_PATIENT + '/' + id);
export const archivePatientById = data => api.post(url.ARCHIVE_PATIENT + '/' + data);
export const unarchivePatientById = data => api.post(url.UNARCHIVE_PATIENT + '/' + data);
// SR
export const generateSR = payload => {
  return api.post(
    `${url.GENERATE_SR}?studyUID=${payload.StudyInstanceUID}&SERIES_INSTANCE_UID=${payload.SeriesInstanceUID}&SOP_INSTANCE_UID=${payload.SOPInstanceUID}&WITH_SECONDARY_CAPTURE=${payload.WithSecondaryCapture}`
  );
};

// Pixel Spacing
export const getPixelSpacing = payload => {
  return api.post(url.GET_PIXEL_SPACING, payload);
};
