import React, { useEffect, useState, useCallback, useContext } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ButtonGroup, Button, StudyItem, ThumbnailList } from '../';
import { StringNumber } from '../../types';
import { useDispatch, useSelector } from 'react-redux';

import { getStudy } from '../../../../viewer/src/Deepecho/store/actions';
import { updateNeedsReload } from '../../../../viewer/src/Deepecho/store/actions';

const getTrackedSeries = displaySets => {
  let trackedSeries = 0;
  displaySets.forEach(displaySet => {
    if (displaySet.isTracked) {
      trackedSeries++;
    }
  });

  return trackedSeries;
};

const StudyBrowser = ({
  tabs,
  activeTabName,
  expandedStudyInstanceUIDs,
  onClickTab,
  onClickStudy,
  setUpdateOnSr,
  onClickThumbnail,
  onDoubleClickThumbnail,
  onClickUntrack,
  activeDisplaySetInstanceUIDs,
  servicesManager,
}) => {
  const { t } = useTranslation('StudyBrowser');
  const { customizationService, displaySetService, measurementService, uiNotificationService } =
    servicesManager?.services || {};
  // const {
  //   measurementService,
  //   displaySetService,
  //   uiDialogService,
  //   hangingProtocolService,
  //   uiNotificationService,
  //   toolbarService,
  // } = servicesManager.services;
  // const [mesurmentTracking, setMesurementTracking] = useState <any[]>([])
  // useEffect(()=>{
  //   const mesurement = mesurementService.getMeasurements()
  //   console.log("mesurementService",mesurement)
  //   setMesurementTracking(mesurement);
  // },[mesurementService.getMeasurements()])
  // const formatDisplayText = (displayText) => {
  //   if (!displayText) return '';

  //   // Ensure displayText is a string
  //   const displayTextString = String(displayText);

  //   // Check if the original displayText includes <sup>2</sup> for mm²
  //   if (displayTextString.includes('<sup>2</sup>')) {
  //     // Extract the numeric value before <sup>2</sup> and ensure 'mm²'
  //     const value = displayTextString.split('<sup>2</sup>')[0].trim();
  //     return `${value}²`;
  //   }

  //   // Check if the displayText includes 'mm' without <sup>2</sup>
  //   if (displayTextString.includes('mm')) {
  //     // Extract the numeric value before 'mm'
  //     const value = displayTextString.split('mm')[0].trim();
  //     return `${value} mm`;
  //   }

  //   // Default case: return the original text without modifications
  //   return displayTextString;
  // };
  const getTabContent = () => {
    const tabData = tabs.find(tab => tab.name === activeTabName);
    return tabData.studies.map(
      ({ studyInstanceUid, date, description, numInstances, modalities, displaySets }) => {
        const isExpanded = expandedStudyInstanceUIDs.includes(studyInstanceUid);
        return (
          <React.Fragment key={studyInstanceUid}>
            <StudyItem
              date={date}
              description={description}
              numInstances={numInstances}
              modalities={modalities}
              trackedSeries={getTrackedSeries(displaySets)}
              isActive={isExpanded}
              onClick={() => {
                onClickStudy(studyInstanceUid);
              }}
              data-cy="thumbnail-list"
            />

            {isExpanded && displaySets && (
              <ThumbnailList
                thumbnails={displaySets}
                activeDisplaySetInstanceUIDs={activeDisplaySetInstanceUIDs}
                onThumbnailClick={onClickThumbnail}
                onThumbnailDoubleClick={onDoubleClickThumbnail}
                onClickUntrack={onClickUntrack}
                setUpdateOnSr={setUpdateOnSr}
                mesurementService={measurementService}
                displaySetService={displaySetService}
                uiNotificationService={uiNotificationService}
              />
            )}
          </React.Fragment>
        );
      }
    );
  };

  return (
    <React.Fragment>
      {/* <div
        className="w-100 border-secondary-light bg-primary-dark flex h-16 flex-row items-center justify-center border-b p-4"
        data-cy={'studyBrowser-panel'}
      >
        <ButtonGroup
          variant="outlined"
          color="secondary"
          splitBorder={false}
        >
          {tabs.map(tab => {
            const { name, label, studies } = tab;
            const isActive = activeTabName === name;
            const isDisabled = !studies.length;
            // Apply the contrasting color for brighter button color visibility
            const classStudyBrowser = customizationService?.getModeCustomization(
              'class:StudyBrowser'
            ) || {
              true: 'default',
              false: 'default',
            };
            const color = classStudyBrowser[`${isActive}`];
            return (
              <Button
                key={name}
                className={'min-w-18 p-2 text-base text-white'}
                size="initial"
                color={color}
                bgColor={isActive ? 'bg-primary-main' : 'bg-black'}
                onClick={() => {
                  onClickTab(name);
                }}
                disabled={isDisabled}
              >
                {t(label)}
              </Button>
            );
          })}
        </ButtonGroup>
      </div> */}

      <div className="ohif-scrollbar invisible-scrollbar flex flex-1 flex-col overflow-auto">
        {getTabContent()}
        {/* <div>{mesurmentTracking.map((item,index)=>{
          return(
            <div className='px-4'>
              <div>
              Mesurement : {index+1}
            </div>
            <div>
            {item?.toolName} : {formatDisplayText(item?.displayText)}
            </div>
            </div>
            
          )
        })}</div> */}
      </div>
    </React.Fragment>
  );
};

StudyBrowser.propTypes = {
  onClickTab: PropTypes.func.isRequired,
  onClickStudy: PropTypes.func,
  onClickThumbnail: PropTypes.func,
  onDoubleClickThumbnail: PropTypes.func,
  onClickUntrack: PropTypes.func,
  activeTabName: PropTypes.string.isRequired,
  expandedStudyInstanceUIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeDisplaySetInstanceUIDs: PropTypes.arrayOf(PropTypes.string),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      studies: PropTypes.arrayOf(
        PropTypes.shape({
          studyInstanceUid: PropTypes.string.isRequired,
          date: PropTypes.string,
          numInstances: PropTypes.number,
          modalities: PropTypes.string,
          description: PropTypes.string,
          displaySets: PropTypes.arrayOf(
            PropTypes.shape({
              displaySetInstanceUID: PropTypes.string.isRequired,
              imageSrc: PropTypes.string,
              imageAltText: PropTypes.string,
              seriesDate: PropTypes.string,
              seriesNumber: StringNumber,
              numInstances: PropTypes.number,
              description: PropTypes.string,
              componentType: PropTypes.oneOf(['thumbnail', 'thumbnailTracked', 'thumbnailNoImage'])
                .isRequired,
              isTracked: PropTypes.bool,
              viewportIdentificator: PropTypes.arrayOf(PropTypes.string),
              /**
               * Data the thumbnail should expose to a receiving drop target. Use a matching
               * `dragData.type` to identify which targets can receive this draggable item.
               * If this is not set, drag-n-drop will be disabled for this thumbnail.
               *
               * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
               */
              dragData: PropTypes.shape({
                /** Must match the "type" a dropTarget expects */
                type: PropTypes.string.isRequired,
              }),
            })
          ),
        })
      ).isRequired,
    })
  ),
};

const noop = () => {};

StudyBrowser.defaultProps = {
  onClickTab: noop,
  onClickStudy: noop,
  onClickThumbnail: noop,
  onDoubleClickThumbnail: noop,
  onClickUntrack: noop,
};

export default StudyBrowser;
