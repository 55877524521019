import { Button } from 'antd';
import React, { useEffect } from 'react';
// import { api } from '../../../../viewer/src/Deepecho/helpers/backendHelper';
import { api } from '../../../../../../viewer/src/Deepecho/helpers/backendHelper';
import { useDispatch } from 'react-redux';
// import { getReport } from 'platform/viewer/src/Deepecho/store/actions';
import { getReport, getStateReport } from '../../../../../../viewer/src/Deepecho/store/actions';
import { tr } from 'date-fns/locale';
// import { useUpdateStateReport } from 'platform/viewer/src/Deepecho/helpers/api/report/report.api';
import { useUpdateStateReport } from '../../../../../../viewer/src/Deepecho/helpers/api/report/report.api';
import { useQueryClient } from '@tanstack/react-query';
// import { useTrackedMeasurements } from 'extensions/measurement-tracking/src/contexts';
// import { useTrackedMeasurements } from '../../../../../../../extensions/measurement-tracking/src/contexts/TrackedMeasurementsContext/TrackedMeasurementsContext';
// import { debounce } from 'lodash'; // Add the missing import

interface MeasurementModeFooterProps {
  drawingMode: boolean;
  createReport?: () => void;
  frameByPlane: any;
  studyReport?: any;
  planeName?: string;
  selectedFrame?: number;
  showModal?: () => void;
  displaySet?: any;
  SerieId?: string;
  measurementService?: any;
  displaySetService?: any;
  activeDisplaySetInstanceUID?: string;
  data?: any;
}

function MeasurementModeFooter({
  drawingMode,
  createReport,
  frameByPlane,
  studyReport,
  planeName,
  selectedFrame,
  showModal,
  displaySet,
  SerieId,
  measurementService,
  displaySetService,
  activeDisplaySetInstanceUID,
  data,
}: MeasurementModeFooterProps) {
  const [disabled, setDisabled] = React.useState(true);
  const [selectedMeasurement, setSelectedMeasurement] = React.useState<any>(null);
  const muttationUpdateReport = useUpdateStateReport(['report'], (oldData, newData) => [
    ...oldData,
    newData,
  ]);
  const dispatch = useDispatch();

  const saveToReport = async () => {
    let measurements = structuredClone(
      frameByPlane?.[SerieId]?.[planeName][selectedFrame]['measurements']
    );
    // let measurements = JSON.parse(JSON.stringify(frameByPlane?.[planeName][selectedFrame]['measurements']));
    for (const measurement of measurements) {
      if (measurement == null) {
        continue;
      }
      const pixel = displaySetService.getPixelSpacing(
        activeDisplaySetInstanceUID,
        measurement?.StudyInstanceUID,
        measurement?.SOPInstanceUID,
        measurement?.SeriesInstanceUID
      );
      // modify the points to be in mm
      let modifiedPoints = measurement?.points.flatMap(point => [
        Math.abs(point[0]) / pixel?.pixelSpacingX,
        Math.abs(point[1]) / pixel?.pixelSpacingY,
      ]);
      measurement.points = modifiedPoints;
      delete measurement?.uid;
    }
    const data = {
      date: Date(),
      study_id: studyReport?.study_id,
      series_id: studyReport?.series_id,
      sop_instance_uid: studyReport?.sop_instance_uid,
      report_content: {
        ...studyReport?.report_content,
        [`${planeName}`]: {
          [selectedFrame]: {
            ...frameByPlane?.[SerieId]?.[planeName][selectedFrame],
            measurements: measurements,
          },
        },
      },
      // patient_name: "patient_name",
      // doctor_name: "doctor_name",
    };
    // console.log('wdata:w data', data, studyReport?.report_content);
    await muttationUpdateReport.mutateAsync(data);
    // try {
    //   const res = await api.put(`${url}/study_reports`, data);
    const payload = {
      StudyUID: studyReport?.study_id,
    };
    dispatch(getReport(payload));
    //   dispatch(getStateReport(payload));
    setDisabled(true);
    // } catch (error) {
    //   console.error('Error saving report:', error);
    // }
  };
  // useEffect(() => {
  //   const measurement = measurementService.getMeasurementByPlaneAndFrameAndSerieId(planeName, selectedFrame, SerieId);
  //   setSelectedMeasurement(measurement);
  // }, [SerieId, planeName, selectedFrame, data]);

  useEffect(() => {
    const measurement = measurementService.getMeasurementByPlaneAndFrameAndSerieId(
      planeName,
      selectedFrame,
      SerieId
    );
    if (measurement) {
      const pixel = displaySetService.getPixelSpacing(
        activeDisplaySetInstanceUID,
        measurement?.referenceStudyUID,
        measurement?.SOPInstanceUID,
        measurement?.referenceSeriesUID
      );
      // console.log('wdata:w pixel', pixel, measurement?.points);
      const modifiedPoints = measurement?.points.flatMap(point => [
        Math.abs(point[1]) / pixel?.pixelSpacingX,
        Math.abs(point[2]) / pixel?.pixelSpacingY,
      ]);
      const hasPlaneName = planeName?.length > 0;
      const hasValidFrame = !isNaN(selectedFrame);
      const hasDifferentSOPInstanceUID =
        studyReport?.report_content?.[planeName]?.[selectedFrame]?.measurements?.[0]
          ?.SOPInstanceUID !== SerieId;
      const pointsMismatch =
        JSON.stringify(modifiedPoints) !==
        JSON.stringify(
          studyReport?.report_content?.[planeName]?.[selectedFrame]?.measurements?.[0]?.points
        );

      if (hasPlaneName && hasValidFrame && hasDifferentSOPInstanceUID) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
      if (pointsMismatch == true) setDisabled(false);
      else setDisabled(true);
    }
  }, [planeName, selectedFrame, SerieId]);

  // const [trackedMeasurements, sendTrackedMeasurementsEvent] = useTrackedMeasurements();
  // const [measurementChangeTimestamp, setMeasurementsUpdated] = React.useState(Date.now().toString());
  // const measurementsPanelRef = React.useRef(null);

  // useEffect(() => {
  //   const added = measurementService.EVENTS.MEASUREMENT_ADDED;
  //   const addedRaw = measurementService.EVENTS.RAW_MEASUREMENT_ADDED;
  //   const updated = measurementService.EVENTS.MEASUREMENT_UPDATED;
  //   const removed = measurementService.EVENTS.MEASUREMENT_REMOVED;
  //   const cleared = measurementService.EVENTS.MEASUREMENTS_CLEARED;
  //   const subscriptions = [];
  //   [added, addedRaw, updated, removed, cleared].forEach(evt => {
  //     subscriptions.push(
  //       measurementService.subscribe(evt, () => {
  //         setMeasurementsUpdated(Date.now().toString());
  //         if (evt === added) {
  //           debounce(() => {
  //             measurementsPanelRef.current.scrollTop = measurementsPanelRef.current.scrollHeight;
  //           }, 300)();
  //         }
  //       }).unsubscribe
  //     );
  //   });

  //   return () => {
  //     subscriptions.forEach(unsub => {
  //       unsub();
  //     });
  //   };
  // }, [measurementService, sendTrackedMeasurementsEvent]);

  return (
    <>
      {drawingMode === false ? (
        <div className="mt-2 flex w-full justify-between space-x-2">
          <Button
            className="w-full rounded-lg border border-blue-500 bg-blue-500 text-xs font-semibold text-white"
            onClick={saveToReport}
            disabled={disabled}
          >
            Proceed as a view
          </Button>
          {/* <Button className='text-primary-active bg-blue-200 text-xs font-semibold w-1/2 border border-blue-200' onClick={showModal}>
          View report
        </Button> */}
        </div>
      ) : (
        <div className="mt-2 flex w-full justify-between space-x-2">
          <Button
            className=" w-full rounded-lg border border-blue-500 bg-blue-500 text-xs font-semibold text-white"
            onClick={createReport}
          >
            Generate Report
          </Button>
        </div>
      )}
    </>
  );
}

export default MeasurementModeFooter;
