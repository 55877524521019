import React, { useState, useRef, useEffect } from 'react';
import '../../style/index.css';

function MultiSelectDropdown({ items, isOpen, setIsOpen, globalState, setGlobalState }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null); // Ref for the button

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleItemClick = item => {
    const isSelected = selectedItems.includes(item?.label);
    let updatedSelectedItems;

    if (isSelected) {
      updatedSelectedItems = selectedItems.filter(i => i !== item?.label);
    } else {
      updatedSelectedItems = [...selectedItems, item?.label];
    }

    setSelectedItems(updatedSelectedItems);
    setGlobalState(prevState => ({
      ...prevState,
      selectedItemsMultidrop: updatedSelectedItems,
    }));
  };

  const handleSelectAll = () => {
    if (selectedItems.length === items.length) {
      setSelectedItems([]);
      setGlobalState({ ...globalState, selectedItemsMultidrop: [] });
    } else {
      const allLabels = items.map(item => item?.label);
      setSelectedItems(allLabels);
      setGlobalState({ ...globalState, selectedItemsMultidrop: allLabels });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Truncate selected items to fit within the button width
  const getTruncatedLabel = () => {
    if (!buttonRef.current) {
      return selectedItems.join(', ');
    }

    const buttonWidth = buttonRef.current.getBoundingClientRect().width;
    const ellipsis = '...';
    let displayedItems = '';

    for (let i = 0; i < selectedItems.length; i++) {
      const tempDisplayed = displayedItems
        ? `${displayedItems}, ${selectedItems[i]}`
        : selectedItems[i];

      // If the text exceeds the button width, add ellipsis
      if (tempDisplayed.length * 8 + ellipsis.length * 8 > buttonWidth) {
        return `${displayedItems}, ${ellipsis}`;
      }

      displayedItems = tempDisplayed;
    }
    return displayedItems;
  };

  const filteredItems = items?.filter(item => item?.label?.toLowerCase()?.includes(searchTerm));
  const isAllSelected = selectedItems.length === items.length;

  return (
    <div className="flex w-[36%] items-center justify-center xl:w-[26%]">
      <div
        className="group relative w-full"
        ref={dropdownMenuRef}
      >
        <button
          ref={buttonRef}
          id="dropdown-button"
          className="inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm focus:border-[#2886c7] focus:outline-none focus:ring-0"
          onClick={toggleDropdown}
        >
          <span className="mr-2">
            {selectedItems.length > 0 ? getTruncatedLabel() : 'Select Plans'}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2 -mr-1 h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {isOpen && (
          <div
            id="dropdown-menu"
            className="custom-scrollbar absolute right-0 z-50 mt-2 max-h-60 w-full space-y-1 overflow-y-auto rounded-md bg-white p-1 shadow-lg ring-1 ring-gray-500 ring-opacity-5"
          >
            <input
              id="search-input"
              className="block w-full rounded-md border border-gray-300 px-4 py-2 text-sm text-gray-800 placeholder:text-sm focus:outline-none"
              type="text"
              placeholder="Search items"
              autoComplete="off"
              value={searchTerm}
              onChange={handleSearch}
            />
            <div
              onClick={handleSelectAll}
              className="block cursor-pointer rounded-md px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 active:bg-blue-100"
            >
              <input
                type="checkbox"
                checked={isAllSelected}
                readOnly
              />
              <span className="ml-2">All Plans</span>
            </div>

            {filteredItems?.map(item => (
              <div
                key={item?.label}
                onClick={() => handleItemClick(item)}
                className="flex cursor-pointer items-center rounded-md px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 active:bg-blue-100"
              >
                <input
                  type="checkbox"
                  checked={selectedItems.includes(item?.label)}
                  readOnly
                />
                <span className="ml-2">{item?.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default MultiSelectDropdown;
