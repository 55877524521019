import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import {
  API_ERROR,
  API_SUCCESS,
  GET_REPORTS,
  GET_REPORT,
  DELETE_REPORT,
  RESET_REPORTS_STATE,
  RESET_REPORT_STATE,
  GET_STATE_REPORT,
} from './actionType';
import { studyApiError, studyApiSuccess } from '../actions';
import {
  getReports as getReportsApi,
  getReport as getReportApi,
  getStateReport as getStateReportApi,
} from '../../helpers/backendHelper';

function* getReports({ payload }) {
  try {
    console.log('payload=', payload);
    const response = yield call(getReportsApi, payload?.filter_options);
    console.log(response);
    yield put(studyApiSuccess(GET_REPORTS, response));
  } catch (error) {
    yield put(studyApiError(GET_REPORTS, error));
  }
}

function* deleteReport({ payload: studyId }) {
  try {
    // const response = yield call(deleteReportApi, studyId);
    // console.log(response);
    // yield put(studyApiSuccess(DELETE_REPORT, response));
  } catch (error) {
    yield put(studyApiError(DELETE_REPORT, error));
  }
}
function* getReport({ payload }) {
  try {
    const response = yield call(getReportApi, payload);
    yield put(studyApiSuccess(GET_REPORT, response));
  } catch (error) {
    yield put(studyApiError(GET_REPORT, error));
  }
}

function* getStateReport({ payload }) {
  try {
    const response = yield call(getStateReportApi, payload);
    yield put(studyApiSuccess(GET_STATE_REPORT, response));
  } catch (error) {
    yield put(studyApiError(GET_STATE_REPORT, error));
  }
}

function* resetReportsState() {
  try {
    yield put(studyApiSuccess(RESET_REPORTS_STATE, {}));
  } catch (error) {
    yield put(studyApiError(error));
  }
}
function* resetReportState() {
  try {
    yield put(studyApiSuccess(RESET_REPORT_STATE, {}));
  } catch (error) {
    yield put(studyApiError(error));
  }
}

export function* watchReports() {
  yield takeEvery(GET_REPORTS, getReports);
  yield takeEvery(GET_REPORT, getReport);
  yield takeEvery(DELETE_REPORT, deleteReport);
  yield takeEvery(RESET_REPORTS_STATE, resetReportsState);
  yield takeEvery(RESET_REPORT_STATE, resetReportState);
  yield takeEvery(GET_STATE_REPORT, getStateReport);
}

function* reportSaga() {
  yield all([fork(watchReports)]);
}

export default reportSaga;
