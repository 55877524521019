import React, { useEffect, useState } from 'react';
import { AC } from './centiles/AC';
import _ from 'lodash';
import { HC } from './centiles/HC';

const ExaminationDetails: React.FC = props => {
  const {
    selectedFrameNumber1,
    selectedFrameNumber2,
    selectedFrameNumber3,
    selectedFrameNumber4,
    reportDetails,
  } = props;

  // - cephalic: HC, BPD
  // - abdomen: AC
  // - femur: FL
  // - af pocket: SDP

  const calculateAge_HC = HC => {
    /*
      Calculate the Gestational Age (GA) based on the Head Circumference (HC).
      This is the 2nd best formula for GA estimation based on CMO opinion.

      Args:
      HC (float): Head Circumference in centimeters.

      Returns:
      float: Estimated Gestational Age in weeks.
    */

    const HC_mm = HC * 10;
    const loge_HC = Math.log(HC_mm);
    const GA = 0.0597 * Math.pow(loge_HC, 2) + 0.000000006409 * Math.pow(HC, 3) + 3.3258;

    const GA_rounded = Math.round(Math.exp(GA));

    const weeks = Math.floor(GA_rounded / 7);
    const days = GA_rounded % 7;

    return `${weeks}w${days}d`;
  };

  const Age_HC = HC => {
    const HC_mm = HC * 10;
    const loge_HC = Math.log(HC_mm);
    const GA = 0.0597 * Math.pow(loge_HC, 2) + 0.000000006409 * Math.pow(HC, 3) + 3.3258;

    const GA_rounded = Math.round(Math.exp(GA));

    return GA_rounded;
  };

  const calculateAge_FL = FL => {
    /*
    Calculate the Gestational Age (GA) based on the Femur Length (FL).

    Args:
    FL (float): Femur Length in centimeters.

    Returns:
    float: Estimated Gestational Age in weeks.
    */

    if (!FL || FL === undefined) return 'Null';
    const GA = 0.262 * (2 * FL) + 2 * FL + 11.5;

    const GA_rounded = Math.round(GA);

    const weeks = Math.floor(GA_rounded / 7);
    const days = GA_rounded % 7;

    return `${weeks}w${days}d`;
  };

  const calculateAge_AC = AC => {
    /*
    Calculate a value based on the abdominal circumference (AC).

    Args:
    AC (float): Abdominal Circumference in centimeters.

    Returns:
    float: Calculated value based on the formula.
    */

    if (!AC || AC === undefined) 'Null';

    const AC_mm = AC * 10;
    const value = 25.31365 + 5.06337 * AC_mm + 1922266 * AC_mm ** 2 - 0.2513339 * AC_mm ** 3;

    const weeks = Math.floor(value / 7);
    const days = value % 7;

    return `${weeks}w${days}d`;
  };

  const efw_hadlock_hc_ac_fl = (HC, AC, FL) => {
    /*
    Estimate fetal weight (EFW) using a variant of the Hadlock formula with three params (PAPER).

    Args:
    HC (float): Head Circumference in cm.
    AC (float): Abdominal Circumference in cm.
    FL (float): Femur Length in cm.

    Returns:
    float: Estimated Fetal Weight in grams.
    */

    const log10_efw = 1.326 + 0.0107 * HC + 0.0438 * AC + 0.158 * FL - 0.00326 * AC * FL;
    const efw = Math.pow(10, log10_efw);

    return efw;
  };

  const efw_hadlock_hc_ac = (AC, HC) => {
    /*
    Estimate fetal weight using a modified Hadlock formula variant that uses only 2 params (EXCEL).

    Args:
    AC (float): Abdominal Circumference in cm.
    HC (float): Head Circumference in cm.

    Returns:
    float: Estimated Fetal Weight in grams.
    */

    const AC_scaled = AC / 100;
    const HC_scaled = HC / 100;
    const result = Math.exp(
      5.08482 -
        54.06633 * Math.pow(AC_scaled, 3) -
        95.80076 * Math.pow(AC_scaled, 3) * Math.log(AC_scaled) +
        3.13637 * HC_scaled
    );
    return result;
  };

  function calculateCentile(measurement_cm, gestational_age, centileTable) {
    const measurement = measurement_cm * 10;

    const row = centileTable['' + Math.floor(gestational_age / 7)];

    if (!_.isEmpty(row)) {
      const centile3rd = row['3rd'];
      const centile5th = row['5th'];
      const centile10th = row['10th'];
      const centile50th = row['50th'];
      const centile90th = row['90th'];
      const centile95th = row['95th'];
      const centile97th = row['97th'];

      if (measurement < centile3rd) {
        return 'Below 3rd Centile';
      } else if (centile3rd <= measurement && measurement < centile5th) {
        return 'Between 3rd and 5th Centile';
      } else if (centile5th <= measurement && measurement < centile10th) {
        return 'Between 5th and 10th Centile';
      } else if (centile10th <= measurement && measurement < centile50th) {
        return 'Between 10th and 50th Centile';
      } else if (centile50th <= measurement && measurement < centile90th) {
        return 'Between 50th and 90th Centile';
      } else if (centile90th <= measurement && measurement < centile95th) {
        return 'Between 90th and 95th Centile';
      } else if (centile95th <= measurement && measurement < centile97th) {
        return 'Between 95th and 97th Centile';
      } else {
        return 'Above 95th Centile';
      }
    } else {
      return 'Gestational age not found in the table';
    }
  }

  calculateCentile(12, 100, AC);

  return (
    <div className="mb-6 px-2 text-sm">
      <h2 className="text-lg font-bold underline">Examination Details</h2>
      <table className="w-full table-auto">
        <thead>
          <tr>
            <th></th>
            <th className="text-left">value</th>
            <th className="text-left">gestational age</th>
            <th className="text-left">centile</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="font-bold">Biparietal diameter (BPD)</td>
            <td>
              <span>
                {reportDetails['cephalic'] &&
                reportDetails['cephalic'][selectedFrameNumber4] &&
                reportDetails['cephalic'][selectedFrameNumber4]['MEAS__BPD']
                  ? reportDetails['cephalic'][selectedFrameNumber4]['MEAS__BPD']
                  : '8.78'}
              </span>
            </td>
            <td>
              <span>26w2d</span>
            </td>
            <td>
              <span>22th</span>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Head circumference (HC)</td>
            <td>
              <span>
                {/* {
                  reportDetails["cephalic"] && reportDetails["cephalic"][selectedFrameNumber4] && reportDetails["cephalic"][selectedFrameNumber4][
                    'MEAS__HC'
                  ]
                } */}
                32.17cm
              </span>
            </td>
            <td>
              <span>
                {/* {calculateAge_HC(
                  reportDetails["cephalic"] && reportDetails["cephalic"][selectedFrameNumber4] && reportDetails["cephalic"][selectedFrameNumber4][
                    'MEAS__HC'
                  ]
                )} */}
                25w3d
              </span>
            </td>
            <td>
              <span>
                {/* {calculateCentile(
                  reportDetails["cephalic"] && reportDetails["cephalic"][selectedFrameNumber4] && reportDetails["cephalic"][selectedFrameNumber4][
                    'MEAS__HC'
                  ],
                  Age_HC(
                    reportDetails["cephalic"] && reportDetails["cephalic"][selectedFrameNumber4] && reportDetails["cephalic"][selectedFrameNumber4][
                      'MEAS__HC'
                    ]
                  ),
                  HC
                )} */}
                50th
              </span>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Femoral length (FL)</td>
            <td>
              <span>
                {reportDetails['femur'] &&
                reportDetails['femur'][selectedFrameNumber1] &&
                reportDetails['femur'][selectedFrameNumber1]['MEAS__FL']
                  ? reportDetails['femur'][selectedFrameNumber1]['MEAS__FL']
                  : '4.95cm'}
              </span>
            </td>
            <td>
              <span>
                {/* {calculateAge_FL(
                  reportDetails["femur"] && reportDetails["femur"][selectedFrameNumber1] && reportDetails["femur"][selectedFrameNumber1][
                    'MEAS__FL'
                  ]
                )} */}
                26w5d
              </span>
            </td>
            <td>
              <span>55th</span>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Abdominal circumference (AC)</td>
            <td>
              <span>
                {reportDetails['abdominal'] &&
                reportDetails['abdominal'][selectedFrameNumber2] &&
                reportDetails['abdominal'][selectedFrameNumber2]['MEAS__AC']
                  ? reportDetails['abdominal'][selectedFrameNumber2]['MEAS__AC']
                  : 'ND'}
              </span>
            </td>
            <td>
              <span>26w7d</span>
            </td>
            <td>
              <span>
                {/* {calculateCentile(
                    reportDetails["abdominal"] && reportDetails["abdominal"][selectedFrameNumber2] && reportDetails["abdominal"][selectedFrameNumber2][
                      'MEAS__AC'
                    ],
                    Age_HC(
                      reportDetails["abdominal"] && reportDetails["abdominal"][selectedFrameNumber2] && reportDetails["abdominal"][selectedFrameNumber2][
                        'MEAS__AC'
                      ]
                    ),
                    HC
                  )} */}
                Between 90th 95th
              </span>
            </td>
          </tr>
          <tr>
            <td className="font-bold">Single deepest pocket (SDP)</td>
            <td>
              <span>
                {/* {
                  reportDetails["afpocket"] && reportDetails["afpocket"][selectedFrameNumber3] && reportDetails["afpocket"][selectedFrameNumber3][
                    'MEAS__SDP'
                  ] ? reportDetails["afpocket"][selectedFrameNumber3][
                    'MEAS__SDP'
                  ]: "ND"
                } */}
                6.43cm
              </span>
            </td>
            <td>
              <span>N/A</span>
            </td>
            <td>
              <span>N/A</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ExaminationDetails;
