import moment from "moment";

export const formatDate = dateString => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const handleDateFilter = dates => {
  if (!dates?.reset) {
    const parsedFilters = {};
    if (dates.startDate) {
      parsedFilters['birth_date__gte'] = formatDate(dates.startDate);
    }
    if (dates.endDate) {
      parsedFilters['birth_date__lte'] = formatDate(dates.endDate);
    }
    return parsedFilters;
  } else {
    return {};
  }
};

export const handleValidDate = date => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  };
