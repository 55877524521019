import { reportAttrsVals_Orig } from './objects';
import deepEqual from 'deep-equal';

export const resetReportObjValues = (setExamData, examData, setResetActive) => {
  if (!deepEqual(examData, reportAttrsVals_Orig)) {
    setExamData(reportAttrsVals_Orig);
    setResetActive(false);
  }
};

export const resetCriteria2 = (setExamData, examData) => {
  setExamData({
    ...examData,
    section2: {
      ...examData.section2,
      firstColFirstRow: {
        ...examData.section2.firstColFirstRow,
        criterias: {
          ...examData.section2.firstColFirstRow.criterias,
          criteria2: {
            ...examData.section2.firstColFirstRow.criterias.criteria2,
            status: reportAttrsVals_Orig.section2.firstColFirstRow.criterias.criteria2.status,
          },
        },
      },
    },
  });
};
