import React from 'react';

import Landing from './UI/Pages/LandingPage/Landing.page';

const publicPages = [
  {
    path: '/',
    element: <Landing />,
  },
];

export default publicPages;
