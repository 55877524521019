import React, { useState } from 'react';
import CompactUIDDisplay from '../CompactUIDDisplay';
import { DatePicker } from 'antd';
import { MdDelete } from 'react-icons/md';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import locale from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';
import { IoReload } from 'react-icons/io5';
import PatientTableSelection from '../PatientTableSelection/PatientTableSelection';
import SeriesTable from '../SeriesTable';
import { DicomDataStructure, DicomUploadStatus, StudySerieUploadInfo } from '../../../interfaces';

interface Props {
  data: DicomDataStructure;
  setDicomDataStructure: React.Dispatch<React.SetStateAction<DicomDataStructure>>;
  setProcessedSOPInstanceUIDs: React.Dispatch<React.SetStateAction<Set<string>>>;
  setStudySerieUploadInfo?: React.Dispatch<React.SetStateAction<StudySerieUploadInfo[]>>;
  studySerieUploadInfo?: StudySerieUploadInfo[];
  handleReloadStudy: (studyUID: string) => void;
}

const ExpandableTable: React.FC<Props> = ({
  data,
  setDicomDataStructure,
  setProcessedSOPInstanceUIDs,
  setStudySerieUploadInfo,
  studySerieUploadInfo,
  handleReloadStudy,
}) => {
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: 'patient' | 'study' | null;
  }>({});

  const [selectedPatientByStudy, setSelectedPatientByStudy] = useState<{
    [key: string]: {
      patient_id?: string;
      patient_name?: string;
    };
  }>({});

  const toggleSection = (studyUID: string, type: 'study' | 'patient') => {
    setExpandedSections(prev => {
      const newState = { ...prev };

      if (prev[studyUID] === type) {
        delete newState[studyUID];
      } else {
        if (type === 'patient') {
          Object.keys(newState).forEach(key => {
            if (newState[key] === 'patient') {
              delete newState[key];
            }
          });
        }

        newState[studyUID] = type;
      }

      return newState;
    });
  };

  const handlePatientSelect = (
    studyUID: string,
    patient: { patient_id?: string; patient_name?: string }
  ) => {
    setSelectedPatientByStudy(prev => ({
      ...prev,
      [studyUID]: patient,
    }));

    setDicomDataStructure(prevData =>
      prevData.map(study =>
        study.study_instance_uid === studyUID
          ? {
              ...study,
              patient_id: patient?.patient_id,
              patient_name: patient?.patient_name,
            }
          : study
      )
    );

    // Close the patient table after selection
    setExpandedSections(prev => {
      const newState = { ...prev };
      delete newState[studyUID];
      return newState;
    });
  };
  const getStatusStyle = (status: DicomUploadStatus) => {
    switch (status) {
      case DicomUploadStatus.Pending:
        return {
          text: 'Pending',
          className: 'text-yellow-800 bg-yellow-100',
        };
      case DicomUploadStatus.Uploading:
        return {
          text: 'Uploading',
          className: 'text-blue-800 bg-blue-100',
        };
      case DicomUploadStatus.Successful:
        return {
          text: 'Uploaded',
          className: 'text-green-800 bg-green-100',
        };
      case DicomUploadStatus.Failed:
        return {
          text: 'Failed',
          className: 'text-red-800 bg-red-100',
        };
      default:
        return {
          text: 'Unknown',
          className: 'text-gray-800 bg-gray-100',
        };
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="mb-4 w-full border-collapse border-b border-gray-300 text-sm">
        <thead>
          <tr className="bg-gray-100 text-left">
            <th className="w-1/6 p-3">Study UID</th>
            <th className="w-1/6 p-3">Study Date</th>
            <th className="w-1/6 p-3">Patient</th>
            <th className="w-1/6 p-3">Total Series</th>
            <th className="w-1/6 p-3">Upload Status</th>
            <th className="w-1/6 p-3">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.map(study => (
            <React.Fragment key={study?.study_instance_uid}>
              <tr className="border-b border-gray-300 text-base font-medium text-[#686C82]">
                <td className="p-3">
                  <CompactUIDDisplay uid={study?.study_instance_uid} />
                </td>
                <td className="p-3">
                  <DatePicker
                    placeholder="Study Date"
                    className="main-input-void mt-0 w-32 rounded-md bg-white text-sm text-[#686C82] placeholder-opacity-50"
                    maxDate={dayjs()}
                    defaultValue={dayjs(study?.study_date)}
                    onChange={(date, dateString) => {
                      console.log(dateString, typeof dateString);
                      setDicomDataStructure(prevData => {
                        const newStudy = prevData.find(
                          item => item?.study_instance_uid === study?.study_instance_uid
                        );
                        if (newStudy) {
                          newStudy.study_date = dateString.toString().replace(/-/g, '');
                        }
                        return prevData;
                      });
                    }}
                    id="date"
                    name="date"
                    locale={locale}
                    disabled={study?.upload_status === DicomUploadStatus.Successful}
                  />
                </td>
                <td className="p-3">
                  <div
                    className="flex w-full cursor-pointer items-center justify-between space-x-2 rounded-md border border-[#D8DBEC] p-1"
                    onClick={() => {
                      if (study?.upload_status === DicomUploadStatus.Successful) {
                        return;
                      }
                      toggleSection(study?.study_instance_uid || '', 'patient');
                    }}
                  >
                    <span className="text-base font-medium text-[#A6ABC8]">
                      {selectedPatientByStudy[study?.study_instance_uid || '']?.patient_name ||
                        'Select Patient'}
                    </span>
                    {expandedSections[study?.study_instance_uid] === 'patient' ? (
                      <FaChevronUp className="h-4 w-4 text-[#A6ABC8]" />
                    ) : (
                      <FaChevronDown className="h-4 w-4 text-[#A6ABC8]" />
                    )}
                  </div>
                </td>

                <td className="p-3">{study?.series?.length}</td>
                <td className="p-3">
                  <span
                    className={`rounded px-2 py-1 text-xs font-semibold ${getStatusStyle(study?.upload_status).className}`}
                  >
                    {getStatusStyle(study?.upload_status).text}
                  </span>
                </td>
                <td className="p-3">
                  <div className="w-full text-blue-500 hover:underline">
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <MdDelete
                          className="h-5 w-5 text-red-600"
                          onClick={() => {
                            setDicomDataStructure(prevData =>
                              prevData.filter(
                                item => item?.study_instance_uid !== study?.study_instance_uid
                              )
                            );
                            setProcessedSOPInstanceUIDs(prev => {
                              const newSet = new Set(prev);
                              study.series.forEach(serie => {
                                serie.instances.forEach(instance => {
                                  newSet.delete(instance.sop_instance_uid || '');
                                });
                              });
                              return newSet;
                            });
                            setStudySerieUploadInfo(prev =>
                              prev.filter(
                                item => item?.study_instance_uid !== study?.study_instance_uid
                              )
                            );
                          }}
                        />
                        {study?.upload_status === DicomUploadStatus.Failed && (
                          <IoReload
                            className="h-5 w-5 text-blue-500"
                            onClick={() => handleReloadStudy(study?.study_instance_uid)}
                          />
                        )}
                      </div>
                      {expandedSections[study?.study_instance_uid] === 'study' ? (
                        <FaChevronUp
                          className="h-5 w-5 text-[#A6ABC8]"
                          onClick={() => toggleSection(study?.study_instance_uid || '', 'study')}
                        />
                      ) : (
                        <FaChevronDown
                          className="h-5 w-5 text-[#A6ABC8]"
                          onClick={() => toggleSection(study?.study_instance_uid || '', 'study')}
                        />
                      )}
                    </div>
                  </div>
                </td>
              </tr>
              {expandedSections[study?.study_instance_uid] === 'patient' && (
                <tr>
                  <td
                    colSpan={6}
                    className="p-2"
                  >
                    <PatientTableSelection
                      studyUID={study?.study_instance_uid || ''}
                      onPatientSelect={handlePatientSelect}
                    />
                  </td>
                </tr>
              )}
              {expandedSections[study?.study_instance_uid] === 'study' && (
                <tr>
                  <td
                    colSpan={6}
                    className="p-2"
                  >
                    <SeriesTable
                      data={study.series}
                      setDicomDataStructure={setDicomDataStructure}
                      setProcessedSOPInstanceUIDs={setProcessedSOPInstanceUIDs}
                      getStatusStyle={getStatusStyle}
                      studySerieUploadInfo={studySerieUploadInfo.find(
                        item => item?.study_instance_uid === study?.study_instance_uid
                      )}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExpandableTable;
