import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';

import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table';

import { Avatar, Badge, Row, Form, Modal, Input, message, Select } from 'antd';

import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';

import { Link } from 'react-router-dom';
// import BreadCrumbs from '../RoleManagementPage/BreadCrumbs';
import moment from 'moment';
import {
  useActivateUser,
  useChangeRoleUser,
  useDeactivateUser,
  usePaginatedUsers,
  usePasswordRecovery,
  useUserPasswordReset,
} from '../../../helpers/api/users/users';

import { APIClient } from '../../../helpers/apiHelper';
import RoleDropdown from './RoleDropdown';

export const api = new APIClient();

const handleValidDate = date => {
  const date1 = moment(new Date(date)).format('DD MMM Y');
  return date1;
};

const handleValidTime = time => {
  const time1 = new Date(time);
  const getHour = time1.getUTCHours();
  const getMin = time1.getUTCMinutes();
  const getTime = `${getHour}:${getMin}`;
  let meridiem = '';
  if (getHour >= 12) {
    meridiem = 'PM';
  } else {
    meridiem = 'AM';
  }
  const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + ' ' + meridiem;
  return updateTime;
};

type Role = {
  id: string;
  fullName: string;
  email: string;
  roles: number;
  // teams: number;
  type: 'active' | 'inactive';
};

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: { behavior: 'smooth', block: 'center' },
  },
  useModalOverlay: true,
};

const Button = () => {
  const tour = useContext(ShepherdTourContext);
  // useEffect(() => {
  //   tour.start();
  // }, [tour]);
  return <> </>;
};

const steps = [
  {
    id: 'intro',
    attachTo: { element: '#new-role', on: 'bottom' },
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: 'btn btn-success',
        text: 'Next',
        action() {
          return this.next();
        },
      },
    ],
    title: 'Role Management Tour !',
    text: ['You can create a new role using this button'],
  },

  {
    id: 'intro1',
    attachTo: { element: '#type-tab', on: 'bottom' },

    buttons: [
      {
        text: 'Back',
        classes: 'btn btn-light',
        action() {
          return this.back();
        },
      },
      {
        text: 'Next',
        classes: 'btn btn-success',
        action() {
          return this.next();
        },
      },
    ],
    title: 'Filter By Type',
    text: 'You can filter the role types using these tabs',
  },
  {
    id: 'intro2',
    attachTo: { element: '#role-table', on: 'bottom' },
    buttons: [
      {
        text: 'Back',
        classes: 'btn btn-light',
        action() {
          return this.back();
        },
      },
      {
        text: 'Next',
        classes: 'btn btn-success',
        action() {
          return this.next();
        },
      },
    ],
    title: 'Role Table',
    text: 'This is the table that contains all the roles data.',
  },
  {
    id: 'intro3',
    attachTo: { element: '#table-row-0', on: 'bottom' },
    buttons: [
      {
        text: 'Back',
        classes: 'btn btn-light',
        action() {
          return this.back();
        },
      },
      {
        text: 'Next',
        classes: 'btn btn-success',
        action() {
          return this.next();
        },
      },
    ],
    title: 'Role Row',
    text: 'This is a single row displaying role info (name ...).',
  },
  {
    id: 'intro3',
    attachTo: { element: '#pagination', on: 'bottom' },
    buttons: [
      {
        text: 'Back',
        classes: 'btn btn-light',
        action() {
          return this.back();
        },
      },
      {
        text: 'Next',
        classes: 'btn btn-success',
        action() {
          return this.next();
        },
      },
    ],
    title: 'Pagination',
    text: 'These are pagination buttons.',
  },
  {
    id: 'intro4',
    attachTo: { element: '#thankyou-tour', on: 'bottom' },
    buttons: [
      {
        text: 'Back',
        classes: 'btn btn-light',
        action() {
          return this.back();
        },
      },
      {
        text: 'Thank you !',
        classes: 'btn btn-primary',
        action() {
          return this.complete();
        },
      },
    ],
    title: 'Good Luck !',
    text: 'You can now  begin managing your roles.',
  },
];

const style = {
  fontFamily: 'Outfit, sans-serif', // Specify your desired font here
};

const UserManagement = () => {
  // const [data, setData] = useState([]);

  const passwordRecovery = usePasswordRecovery();
  const activateUser = useActivateUser();
  const deactivateUser = useDeactivateUser();
  const changeRoleUser = useChangeRoleUser();

  const [tabs, setTabs] = useState([
    {
      name: 'Users',
      icon: 'ri-user-line',
      active: true,
    },
    // {
    //   name: 'Teams',
    //   icon: 'ri-group-line',
    //   active: false,
    // },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [passwordResetForm] = Form.useForm();
  const [toReset, setToReset] = useState();

  const userPasswordReset = useUserPasswordReset();

  const handleCreateUser = async (values: any) => {
    try {
      const response = await api.post('/users/create', {
        username: values.username,
        name: values.name,
        email: values.email,
        role: values.role,
      });

      if (!response.status_code === 201) {
        throw new Error('Failed to create userr');
      }

      message.success('User created successfully');
      setIsModalOpen(false);
      form.resetFields();

      // Refresh the users table
      users.refetch();
    } catch (error) {
      console.error('Error creating user:', error);
      message.error('Failed to create user');
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showPasswordResetModal = () => {
    setIsPasswordResetModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleResetPasswordCancel = () => {
    setIsPasswordResetModalOpen(false);
    passwordResetForm.resetFields();
  };

  const columns = React.useMemo<ColumnDef<Role>[]>(
    () => [
      {
        header: () => (
          <div className="flex items-center">
            <input
              type="checkbox"
              id="checkBoxAll"
              className=""
              // onClick={() => {}}
            />
          </div>
        ),
        cell: cell => {
          return (
            <div className="flex items-center">
              <input
                type="checkbox"
                className="orderCheckBox form-check-input"
                // value={cell.getValue()}
                // onChange={() => deleteCheckbox()}
              />
            </div>
          );
        },
        id: '#',
        // accessorKey: "id"
      },
      // {
      //   accessorKey: 'id',
      //   cell: info => info.getValue(),
      //   header: () => <span>ID</span>,
      //   footer: props => props.column.id,
      // },
      {
        accessorKey: 'name',
        cell: cellProps => (
          <div className="flex items-center gap-2">
            <Avatar
              size={40}
              src={
                <img
                  src={cellProps.row.original.avatar}
                  alt="avatar"
                />
              }
            />

            <span>{cellProps.getValue()}</span>
            {cellProps.row.original.roles?.length > 0 ? (
              <RoleDropdown
                cellProps={cellProps}
                handleRoleChange={role => {
                  changeRoleUser.mutate({
                    uid: cellProps.row.original.uid,
                    role,
                  });
                }}
              />
            ) : (
              <>--</>
            )}
          </div>
        ),
        header: () => <span>User</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'email',
        id: 'createdBy',
        cell: info => info.getValue(),
        header: () => <span>Email</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'is_active',
        cell: cellProps => (
          <div className="flex items-center gap-2">
            {cellProps.row.original.is_active ? (
              <Badge
                count="ACTIVE"
                style={{ backgroundColor: '#52c41a' }}
              />
            ) : (
              <Badge count="INACTIVE" />
            )}
          </div>
        ),
        header: () => <span>Active</span>,
        footer: props => props.column.id,
      },
      {
        accessorKey: 'last_login',
        cell: cell => (
          <>
            {handleValidDate(cell.getValue())},
            <small className="text-muted">{handleValidTime(cell.getValue())}</small>
          </>
        ),
        header: () => <span>Last Login</span>,
        footer: props => props.column.id,
      },
      {
        header: 'Action',
        size: 5,
        cell: cellProps => {
          return (
            <ul className="flex">
              <>
                <Link to={`/profile/${cellProps.row.original.uid}`}>
                  <li className="mr-2 cursor-pointer text-blue-800">
                    <span className="text-primary d-inline-block">
                      <i className="ri-eye-fill fs-16"></i>
                    </span>
                  </li>
                </Link>

                {!cellProps.row.original.is_superuser && (
                  <>
                    {cellProps.row.original.is_active ? (
                      <li
                        className="mr-2 cursor-pointer text-red-500"
                        onClick={() => {
                          deactivateUser.mutate(cellProps.row.original.uid);
                        }}
                      >
                        <span className="text-primary d-inline-block">
                          <i className="ri-user-forbid-fill fs-16"></i>
                        </span>
                      </li>
                    ) : (
                      <li
                        className="mr-2 cursor-pointer text-green-500"
                        onClick={() => {
                          activateUser.mutate(cellProps.row.original.uid);
                        }}
                      >
                        <span className="text-primary d-inline-block">
                          <i className="ri-user-follow-fill fs-16"></i>
                        </span>
                      </li>
                    )}
                  </>
                )}
                <li
                  onClick={() => {
                    setToReset(cellProps.row.original.uid);
                    showPasswordResetModal();
                    // passwordRecovery.mutate(cellProps.row.original.uid);
                  }}
                  className="mr-2 cursor-pointer text-orange-500"
                >
                  <span className="text-primary d-inline-block">
                    <i className="ri-lock-fill fs-16"></i>
                  </span>
                </li>
              </>
            </ul>
          );
        },
      },
    ],
    []
  );

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const users = usePaginatedUsers(
    pagination.pageIndex + 1,
    pagination.pageSize,
    {},
    { refetchInterval: 60000 }
  );

  const defaultData = React.useMemo(() => [], []);

  const table = useReactTable({
    data: users.data?.items ?? [],
    columns,
    // pageCount: dataQuery.data?.pageCount ?? -1, //you can now pass in `rowCount` instead of pageCount and `pageCount` will be calculated internally (new in v8.13.0)
    rowCount: users.data?.total_count, // new in v8.13.0 - alternatively, just pass in `pageCount` directly
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true, //we're doing manual "server-side" pagination
    // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    debugTable: true,
  });

  const setActiveTab = (name: string) => {
    setTabs(prev =>
      prev.map(item =>
        item.name === name ? { ...item, active: true } : { ...item, active: false }
      )
    );
  };

  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (userPasswordReset.isSuccess) {
      handleResetPasswordCancel();
    }
  }, [userPasswordReset.isSuccess]);

  return (
    <div
      className="flex h-full flex-col px-4"
      style={style}
    >
      {/* <BreadCrumbs
        title="Users & Teams Management"
        pages={
          [
            // { path: '/tedfre', label: 'miaw' },
            // { path: '/tedfre', label: 'miw miw' },
          ]
        }
      /> */}
      <div className="mb-2">
        {/* <h1 className="text-3xl font-semibold text-gray-700">Role Management</h1> */}
        <h5 className="text-gray-600">Manage users </h5>
      </div>
      <div className="h-full rounded-md border border-gray-200 bg-white">
        <div className="p-2">
          <div className="flex h-8 w-full flex-row-reverse">
            <div
              className="relative"
              ref={dropdownRef}
            >
              <button
                id="new-role"
                onClick={toggleDropdown}
                className="ml-2 flex h-8 items-center justify-between rounded bg-blue-600 font-normal text-white transition duration-150 ease-in-out hover:bg-blue-700"
              >
                <i className="ri-add-large-line flex h-full w-8 items-center justify-center rounded-l bg-[rgba(255,255,255,0.1)] py-2 text-center"></i>
                <span className="px-2 py-2">New</span>
              </button>

              {isOpen && (
                <div className="absolute right-0 mt-2 w-32 origin-top-right rounded-md border border-gray-300 bg-white text-lg shadow-lg focus:outline-none">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <a
                        onClick={showModal}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                      >
                        Create User
                      </a>
                    </div>
                    {/* <Link
                      to={`#`}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      Create Team
                    </Link> */}
                  </div>
                </div>
              )}
            </div>

            <div className="flex h-full items-center justify-center">
              <button className="border-sm flex h-8 items-center justify-between rounded border border-blue-600 font-normal text-blue-600 transition duration-150 ease-in-out hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-200">
                <i className="ri-equalizer-line flex h-full w-8 items-center justify-center py-2 text-center"></i>
                <span className="py-2 pr-2">Filters</span>
              </button>
            </div>
          </div>
          <div className="mb-2 border-b border-gray-200">
            <ul
              id="type-tab"
              className="-mb-px flex flex-wrap text-center text-sm font-normal text-gray-500"
            >
              {tabs.map((tab, index) => (
                <li
                  className="mr-3"
                  key={index}
                >
                  <span
                    onClick={() => {
                      setActiveTab(tab.name);
                    }}
                    className={`group inline-flex cursor-pointer items-center justify-center gap-1 p-2 transition duration-150 ease-in-out ${
                      tab.active
                        ? 'border-b-2 border-blue-600 font-semibold text-blue-600'
                        : 'border-b-2 border-transparent text-gray-700 hover:text-gray-600'
                    }`}
                    aria-current={tab.active ? 'page' : undefined}
                  >
                    <i className={`${tab.icon} flex h-4 w-4 items-center text-lg`}></i>
                    {tab.name}
                    <span
                      className={`rounded px-2 py-0.5 text-xs font-semibold ${
                        tab.active ? 'bg-blue-500 text-white' : 'bg-gray-300 text-white'
                      }`}
                    >
                      {tab.name === 'Users' ? users.data?.total_count : 5}
                    </span>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div>
          <table
            id="role-table"
            className="mb-2 w-full"
          >
            <thead className="h-10 border-t border-dashed border-gray-200 bg-gray-100 p-2 text-left text-gray-500">
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        className="p-2"
                        key={header.id}
                        colSpan={header.colSpan}
                      >
                        {header.isPlaceholder ? null : (
                          <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <tr
                    id={`table-row-${row.id}`}
                    key={row.id}
                    className="h-10 border-t border-b border-gray-200 hover:bg-blue-100"
                  >
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td
                          key={cell.id}
                          className="p-2"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex w-full items-center justify-between px-2">
            <div className="text-gray-600">
              Showing{' '}
              <span className="text-gray-700">
                {table.getRowModel().rows.length.toLocaleString()}
              </span>{' '}
              of <span className="text-gray-700">{users.data?.total_count}</span> Rows
            </div>
            <div
              id="pagination"
              className="flex items-center gap-2"
            >
              <button
                className={`rounded border py-2 px-3 transition duration-150 ease-in-out ${
                  !table.getCanPreviousPage() ? 'text-gray-500' : 'text-blue-900 hover:bg-gray-200'
                }`}
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {'Previous'}
              </button>
              {[...Array(table.getPageCount())].map((_, i) => (
                <button
                  className={`rounded border py-2 px-3 transition duration-150 ease-in-out ${
                    table.getState().pagination.pageIndex === i
                      ? 'border-blue-600 bg-blue-600 text-white'
                      : 'text-blue-900  hover:bg-gray-100'
                  }`}
                  onClick={() => table.setPageIndex(i)}
                  disabled={table.getState().pagination.pageIndex === i}
                >
                  {i + 1}
                </button>
              ))}
              <button
                className={`rounded border py-2 px-3 transition duration-150 ease-in-out ${
                  !table.getCanNextPage() ? 'text-gray-500' : 'text-blue-900  hover:bg-gray-100'
                }`}
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {'Next'}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Create New User"
        open={isModalOpen}
        onOk={form.submit}
        onCancel={handleCancel}
        okText="Create"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleCreateUser}
        >
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: 'Please input username!' }]}
          >
            <Input placeholder="Enter username" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Full Name"
            rules={[{ required: true, message: 'Please input full name!' }]}
          >
            <Input placeholder="Enter full name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Please input email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: 'Please select role!' }]}
          >
            <Select>
              <Select.Option value="doctor">Doctor</Select.Option>
              <Select.Option value="user_manager">User Manager</Select.Option>
              <Select.Option value="lead_doctor">Lead Doctor</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Reset Password"
        open={isPasswordResetModalOpen}
        onOk={passwordResetForm.submit}
        onCancel={handleResetPasswordCancel}
        okText="Reset"
      >
        <Form
          form={passwordResetForm}
          layout="vertical"
          onFinish={values => {
            const formData = new FormData();
            formData.append('new_password', values.password);
            userPasswordReset.mutate({ uid: toReset, form: formData });
          }}
        >
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please input password!' }]}
          >
            <Input.Password placeholder="Enter password" />
          </Form.Item>
          <Form.Item
            name="repeat_password"
            label="Repeat password"
            dependencies={['password']} // This ensures that validation runs when 'password' changes
            rules={[
              { required: true, message: 'Please repeat password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Repeat password" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserManagement;
