import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { getProfile } from './store/actions';

import { useAuth } from 'react-oidc-context';

const PublicRoutes = ({ children }) => {
  if (process.env.IS_AUTH_ACTIVATED === 'False') {
    return children;
  }
  const auth = useAuth();
  const location = useLocation();

  if (auth?.isLoading !== false) {
    return <></>;
  }

  if (auth.isAuthenticated && process.env.DEPLOYMENT_TARGET == 'Morocco') {
    return (
      <Navigate to={{ pathname: location.state ? location.state.from : '/realtime-inferencing' }} />
    );
  }

  if (auth.isAuthenticated && location.pathname !== '/') {
    return <Navigate to={{ pathname: location.state ? location.state.from : '/home' }} />;
  }

  return children;
};

export default PublicRoutes;
