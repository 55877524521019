// export const defaultWidth = 1000;
// export const defaultHeight = 600;

export const machineWidths = {
  ws: 800,
  // ws80a: 1920,
  ws80a: 1000,
  voluson_e6: 1000,
  ls: 1600,
  versana: 1258,
  'iqplus-butter': 1000,
  'iq3-butter': 1000,
  affiniti70: 900,
  voluson_s10: 900,
  voluson_s8: 900,
  mindray_dc_40: 900,
};

export const machineHeights = {
  ws: 500,
  // ws80a: 1080,
  ws80a: 600,
  voluson_e6: 600,
  ls: 900,
  versana: 710,
  'iqplus-butter': 600,
  'iq3-butter': 600,
  affiniti70: 900,
  voluson_s10: 900,
  voluson_s8: 900,
  mindray_dc_40: 900,
};

export const outputWidths = {
  ws: 800,
  // ws80a: 1920,
  ws80a: 1000,
  voluson_e6: 1000,
  ls: 1600,
  versana: 1258,
  'iqplus-butter': 1000,
  'iq3-butter': 1000,
  affiniti70: 900,
  voluson_s10: 900,
  voluson_s8: 900,
  mindray_dc_40: 900,
};

export const outputHeights = {
  ws: 500,
  // ws80a: 1080,
  ws80a: 600,
  voluson_e6: 600,
  ls: 900,
  versana: 710,
  'iqplus-butter': 600,
  'iq3-butter': 600,
  affiniti70: 900,
  voluson_s10: 900,
  voluson_s8: 900,
  mindray_dc_40: 900,
};

export const cropPropertiesPerMachine = {
  ws: {
    cropX: 426,
    cropY: 125,
    cropWidth: 1000,
    cropHeight: 738,
  },
  // ws80a: {
  //   cropX: 420,
  //   cropY: 78,
  //   cropWidth: 1040,
  //   cropHeight: 800,
  // },
  ws80a: {
    cropX: 426,
    cropY: 110,
    cropWidth: 1010,
    cropHeight: 700,
  },
  ls: {
    cropX: 0,
    cropY: 0,
    cropWidth: 1600,
    cropHeight: 900,
  },
  voluson_e6: {
    cropX: 350,
    cropY: 50,
    cropWidth: 1160,
    cropHeight: 800,
  },
  versana: {
    cropX: 330,
    cropY: 38,
    cropWidth: 700,
    cropHeight: 700,
  },
  'iqplus-butter': {
    cropX: 426,
    cropY: 100,
    cropWidth: 1000,
    cropHeight: 738,
  },
  'iq3-butter': {
    cropX: 426,
    cropY: 125,
    cropWidth: 1000,
    cropHeight: 738,
  },
  affiniti70: {
    cropX: 0,
    cropY: 0,
    cropWidth: 700,
    cropHeight: 700,
  },
  voluson_s10: {
    cropX: 0,
    cropY: 0,
    cropWidth: 700,
    cropHeight: 700,
  },
  voluson_s8: {
    cropX: 0,
    cropY: 0,
    cropWidth: 700,
    cropHeight: 700,
  },
  mindray_dc_40: {
    cropX: 0,
    cropY: 0,
    cropWidth: 700,
    cropHeight: 700,
  },
};

export const OPACITY = 1;

// export const CATEGORIES = [
//   { id: 1, name: 'Cephalic Plane', color: `rgba(255, 69, 0, ${OPACITY})` }, // Orange
//   { id: 2, name: 'Cisterna Magna', color: `rgba(0, 191, 255, ${OPACITY})` }, // Deep Sky Blue
//   {
//     id: 3,
//     name: 'Cavum Septum Pellucidum',
//     color: `rgba(218, 112, 214, ${OPACITY})`,
//   }, // Orchid
//   { id: 4, name: 'Cerebellum', color: `rgba(255, 215, 0, ${OPACITY})` }, // Gold
//   { id: 5, name: 'Thalamus', color: `rgba(30, 144, 255, ${OPACITY})` }, // Dodger Blue
//   { id: 6, name: 'Ventricle', color: `rgba(255, 20, 147, ${OPACITY})` }, // Deep Pink
//   { id: 7, name: 'Vermis', color: `rgba(255, 165, 0, ${OPACITY})` }, // Orange Red
//   { id: 8, name: 'Stomach Bubble', color: `rgba(0, 128, 0, ${OPACITY})` }, // Green
//   { id: 9, name: 'Portal Sinus', color: `rgba(255, 105, 180, ${OPACITY})` }, // Hot Pink
//   {
//     id: 10,
//     name: 'Abdominal Biometry',
//     color: `rgba(0, 206, 209, ${OPACITY})`,
//   }, // Dark Turquoise
//   { id: 11, name: 'Fetal Kidneys', color: `rgba(240, 128, 128, ${OPACITY})` }, // Light Coral
//   { id: 12, name: 'Femur', color: `rgba(70, 130, 180, ${OPACITY})` }, // Steel Blue
//   { id: 13, name: 'Placenta', color: `rgba(0, 128, 128, ${OPACITY})` }, // Teal
//   { id: 14, name: 'AF Pocket', color: `rgba(255, 20, 147, ${OPACITY})` }, // Deep Pink
//   { id: 15, name: 'Umbilical Cord', color: `rgba(0, 255, 0, ${OPACITY})` }, // Lime Green
//   { id: 16, name: 'Fetal Spine', color: `rgba(255, 140, 0, ${OPACITY})` }, // Dark Orange
//   {
//     id: 17,
//     name: 'Sagital Fetal Spine',
//     color: `rgba(148, 0, 211, ${OPACITY})`,
//   }, // Dark Violet
// ];

// =========================================== model 14
// export const CATEGORIES = [
//     { id: 0, name: 'Cephalic Plane', color: `rgba(255, 69, 0, ${OPACITY})` }, // Bright Orange
//     { id: 1, name: 'Cerebellum', color: `rgba(255, 215, 0, ${OPACITY})` }, // Bright Yellow
//     { id: 2, name: 'Thalamus', color: `rgba(0, 0, 255, ${OPACITY})` }, // Pure Blue
//     { id: 3, name: 'Cavum Septum Pellucidum', color: `rgba(128, 0, 128, ${OPACITY})` }, // Purple
//     { id: 4, name: 'Portal Sinus', color: `rgba(255, 0, 255, ${OPACITY})` }, // Magenta
//     { id: 5, name: 'Abdominal Biometry', color: `rgba(0, 255, 255, ${OPACITY})` }, // Cyan
//     { id: 6, name: 'Stomach Bubble', color: `rgba(0, 128, 0, ${OPACITY})` }, // Green
//     { id: 7, name: 'Fetal Kidneys', color: `rgba(128, 0, 0, ${OPACITY})` }, // Dark Red
//     { id: 8, name: 'Fetal Spine', color: `rgba(255, 165, 0, ${OPACITY})` }, // Bright Orange
//     { id: 9, name: 'Femur', color: `rgba(0, 0, 139, ${OPACITY})` }, // Dark Blue
//     { id: 10, name: 'Femur visible', color: `rgba(34, 139, 34, ${OPACITY})` }, // Forest Green
//     { id: 11, name: 'Umbilical Cord', color: `rgba(255, 0, 0, ${OPACITY})` }, // Red
//     { id: 12, name: 'AF Pocket', color: `rgba(148, 0, 211, ${OPACITY})` }, // Dark Violet
//     { id: 13, name: 'Placenta', color: `rgba(75, 0, 130, ${OPACITY})` }, // Indigo
//     { id: 14, name: 'Fourth Ventricle', color: `rgba(210, 105, 30, ${OPACITY})` }, // Chocolate
//     { id: 15, name: 'Forearm Bones', color: `rgba(250, 128, 114, ${OPACITY})` }, // Salmon
//     { id: 16, name: 'Inferior Vena Cava', color: `rgba(0, 191, 255, ${OPACITY})` }, // Deep Sky Blue
//     { id: 17, name: 'Right Ventricle', color: `rgba(255, 99, 71, ${OPACITY})` }, // Tomato
//     { id: 18, name: 'Ribs', color: `rgba(240, 230, 140, ${OPACITY})` }, // Khaki
//     { id: 19, name: 'Sagittal Complete Spine with Skin Covering', color: `rgba(189, 183, 107, ${OPACITY})` }, // Dark Khaki
//     { id: 20, name: 'Adrenal Gland', color: `rgba(50, 205, 50, ${OPACITY})` }, // Lime Green
//     { id: 21, name: 'Sylvian Fissure', color: `rgba(255, 140, 0, ${OPACITY})` }, // Dark Orange
//     { id: 22, name: 'Ventricle', color: `rgba(0, 206, 209, ${OPACITY})` }, // Dark Turquoise
//     { id: 23, name: 'Superior Vena Cava', color: `rgba(65, 105, 225, ${OPACITY})` }, // Royal Blue
//     { id: 24, name: 'Diaphragm', color: `rgba(218, 112, 214, ${OPACITY})` }, // Orchid
//     { id: 25, name: 'Third Ventricle', color: `rgba(255, 20, 147, ${OPACITY})` }, // Deep Pink
//     { id: 26, name: 'Umbilical Vein', color: `rgba(0, 255, 127, ${OPACITY})` }, // Spring Green
//     { id: 27, name: 'Hippocampal Gyri', color: `rgba(255, 182, 193, ${OPACITY})` }, // Light Pink
//     { id: 28, name: 'Leg Bones', color: `rgba(176, 196, 222, ${OPACITY})` }, // Light Steel Blue
//     { id: 29, name: 'Corpus Callosum', color: `rgba(255, 105, 180, ${OPACITY})` }, // Hot Pink
//     { id: 30, name: 'Nostrils', color: `rgba(173, 255, 47, ${OPACITY})` }, // Green Yellow
//     { id: 31, name: 'Spina Bifida', color: `rgba(255, 228, 196, ${OPACITY})` }, // Bisque
//     { id: 32, name: 'Nasal Bone', color: `rgba(135, 206, 235, ${OPACITY})` }, // Sky Blue
//     { id: 33, name: 'Hard Palate', color: `rgba(255, 192, 203, ${OPACITY})` }, // Pink
//     { id: 34, name: 'Pulmonary Artery', color: `rgba(221, 160, 221, ${OPACITY})` }, // Plum
//     { id: 35, name: 'Right Atrium', color: `rgba(176, 224, 230, ${OPACITY})` }, // Powder Blue
//     { id: 36, name: 'Orbit', color: `rgba(255, 160, 122, ${OPACITY})` }, // Light Salmon
//     { id: 37, name: 'Inter Atrial Septum', color: `rgba(152, 251, 152, ${OPACITY})` }, // Pale Green
//     { id: 38, name: 'Umbilical Cord Insertion on Fetal Abdomen', color: `rgba(238, 130, 238, ${OPACITY})` }, // Violet
//     { id: 39, name: 'Arm Humerus', color: `rgba(245, 222, 179, ${OPACITY})` }, // Wheat
//     { id: 40, name: 'Fetal Neck', color: `rgba(255, 250, 205, ${OPACITY})` }, // Lemon Chiffon
//     { id: 41, name: 'Lungs', color: `rgba(175, 238, 238, ${OPACITY})` }, // Pale Turquoise
//     { id: 42, name: 'Left Ventricle', color: `rgba(218, 165, 32, ${OPACITY})` }, // Golden Rod
//     { id: 43, name: 'Cerebral Peduncle', color: `rgba(127, 255, 212, ${OPACITY})` }, // Aquamarine
//     { id: 44, name: 'Choroid Plexus', color: `rgba(255, 218, 185, ${OPACITY})` }, // Peach Puff
//     { id: 45, name: 'Cisterna Magna', color: `rgba(216, 191, 216, ${OPACITY})` }, // Thistle
//     { id: 46, name: 'Umbilical Cord Insertion Site on the Placenta', color: `rgba(240, 128, 128, ${OPACITY})` }, // Light Coral
//     { id: 47, name: 'Fetal Bladder', color: `rgba(144, 238, 144, ${OPACITY})` }, // Light Green
//     { id: 48, name: 'Left Atrium', color: `rgba(255, 222, 173, ${OPACITY})` }, // Navajo White
//     { id: 49, name: 'Midline Falx', color: `rgba(250, 235, 215, ${OPACITY})` }, // Antique White
//     { id: 50, name: 'Aorta', color: `rgba(255, 228, 225, ${OPACITY})` }, // Misty Rose
//     { id: 51, name: 'Interventricular Septum', color: `rgba(240, 255, 240, ${OPACITY})` }, // Honeydew
//     { id: 52, name: 'Placenta Cysts', color: `rgba(253, 245, 230, ${OPACITY})` }, // Old Lace
//   { id: 53, name: 'Coronal Complete Spine', color: `rgba(255, 239, 213, ${OPACITY})` }, // Papaya Whip
//   { id: 54, name: 'Gallbladder', color: `rgba(255, 228, 181, ${OPACITY})` }, // Moccasin
//   { id: 55, name: 'Vermis', color: `rgba(255, 250, 240, ${OPACITY})` }, // Floral White
//   { id: 56, name: 'Upper Lip', color: `rgba(245, 255, 250, ${OPACITY})` }, // Mint Cream
//   { id: 57, name: 'Arterial Duct', color: `rgba(230, 230, 250, ${OPACITY})` }, // Lavender
//   { id: 58, name: 'Mandible', color: `rgba(255, 248, 220, ${OPACITY})` }, // Cornsilk
//   { id: 59, name: 'Heart', color: `rgba(255, 240, 245, ${OPACITY})` }, // Lavender Blush
//   { id: 60, name: 'Hepatic Vein', color: `rgba(240, 255, 255, ${OPACITY})` }, // Azure
//   { id: 61, name: 'Nuchal Translucency', color: `rgba(248, 248, 255, ${OPACITY})` }, // Ghost White
//   { id: 62, name: 'Trachea', color: `rgba(245, 245, 245, ${OPACITY})` }, // White Smoke
//   { id: 63, name: 'Genitalia', color: `rgba(255, 245, 238, ${OPACITY})` }, // Seashell
//   { id: 64, name: 'Cervix', color: `rgba(245, 245, 220, ${OPACITY})` }, // Beige
//   { id: 65, name: 'Lenses Cristallin', color: `rgba(220, 220, 220, ${OPACITY})` }, // Gainsboro
//   { id: 66, name: 'Hand', color: `rgba(211, 211, 211, ${OPACITY})` }, // Light Gray
//   { id: 67, name: 'Maternal Bladder', color: `rgba(192, 192, 192, ${OPACITY})` } // Silver
// ];

// =========================================== model 64
export const CATEGORIES = [
  { id: 0, name: 'Cephalic Plane', color: `rgba(255, 69, 0, ${OPACITY})` }, // Bright Orange
  { id: 1, name: 'Cerebellum', color: `rgba(255, 215, 0, ${OPACITY})` }, // Bright Yellow
  { id: 2, name: 'Thalamus', color: `rgba(0, 0, 255, ${OPACITY})` }, // Pure Blue
  { id: 3, name: 'Cavum Septum Pellucidum', color: `rgba(128, 0, 128, ${OPACITY})` }, // Purple
  { id: 4, name: 'Portal Sinus', color: `rgba(255, 0, 255, ${OPACITY})` }, // Magenta
  { id: 5, name: 'Abdominal Biometry', color: `rgba(0, 255, 255, ${OPACITY})` }, // Cyan
  { id: 6, name: 'Stomach Bubble', color: `rgba(0, 128, 0, ${OPACITY})` }, // Green
  { id: 7, name: 'Fetal Kidneys', color: `rgba(128, 0, 0, ${OPACITY})` }, // Dark Red
  { id: 8, name: 'Fetal Spine', color: `rgba(255, 165, 0, ${OPACITY})` }, // Bright Orange
  { id: 9, name: 'Femur', color: `rgba(0, 0, 139, ${OPACITY})` }, // Dark Blue
  { id: 10, name: 'Femur visible', color: `rgba(34, 139, 34, ${OPACITY})` }, // Forest Green
  { id: 12, name: 'AF Pocket', color: `rgba(148, 0, 211, ${OPACITY})` }, // Dark Violet
  { id: 13, name: 'Placenta', color: `rgba(75, 0, 130, ${OPACITY})` }, // Indigo
  { id: 16, name: 'Inferior Vena Cava', color: `rgba(0, 191, 255, ${OPACITY})` }, // Deep Sky Blue
  { id: 17, name: 'Right Ventricle', color: `rgba(255, 99, 71, ${OPACITY})` }, // Tomato
  { id: 22, name: 'Ventricle', color: `rgba(0, 206, 209, ${OPACITY})` }, // Dark Turquoise
  { id: 26, name: 'Umbilical Vein', color: `rgba(0, 255, 127, ${OPACITY})` }, // Spring Green
  { id: 28, name: 'Leg Bones', color: `rgba(176, 196, 222, ${OPACITY})` }, // Light Steel Blue
  { id: 35, name: 'Right Atrium', color: `rgba(176, 224, 230, ${OPACITY})` }, // Powder Blue
  { id: 37, name: 'Inter Atrial Septum', color: `rgba(152, 251, 152, ${OPACITY})` }, // Pale Green
  { id: 41, name: 'Lungs', color: `rgba(175, 238, 238, ${OPACITY})` }, // Pale Turquoise
  { id: 42, name: 'Left Ventricle', color: `rgba(218, 165, 32, ${OPACITY})` }, // Golden Rod
  { id: 43, name: 'Cerebral Peduncle', color: `rgba(127, 255, 212, ${OPACITY})` }, // Aquamarine
  { id: 44, name: 'Choroid Plexus', color: `rgba(255, 218, 185, ${OPACITY})` }, // Peach Puff
  { id: 45, name: '255Cisterna Magna', color: `rgba(216, 191, 216, ${OPACITY})` }, // Thistle
  { id: 47, name: 'Fetal Bladder', color: `rgba(144, 238, 144, ${OPACITY})` }, // Light Green
  { id: 48, name: 'Left Atrium', color: `rgba(255, 222, 173, ${OPACITY})` }, // Navajo White
  { id: 50, name: 'Aorta', color: `rgba(255, 228, 225, ${OPACITY})` }, // Misty Rose
  { id: 51, name: 'Interventricular Septum', color: `rgba(240, 255, 240, ${OPACITY})` }, // Honeydew
  { id: 54, name: 'Gallbladder', color: `rgba(255, 228, 181, ${OPACITY})` }, // Moccasin
  { id: 56, name: 'Upper Lip', color: `rgba(245, 255, 250, ${OPACITY})` }, // Mint Cream
  { id: 59, name: 'Heart', color: `rgba(255, 240, 245, ${OPACITY})` }, // Lavender Blush
];

// ============================================= model 68 all the segmentations
// export const CATEGORIES = [
//   { id: 0, name: 'Cephalic Plane', color: `rgba(255, 69, 0, ${OPACITY})` }, // Bright Orange
//   { id: 1, name: 'Cerebellum', color: `rgba(255, 215, 0, ${OPACITY})` }, // Bright Yellow
//   { id: 2, name: 'Thalamus', color: `rgba(0, 0, 255, ${OPACITY})` }, // Pure Blue
//   { id: 3, name: 'Cavum Septum Pellucidum', color: `rgba(128, 0, 128, ${OPACITY})`}, // Purple
//   { id: 4, name: 'Portal Sinus', color: `rgba(255, 0, 255, ${OPACITY})` }, // Magenta
//   { id: 5, name: 'Abdominal Biometry', color: `rgba(0, 255, 255, ${OPACITY})` }, // Cyan
//   { id: 6, name: 'Stomach Bubble', color: `rgba(0, 128, 0, ${OPACITY})` }, // Green
//   { id: 7, name: 'Fetal Kidneys', color: `rgba(128, 0, 0, ${OPACITY})` }, // Dark Red
//   { id: 8, name: 'Fetal Spine', color: `rgba(255, 165, 0, ${OPACITY})` }, // Bright Orange
//   { id: 9, name: 'Femur', color: `rgba(0, 0, 139, ${OPACITY})` }, // Dark Blue
//   { id: 10, name: 'Femur visible', color: `rgba(34, 139, 34, ${OPACITY})` }, // Forest Green
//   { id: 11, name: 'Umbilical Cord', color: `rgba(255, 0, 0, ${OPACITY})` }, // Red
//   { id: 12, name: 'AF Pocket', color: `rgba(148, 0, 211, ${OPACITY})` }, // Dark Violet
//   { id: 13, name: 'Placenta', color: `rgba(75, 0, 130, ${OPACITY})` }, // Indigo
//   { id: 14, name: 'Fourth Ventricle', color: `rgba(210, 105, 30, ${OPACITY})` }, // Chocolate
//   { id: 15, name: 'Forearm Bones', color: `rgba(250, 128, 114, ${OPACITY})` }, // Salmon
//   { id: 16, name: 'Inferior Vena Cava', color: `rgba(0, 191, 255, ${OPACITY})` }, // Deep Sky Blue
//   { id: 17, name: 'Right Ventricle', color: `rgba(255, 99, 71, ${OPACITY})` }, // Tomato
//   { id: 18, name: 'Ribs', color: `rgba(240, 230, 140, ${OPACITY})` }, // Khaki
//   { id: 19, name: 'Sagittal Complete Spine with Skin Covering', color: `rgba(189, 183, 107, ${OPACITY})` }, // Dark Khaki
//   { id: 20, name: 'Adrenal Gland', color: `rgba(50, 205, 50, ${OPACITY})` }, // Lime Green
//   { id: 21, name: 'Sylvian Fiss255ure', color: `rgba(255, 140, 0, ${OPACITY})` }, // Dark Orange
//   { id: 22, name: 'Ventricle', color: `rgba(0, 206, 209, ${OPACITY})` }, // Dark Turquoise
//   { id: 23, name: 'Superior Vena Cava', color: `rgba(65, 105, 225, ${OPACITY})` }, // Royal Blue
//   { id: 24, name: 'Diaphragm', color: `rgba(218, 112, 214, ${OPACITY})` }, // Orchid
//   { id: 25, name: 'Third Ventricle', color: `rgba(255, 20, 147, ${OPACITY})` }, // Deep Pink
//   { id: 26, name: 'Umbilical Vein', color: `rgba(0, 255, 127, ${OPACITY})` }, // Spring Green
//   { id: 27, name: 'Hippocampal Gyri', color: `rgba(255, 182, 193, ${OPACITY})` }, // Light Pink
//   { id: 28, name: 'Leg Bones', color: `rgba(176, 196, 222, ${OPACITY})` }, // Light Steel Blue
//   { id: 29, name: 'Corpus Callosum', color: `rgba(255, 105, 180, ${OPACITY})` }, // Hot Pink
//   { id: 30, name: 'Nostrils', color: `rgba(173, 255, 47, ${OPACITY})` }, // Green Yellow
//   { id: 31, name: 'Spina Bifida', color: `rgba(255, 228, 196, ${OPACITY})` }, // Bisque
//   { id: 32, name: 'Nasal Bone', color: `rgba(135, 206, 235, ${OPACITY})` }, // Sky Blue
//   { id: 33, name: 'Hard Palate', color: `rgba(255, 192, 203, ${OPACITY})` }, // Pink
//   { id: 34, name: 'Pulmonary Artery', color: `rgba(221, 160, 221, ${OPACITY})` }, // Plum
//   { id: 35, name: 'Right Atrium', color: `rgba(176, 224, 230, ${OPACITY})` }, // Powder Blue
//   { id: 36, name: 'Orbit', color: `rgba(255, 160, 122, ${OPACITY})` }, // Light Salmon
//   { id: 37, name: 'Inter Atrial Septum', color: `rgba(152, 251, 152, ${OPACITY})` }, // Pale Green
//   { id: 38, name: 'Umbilical Cord Insertion on Fetal Abdomen', color: `rgba(238, 130, 238, ${OPACITY})` }, // Violet
//   { id: 39, name: 'Arm Humerus', color: `rgba(245, 222, 179, ${OPACITY})` }, // Wheat
//   { id: 40, name: 'Fetal Neck', color: `rgba(255, 250, 205, ${OPACITY})` }, // Lemon Chiffon
//   { id: 41, name: 'Lungs', color: `rgba(175, 238, 238, ${OPACITY})` }, // Pale Turquoise
//   { id: 42, name: 'Left Ventricle', color: `rgba(218, 165, 32, ${OPACITY})` }, // Golden Rod
//   { id: 43, name: 'Cerebral Peduncle', color: `rgba(127, 255, 212, ${OPACITY})` }, // Aquamarine
//   { id: 44, name: 'Choroid Plexus', color: `rgba(255, 218, 185, ${OPACITY})` }, // Peach Puff
//   { id: 45, name: 'Cisterna Magna', color: `rgba(216, 191, 216, ${OPACITY})` }, // Thistle
//   { id: 46, name: 'Umbilical Cord Insertion Site on the Placenta', color: `rgba(240, 128, 128, ${OPACITY})` }, // Light Coral
//   { id: 47, name: 'Fetal Bladder', color: `rgba(144, 238, 144, ${OPACITY})` }, // Light Green
//   { id: 48, name: 'Left Atrium', color: `rgba(255, 222, 173, ${OPACITY})` }, // Navajo White
//   { id: 49, name: 'Midline Falx', color: `rgba(250, 235, 215, ${OPACITY})` }, // Antique White
//   { id: 50, name: 'Aorta', color: `rgba(255, 228, 225, ${OPACITY})` }, // Misty Rose
//   { id: 51, name: 'Interventricular Septum', color: `rgba(240, 255, 240, ${OPACITY})` }, // Honeydew
//   { id: 52, name: 'Placenta Cysts', color: `rgba(253, 245, 230, ${OPACITY})` }, // Old Lace
//   { id: 53, name: 'Coronal Complete Spine', color: `rgba(255, 239, 213, ${OPACITY})` }, // Papaya Whip
//   { id: 54, name: 'Gallbladder', color: `rgba(255, 228, 181, ${OPACITY})` }, // Moccasin
//   { id: 55, name: 'Vermis', color: `rgba(255, 250, 240, ${OPACITY})` }, // Floral White
//   { id: 56, name: 'Upper Lip', color: `rgba(245, 255, 250, ${OPACITY})` }, // Mint Cream
//   { id: 57, name: 'Arterial Duct', color: `rgba(230, 230, 250, ${OPACITY})` }, // Lavender
//   { id: 58, name: 'Mandible', color: `rgba(255, 248, 220, ${OPACITY})` }, // Cornsilk
//   { id: 59, name: 'Heart', color: `rgba(255, 240, 245, ${OPACITY})` }, // Lavender Blush
//   { id: 60, name: 'Hepatic Vein', color: `rgba(240, 255, 255, ${OPACITY})` }, // Azure
//   { id: 61, name: 'Nuchal Translucency', color: `rgba(248, 248, 255, ${OPACITY})` }, // Ghost White
//   { id: 62, name: 'Trachea', color: `rgba(245, 245, 245, ${OPACITY})` }, // White Smoke
//   { id: 63, name: 'Genitalia', color: `rgba(255, 245, 238, ${OPACITY})` }, // Seashell
//   { id: 64, name: 'Cervix', color: `rgba(245, 245, 220, ${OPACITY})` }, // Beige
//   { id: 65, name: 'Lenses Cristallin', color: `rgba(220, 220, 220, ${OPACITY})` }, // Gainsboro
//   { id: 66, name: 'Hand', color: `rgba(211, 211, 211, ${OPACITY})` }, // Light Gray
//   { id: 67, name: 'Maternal Bladder', color: `rgba(192, 192, 192, ${OPACITY})` }, // Silver
// ];
