import React, { useState, useEffect } from 'react';
import '../../style/index.css';
import Modal from '../../../../Components/modal';
import Dropdown from '../customize-dropdown';
import { FaGear } from 'react-icons/fa6';
import { RiArrowGoBackLine } from 'react-icons/ri';
import axios from 'axios';
import { APIClient } from '../../../../../helpers/apiHelper';
import EditableTable from '../editable-table';

function DevicesModal({
  inputDevices,
  setDeviceId,
  setInputDevices,
  globalState,
  setGlobalState,
  mediaTypeSwitcher,
  handleNext,
  api,
  token,
}) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [lengthDeviceArray, setLengthDeviceArray] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [optionValue, setOptionValue] = useState('');
  const [isConfig, setIsConfig] = useState(false);
  const [itemDrop, setItemDrop] = useState();
  const apiClient = new APIClient();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    mediaTypeSwitcher();
  };

  useEffect(() => {
    if (isChecked) {
      setOptionValue('Share');
      setGlobalState(prevState => ({
        ...prevState,
        optionValueDevice: 'Share',
      }));
    } else {
      setOptionValue('USB');
      setGlobalState(prevState => ({
        ...prevState,
        optionValueDevice: 'USB',
      }));
    }
  }, [isChecked]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkDeviceChanges();
    }, 5000); // Check every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchUltrasoundMachines = async () => {
    try {
      const machines = await apiClient.getUltrasoundMachines();
      setItemDrop(machines.data);
    } catch (error) {
      console.error('Failed to fetch ultrasound machines:', error);
    }
  };
  useEffect(() => {
    fetchUltrasoundMachines();
  }, [isConfig]);
  const checkDeviceChanges = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          console.log('Available devices:', devices);
          setLengthDeviceArray(devices?.length);
          setInputDevices(
            devices.filter(device => {
              return (
                device.kind === 'videoinput' &&
                device.label.toLowerCase().indexOf('integrateaaad') === -1 &&
                device.label.toLowerCase().indexOf('cameraaa') === -1
              );
            })
          );
        })
        .catch(error => {
          console.error('Error enumerating devices: ', error);
        });
    } else {
      console.error('enumerateDevices is not supported on this browser');
    }
  };

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          console.log('Available devices:', devices);
          setLengthDeviceArray(devices?.length);
          setInputDevices(
            devices.filter(device => {
              return (
                device.kind === 'videoinput' &&
                device.label.toLowerCase().indexOf('integrated______') === -1 &&
                device.label.toLowerCase().indexOf('camera__________') === -1
              );
            })
          );
        })
        .catch(error => {
          console.error('Error enumerating devices: ', error);
        });
    } else {
      console.error('enumerateDevices is not supported on this browser');
    }
  }, []);

  return (
    <div className="relative h-full w-full">
      <div className="relative h-full w-full p-3">
        <div className="inline-flex items-center justify-between">
          <div className="flex w-full items-center justify-between">
            <div className="text-xl font-semibold text-[#1F384C]">Configure Your Setup</div>
          </div>
        </div>

        <div className="w-full py-5">
          <div className="text-lg font-semibold text-[#686C82]">Select Machine Type</div>
          <div className="mt-5">
            <Dropdown
              items={itemDrop}
              isOpen={openDropdown}
              setIsOpen={setOpenDropdown}
              globalState={globalState}
              setGlobalState={setGlobalState}
            />
          </div>
        </div>

        {globalState.machineType && (
          <>
            <div className="text-lg font-semibold text-[#686C82]">Select Input Device</div>
            <ul className="ml-1 mt-1 w-fit rounded-lg text-sm font-medium text-gray-900">
              {inputDevices.map((device, index) => (
                <li
                  key={index}
                  className="w-full rounded-t-lg"
                >
                  <div className="ps-3 flex items-center space-x-3">
                    <input
                      id={`device-checkbox-${index}`}
                      type="checkbox"
                      name="inputDevice"
                      value={device.label}
                      className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 outline-none"
                      onChange={() => {
                        setDeviceId(device.deviceId);
                        handleNext();
                      }}
                    />
                    <label
                      htmlFor={`device-checkbox-${index}`}
                      className="ms-2 w-full py-3 text-sm font-medium text-gray-900"
                    >
                      {device.label}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
        <div className="absolute bottom-12 right-3 flex w-full items-center justify-end">
          <div className="checkbox-wrapper-35 space-x-2">
            <input
              value="private"
              name="switch"
              id="switch"
              type="checkbox"
              className="switch"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="switch"
              className="mt-1"
            ></label>
          </div>
          <div className="mt-0.5 text-lg text-[#A6ABC8]">share from laptop</div>
        </div>
      </div>
    </div>
  );
}
export default DevicesModal;
