import React, { useEffect, useState } from 'react';

function ProgressPlanes({ plane, isComplete, percent, logo }) {
  const [numberPro, setNumberPro] = useState(0);
  useEffect(() => {
    if (plane == 'Cephal' || plane == 'Abdominal') {
      if (percent == 100) {
        setNumberPro(4);
      } else if (percent == 75) {
        setNumberPro(3);
      } else if (percent == 50) {
        setNumberPro(2);
      } else if (percent == 25) {
        setNumberPro(1);
      } else {
        setNumberPro(0);
      }
    } else if (plane == 'Femur') {
      if (percent == 100) {
        setNumberPro(3);
      } else if (percent == (100 * 2) / 3) {
        setNumberPro(2);
      } else if (percent == 100 / 3) {
        setNumberPro(1);
      }
    } else {
      if (percent == 100) {
        setNumberPro(1);
      }
    }
  });
  return (
    <div className="inline-flex w-full items-center space-x-2 py-2">
      {/* <div>
        <img src={logo} />
      </div> */}
      <div className="flex w-full flex-col space-y-2 text-sm">
        <div className="inline-flex justify-between">
          <div className="space-x-1 font-medium">
            <span className="text-xs">{plane}</span>
            {isComplete && (
              <span className="rounded-md bg-[#caf4d8] px-1 text-xs text-[#40d672]">STD</span>
            )}
          </div>
          {plane == 'Cephal' || plane == 'Abdominal' ? (
            <div className="text-xs font-medium text-[#a6abc8]">{numberPro}/4</div>
          ) : plane == 'Femur' ? (
            <div className="text-xs font-medium text-[#a6abc8]">{numberPro}/3</div>
          ) : (
            <div className="text-xs font-medium text-[#a6abc8]">{numberPro}/1</div>
          )}
        </div>
        <div className="h-1.5 w-full rounded-full bg-gray-200">
          {plane == 'Cephal' || plane == 'Abdominal' ? (
            <div
              className={`h-1.5 rounded-full  ${percent == 100 ? 'bg-[#40d672]' : percent == 75 ? 'bg-[#d6d340]' : percent == 50 ? 'bg-[#f4944e]' : percent == 25 ? 'bg-[#ec5252]' : ''}`}
              style={{ width: `${percent}%` }}
            ></div>
          ) : plane == 'Femur' ? (
            <div
              className={`h-1.5 rounded-full  ${percent == 100 ? 'bg-[#40d672]' : percent == (100 * 2) / 3 ? 'bg-[#f4944e]' : percent == 100 / 3 ? 'bg-[#ec5252]' : ''}`}
              style={{ width: `${percent}%` }}
            ></div>
          ) : (
            <div
              className={`h-1.5 rounded-full  ${percent == 100 ? 'bg-[#40d672]' : percent == (100 * 2) / 3 ? 'bg-[#f4944e]' : percent == 100 / 3 ? 'bg-[#ec5252]' : ''}`}
              style={{ width: `${percent}%` }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ProgressPlanes;
