import React, { useEffect, useState } from 'react';
import './patient.page.css';
import 'simplebar-react/dist/simplebar.min.css';
import { useParams } from 'react-router-dom';
import PatientInformation from '../../Components/PatientInfo/patient.information';
import { Patient as patient } from '../../Sections/PatientsList/patientsList.section.custom';
import { getPatientById } from '../../../helpers/backendHelper';
import PatientExams from '../../Components/PaientExams/patient.exams';
import PatientHistoricalImagery from '../../Components/PatientHistoricalImagery/patient.historical.imagery';
import ErrorPage from '../../Components/Error/error';

interface ExamItem {
  title: string;
  imgSrc: string;
}

const PatientExamsList: Array<ExamItem> = [
  {
    title: 'exam0-patient0',
    imgSrc:
      'https://www.shutterstock.com/shutterstock/photos/1493574323/display_1500/stock-photo-ultrasound-small-baby-at-weeks-weeks-pregnant-ultrasound-image-show-baby-or-fetus-1493574323.jpg',
  },

  {
    title: 'exam0-patient0',
    imgSrc:
      'https://www.shutterstock.com/shutterstock/photos/1493574323/display_1500/stock-photo-ultrasound-small-baby-at-weeks-weeks-pregnant-ultrasound-image-show-baby-or-fetus-1493574323.jpg',
  },
];

interface ReportItem {
  title: string;
  imgSrc: string;
}

const PatientReportsList: Array<ReportItem> = [
  {
    title: 'report0-patient0',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
  },

  {
    title: 'report0-patient0',
    imgSrc:
      'https://images.sampleforms.com/wp-content/uploads/2019/01/Medical-Report-Form-Sample.jpg',
  },
];

const Patient: React.FC = () => {
  const { PatientID } = useParams();
  const [PatientInfo, setPatient] = useState<any>();
  const [Error, setError] = useState<boolean>(false);
  const [msgError, setMsgError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [PatientName, SetPatientName] = useState<string>('');
  const [Id, setId] = useState<string>('');
  const fullName = `${PatientInfo?.patient_name}`;
  useEffect(() => {
    getPatientById(PatientID)
      .then(res => {
        if (res) {
          console.log('res', res);
          setPatient(res);
          SetPatientName(res?.patient_name);
          setId(res?.id);
        }
        setLoading(false);
      })
      .catch(err => {
        setMsgError(err);
        setError(true);
        setLoading(false);
      });
  }, [PatientID]);
  // useEffect(() => {
  //   console.log('hello all ::' + JSON.stringify(PatientInfo));
  // });
  return loading ? (
    <div>Loading...</div>
  ) : Error ? (
    <ErrorPage msg={msgError} />
  ) : (
    <div className=" flex h-full flex-col gap-3 rounded-md p-6 shadow-md">
      <h1 className="font-[18px] text-[#1F384C]">Patients Profile</h1>
      <div className="h-[40%]">
        <PatientInformation
          patient={PatientInfo}
          setPatient={setPatient}
        />
      </div>
      <div className="flex h-[60%] w-full gap-3 ">
        <div className="w-1/2 rounded-lg border">
          <PatientExams
            fullName={PatientName}
            PatientId={Id}
          />
        </div>
        <div className="w-1/2 rounded-lg border">
          <PatientHistoricalImagery patientId={PatientID} />
        </div>
      </div>
    </div>
  );
};

export default Patient;
