import React, { useState, useEffect, useRef } from 'react';

interface Props {
  cellProps: {
    row: {
      original: {
        roles: string[];
      };
    };
  };
  handleRoleChange: (role: string) => void;
}

const RoleDropdown: React.FC<Props> = ({ cellProps, handleRoleChange }) => {
  const roles = ['doctor', 'lead_doctor', 'user_manager'];
  const currentRole = cellProps.row.original.roles[0] || '';
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const isSuperAdmin = currentRole === 'super_admin';
  const otherRoles = roles.filter(role => role !== currentRole);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleRoleClick = (role: string) => {
    handleRoleChange(role);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getBadgeColor = (role: string) => {
    switch (role) {
      case 'doctor':
        return 'bg-green-400';
      case 'lead_doctor':
        return 'bg-blue-400';
      case 'user_manager':
        return 'bg-orange-400';
      default:
        return 'bg-red-400';
    }
  };

  return roles.length > 0 ? (
    <div
      className="relative inline-block"
      ref={dropdownRef}
    >
      <div
        className={`inline-flex items-center rounded px-3 py-1 text-sm font-medium text-white ${getBadgeColor(
          currentRole
        )}`}
        onClick={!isSuperAdmin ? toggleDropdown : undefined}
        style={{ cursor: isSuperAdmin ? 'default' : 'pointer' }}
      >
        {currentRole || '--'}
      </div>
      {isOpen && (
        <div className="absolute right-0 z-50 mt-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {otherRoles.map(role => (
              <button
                key={role}
                onClick={() => handleRoleClick(role)}
                className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
              >
                {role}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  ) : (
    <>--</>
  );
};

export default RoleDropdown;
