import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  RESET_PROFILE_FLAG,
  GET_PROFILE,
  CHOOSE_ORGANIZAITON,
} from './actionTypes';

const initialState = {
  user: {},
  currentOrganization: {},
};

const Profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      state = { ...state, loading: true };
      break;

    case CHOOSE_ORGANIZAITON:
      localStorage.setItem('organization', action.payload.id);
      state = {
        ...state,
        currentOrganization: action.payload,
      };
      break;

    case PROFILE_SUCCESS:
      state = {
        ...state,
        success: true,
        user: action.payload,
        loading: false,
      };
      break;
    case PROFILE_ERROR:
      state = {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
      };
      break;
    case RESET_PROFILE_FLAG:
      state = {
        ...state,
        success: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Profile;
