import {
  PROFILE_ERROR,
  CHOOSE_ORGANIZAITON,
  PROFILE_SUCCESS,
  RESET_PROFILE_FLAG,
  GET_PROFILE,
} from './actionTypes';

export const getProfile = () => {
  return {
    type: GET_PROFILE,
  };
};

export const chooseOrganization = organization => {
  return {
    type: CHOOSE_ORGANIZAITON,
    payload: organization,
  };
};

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  };
};

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
};

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  };
};
