import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa'; // Using a more professional exclamation icon

const NoDataFound = ({ title = 'No Data Found' }) => {
  return (
    <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100 p-8 font-semibold text-[#2B5170] shadow-md">
      <FaExclamationCircle className="text-6xl" />
      <h2 className="mt-4 text-xl">{title}</h2>
    </div>
  );
};

export default NoDataFound;
