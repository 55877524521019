import { archiveStudy } from '../../store/actions';

export interface Study {
  study_instance_uid: string;
  patient_id: number;
  doctor_name: string | null;
  modality: string | null;
  study_description: string | null;
  study_creation_date: string | null;
  archived: boolean;
  archived_at: string | null;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
  is_deleted: boolean;
  patient_id_1: number;
  patient_patient_name: string;
  patient_email: string;
  patient_phone_number: string;
  patient_birth_date: string;
  patient_sex: string;
  patient_weight: number;
  patient_height: number;
  patient_country: string;
  patient_city: string;
  patient_patient_middle_name: string | null;
  patient_patient_last_name: string | null;
  patient_addressline1: string;
  patient_addressline2: string;
  patient_zip: string;
  patient_state: string;
  patient_watchlist: boolean;
  patient_policy_number: string;
  patient_medical_plan: string;
  patient_emergency_contact_name: string;
  patient_emergency_contact_phone: string;
  patient_emergency_contact_relationship: string;
  patient_patient_id: number;
  patient_created_at: string;
  patient_updated_at: string | null;
  patient_deleted_at: string | null;
  patient_is_deleted: boolean;
  isChecked?: boolean;
}

export interface StudyState {
  studies: Study[];
  report: any;
  study: any;
  needsReload: boolean;
  studyDeleted: boolean;
  totalCount: number;
  hasMore: boolean;
  error: any | null;
  loading: boolean;
  success: boolean;
  filtered_conditions: string[];
}

interface Report {
  reports: any[];
  error: any;
}

interface Profile {
  user: any;
  currentOrganization: any;
}

interface Login {
  errorMsg: string;
  loading: boolean;
  error: boolean;
  needsVerification: boolean;
  success: boolean;
}

interface Register {
  registrationError: any | null;
  message: any | null;
  loading: boolean;
  user: any | null;
  success: boolean;
  error: boolean;
}

interface Organization {
  organizations: any[];
  activeOrganization: any;
  error: boolean;
  loading: boolean;
  success: boolean;
}

interface SR {
  error: any;
  needsReload: boolean;
}

interface Role {
  organizations: any[];
  activeOrganization: any;
  error: any;
  loading: boolean;
}

interface Patient {
  patients: any[];
  totalCount: number;
  hasMore: boolean;
  currentPage: number;
  itemsPerPage: number;
  error: any | null;
  loading: boolean;
  success: boolean;
  filtered_conditions: any;
}

interface SR_Report {
  SR_Report: any;
}

export interface State {
  Study?: StudyState;
  Report?: Report;
  Profile?: Profile;
  Login?: Login;
  Register?: Register;
  Organization?: Organization;
  SR?: SR;
  Role?: Role;
  Patient?: Patient;
  SR_Report?: SR_Report;
}

export interface ArchiveStudyPayload {
  items: Study[];
  page: number;
  items_per_page: number;
  filter_options: string[];
}

export interface Menstrual {
  id: number;
  DateOfLastMenstrualPeriod: string;
  AgeAtFirstPeriod: string;
  AgeAtMenopause: string;
  MenstrualCycleLength: string;
  MenstrualFlowDuration: string;
  HeavyPeriods: boolean;
  Painful: boolean;
  Irregular: boolean;
  PMS: boolean; // ask taha about this PMS
}

export interface GlobalReportSearchParams {
  study_id: string;
  serie_id: string;
  date: string;
}

export enum DicomUploadStatus {
  Pending = 'PENDING',
  Uploading = 'UPLOADING',
  Successful = 'SUCCESSFUL',
  Failed = 'FAILED',
}

export interface DicomInstance {
  sop_instance_uid?: string;
  image_type?: string[];
  transfer_syntax_uid?: string;
  instance_number?: number;
  sop_class_uid?: string;
  height?: number;
  width?: number;
  pixel_spacing?: [number, number];
  frames?: number;
  instance_description?: string;
  file?: string | File;
  upload_status?: DicomUploadStatus;
  error?: string;
}

export interface DicomSeries {
  series_instance_uid?: string;
  series_number?: number;
  series_modality?: string;
  physician_name?: string;
  series_institution_name?: string;
  frame_numbers?: number;
  instances: DicomInstance[];
  upload_status?: DicomUploadStatus;
}

export interface DicomStudy {
  study_instance_uid?: string;
  study_date?: string;
  study_time?: string;
  study_id?: string;
  patient_id?: string;
  patient_name?: string;
  series: DicomSeries[];
  upload_status?: DicomUploadStatus;
}

export type DicomDataStructure = DicomStudy[];

export interface StudieInfo {
  study_instance_uid?: string; //
  study_date?: string; //
  study_time?: string;
  study_id?: string;
  patient_id?: string; //
  patient_name?: string; //
  series_instance_uid?: string; //
  series_modality?: string; //
  sop_instance_uid?: string; //
  transfer_syntax_uid?: string; //
  sop_class_uid?: string; //
  pixel_spacing?: [number, number]; //
  frames?: number; //
  study_upload_status: DicomUploadStatus;
  series_upload_status: DicomUploadStatus;
  instance_upload_status: DicomUploadStatus;
}

export interface StudySerieUploadInfo {
  study_instance_uid: string;
  series_instance_uid: string;
  total_files: number;
  total_failed: number;
  total_success: number;
  failed_instances: DicomInstance[];
}
export interface AddStudie {
  file: string | File;
  StudieInfo: StudieInfo;
}

export interface StudyResult {
  sop_instance_uid: string;
  study_instance_uid: string;
  serie_instance_uid: string;
}

export interface ApiResponse<T> {
  message: string;
  status_code: number;
  data: {
    result: T;
  };
}

// Usage example
export type StudyUploadResponse = ApiResponse<StudyResult>;

export interface ApiErrorDetails {
  [key: string]: any;
}

export interface ApiError {
  error: string;
  message: string;
  status_code: number;
  details?: ApiErrorDetails;
}

// Example of how to use the error interface
export interface StudyUploadError extends ApiError {
  details?: {
    original_study_info?: string;
    study_info_dict?: Record<string, any>;
    patient_id?: number;
    filename?: string;
    validation_error?: string;
    dicom_error?: string;
    transfer_syntax?: string;
  };
}

export function isApiError(response: any): response is ApiError {
  return (
    response &&
    typeof response.error === 'string' &&
    typeof response.message === 'string' &&
    typeof response.status_code === 'number'
  );
}
