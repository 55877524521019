import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStudies, resetStudyState } from '../../../store/actions';
import ExamTable from './exam.table';
import { useDebouncedCallback } from 'use-debounce';
import { State, StudyState } from '../../interfaces';
import NewExam from '../../Components/Action/NewExam';
import DateRangePicker from '../../Components/date-range-picker';
import * as rdrLocales from 'react-date-range/dist/locale';
import { format } from 'date-fns';
const TempExam = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [archived, setArchived] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const { studyState }: { studyState: StudyState } = useSelector((state: State) => ({
    studyState: state.Study,
  }));
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    reset: false,
  });
  const [dateFilter, setDateFilter] = useState<any[]>([]);
  const [filters, setFilters] = useState({
    dates: {
      min: format(new Date('2021-01-01'), 'yyyy-MM-dd'),
      max: format(new Date(), 'yyyy-MM-dd'),
    },
  });

  function HandlePrevPage() {
    dispatch(
      getStudies({
        filter_options: {
          ...studyState.filtered_conditions,
          page: page - 1,
          items_per_page: itemsPerPage,
        },
      })
    );
    setPage(prev => prev - 1);
  }
  function handleNextNext() {
    dispatch(
      getStudies({
        filter_options: {
          ...studyState.filtered_conditions,
          page: page + 1,
          items_per_page: itemsPerPage,
        },
      })
    );
    setPage(prev => prev + 1);
  }
  function HandleRowChange(e: any) {
    dispatch(
      getStudies({
        filter_options: {
          ...studyState.filtered_conditions,
          page: 1,
          items_per_page: e.target.value,
        },
      })
    );
    setItemsPerPage(e.target.value);
    setPage(1);
  }

  const searchExam = (search: string) => {
    const dateFormatRegex = /^(\d{4}-\d{2}-\d{2}|\d{3}\/\d{2}-\d{2})$/;
    setPage(1);
    if (dateFormatRegex.test(search)) {
      console.log('searchExam 1', search);
    } else {
      // ask about the default values for the page and items_per_page
      dispatch(
        getStudies({
          filter_options: {
            ...studyState.filtered_conditions,
            page: page,
            items_per_page: itemsPerPage,
            patient_patient_name__contains: search,
          },
        })
      );
    }
  };

  const searchExamByDate = dates => {
    const formatDate = dateString => {
      if (!dateString) {
        return '';
      }
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    if (dates?.reset === false && (dates?.startDate || dates?.endDate)) {
      const formattedStartDate = formatDate(dates.startDate);
      const formattedEndDate = formatDate(dates.endDate);

      dispatch(
        getStudies({
          filter_options: {
            ...studyState.filtered_conditions,
            page: 1,
            items_per_page: itemsPerPage,
            study_creation_date__gte: formattedStartDate,
            study_creation_date__lte: formattedEndDate,
          },
        })
      );
    }
  };
  const resetExamByDate = dates => {
    if (dates?.reset == true) {
      const { study_creation_date__gte, study_creation_date__lte, ...filteredConditions } =
        studyState.filtered_conditions as unknown as {
          study_creation_date__gte: string;
          study_creation_date__lte: string;
        };
      dispatch(
        getStudies({
          filter_options: { ...filteredConditions, page: 1, items_per_page: itemsPerPage },
        })
      );
    }
  };
  const debouncedSearchBackend = useDebouncedCallback(searchExam, 500, {
    maxWait: 2000,
    leading: true,
    trailing: false,
  });
  useEffect(() => {
    if (search.length > 0) {
      debouncedSearchBackend(search);
    } else {
      dispatch(
        getStudies({
          filter_options: { archived: archived, page: page, items_per_page: itemsPerPage },
        })
      );
    }

    return () => {
      debouncedSearchBackend.cancel();
    };
  }, [search, debouncedSearchBackend]);
  useEffect(() => {
    dispatch(
      getStudies({
        filter_options: { archived: archived, page: page, items_per_page: itemsPerPage },
      })
    );
    return () => {
      dispatch(resetStudyState());
    };
  }, [dispatch]);

  function handleSwitchChange(checked: boolean) {
    if (checked === false) {
      dispatch(
        getStudies({
          filter_options: {
            ...studyState.filtered_conditions,
            archived: true,
            page: 1,
            items_per_page: itemsPerPage,
          },
        })
      );
      setArchived(true);
      setPage(1);
    } else {
      dispatch(
        getStudies({ filter_options: { archived: false, page: 1, items_per_page: itemsPerPage } })
      );
      setArchived(false);
      setPage(1);
    }
  }
  function handleClickShow() {
    setShow(!show);
  }

  return (
    <div className="mx-auto h-full w-full overflow-auto p-8">
      <h1 className="h-[5%] text-[18px] font-medium text-[#1F384C]">Exam Records</h1>
      <div className="h-[95%]">
        <div className="flex h-[60px] w-full items-center justify-between bg-white py-4 px-2">
          <div className="flex w-2/3">
            {/* <button className="flex h-8 items-center justify-center rounded border border-[#91B9ED] bg-[#ffffff] px-4 py-2 text-[24px] font-bold text-white">
              <div className="flex items-center justify-center space-x-2">
                <img src="/icons/filter.svg" className="h-6" />
                <span className="text-[16px] font-medium text-[#25213B]">Filter</span>
              </div>
            </button> */}

            {/* <div className="ml-4 mr-4 flex h-8 items-center  rounded space-x-3"> */}
            <div className="flex h-8 items-center">
              <div className="mr-3 flex h-full items-center rounded bg-[#EEF5FF]">
                <img
                  src="/icons/search.svg"
                  className="ml-2 h-4 w-4"
                />
                <input
                  type="text"
                  className="ml-2 flex-grow bg-transparent text-[#2B5170] placeholder-[#2B5170] outline-none"
                  placeholder="Search by patient Name"
                  onChange={e => setSearch(e.target.value)}
                />
              </div>
              <div>
                <DateRangePicker
                  locale={rdrLocales.fr}
                  range={dateRange}
                  currentFixed={dateFilter}
                  type="range"
                  color="#f85a32"
                  onChange={dates => {
                    setDateRange(dates);
                    searchExamByDate(dates);
                  }}
                  onReset={dates => {
                    resetExamByDate(dates);
                  }}
                  isDisabled={false}
                  isLoading={false}
                  inputRanges={[]}
                  showDateDisplay={true}
                  showMonthAndYearPickers={true}
                  // maxDate={new Date()}
                />
              </div>
            </div>
          </div>

          <div className="flex w-1/3 items-center justify-end space-x-3">
            {/* <Switch
              size="default"
              className="bg-gray-600"
              checkedChildren={
                <div className="">
                  <CheckOutlined /> Active
                </div>
              }
              unCheckedChildren={
                <div className="">
                  <CloseOutlined /> Achived
                </div>
              }
              defaultChecked
              onChange={handleSwitchChange}
            /> */}
            <div className="flex items-center justify-center">
              <div className=" inline-flex items-center justify-center rounded-md bg-gray-200 text-sm font-semibold">
                <div
                  className={`${!archived ? 'bg-[#005DD4] text-white shadow-inner' : ''} w-full cursor-pointer whitespace-nowrap rounded-md py-1.5 px-4 text-center`}
                  onClick={() => {
                    handleSwitchChange(archived);
                  }}
                >
                  Active
                </div>
                <div
                  className={`${!archived ? '' : 'bg-[#005DD4] text-white'} w-full cursor-pointer whitespace-nowrap rounded-md py-1.5 px-4 text-center`}
                  onClick={() => {
                    handleSwitchChange(archived);
                  }}
                >
                  Achived
                </div>
              </div>
            </div>
            <NewExam page={'examPage'} />
          </div>
        </div>
        <div className="h-[calc(100%-60px)] w-full">
          <div className="h-full">
            <ExamTable
              exams={studyState}
              archived={archived}
              handleNextNext={handleNextNext}
              HandlePrevPage={HandlePrevPage}
              HandleRowChange={HandleRowChange}
              itemsPerPage={itemsPerPage}
              page={page}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TempExam;
