import { title } from 'process';
import React, { useEffect, useRef, useState } from 'react';
import { IoIosCreate, IoMdSend } from 'react-icons/io';
import axios from 'axios';
import { useGlobalState } from '../../../../state/RealtimeGlobalContextProvider';
import faceImgS from '../../../../../assets/brain.png';
import femurImgS from '../../../../../assets/femur.png';
import abdoImgS from '../../../../../assets/abdoicon.png';
import afImgS from '../../../../../assets/AF-pocket.png';
import { TbReportAnalytics } from 'react-icons/tb';
import { GoCheckCircle } from 'react-icons/go';
import { TiDeleteOutline } from 'react-icons/ti';
import RatingBar from './rating-bar';
import { RiExpandLeftLine, RiExpandRightLine } from 'react-icons/ri';
import { MdOutlineResetTv } from 'react-icons/md';
import { LuLoader2 } from 'react-icons/lu';
import { FaXmark } from 'react-icons/fa6';
import { CiImageOff } from 'react-icons/ci';
import DetectedPlans from './detected-plans';
import DynamicPlanList from './closed-detected-plans';

function SideBar({ frames }) {
  const { globalState, setGlobalState } = useGlobalState();
  const [buttonReportVisible, setButtonReportVisible] = useState(false);
  const prevStateRef = useRef(globalState?.planes);
  const hasChangedOnce = useRef(false);
  const [planSelected, setPlanSelected] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showQualityCriteria, setShowQualityCriteria] = useState(true);

  const [imgSrc, setImgSrc] = useState(null);

  const handleGoOutFromExam = async () => {
    setGlobalState(prevState => ({
      ...prevState,
      isDeviceChange: true,
      isExamEnded: true,
    }));
  };
  const handleSubmit = async () => {
    const formData = new FormData();
    // let predictions = {}; test
    const predictions: { cephal?: any; femur?: any; abdo?: any; afpocket?: any } = {};
    if (globalState.planes.femur.score > -1) {
      const response = await fetch(globalState.planes.femur.frame);
      const blob = await response.blob();
      formData.append('femur', blob, 'femur.jpg'); // Change the file name and type as needed
      predictions.femur = globalState.planes.femur.prediction;
    } else {
      const emptyBlob = new Blob([]);
      formData.append('femur', emptyBlob, 'femur.jpg'); // Change the file name and type as needed
      predictions.femur = '';
    }
    if (globalState.planes.abdominal.score > -1) {
      const response = await fetch(globalState.planes.abdominal.frame);
      const blob = await response.blob();
      formData.append('abdo', blob, 'abdo.jpg'); // Change the file name and type as needed
      predictions.abdo = globalState.planes.abdominal.prediction;
    } else {
      const emptyBlob = new Blob([]);
      formData.append('abdo', emptyBlob, 'abdo.jpg'); // Change the file name and type as needed
      predictions.abdo = '';
    }
    if (globalState.planes.cephalic.score > -1) {
      const response = await fetch(globalState.planes.cephalic.frame);
      const blob = await response.blob();
      formData.append('cephal', blob, 'cephal.jpg'); // Change the file name and type as needed
      predictions.cephal = globalState.planes.cephalic.prediction;
    } else {
      const emptyBlob = new Blob([]);
      formData.append('cephal', emptyBlob, 'cephal.jpg'); // Change the file name and type as needed
      predictions.cephal = '';
    }
    if (globalState.planes.af_pocket.score > -1) {
      const response = await fetch(globalState.planes.af_pocket.frame);
      const blob = await response.blob();
      formData.append('afpocket', blob, 'afpocket.jpg'); // Change the file name and type as needed
      predictions.afpocket = globalState.planes.af_pocket.prediction;
    } else {
      const emptyBlob = new Blob([]);
      formData.append('afpocket', emptyBlob, 'afpocket.jpg'); // Change the file name and type as needed
      predictions.afpocket = '';
    }
    formData.append('predictions', JSON.stringify(predictions));
    formData.append(
      'patient',
      JSON.stringify({
        id: 10000,
        patient_name: globalState.firstName,
        patient_last_name: globalState.lastName,
      })
    );

    try {
      const response = await axios.post(`${process.env.BACKEND_URL}/realtime_save`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleNewExam = async () => {
    setGlobalState(prevState => ({
      ...prevState,
      isStart: false,
      isVideoFrozen: true,
      socket: null,
      selectedFilter: 'Select',
      alertPopVars: {
        latestTestFrame: {},
        totalTest: 0,
        afPocketDetectedTest: false,
        prevScoreTest: 0,
        currentScoreTest: 0,
        afPocketScoreTest: 0,
        qualityCriteriaTest: {},
      },
      ratingScore: 0,
      frames: [],
      latestResponseFrame: null,
      planes: {
        femur: { score: -1 },
        abdominal: { score: -1 },
        cephalic: { score: -1 },
        af_pocket: { score: -1 },
      },
      isExamEnded: true,
    }));
  };

  useEffect(() => {
    const imgReportPromise = globalState?.planes[planSelected]?.imgReport;
    if (imgReportPromise) {
      imgReportPromise
        .then(imgReport => {
          setImgSrc(imgReport); // Set the resolved value as the src
        })
        .catch(error => {
          console.error('Error fetching imgReport:', error);
        });
    } else {
      console.log('imgReport does not exist.');

      setImgSrc(null);
    }
  }, [planSelected]);

  const listOfSelectedFilter = [
    {
      key: 'Cephalic',
      list: [
        {
          title: 'Thalami visible',
          bool: globalState?.planes?.cephalic?.qualityCriteria?.QUAL_FLAG__cephal_thalamus,
        },
        {
          title: 'Cavum septum pellucidum visible',
          bool: globalState?.planes?.cephalic?.qualityCriteria?.QUAL_FLAG_cavum_septi_pellucidi,
        },
        {
          title: 'Cerebellum not visible',
          bool: globalState?.planes?.cephalic?.qualityCriteria?.QUAL_FLAG_Cerebellum_not_visble,
        },
        {
          title: 'Zoom > 50%',
          bool: globalState?.planes?.cephalic?.qualityCriteria?.QUAL_VAL__ZOOM,
        },
      ],
    },
    {
      key: 'Femur',
      list: [
        {
          title: 'Both femur ends visible',
          bool: globalState?.planes?.femur?.qualityCriteria?.QUAL_VAL__BOTH_EXTREMITIES_VISIBLE,
        },
        {
          title: 'Angle to the horizontal < 45°',
          bool: globalState?.planes?.femur?.qualityCriteria?.QUAL_VAL__FEMURAL_ANGLE,
        },
        {
          title: 'Zoom > 50%',
          bool: globalState?.planes?.femur?.qualityCriteria?.QUAL_VAL__ZOOM,
        },
      ],
    },
    {
      key: 'Abdominal',
      list: [
        {
          title: 'Stomach bubble visible',
          bool: globalState?.planes?.abdominal?.qualityCriteria?.QUAL_FLAG__abdo_stomach_bubble,
        },
        {
          title: 'Portal sinus visible',
          bool: globalState?.planes?.abdominal?.qualityCriteria?.QUAL_FLAG__abdo_portal_sinus,
        },
        {
          title: 'Kidneys not visible',
          bool: globalState?.planes?.abdominal?.qualityCriteria
            ?.QUAL_FLAG__abdo_fetal_kidneys_not_visible,
        },
        {
          title: 'Zoom > 50%',
          bool: globalState?.planes?.abdominal?.qualityCriteria?.QUAL_VAL__ZOOM,
        },
      ],
    },
    // {
    //   key: 'Plan A',
    //   list: [
    //     { title: 'Criteria 1 for Plan A', bool: false },
    //     { title: 'Criteria 2 for Plan A', bool: false },
    //     { title: 'Criteria 3 for Plan A', bool: false },
    //     { title: 'Zoom > 50% for Plan A', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan B',
    //   list: [
    //     { title: 'Criteria 1 for Plan B', bool: false },
    //     { title: 'Criteria 2 for Plan B', bool: false },
    //     { title: 'Criteria 3 for Plan B', bool: false },
    //     { title: 'Zoom > 50% for Plan B', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan C',
    //   list: [
    //     { title: 'Criteria 1 for Plan C', bool: false },
    //     { title: 'Criteria 2 for Plan C', bool: false },
    //     { title: 'Criteria 3 for Plan C', bool: false },
    //     { title: 'Zoom > 50% for Plan C', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan D',
    //   list: [
    //     { title: 'Criteria 1 for Plan D', bool: false },
    //     { title: 'Criteria 2 for Plan D', bool: false },
    //     { title: 'Criteria 3 for Plan D', bool: false },
    //     { title: 'Zoom > 50% for Plan D', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan E',
    //   list: [
    //     { title: 'Criteria 1 for Plan E', bool: false },
    //     { title: 'Criteria 2 for Plan E', bool: false },
    //     { title: 'Criteria 3 for Plan E', bool: false },
    //     { title: 'Zoom > 50% for Plan E', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan F',
    //   list: [
    //     { title: 'Criteria 1 for Plan F', bool: false },
    //     { title: 'Criteria 2 for Plan F', bool: false },
    //     { title: 'Criteria 3 for Plan F', bool: false },
    //     { title: 'Zoom > 50% for Plan F', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan G',
    //   list: [
    //     { title: 'Criteria 1 for Plan G', bool: false },
    //     { title: 'Criteria 2 for Plan G', bool: false },
    //     { title: 'Criteria 3 for Plan G', bool: false },
    //     { title: 'Zoom > 50% for Plan G', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan H',
    //   list: [
    //     { title: 'Criteria 1 for Plan H', bool: false },
    //     { title: 'Criteria 2 for Plan H', bool: false },
    //     { title: 'Criteria 3 for Plan H', bool: false },
    //     { title: 'Zoom > 50% for Plan H', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan I',
    //   list: [
    //     { title: 'Criteria 1 for Plan I', bool: false },
    //     { title: 'Criteria 2 for Plan I', bool: false },
    //     { title: 'Criteria 3 for Plan I', bool: false },
    //     { title: 'Zoom > 50% for Plan I', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan J',
    //   list: [
    //     { title: 'Criteria 1 for Plan J', bool: false },
    //     { title: 'Criteria 2 for Plan J', bool: false },
    //     { title: 'Criteria 3 for Plan J', bool: false },
    //     { title: 'Zoom > 50% for Plan J', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan K',
    //   list: [
    //     { title: 'Criteria 1 for Plan K', bool: false },
    //     { title: 'Criteria 2 for Plan K', bool: false },
    //     { title: 'Criteria 3 for Plan K', bool: false },
    //     { title: 'Zoom > 50% for Plan K', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan L',
    //   list: [
    //     { title: 'Criteria 1 for Plan L', bool: false },
    //     { title: 'Criteria 2 for Plan L', bool: false },
    //     { title: 'Criteria 3 for Plan L', bool: false },
    //     { title: 'Zoom > 50% for Plan L', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan M',
    //   list: [
    //     { title: 'Criteria 1 for Plan M', bool: false },
    //     { title: 'Criteria 2 for Plan M', bool: false },
    //     { title: 'Criteria 3 for Plan M', bool: false },
    //     { title: 'Zoom > 50% for Plan M', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan N',
    //   list: [
    //     { title: 'Criteria 1 for Plan N', bool: false },
    //     { title: 'Criteria 2 for Plan N', bool: false },
    //     { title: 'Criteria 3 for Plan N', bool: false },
    //     { title: 'Zoom > 50% for Plan N', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan O',
    //   list: [
    //     { title: 'Criteria 1 for Plan O', bool: false },
    //     { title: 'Criteria 2 for Plan O', bool: false },
    //     { title: 'Criteria 3 for Plan O', bool: false },
    //     { title: 'Zoom > 50% for Plan O', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan P',
    //   list: [
    //     { title: 'Criteria 1 for Plan P', bool: false },
    //     { title: 'Criteria 2 for Plan P', bool: false },
    //     { title: 'Criteria 3 for Plan P', bool: false },
    //     { title: 'Zoom > 50% for Plan P', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan Q',
    //   list: [
    //     { title: 'Criteria 1 for Plan Q', bool: false },
    //     { title: 'Criteria 2 for Plan Q', bool: false },
    //     { title: 'Criteria 3 for Plan Q', bool: false },
    //     { title: 'Zoom > 50% for Plan Q', bool: false },
    //   ],
    // },
    // {
    //   key: 'Plan R',
    //   list: [
    //     { title: 'Criteria 1 for Plan R', bool: false },
    //     { title: 'Criteria 2 for Plan R', bool: false },
    //     { title: 'Criteria 3 for Plan R', bool: false },
    //     { title: 'Zoom > 50% for Plan R', bool: false },
    //   ],
    // },
  ];

  const selectedListFilter = listOfSelectedFilter.find(
    filter => filter.key === globalState.selectedFilter
  );

  const handleOpenPanel = () => {
    setGlobalState(prevState => ({
      ...prevState,
      openPanel: !prevState.openPanel,
    }));
  };

  const handleFilterClick = filter => {
    if (globalState.selectedFilter === filter) {
      setGlobalState(prevState => ({
        ...prevState,
        selectedFilter: 'Select',
      }));
      setShowQualityCriteria(false);
    } else {
      setGlobalState(prevState => ({
        ...prevState,
        selectedFilter: filter,
      }));
      setShowQualityCriteria(true);
    }
  };

  const haveScoresChanged = (prevState, currentState) => {
    const prevKeys = Object.keys(prevState);
    const currentKeys = Object.keys(currentState);

    // Check if any scores or scoreOrigins have changed
    return prevKeys.some(key => {
      const prevPlan = prevState[key];
      const currentPlan = currentState[key];

      // Compare score and scoreOrigin
      const prevScore = prevPlan?.score || -1; // default to -1 if undefined
      const prevScoreOrigin = prevPlan?.scoreOrigin || 0; // assume default is 0 for origin

      const currentScore = currentPlan?.score || -1; // default to -1 if undefined
      const currentScoreOrigin = currentPlan?.scoreOrigin || 0; // assume default is 0 for origin

      // If score or scoreOrigin have changed, return true
      return prevScore !== currentScore || prevScoreOrigin !== currentScoreOrigin;
    });
  };

  useEffect(() => {
    // Check if globalState.planes is defined
    if (globalState?.planes) {
      // Check if all scores are still the initial value (-1)
      const isInitialState = Object.keys(globalState.planes).every(key => {
        const score = globalState.planes[key].score;
        return score === -1; // Check if all scores are still -1
      });

      console.log('isInitialState', isInitialState);

      // If it's not the initial state, check if scores have changed
      if (!isInitialState) {
        // Check if scores have changed from previous state
        if (haveScoresChanged(prevStateRef.current, globalState?.planes)) {
          setButtonReportVisible(true); // Show the button if scores have changed
        }
      } else {
        setButtonReportVisible(false); // Hide the button if still in initial state
      }
    }

    // Update the previous state ref to the current state after each render
    prevStateRef.current = globalState?.planes;
  }, [globalState?.planes]);
  return (
    <>
      <div>
        {isOpen && (
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              zIndex: 1000,
            }}
          >
            {/* <h2>Image Modal</h2> */}
            <div className="flex w-full items-center justify-end p-2">
              <button onClick={() => setIsOpen(false)}>
                <FaXmark />
              </button>
            </div>
            <div className="px-5 pb-5">
              {imgSrc ? (
                <img
                  src={imgSrc}
                  alt="Modal"
                />
              ) : (
                <div className="flex w-64 items-center justify-center text-[#1f384c]">
                  <CiImageOff className="h-44 w-64" />
                </div>
              )}
            </div>
          </div>
        )}
        {isOpen && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999,
            }}
            onClick={() => {
              setIsOpen(false);
              setImgSrc(null);
              setPlanSelected('');
            }}
          ></div>
        )}
      </div>
      <div
        className={`flex justify-end ${globalState.openPanel ? 'w-[23%] xl:w-[17%]' : 'w-[8%] xl:w-[6%]'} bg-[#fcfcfc]`}
      >
        <div
          className={`body-font flex h-full ${globalState.openPanel ? 'w-[100%]' : 'w-full'} rounded-md bg-white px-2 py-2 text-black shadow-md`}
        >
          <div className="relative w-full">
            <div
              className={`flex w-full ${globalState.openPanel ? '' : 'justify-center'} text-lg font-semibold text-[#686C82]`}
            >
              {globalState.openPanel && (
                <div className="w-full justify-start text-sm xl:text-lg">Detected Plans</div>
              )}
              {globalState.openPanel ? (
                <div
                  className="flex cursor-pointer items-center justify-end"
                  onClick={handleOpenPanel}
                >
                  <RiExpandRightLine />
                </div>
              ) : (
                <div
                  className="-w-full flex cursor-pointer items-center justify-end"
                  onClick={handleOpenPanel}
                >
                  <RiExpandLeftLine />
                </div>
              )}
            </div>
            {globalState.openPanel ? (
              <>
                <div className="mt-6 w-full pr-4">
                  <DetectedPlans
                    globalState={globalState}
                    handleFilterClick={handleFilterClick}
                    setPlanSelected={setPlanSelected}
                    setIsOpen={setIsOpen}
                  />
                </div>
                <div
                  className={`mt-3 space-y-2 xl:mt-7 ${globalState.openPanel ? 'w-[100%]' : 'w-16'}`}
                >
                  <div className="flex justify-between text-sm">
                    <div className="flex w-full justify-start text-[#686C82]">Quality Gauge</div>
                    <div className="flex w-full justify-end text-[#686C82]">
                      {globalState?.ratingScore === -1 ? 0 : Math.round(globalState?.ratingScore)}%
                    </div>
                  </div>
                  <RatingBar
                    value={globalState?.ratingScore}
                    align="H"
                    globalState={globalState}
                    transitionDuration={500} // Add a transition duration in milliseconds
                  />
                </div>

                {selectedListFilter && showQualityCriteria && (
                  <>
                    <div className="mt-3 inline-flex w-full items-center justify-between text-[#686C82] xl:mt-5">
                      <div className="text-lg font-semibold">Quality Criteria</div>
                      <div
                        className="cursor-pointer text-sm"
                        onClick={() => {
                          setShowQualityCriteria(false);
                          setGlobalState(prevState => ({
                            ...prevState,
                            selectedFilter: 'Select',
                          }));
                        }}
                      >
                        Hide
                      </div>
                    </div>

                    <div className="mt-3 w-full">
                      {selectedListFilter?.list?.map((item, index) => (
                        <div
                          className="mb-0.5 flex w-full items-center justify-between	 
                          rounded-sm bg-[#eaf2fc] py-1.5 px-2 font-semibold text-[#686C82]"
                          key={index}
                        >
                          <label
                            htmlFor={`${item.title.toLowerCase().replace(/\s+/g, '-')}-checkbox`}
                            className="ms-2 text-sm font-medium xl:text-xs"
                          >
                            {item.title}
                          </label>
                          <div className={`${item?.bool ? 'text-[#50C878]' : 'text-[#EC5252]'}`}>
                            {item?.bool ? (
                              <GoCheckCircle size={16} />
                            ) : (
                              <TiDeleteOutline size={16} />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                <div className="absolute bottom-2 flex w-full justify-center space-x-2 text-xs">
                  {globalState.isStart ? (
                    <div className="inline-flex w-full cursor-not-allowed items-center justify-center space-x-3 rounded-lg bg-[#2886c7]  px-6 py-2 text-sm font-medium text-white opacity-50 hover:opacity-70">
                      <span>Examination in progress</span>
                    </div>
                  ) : (
                    <div className="flex flex-col space-y-2">
                      {buttonReportVisible && (
                        <div
                          className="mt-3 flex w-full cursor-pointer items-center justify-center text-sm"
                          onClick={() => {
                            setGlobalState(prevState => ({
                              ...prevState,
                              openGR: !globalState.openGR,
                            }));
                          }}
                        >
                          <div className="inline-flex w-full items-center justify-center space-x-3 rounded-lg bg-[#2886c7] px-6  py-2 text-sm font-medium text-white hover:opacity-70">
                            <div className="">
                              <TbReportAnalytics size={18} />
                            </div>
                            <span>Generate Report</span>
                          </div>
                        </div>
                      )}

                      {globalState.examinationDuration !== null && (
                        <div
                          className="w-full"
                          onClick={() => handleNewExam()}
                        >
                          <div className="inline-flex w-full cursor-pointer items-center justify-center space-x-3 rounded-lg bg-[#2886c7] px-6 py-2 text-sm font-medium text-white hover:opacity-70">
                            <IoIosCreate size={18} />
                            <span>New Exam</span>
                          </div>
                        </div>
                      )}
                      <div
                        className="w-full"
                        onClick={() => handleGoOutFromExam()}
                      >
                        <div className="inline-flex w-full items-center justify-center space-x-3 rounded-lg bg-[#2886c7] px-6 py-2 text-sm font-medium text-white hover:opacity-70">
                          <MdOutlineResetTv size={18} />
                          <span>Change Device</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="flex w-full  flex-col  justify-center">
                  {/* <div className="mt-6 flex flex-col items-center space-y-2">
                    <div
                      onClick={() => {
                        if (frames?.length > 0) {
                          setPlanSelected('femur');
                          setIsOpen(true);
                        }
                      }}
                      className="flex flex-col items-center"
                    >
                      <div
                        className={`${globalState?.planes?.femur?.scoreOrigin > 0 ? 'bg-[#32CD32] bg-opacity-60' : 'bg-gray-400'} rounded-full p-1`}
                      >
                        <img
                          src={femurImgS}
                          className="h-6 w-6"
                        />
                      </div>
                      <span className="mt-1 text-xs">Femur</span>
                    </div>
                    <div
                      onClick={() => {
                        if (frames?.length > 0) {
                          setPlanSelected('abdominal');
                          setIsOpen(true);
                        }
                      }}
                      className="flex flex-col items-center"
                    >
                      <div
                        className={`${globalState?.planes?.abdominal?.scoreOrigin > 0 ? 'bg-[#32CD32] bg-opacity-60' : 'bg-gray-400'} rounded-full p-1`}
                      >
                        <img
                          src={abdoImgS}
                          className="h-6 w-6"
                        />
                      </div>
                      <span className="mt-1 text-xs">Abdominal</span>
                    </div>
                    <div
                      onClick={() => {
                        if (frames?.length > 0) {
                          setPlanSelected('cephalic');
                          setIsOpen(true);
                        }
                      }}
                      className="flex flex-col items-center"
                    >
                      <div
                        className={`${globalState?.planes?.cephalic?.scoreOrigin > 0 ? 'bg-[#32CD32] bg-opacity-60' : 'bg-gray-400'} rounded-full p-1`}
                      >
                        <img
                          src={faceImgS}
                          className="h-6 w-6"
                        />
                      </div>
                      <span className="mt-1 text-xs">Cephal</span>
                    </div>
                    <div
                      onClick={() => {
                        if (frames?.length > 0) {
                          setPlanSelected('af_pocket');
                          setIsOpen(true);
                        }
                      }}
                      className="flex flex-col items-center"
                    >
                      <div
                        className={`${globalState?.planes?.af_pocket?.score > 0 ? 'bg-[#32CD32] bg-opacity-60' : 'bg-gray-400'} rounded-full p-1`}
                      >
                        <img
                          src={afImgS}
                          className="h-6 w-6"
                        />
                      </div>
                      <span className="mt-1 text-xs">AF Pocket</span>
                    </div>
                  </div> */}
                  <DynamicPlanList
                    globalState={globalState}
                    setPlanSelected={setPlanSelected}
                    setIsOpen={setIsOpen}
                    frames={globalState.frames}
                  />

                  <div className="mt-5">
                    <RatingBar
                      align={'V'}
                      globalState={globalState}
                    />
                  </div>

                  <div className="absolute bottom-2 flex w-full flex-col items-center space-y-2 text-sm">
                    {globalState.isStart ? (
                      <div className="cursor-not-allowed rounded-full bg-[#2886c7] p-1.5 opacity-50">
                        <div className="rounded-full bg-white p-0.5">
                          <LuLoader2
                            size={12}
                            className="animate-spin text-[#2886c7]"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          className="group relative cursor-pointer"
                          onClick={() =>
                            setGlobalState(prevState => ({
                              ...prevState,
                              openGR: !globalState.openGR,
                            }))
                          }
                        >
                          <div className="rounded-full bg-[#2886c7] p-1.5 hover:bg-[#2886c7] hover:bg-opacity-70">
                            <div className="rounded-full bg-white p-0.5">
                              <TbReportAnalytics
                                size={12}
                                className="text-[#2886c7]"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="group relative cursor-pointer"
                          onClick={() => handleNewExam()}
                        >
                          <div className="rounded-full bg-[#2886c7] p-1.5 hover:bg-[#2886c7] hover:bg-opacity-70">
                            <div className="rounded-full bg-white p-0.5">
                              <IoIosCreate
                                size={12}
                                className="text-[#2886c7]"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="group relative cursor-pointer"
                          onClick={() => handleGoOutFromExam()}
                        >
                          <div className="rounded-full bg-[#2886c7] p-1.5 hover:bg-[#2886c7] hover:bg-opacity-70">
                            <div className="rounded-full bg-white p-0.5">
                              <MdOutlineResetTv
                                size={12}
                                className="text-[#2886c7]"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default SideBar;
