import React, { useEffect, useState } from 'react';
import { Avatar, Button, List, Skeleton } from 'antd';
import { getStudyByPatientId } from '../../../helpers/backendHelper';
import { Link } from 'react-router-dom';
import { EyeOutlined, FileTextOutlined } from '@ant-design/icons';
import { Study } from '../../interfaces';

interface ExamListProps {
  patientId: string;
}

function ExamsList({ patientId }: ExamListProps) {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Study[]>([]);
  const [more, setMore] = useState(true);
  const [count, setCount] = useState<number>(3);
  const [page, setPage] = useState<number>(1);
  useEffect(() => {
    getStudyByPatientId(patientId, count, page)
      .then(res => {
        setData(res.data);
        setInitLoading(false);
        setData(res.data);
      })
      .catch(err => {});
  }, [patientId, count]);

  const onLoadMore = () => {
    if (more === false) {
      return;
    }
    setLoading(true);
    getStudyByPatientId(patientId, count, page + 1)
      .then(res => {
        setData([...data, ...res.data]);
        setLoading(false);
        setPage(prev => prev + 1);
        if (res?.has_more === false) {
          setMore(false);
        }
      })
      .catch(err => {});
  };

  const loadMore =
    !initLoading && !loading && more ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        {' '}
        {data.length > 0 ? (
          <Button
            onClick={onLoadMore}
            className="bg-[#005DD4] text-white"
          >
            loading more
          </Button>
        ) : null}
      </div>
    ) : null;
  return (
    <div className="inline-block h-full w-full overflow-scroll">
      {data.length > 0 ? (
        <List
          className="demo-loadmore-list"
          loading={initLoading}
          itemLayout="horizontal"
          loadMore={loadMore}
          dataSource={data}
          renderItem={item => (
            console.log(
              'inside =',
              item,
              typeof item?.study_description,
              item?.study_description?.length
            ),
            (
              <List.Item
                actions={[
                  <Link
                    to={`/exams/viewer?StudyInstanceUIDs=${item?.study_instance_uid}`}
                    className=" flex w-10 items-center justify-center gap-1"
                  >
                    <EyeOutlined />
                  </Link>,
                  <Link
                    to=""
                    className=" flex w-10 items-center justify-center gap-1"
                  >
                    <FileTextOutlined />
                  </Link>,
                ]}
              >
                <List.Item.Meta
                  title={item?.study_instance_uid}
                  description={
                    item?.study_description !== 'null' ? item?.study_description : 'no description'
                  }
                />
                <div>Modality {'(' + item?.modality + ')'}</div>
              </List.Item>
            )
          )}
        />
      ) : (
        loading === false && (
          <div className="flex h-full w-full items-center justify-center">
            <p className="border border-blue-400 p-3 text-lg font-normal text-[#5A5A5A]">
              No Exams Found
            </p>
          </div>
        )
      )}
    </div>
  );
}

export default ExamsList;
