import React, { ChangeEvent, useState } from 'react';

import newPatientImg from '../../../../assets/newPatient.png';
import { Patient } from '../../Sections/PatientsList/patientsList.section.custom';
import { set } from 'lodash';
import PatientModal from '../../Pages/PatientManagement/patient.modal';
interface Props {
  title: string;
  description: string;
  page: string;
}

const NewPatient: React.FC<Props> = ({ title, description, page }: Props) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
    console.log("asda")
  };

  const [newPatient, setNewPatient] = useState<Patient>({
    patient_name: '',
    patient_last_name: '',
  });
  function initialiseNewPatient() {
    setNewPatient({
      patient_name: '',
      patient_last_name: '',
    });
  }
  const closeNewPatientModal = () => {
    initialiseNewPatient();
    setOpen(!open);
  };

  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex cursor-pointer flex-col items-center justify-center">
        <h5 className="text-base font-normal text-[#21426D]">{title}</h5>
        <div className="">
          <img
            src={newPatientImg}
            alt="newPatient"
            onClick={showModal}
          />
        </div>
        <div className="flex items-center justify-center px-6 text-center text-xs">
          {description}
        </div>
        {open && (
          // <PatientModal
          //   Patient={newPatient}
          //   setNewPatient={setNewPatient}
          //   closeNewPatientModal={closeNewPatientModal}
          //   edit={false}
          //   location={page}
          //   isOpen={open}
          <PatientModal
          closePatientModal={closeNewPatientModal}
          edit={false}
          isOpen={open}
          toEdit={null}
        />
          
        )}
      </div>
    </div>
  );
};

export default NewPatient;
