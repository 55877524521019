import React, { useEffect, useRef, useState } from 'react';
import { useGlobalState } from '../../../state/RealtimeGlobalContextProvider';
import { LuLoader2 } from 'react-icons/lu';
import DevicesModal from './components/devices-modal';
import { getUser } from '../../../helpers/apiHelper';
import PixelSpacingModal from './components/pixel-spacing';
import Card from './components/card';
import RatingBar from './components/rating-bar';
import { MdFiberManualRecord } from 'react-icons/md';
import { FaPrint, FaShare } from 'react-icons/fa6';
import ButtonWithCursorMovement from './components/invisible-button-click/button-with-cursor-movement';
import { ImEnlarge2 } from 'react-icons/im';
import VideoModal from './components/video-full-screen';
import { PiInfo } from 'react-icons/pi';
import { v4 as uuidv4 } from 'uuid';
import ReconnectingWebSocket from 'reconnecting-websocket';
import vi from 'platform/i18n/src/locales/vi';
export enum MediaType {
  USER_MEDIA = 'UserMedia',
  DISPLAY_MEDIA = 'DisplayMedia',
}

const mediaTypeMap = new Map([
  ['Share', MediaType.DISPLAY_MEDIA],
  ['USB', MediaType.USER_MEDIA],
]);

function NewRealtimePage() {
  const { globalState, setGlobalState } = useGlobalState();
  const [deviceMediaType, setDeviceMediaType] = useState(MediaType.DISPLAY_MEDIA);
  const API = process.env.BACKEND_URL.replace(/\/api\/v1$/, '');
  const user = getUser();
  const canvasRef = useRef(null);
  const localVideoRef = useRef(null); // For local screen share or webcam
  const [videoStream, setVideoStream] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [canvasDimensions, setCanvasDimensions] = useState({ width: 0, height: 0 });
  const [isChecked, setIsChecked] = useState(false);
  const [isInferencing, setIsInferencing] = useState(undefined);

  const [offerSdp, setOfferSdp] = useState('');
  const [answerSdp, setAnswerSdp] = useState('');
  const [useDataChannel, setUseDataChannel] = useState(true);
  const [videoTransform, setVideoTransform] = useState('rotate');
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const videoRef = useRef(null);
  const pcRef = useRef(null);
  const dataChannelRef = useRef(null);

  const signalingUrl = 'http://localhost:8000/api/v1/offer'; // FastAPI signaling URL
  const websocketRef = useRef(null);
  // const [clientId, setClientId] = useState(null);

  const mediaTypeSwitcher = () => {
    mediaTypeMap.forEach((mediaType, option) => {
      if (globalState.optionValueDevice === option) {
        setDeviceMediaType(mediaType);
      }
    });
  };

  // Create PeerConnection and negotiate with backend
  const createPeerConnection = () => {
    const config = {
      iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
    };
    const pc = new RTCPeerConnection(config);

    pc.onicegatheringstatechange = () =>
      console.log('=======================', pc.iceGatheringState);
    pc.oniceconnectionstatechange = () =>
      console.log('=======================', pc.iceConnectionState);
    pc.onsignalingstatechange = () => console.log('=======================', pc.signalingState);
    pc.ontrack = event => {
      if (event.track.kind === 'video') {
        console.log('============= event.streams[0] =============', event.streams[0]);

        videoRef.current.srcObject = event.streams[0];
      }
    };

    return pc;
  };

  const negotiate = () => {
    pcRef.current
      .createOffer()
      .then(offer => {
        return pcRef.current.setLocalDescription(offer);
      })
      .then(() => {
        return new Promise<void>(resolve => {
          if (pcRef.current.iceGatheringState === 'complete') {
            resolve();
          } else {
            const checkState = () => {
              if (pcRef.current.iceGatheringState === 'complete') {
                pcRef.current.removeEventListener('icegatheringstatechange', checkState);
                resolve();
              }
            };
            pcRef.current.addEventListener('icegatheringstatechange', checkState);
          }
        });
      })
      .then(() => {
        const offer = pcRef.current.localDescription;

        setOfferSdp(offer.sdp);
        // const uuid = uuidv4();
        // setClientId(uuid);
        const user = getUser();
        console.log('uuid:**1', user?.profile?.sub);

        return fetch(signalingUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sdp: offer.sdp,
            type: offer.type,
            video_transform: videoTransform,
            client_id: user?.profile?.sub,
          }),
        });
      })
      .then(response => response.json())
      .then(data => {
        setAnswerSdp(data.sdp);
        const answerDesc = new RTCSessionDescription(data);
        return pcRef.current.setRemoteDescription(answerDesc);
      })
      .catch(err => console.error(err));
  };

  const start = () => {
    console.log('==================== Start function called');
    if (!videoStream) {
      console.log('==================== No videoStream available');
      return; // Ensure videoStream is initialized
    }

    // Create PeerConnection
    pcRef.current = createPeerConnection();
    console.log('==================== PeerConnection created');

    if (useDataChannel) {
      const dataChannel = pcRef.current.createDataChannel('chat');
      dataChannel.onopen = () => console.log('==================== Data channel open');
      dataChannel.onmessage = event =>
        console.log('==================== Message from data channel:', event.data);
      dataChannelRef.current = dataChannel;
      console.log('==================== Data channel created');
    }

    videoStream.getTracks().forEach(track => {
      pcRef.current.addTrack(track, videoStream);
      console.log('==================== Track added to PeerConnection:', track);
    });

    videoRef.current.srcObject = videoStream; // Use WebRTC-connected videoRef
    console.log('==================== videoRef srcObject set to videoStream');
    setIsInferencing(true); // Indicate inference is active
    console.log('==================== Inference set to active');
    return negotiate();
  };

  const stop = () => {
    if (dataChannelRef.current) {
      dataChannelRef.current.close();
    }

    pcRef.current.getSenders().forEach(sender => sender.track.stop());
    pcRef.current.close();
    pcRef.current = null;
    setIsInferencing(false);
  };

  const handleChange = e => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      setIsInferencing(true);
      localVideoRef.current.pause();
      // videoStream.active = true
    } else {
      setIsInferencing(false);
      localVideoRef.current.play();
    }
    // toggleVideoFreeze();
  };

  useEffect(() => {
    if (isInferencing === undefined) {
      return;
    }

    if (isInferencing) {
      start();
    } else {
      stop();
    }
  }, [isInferencing]);

  useEffect(() => {
    const getMediaStream = async constraints => {
      try {
        let stream;
        if (globalState.optionValueDevice === 'Share') {
          stream = await navigator.mediaDevices.getDisplayMedia(constraints); // Screen sharing
        } else if (globalState.optionValueDevice === 'USB') {
          stream = await navigator.mediaDevices.getUserMedia(constraints); // Webcam
        }

        setVideoStream(stream);
        setIsVideoLoading(false);

        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream; // Local video stream for screen share or webcam
        }

        // start();  // Start the WebRTC connection

        const videoTrack = stream.getVideoTracks()[0];
        const { width, height } = videoTrack.getSettings();
        if (width * height <= 450000) {
          setCanvasDimensions({ width, height });
        }

        setGlobalState(prevState => ({
          ...prevState,
          isVideoFrozen: !prevState.isVideoFrozen,
        }));
      } catch (error) {
        console.error('Error accessing the video stream: ', error);
      }
    };

    if (navigator.mediaDevices && globalState.deviceId !== '') {
      // const constraints = {
      //   video: globalState.optionValueDevice === 'USB'
      //     ? { deviceId: { exact: globalState.deviceId }, width: canvasDimensions.width, height: canvasDimensions.height }
      //     : {},
      // };
      const constraints = {
        video: {
          width: { ideal: 800 }, //354 640 854 1280 1920
          height: { ideal: 600 }, //280 360 480 720 1080
        },
      };

      getMediaStream(constraints);
    }
  }, [
    globalState.deviceId,
    deviceMediaType,
    globalState.optionValueDevice,
    globalState?.selectedItem,
  ]);

  useEffect(() => {
    setCanvasDimensions({
      width: globalState?.configMachine?.machine_width,
      height: globalState?.configMachine?.machine_height,
    });
  }, [globalState?.machineType]);

  console.log('localVideoRef', localVideoRef);

  return (
    <div className={`relative mx-auto flex h-full flex-col bg-[#fcfcfc]`}>
      {/*globalState.isVideoFrozen &&
        !isVideoLoading &&
        !localStorage.getItem(`${globalState.machineType}`) && (
          <PixelSpacingModal
            globalState={globalState}
            setGlobalState={setGlobalState}
            videoRef={localVideoRef}  // Using localVideoRef for local video stream
            canvasDimensions={canvasDimensions}
            canvasRef={canvasRef}
          />
        )*/}
      <div className="relative flex h-full w-full justify-between">
        <div
          className={`${globalState.deviceId === '' || globalState?.selectedItem === 'Select your machine' ? '' : 'relative'} flex h-full w-full flex-1 items-center justify-center bg-[#fcfcfc]`}
        >
          <div
            className={` flex h-full ${globalState.openPanel ? 'w-[100%]' : 'w-[94%]'} items-center justify-center bg-[#fcfcfc] text-black`}
          >
            {globalState.deviceId === '' || globalState.selectedItem === 'Select your machine' ? (
              <DevicesModal
                mediaTypeSwitcher={mediaTypeSwitcher}
                globalState={globalState}
                setGlobalState={setGlobalState}
                inputDevices={globalState.inputDevices}
                api={API}
                token={user?.access_token}
                setDeviceId={value =>
                  setGlobalState(prevState => ({
                    ...prevState,
                    deviceId: value,
                  }))
                }
                setInputDevices={value =>
                  setGlobalState(prevState => ({
                    ...prevState,
                    inputDevices: value,
                  }))
                }
              />
            ) : (
              <div className="relative flex h-full w-full items-center justify-center">
                <VideoModal
                  isOpen={isModalOpen}
                  onClose={() => setModalOpen(false)}
                  videoStream={videoStream}
                  canvasRef={canvasRef}
                  canvasDimensions={canvasDimensions}
                  // toggleVideoFreeze={toggleVideoFreeze}
                  globalState={globalState}
                  handleChange={handleChange}
                  isChecked={isChecked}
                  imgPlans={imgPlans}
                />
                <div className="flex h-full w-full flex-col space-y-2 px-2 py-2">
                  <div className="flex w-full justify-between">
                    <div className="flex w-full items-center justify-end space-x-2">
                      <div className="inline-flex w-fit cursor-pointer items-center justify-center space-x-5 rounded-md border border-[#2886c7] px-6 py-1 text-sm font-bold text-[#2886c7] hover:opacity-70">
                        <span>Share</span>
                        <FaShare size={18} />
                      </div>
                      <div className="inline-flex w-fit cursor-pointer items-center justify-center space-x-6 rounded-md border border-[#2886c7] px-6 py-1 text-sm font-bold text-[#2886c7] hover:opacity-70">
                        <span>Print</span>
                        <FaPrint size={18} />
                      </div>
                    </div>
                  </div>
                  <div className="flex h-64 w-full space-x-4">
                    <Card className="h-56 w-full xl:h-64">
                      <div className="flex h-full w-full space-x-5">
                        <div className="w-[57%]">
                          <div className="flex w-[100%] items-center justify-between">
                            <div className="flex w-full items-center space-x-4 text-xl font-bold">
                              <div>Real-time</div>
                              {!globalState.isVideoFrozen && !isVideoLoading && (
                                <MdFiberManualRecord
                                  size={18}
                                  className="mt-1 text-red-700"
                                />
                              )}
                            </div>
                            <label className="switch">
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={isChecked}
                                onChange={handleChange}
                              />
                              <div className="slider"></div>
                            </label>
                          </div>
                          <div className="relative flex h-[90%] w-[100%] items-center justify-center">
                            <div
                              className={`${isVideoLoading ? 'visible absolute top-0 z-50 flex h-[calc(100%-2%)] w-[calc(100%-5%)] items-center justify-center' : 'hidden'}`}
                            >
                              <div className="inline-flex items-center space-x-2">
                                <LuLoader2 className="animate-spin" />
                                <span>Please wait</span>
                              </div>
                            </div>
                            <div
                              className={`relative h-fit w-fit ${isVideoLoading ? 'hidden' : 'visible'}`}
                            >
                              <div
                                className="absolute top-3 right-2 z-20 cursor-pointer text-white"
                                onClick={() => setModalOpen(true)}
                              >
                                <ImEnlarge2 size={18} />
                              </div>
                              {/* <ButtonWithCursorMovement
                                begin={globalState.isVideoFrozen}
                                setBegin={toggleVideoFreeze}
                                deviceId={globalState.deviceId}
                              /> */}
                              <video
                                ref={localVideoRef}
                                autoPlay
                                playsInline
                                width={400}
                                height={canvasDimensions.height}
                                className={`${isInferencing ? 'hidden' : 'visible'}  max-h-full max-w-full`}
                              />
                              <video
                                ref={videoRef}
                                autoPlay
                                playsInline
                                width={400}
                                height={canvasDimensions.height}
                                className={`${isInferencing ? 'visible' : 'hidden'} max-h-full max-w-full`}
                              />
                              {/* <canvas
                                ref={videoRef}
                                width={400}
                                height={canvasDimensions.height}
                                className={`${isInferencing ? 'visible' : 'hidden'}  max-h-full max-w-full`}
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div className="mt-9 flex w-[43%] space-x-2">
                          <div className="flex items-center justify-start">
                            <RatingBar
                              align={'V'}
                              globalState={globalState}
                            />
                          </div>
                          <div className="flex w-full flex-col items-center justify-center space-y-2 text-sm font-medium text-[#686c82]">
                            <div className="inline-flex w-full space-x-2">
                              <PiInfo
                                size={22}
                                className="text-[#2886c7]"
                              />
                              <span>Ensure proper gel application for clear imaging</span>
                            </div>
                            <div className="inline-flex w-full space-x-2">
                              <PiInfo
                                size={26}
                                className="text-[#2886c7]"
                              />
                              <span>Ensure steady probe movement to capture accurate scans.</span>
                            </div>
                            <div className="inline-flex w-full space-x-2">
                              <PiInfo
                                size={22}
                                className="text-[#2886c7]"
                              />
                              <span>Adjust depth and focus for optimal image clarity.</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Card className="h-56 w-full xl:h-64">
                      <div className="flex h-full flex-col"></div>
                    </Card>
                  </div>
                  <div className="flex h-full w-full"></div>
                </div>

                {globalState?.isStart && globalState?.encounterError && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="rounded-lg bg-white p-6 shadow-lg">
                      <h2 className="mb-4 text-2xl font-bold text-red-600">Connection Error</h2>
                      <p className="text-gray-700">
                        An error occurred while connecting to the socket server. Please try again.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewRealtimePage;
