import React, { useEffect, useState } from 'react';
import reportLogo from './assets/deepecho-logo.svg';
import QRCode from 'react-qr-code';
import { Icon } from '@ohif/ui';

const ReportHeader: React.FC = props => {
  return (
    <div className=" col-span-12 row-span-1 border-b-2 border-black px-4 ">
      <div
        className="grid grid-cols-3"
        style={{
          height: '-webkit-fill-available',
        }}
      >
        <div className=" ">
          <h2 className="text-3xl font-bold">FETAL BIOMETRY</h2>
          <h3 className="text-lg font-bold">ULTRASOUND BIOMETRY</h3>
          <br />
          <div>
            <span className="font-bold">EXAM DATE:</span>
            <span>24-02-2024</span>
          </div>
          <div>
            <span className="font-bold">PRACTITIONER:</span>
            <span>Dr. Roche</span>
          </div>
        </div>
        <div className=" my-2 text-center">
          <div className="flex h-20 items-center justify-center">
            <Icon
              name="deepechoLogo"
              className=" mx-auto my-auto"
            />
          </div>
          <h3 className="text-blue-900 underline">contact@deepecho.io</h3>
          {/* <p
            style={{
              fontSize: '9px',
            }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy.
          </p> */}
        </div>
        <div className=" mb-1 text-right">
          <div className="grid grid-cols-5 ">
            <div className="col-span-2 m-auto ">
              <h4 className="mb-1 text-sm font-bold">Access to full exam</h4>
              <QRCode
                className="m-auto"
                title="Deepecho"
                value={'test test'}
                bgColor={'white'}
                fgColor={'black'}
                size={100}
              />
              <h4 className=" text-center text-sm">275348975935</h4>
            </div>
            <div className="col-span-3 my-4">
              {/* <h3>
                <span className="font-bold">Attribute X:</span>
                <span>0979696</span>
              </h3>
              <h3>
                <span className="font-bold">Attribute Y:</span>
                <span>0979696</span>
              </h3> */}
              <h3>
                <span className="font-bold">Patient ID:</span>
                <span>528027397</span>
              </h3>
              <h3>
                <span className="font-bold">Patient City:</span>
                <span>New York</span>
              </h3>
              <h3>
                <span className="font-bold">Patient Country:</span>
                <span>USA</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportHeader;
