import { chain } from 'lodash';
import React from 'react';

interface InputProps {
  name?: string;
  type?: string;
  id?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  invalid?: boolean;
  inerror?: boolean;
  disabled?: boolean;
  className?: string;
  checked?: boolean;
  children?: React.ReactNode;
}

const Input = ({
  name,
  type,
  id,
  placeholder,
  onChange,
  onBlur,
  value,
  invalid,
  inerror,
  disabled,
  className,
  checked,
  children,
}: InputProps) => {
  return (
    <div className="relative">
      <input
        name={name}
        type={type}
        id={id}
        placeholder={placeholder}
        className={`w-full rounded-md border px-3 py-2 focus:outline-none ${className}  ${invalid || inerror ? 'border-red-500' : ''}`}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        checked={checked}
      />
      {children}
    </div>
  );
};

export default Input;
