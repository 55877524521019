import React from 'react';
import PropTypes from 'prop-types';

import PatientListTableRow from './PatientListTableRow';

const PatientListTable = ({ tableDataSource }) => {
  return (
    <div className="container relative m-auto bg-white ">
      <table className="w-full text-black">
        <tbody data-cy="study-list-results">
          {tableDataSource.map((tableData, i) => {
            return (
              <PatientListTableRow
                tableData={tableData}
                key={i}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

PatientListTable.propTypes = {
  tableDataSource: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.array.isRequired,
      onClickRow: PropTypes.func.isRequired,
    })
  ),
};

export default PatientListTable;
