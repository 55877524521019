import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from '..';
import getGridWidthClass from '../../utils/getGridWidthClass';

const PatientListTableRow = props => {
  const { tableData } = props;
  const { row, onClickRow } = tableData;
  return (
    <>
      <tr className="select-none">
        <td
          className={classnames('border-0 p-0', {
            'border-b bg-white': false,
          })}
        >
          <div
            className={classnames(
              'w-full transition duration-300',
              {
                'border-primary-light overflow-hidden rounded border': false,
              },
              {
                'border-transparent': !false,
              }
            )}
          >
            <table className={classnames('w-full p-4')}>
              <tbody>
                <tr
                  className={classnames(
                    'cursor-pointer transition duration-500 hover:bg-indigo-200',
                    {
                      'bg-white': !false,
                    },
                    { 'bg-indigo-200': false }
                  )}
                  onClick={onClickRow}
                >
                  {row.map((cell, index) => {
                    const { content, title, gridCol } = cell;
                    return (
                      <td
                        key={index}
                        className={classnames(
                          'truncate px-4 py-2 text-base',
                          { 'border-b': !false },
                          getGridWidthClass(gridCol) || ''
                        )}
                        style={{
                          maxWidth: 0,
                        }}
                        title={title}
                      >
                        <div className="flex">
                          <div
                            className={classnames({ 'overflow-hidden': true }, { truncate: true })}
                          >
                            {content}
                          </div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  );
};

PatientListTableRow.propTypes = {
  tableData: PropTypes.shape({
    /** A table row represented by an array of "cell" objects */
    row: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        /** Optional content to render in row's cell */
        content: PropTypes.node,
        /** Title attribute to use for provided content */
        title: PropTypes.string,
        gridCol: PropTypes.number.isRequired,
      })
    ).isRequired,
    expandedContent: PropTypes.node.isRequired,
    onClickRow: PropTypes.func.isRequired,
    false: PropTypes.bool.isRequired,
  }),
};

export default PatientListTableRow;
