import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { ServicesManager } from '@ohif/core';
import { ServicesManager } from '../../../../core/src/services/index';
import { utilities as csToolsUtils } from '@cornerstonejs/tools';
import { useLocation } from 'react-router';
import { api } from '../../../../viewer/src/Deepecho/helpers/backendHelper';
import { useViewportGrid } from '@ohif/ui';
import { Modal } from 'antd';
import { commandsManager, extensionManager } from '../../../../../platform/viewer/src/App';
import findSRWithSameSeriesDescription from '../../../../../extensions/measurement-tracking/src/contexts/TrackedMeasurementsContext/findSRWithSameSeriesDescription';
import createReportAsync from '../../../../../extensions/measurement-tracking/src/_shared/createReportAsync';
import { getFormattedDate } from './functions/help';
import { MedicalReport } from './interfaces';
import Report from './Components/Report/report.page';
import DrawingMode from './Components/DrawingMode/DrawingMode';
import {
  useGetSrReport,
  useGetGlobalReport,
} from '../../../../viewer/src/Deepecho/helpers/api/report/report.api';
import { GlobalReportSearchParams } from '../../../../viewer/src/Deepecho/UI/interfaces/index';
interface MeasurementData {
  measurements: any[];
  [key: string]: any;
}

interface Record {
  [key: string]: Object;
}

interface frameByPlane {
  [key: string]: Record;
}
interface serieReport {
  [key: string]: frameByPlane;
}

const MeasurementTable = ({ data, title, onClick, onEdit, servicesManager, onClickUntrack }) => {
  const url = process.env.BACKEND_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [report, setReport] = useState<MedicalReport>();
  const [frameByPlane, setFrameByPlane] = useState<serieReport>({});
  const [unlabeledMeasurements, setUnlabeledMeasurements] = useState([]);
  const [globalReportSearchParams, setGlobalReportSearchParams] =
    useState<GlobalReportSearchParams>();
  const [activeDisplaySetInstanceUID, setActiveDisplaySetInstanceUID] = useState('');
  const [displaySet, setDisplaySet] = useState<any>();

  const location = useLocation();

  let StudyUID = new URLSearchParams(location.search).get('StudyInstanceUIDs');

  const {
    isLoading: globalReportDataLoading,
    data: globalReportData,
    isError: globalReportDataError,
  } = useGetGlobalReport(StudyUID, ['report', 'globalReportData', StudyUID]);

  const {
    isLoading: serieReportLoading,
    data: serieSrReport,
    isError: serieReportError,
  } = useGetSrReport(globalReportSearchParams, [
    'report',
    'serieReport',
    globalReportSearchParams?.serie_id,
    report,
  ]);

  const { uiDialogService, measurementService, displaySetService, toolbarService } = (
    servicesManager as ServicesManager
  ).services;

  const [{ activeViewportIndex, viewports, numCols, numRows }, viewportGridService]: any =
    useViewportGrid();

  useEffect(() => {
    const activeViewport = viewports[activeViewportIndex];

    setActiveDisplaySetInstanceUID(activeViewport.displaySetInstanceUIDs[0]);
  }, [activeViewportIndex, viewports]);

  useEffect(() => {
    const displaySet = displaySetService.getDisplaySetByUID(activeDisplaySetInstanceUID);
    setDisplaySet(displaySet);
  }, [activeDisplaySetInstanceUID]);

  useEffect(() => {
    const SeriesDescription = displaySet?.SeriesDescription?.includes('calippers')
      ? displaySet?.SeriesDescription?.split('-').slice(1).join('-')
      : displaySet?.SeriesDescription;
    const StudyInstanceUID = displaySet?.StudyInstanceUID;
    const SeriesInstanceUID = displaySet?.SeriesInstanceUID;
    const Modality = displaySet?.Modality;
    const updatedSeriesDescription = SeriesDescription?.includes('segmentation')
      ? SeriesDescription?.replace('segmentation-', '')
      : SeriesDescription;
    if (Modality === 'SR')
      setGlobalReportSearchParams({
        study_id: StudyInstanceUID,
        serie_id: SeriesInstanceUID,
        date: updatedSeriesDescription,
      });
  }, [displaySet]);

  useEffect(() => {
    measurementService.mergeQualityValues(serieSrReport);
  }, [serieSrReport]);

  useEffect(() => {
    const SOPInstanceUID = JSON.stringify(displaySet?.instances, null, 2)
      ? JSON.parse(JSON.stringify(displaySet?.instances, null, 2))[0]?.SOPInstanceUID
      : '';
    const data_sr_report = {
      date: getFormattedDate(),
      study_id: displaySet?.StudyInstanceUID,
      series_id: displaySet?.SeriesInstanceUID,
      sop_instance_uid: SOPInstanceUID,
      series_number: '36526545232',
      report_content: {
        study_id: displaySet?.StudyInstanceUID,
        sop_instance_uid: SOPInstanceUID,
      },
    };
    setReport(data_sr_report);
  }, [displaySet]);

  useEffect(() => {
    const report = measurementService.getReport();
    // const measurements = measurementService.getMeasurements();
    const unlabelledMeasurements = measurementService.getUnlabeledMeasurements();
    setFrameByPlane(report);
    setUnlabeledMeasurements(unlabelledMeasurements);
    // console.log('PlaneMeasurements', measurements);
    // console.log('FrameByPlane', frameByPlane)
    // console.log('unlabelledMeasurements', unlabelledMeasurements);
  }, [data]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onImageScrollbarChange = (imageIndex, viewportIndex) => {
    const viewportInfo = cornerstoneViewportService.getViewportInfoByIndex(viewportIndex);
    const viewportId = viewportInfo.getViewportId();
    const viewport = cornerstoneViewportService.getCornerstoneViewport(viewportId);
    const { isCineEnabled } = cineService.getState();
    if (isCineEnabled) {
      // on image scrollbar change, stop the CINE if it is playing
      cineService.stopClip(element);
      cineService.setCine({ id: viewportIndex, isPlaying: false });
    }

    csToolsUtils.jumpToSlice(viewport.element, {
      imageIndex,
      debounceLoading: true,
    });
  };

  const createReport = async () => {
    const measurements = measurementService.getMeasurements();
    const dataSources = extensionManager.getDataSources();
    const dataSource = dataSources[0];
    const reportX = measurementService.getReport();
    console.log('before measurements', measurements);
    const { report_content = {}, ...otherReportFields } = serieSrReport || {};
    const payload = {
      ...otherReportFields,
      date: getFormattedDate(),
      report_content: {},
    };
    // const payload = { ...report, date: getFormattedDate() };
    console.log('before report', payload);
    const options = findSRWithSameSeriesDescription(payload.date, displaySetService);
    let displaySetInstanceUIDs;
    const planes = ['cephalic', 'femur', 'abdominal', 'afpocket'];
    console.log('reportX', reportX);
    const seriesKeys = Object.keys(reportX);
    const getReport = async () => {
      return commandsManager.runCommand(
        'storeMeasurements',
        {
          measurementData: measurements,
          dataSource,
          additionalFindingTypes: ['ArrowAnnotate'],
          options,
        },
        'CORNERSTONE_STRUCTURED_REPORT'
      );
    };
    [displaySetInstanceUIDs] = await createReportAsync({
      servicesManager,
      getReport,
    });
    const newDisplaySet = displaySetService.getDisplaySetByUID(displaySetInstanceUIDs);
    const newSerieId =
      newDisplaySet?.instances?.[0]?.CurrentRequestedProcedureEvidenceSequence?.[0]
        ?.ReferencedSeriesSequence?.ReferencedSOPSequence?.ReferencedSOPInstanceUID;

    for (const serie of seriesKeys) {
      let plane = reportX[serie];
      for (const item of planes) {
        let keys = Object.keys(plane);
        if (keys.length > 0) {
          for (const item of keys) {
            for (const frame of Object.keys(plane[item])) {
              let meas = [...plane[item][frame]?.measurements.filter(meas => meas !== null)];
              let content = [];
              for (const measItem of meas) {
                const measurement = measurementService.getMeasurement(measItem.uid);

                const matchingInstance = displaySet?.instances?.find(
                  instance =>
                    measurement?.referenceSeriesUID === instance.SeriesInstanceUID &&
                    measurement?.SOPInstanceUID === instance.SOPInstanceUID &&
                    measurement?.referenceStudyUID === instance.StudyInstanceUID
                );
                let pixelSpacingX = 1,
                  pixelSpacingY = 1;
                if (
                  matchingInstance?.SequenceOfUltrasoundRegions?.[0]?.PhysicalDeltaX &&
                  matchingInstance?.SequenceOfUltrasoundRegions?.[0]?.PhysicalDeltaY
                ) {
                  pixelSpacingX =
                    matchingInstance?.SequenceOfUltrasoundRegions?.[0]?.PhysicalDeltaX * 10;
                  pixelSpacingY =
                    matchingInstance?.SequenceOfUltrasoundRegions?.[0]?.PhysicalDeltaY * 10;
                }

                if (matchingInstance && matchingInstance?.PixelSpacing) {
                  pixelSpacingX = matchingInstance?.PixelSpacing[0];
                  pixelSpacingY = matchingInstance?.PixelSpacing[1];
                }

                let modifiedPoints = measItem?.points.flatMap(point => [
                  Math.abs(point[0]) / pixelSpacingX,
                  Math.abs(point[1]) / pixelSpacingY,
                ]);
                content.push({
                  points: modifiedPoints,
                  type: measItem.type,
                  frame: frame,
                });
                if (!payload['report_content'][newSerieId]) {
                  payload['report_content'][newSerieId] = {};
                }
                if (!payload['report_content'][newSerieId][item])
                  payload['report_content'][newSerieId][item] = {};
                if (!payload['report_content'][newSerieId][item][frame])
                  payload['report_content'][newSerieId][item][frame] = {
                    ...plane[item][frame],
                  };
                payload['report_content'][newSerieId][item][frame]['measurements'] = content;
                payload['report_content'][newSerieId][item][frame]['SeriesInstanceUID'] =
                  measurement?.referenceSeriesUID;
                payload['report_content'][newSerieId][item][frame]['SOPInstanceUID'] =
                  measurement?.SOPInstanceUID;
              }
            }
          }
        }
      }
    }
    const res = await api.post(`${url}/report`, payload);
    console.log(res);
  };

  function handleCancleButtonClick(displaySet, servicesManager) {
    measurementService.clearMeasurements();
    onClickUntrack(displaySet?.displaySetInstanceUID);
  }
  const { cineService, cornerstoneViewportService } = (servicesManager as ServicesManager).services;

  const onImageScrollbarChanges = (imageIndex, viewportIndex) => {
    const viewportInfo = cornerstoneViewportService.getViewportInfoByIndex(viewportIndex);

    const viewportId = viewportInfo.getViewportId();
    const viewport = cornerstoneViewportService.getCornerstoneViewport(viewportId);

    csToolsUtils.jumpToSlice(viewport.element, {
      imageIndex,
      debounceLoading: true,
    });
  };

  return (
    <div className="h-full overflow-auto p-3">
      <Modal
        title="Report"
        open={isModalOpen}
        onCancel={handleCancel}
        width={1600}
        footer={null}
        centered={true}
      >
        <Report
          StudyUID={displaySet?.StudyInstanceUID}
          ohifViewer={true}
        />
      </Modal>
      {data.length !== 0 && (
        <DrawingMode
          data={data}
          frameByPlane={frameByPlane}
          measurementService={measurementService}
          activeViewportIndex={activeViewportIndex}
          onImageScrollbarChange={onImageScrollbarChanges}
          onClick={onClick}
          unlabeledMeasurements={unlabeledMeasurements}
          displaySet={displaySet}
          handleCancleButtonClick={handleCancleButtonClick}
          servicesManager={servicesManager}
          createReport={createReport}
          studyReport={globalReportData}
          toolbarService={toolbarService}
          showModal={showModal}
          displaySetService={displaySetService}
          activeDisplaySetInstanceUID={activeDisplaySetInstanceUID}
        />
      )}
    </div>
  );
};

MeasurementTable.defaultProps = {
  data: [],
  onClick: () => {},
  onEdit: () => {},
};

MeasurementTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      displayText: PropTypes.arrayOf(PropTypes.string),
      isActive: PropTypes.bool,
    })
  ),
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
};

export default MeasurementTable;
