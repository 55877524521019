import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  EyeOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import React, { useState } from 'react';
import { AiOutlineExport } from 'react-icons/ai';
import { BiExport } from 'react-icons/bi';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import { PiExportFill } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getReports } from '../../../store/actions';
interface ReportTableProps {
  report: any;
  page: number;
  itemsPerPage: number;
  openReport: any;
  setPage: any;
  setItemsPerPage: any;
}
function ReportTable({
  report,
  page,
  itemsPerPage,
  openReport,
  setItemsPerPage,
  setPage,
}: ReportTableProps) {
  const [row, setRow] = useState(itemsPerPage);
  const [copied, setCopied] = useState(false);
  const dispatch = useDispatch();

  const copyToClipboard = textToCopy => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => setCopied(true))
      .catch(error => console.error('Failed to copy:', error));
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }
  const setColor = status => {
    if (status === undefined) {
      return 'gray-500';
    }
    if (status === true) {
      return 'green-500';
    }
    if (status === false) {
      return 'red-500';
    }
    return 'gray-500';
  };

  const setCell = (cell, row) => {
    if (['refusé', 'en attente', 'confirmé'].includes(cell)) {
      return (
        <span className={`h-[0.75rem] w-[0.75rem] rounded-full bg-[${setColor(true)}]`}></span>
      );
    }
    return '';
  };
  function HandlePrevPage() {
    console.log('prev page', report.filtered_conditions);
    dispatch(
      getReports({
        filter_options: {
          ...report.filtered_conditions,
          page: page - 1,
          items_per_page: itemsPerPage,
        },
      })
    );
    setPage(prev => prev - 1);
  }
  function handleNextNext() {
    console.log('next page', report.filtered_conditions);
    dispatch(
      getReports({
        filter_options: {
          ...report.filtered_conditions,
          page: page + 1,
          items_per_page: itemsPerPage,
        },
      })
    );
    setPage(prev => prev + 1);
  }
  function HandleRowChange(e: any) {
    dispatch(
      getReports({
        filter_options: { ...report.filtered_conditions, page: 1, items_per_page: e.target.value },
      })
    );
    setPage(1);
    setItemsPerPage(e.target.value);
  }

  return (
    <table
      className={`block w-full border-collapse rounded-lg shadow-xl ${report?.reports?.length === 0 ? '' : 'h-full'}`}
    >
      <thead className="relative block h-[56px] w-full bg-[#EEF5FF] text-center text-[#000000]">
        <tr className="flex h-full w-full items-center justify-between border border-[#C7DBF5] text-[12px] font-semibold text-[#2B5170]">
          <th className="block grow basis-full pl-4">
            <div className="">
              <input
                type="checkbox"
                className="h-6"
                id="allselect"
              />
            </div>
          </th>
          <th className="block grow basis-full">REPORT ID</th>
          <th className="block grow basis-full">STUDY ID</th>
          <th className="block grow basis-full">CREATION DATE</th>
          <th className="block grow basis-full">PATIENT NAME</th>
          <th className="block grow basis-full">DOCTOR NAME</th>
          <th className="block grow basis-full">STATUS</th>
          <th className="block grow basis-full">ACTIONS</th>
        </tr>
      </thead>
      <tbody className={`relative block max-h-[calc(100%-96px)] w-full overflow-auto text-center`}>
        {report?.reports?.map(report => (
          <tr
            key={report?.id}
            className="flex  w-full items-center justify-between border-b border-[#C7DBF5] hover:bg-gray-100 "
          >
            <td className="block grow basis-full py-3 pl-4  ">
              {/* <img src="/icons/check.svg" className="h-6" /> */}
              <input
                type="checkbox"
                className="h-6"
                id={report?.id}
                checked={report?.isChecked || false}
              />
            </td>
            <td className="block grow basis-full  text-[14px] font-medium text-[#25213B] ">
              {report?.id}
            </td>

            <td
              className="block grow basis-full  text-[14px] font-medium text-[#25213B]"
              title={report?.study_id}
            >
              {report?.study_id && report.study_id.length > 5
                ? `${report.study_id.substring(0, 5)}...`
                : report.study_id}
              <button onClick={() => copyToClipboard(report?.study_id)}>
                <CopyOutlined className="text-blue-500" />
              </button>
            </td>

            <td className="block grow basis-full  text-[#25213B] ">
              {formatDate(report?.created_at)}
            </td>
            <td className="block w-56 grow  basis-full text-[#25213B]">{report?.patient_name}</td>
            <td className="block w-56 grow  basis-full text-[#25213B]">{report?.doctor_name}</td>
            <td className="block flex w-56  grow basis-full items-center justify-center text-[#25213B]">
              <span
                className={`h-[0.75rem] w-[0.75rem] rounded-full bg-${setColor(report?.is_completed)} mr-2 inline-block`}
              ></span>
              {report?.is_completed === false ? 'Ongoing' : 'Finished'}
            </td>
            <td className="block grow basis-full  ">
              <button
                className=" w-10 items-center justify-center"
                onClick={() => openReport(report?.study_id, report?.is_completed)}
              >
                {report?.is_completed === false ? (
                  <BsFillEyeSlashFill size={20} />
                ) : (
                  <BsFillEyeFill size={20} />
                )}
              </button>
              <button className=" w-10">
                <PiExportFill size={20} />
              </button>
              <button className=" w-10">
                <MdDelete size={20} />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot
        className={`block h-[40px] w-full bg-[#EEF5FF] ${report?.reports?.length === 0 ? 'hidden' : ''}`}
      >
        <tr className="flex h-full w-full justify-between border">
          <td
            colSpan={8}
            className="block grow basis-full space-x-4 px-4 text-right"
          >
            <div className="inline-flex flex-row items-end space-x-4 text-sm font-semibold text-[#2B5170]">
              <div>
                Rows per page:
                <select
                  name="row"
                  id="row"
                  onChange={HandleRowChange}
                  className="bg-[#EEF5FF]"
                >
                  <option value={row}>{row}</option>
                  <option value="1">1</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>
              </div>
              <div>
                {report?.totalCount > 0 &&
                  `${(page - 1) * itemsPerPage + 1}-${Math.min(itemsPerPage * page, report?.totalCount)} of ${report?.totalCount}`}
              </div>

              <div className="space-x-4">
                <button
                  className="text-[14px] font-semibold text-[#005DD4]"
                  onClick={HandlePrevPage}
                  disabled={page > 1 ? false : true}
                >
                  <LeftOutlined />
                </button>
                <button
                  className="text-[14px] font-semibold text-[#005DD4]"
                  onClick={handleNextNext}
                  disabled={!report?.hasMore}
                >
                  <RightOutlined />
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

export default ReportTable;
