import React, { useState, useEffect, useRef } from 'react';
import { IoFilterSharp } from 'react-icons/io5';
import { TiDelete } from 'react-icons/ti';

const FilterForm = ({ onSearch, operator, setOperator }) => {
  const allFilters = ['First Name', 'Last Name', 'Email'];
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleFilterSelection = filter => {
    if (!selectedFilters.includes(filter)) {
      setSelectedFilters([...selectedFilters, filter]);
    }
    setIsDropdownVisible(false);
  };

  const handleInputChange = (filter, value) => {
    const updatedInputValues = {
      ...inputValues,
      [filter]: value,
    };
    setInputValues(updatedInputValues);
    onSearch(formatFilters(updatedInputValues));
  };

  const handleRemoveFilter = filter => {
    setSelectedFilters(selectedFilters.filter(f => f !== filter));
    const newInputValues = { ...inputValues };
    delete newInputValues[filter];
    setInputValues(newInputValues);
    onSearch(formatFilters(newInputValues));
  };

  const formatFilters = filters => {
    const keyMap = {
      'First Name': 'patient_name__ilike',
      'Last Name': 'patient_last_name__ilike',
      Email: 'email__ilike',
    };

    const formattedFilters = {};
    for (const [key, value] of Object.entries(filters)) {
      formattedFilters[keyMap[key] || key] = value;
    }
    return formattedFilters;
  };

  // Close dropdown on click outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative flex w-full space-x-4 rounded">
      <button
        ref={buttonRef}
        className="relative flex h-8 items-center justify-center rounded px-4 py-2 text-[24px] font-bold"
        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
      >
        <div className="flex items-center justify-center space-x-2">
          <IoFilterSharp className="h-6" />
          <span className="text-[16px] font-semibold text-[#25213B]">Filter</span>
        </div>
      </button>

      {isDropdownVisible && (
        <div
          ref={dropdownRef}
          className="absolute z-10 mt-9 w-40 rounded-lg border bg-white shadow-md"
        >
          <div className="p-2">Operators</div>
          <div className="flex flex-col px-2">
            <button
              onClick={() => setOperator('or')}
              className={`${operator === 'or' ? 'bg-gray-200' : ''} rounded-lg px-3 py-1 text-gray-600 focus:outline-none`}
            >
              Or
            </button>
            <button
              onClick={() => setOperator('and')}
              className={`${operator === 'and' ? 'bg-gray-200' : ''} rounded-lg px-3 py-1 text-gray-600 focus:outline-none`}
            >
              And
            </button>
          </div>
          <div className={`${selectedFilters.length < 3 ? 'visible' : 'hidden'} px-2 pt-2`}>
            Search by
          </div>
          <div className="flex flex-col p-2">
            {allFilters
              .filter(filter => !selectedFilters.includes(filter))
              .map(filter => (
                <button
                  key={filter}
                  onClick={() => handleFilterSelection(filter)}
                  className="rounded-lg px-3 py-1 text-gray-600 hover:bg-gray-200 focus:outline-none"
                >
                  {filter}
                </button>
              ))}
          </div>
        </div>
      )}

      {selectedFilters.map(filter => (
        <div
          key={filter}
          className="relative flex items-center justify-between space-x-2"
        >
          <input
            type={filter === 'Email' ? 'email' : 'text'}
            value={inputValues[filter] || ''}
            onChange={e => handleInputChange(filter, e.target.value)}
            placeholder={`Search by ${filter}`}
            className="rounded-md border border-[#005DD4] px-3 py-1 focus:outline-none focus:ring-0"
          />
          <TiDelete
            onClick={() => handleRemoveFilter(filter)}
            className="absolute right-2 cursor-pointer text-[#005DD4]"
            size={18}
          />
        </div>
      ))}
    </div>
  );
};

export default FilterForm;
