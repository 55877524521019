import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Icon } from '../';

const baseClasses =
  'first:border-0 border-t border-secondary-light cursor-pointer select-none outline-none';

const StudyItem = ({
  date,
  description,
  numInstances,
  modalities,
  trackedSeries,
  isActive,
  onClick,
}) => {
  return (
    <div
      className={classnames(isActive ? 'bg-white' : 'bg-white', baseClasses)}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
    >
      {/* deepecho_change */}
      {/* <div className="flex flex-col flex-1 px-4 pb-2">
        <div className="flex flex-row items-center justify-between pt-2 pb-2">
          <div className="text-base text-black">{date}</div>
          <div className="flex flex-row items-center text-base text-blue-500">
            <Icon
              name="group-layers"
              className="mx-2 w-4 text-blue-300"
            />
            {numInstances}
          </div>
        </div>
        <div className="flex flex-row py-1">
          <div className="pr-5 text-xl text-blue-300">{modalities}</div>
          <div className="truncate-2-lines break-words text-base text-blue-300">{description}</div>
        </div>
      </div> */}
      {!!trackedSeries && (
        <div className="flex-2 flex">
          <div
            className={classnames(
              ' mt-2 flex flex-row justify-between bg-white py-1 pl-2 pr-2 text-base text-[#21426d] ',
              isActive
                ? 'flex-1 justify-center border-blue-300'
                : 'flex-1 justify-center border-blue-300'
            )}
          >
            <div className="ml-3 inline-flex  space-x-3">
              <Icon
                name="circled-checkmark"
                className="mr-2 w-4 text-[#21426d]"
              />
              {trackedSeries} Tracked Series
            </div>
            <div className="flex items-center justify-center">
              {isActive ? (
                <Icon
                  name="ui-arrow-up"
                  className="mr-2 w-4 text-[#21426d]"
                />
              ) : (
                <Icon
                  name="ui-arrow-down"
                  className="mr-2 w-4 text-[#21426d]"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StudyItem.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  modalities: PropTypes.string.isRequired,
  numInstances: PropTypes.number.isRequired,
  trackedSeries: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default StudyItem;
