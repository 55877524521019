import React, { useMemo, useState, useEffect } from 'react';
import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { Patient } from '../../../Sections/PatientsList/patientsList.section.custom';
import { usePaginatedPatients } from '../../../helpers/api/patient/new_patient.api';
import MinimunPatientForm from './minmumPatientform';
import { MdArrowBackIosNew } from 'react-icons/md';

interface PatientTableSelectionProps {
  selectedRow: any; // Adjust type as needed
  setSelectedRow: React.Dispatch<React.SetStateAction<any>>;
  handleBack: () => void;
  handleNext: () => void;
}

function PatientTableSelectionRealtime({
  handleBack,
  handleNext,
  selectedRow,
  setSelectedRow,
}: PatientTableSelectionProps) {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 6,
  });

  const [globalFilter, setGlobalFilter] = useState<string>('');
  const { data, isError, isLoading } = usePaginatedPatients(
    pagination.pageIndex + 1,
    pagination.pageSize,
    { global__ilike: globalFilter }
  );

  console.log('================== data', data?.data?.items);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'patient_last_name',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
      },
      {
        accessorKey: 'patient_name',
        cell: info => info.getValue(),
        header: () => <span>First Name</span>,
      },
      {
        accessorKey: 'email',
        cell: info => info.getValue(),
        header: () => <span>Email</span>,
      },
      {
        accessorKey: 'birth_date',
        cell: info => formatBirthDate(info.getValue()),
        header: () => <span>Date Of Birth</span>,
      },
      {
        accessorKey: 'phone_number',
        cell: info => info.getValue(),
        header: () => <span>Phone Number</span>,
      },
      {
        accessorKey: 'policy_number',
        cell: info => info.getValue(),
        header: () => <span>Policy Number</span>,
      },
    ],
    []
  );
  function formatBirthDate(birthDate: any) {
    const options: any = { month: 'long', day: 'numeric', year: 'numeric' };
    return new Date(birthDate).toLocaleDateString('en-US', options);
  }
  const table = useReactTable({
    data: data?.data?.items ?? [],
    columns,
    rowCount: data?.total_count ?? 0,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const handleRowClick = (row: any) => {
    console.log('row', row);

    const newRowSelected = {
      row_id: row.id,
      patient: row.original,
    };

    setSelectedRow(prevSelectedRow => {
      if (prevSelectedRow && prevSelectedRow.row_id === newRowSelected.row_id) {
        return null;
      }
      return newRowSelected;
    });
  };
  const handlePatientAddClick = (patient: Patient) => {
    const patientData = {
      patient_id: String(patient.id),
      patient_name: `${patient.patient_name} ${patient.patient_last_name}`.trim(),
    };
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setPagination(prev => ({ ...prev }));
    }, 500);

    setPagination(prev => ({ ...prev, pageIndex: 0 }));
    return () => clearTimeout(timeoutId);
  }, [globalFilter]);

  return (
    <>
      <div className="rounded-md p-2">
        <div className="flex w-full items-center justify-between pb-1">
          <div className="text-lg font-medium text-gray-900">Select Patient</div>
          <div className="flex space-x-2">
            <input
              type="text"
              value={globalFilter}
              onChange={e => setGlobalFilter(e.target.value)}
              placeholder="Search Patients..."
              className="h-7 w-full rounded-md border p-1 focus:outline-none focus:ring-0"
            />
          </div>
        </div>
        <div className="w-full">
          <MinimunPatientForm handlePatientAddClick={handlePatientAddClick} />
        </div>
        <table className="w-full border-collapse rounded-md border border-gray-200 text-sm">
          <thead className="h-10 border-t border-dashed border-gray-200 bg-gray-100 p-2 text-left text-gray-500">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    className="p-2"
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="text-sm font-medium leading-5 text-[#686C82]">
            {isLoading ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="p-4 text-center"
                >
                  <Spin size="large" /> {/* Loading spinner */}
                  <div>Loading patients...</div>
                </td>
              </tr>
            ) : isError ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="p-4 text-center text-red-500"
                >
                  Error loading patients. Please try again.
                </td>
              </tr>
            ) : table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map(row => (
                <tr
                  id={`table-row-${row.id}`}
                  key={row.id}
                  className={`h-10 cursor-pointer border-t border-b border-gray-200 ${row.id == selectedRow?.row_id ? 'bg-[#2886C7] text-white' : 'hover:bg-blue-100'}`}
                  onClick={() => handleRowClick(row)}
                >
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      className="p-2"
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className="p-4 text-center"
                >
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="flex items-center justify-end gap-2 pt-1">
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount().toLocaleString()}
            </strong>
          </span>
          <button
            className="text-[14px] font-semibold text-[#005DD4]"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <LeftOutlined />
          </button>
          <button
            className="text-[14px] font-semibold text-[#005DD4]"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <RightOutlined />
          </button>
        </div>
        <div className={`mt-5 flex w-full justify-between p-5`}>
          <div className={`flex w-full  justify-start text-[#A6ABC8] hover:text-[#999db3]`}>
            <button
              className={`flex items-center justify-center space-x-2`}
              onClick={() => {
                handleBack();
              }}
            >
              <MdArrowBackIosNew />

              <div>Go Back</div>
            </button>
          </div>

          <div className={`flex w-full justify-end px-4`}>
            <button
              className={`rounded-md border border-[#2886c7] bg-[#2886c7] py-1 px-4 text-white hover:bg-white hover:text-[#2886c7] ${selectedRow ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'}`}
              disabled={isLoading}
              onClick={() => {
                if (selectedRow) {
                  handleNext();
                }
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientTableSelectionRealtime;
