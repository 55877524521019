import React, { useEffect, useState } from 'react';
import LatestExams from '../../Sections/LatestExams/latestExams.section';
import RecentReports from '../../Sections/RecentReports/recentReports.section';
import { useSelector } from 'react-redux';
import { getStudyByPatientId } from '../../../helpers/backendHelper';
import ExamsList from '../ExamsList/exams.list';

interface PatientHistoricalImageryProps {
  patientId: string;
}
const PatientHistoricalImagery = ({ patientId }: PatientHistoricalImageryProps) => {
  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<number>(4);
  const [page, setPage] = useState<number>(1);
  const { profile } = useSelector(state => ({
    profile: state.Profile,
  }));
  return (
    <div className="inline-block h-full w-full overflow-hidden p-3 text-[#1F384C] ">
      <span className="h-7 text-[1rem]">Historical Imagery</span>
      <div className="h-[calc(100%-28px)] space-y-6">
        <div className="h-[50%]">
          <span className="h-7 text-[1rem] font-[500] text-[#5A5A5A]">RAW EXAMS IMAGERY</span>
          {/* <LatestExams data={data} /> */}
          <ExamsList patientId={patientId} />
        </div>
        <div className="h-[50%] space-y-2 overflow-hidden">
          <span className="h-7 text-[1rem] font-[500] text-[#5A5A5A]">FINAL REPORTS</span>
          <RecentReports />
        </div>
      </div>
    </div>
  );
};

export default PatientHistoricalImagery;
