import React, { useEffect, useState } from 'react';
import checkImage from './assets/check.jpg';
import unCheckImage from './assets/uncheck.jpg';
import { Switch, Tooltip } from 'antd';
import NotFound from './assets/notfound.png';
import FramesDisplayer from './framesDisplayer';
import { MdOutlineImageNotSupported } from 'react-icons/md';
const ReportPlane: React.FC = props => {
  const {
    setExamData,
    plan,
    examData,
    editMode,
    reportDetails,
    selectedFrame,
    setSelectedFrameID,
    setSelectedFrame,
    setSelectedFramePlan,
    onChangeSwitch_cri1,
    resetCriteria2,
    onChangeSwitch_cri2,
    onChangeSwitch_cri3,
    onChangeSwitch_cri4,
    onChangeSwitch_cri6,
    onChangeSwitch_cri5,
    selectedFrameID,
    qualityCriterias,
    image,
    frame,
  } = props;
  return (
    console.log(
      'plane',
      plan,
      'reportDetails**',
      reportDetails,
      reportDetails?.report?.report_content?.[plan]
    ),
    (
      <>
        <div className="px-2">
          <div className="mb-3 grid grid-cols-2">
            <span className="col-span-1 pl-2 text-left text-sm ">
              <span className="font-bold">Detected plane</span>:{plan}
            </span>
            <span className="col-span-1 hidden pr-2 text-right text-sm">
              <span className="font-bold">Quality score</span>:
              {!reportDetails?.report?.report_content?.[plan]?.[frame]?.QUAL_VAL__SCORE
                ? 'N/A'
                : (
                    Number(
                      reportDetails?.report?.report_content?.[plan]?.[frame]?.QUAL_VAL__SCORE
                    ) * 100
                  ).toFixed(2) + '%'}
            </span>
          </div>
          {reportDetails?.report?.report_content?.[plan] &&
          Object.keys(reportDetails?.report?.report_content?.[plan]).length !== 0 ? (
            <div className="px-2">
              <div className="mb-2">
                <div>
                  {/* <FramesDisplayer
                  resetCriteria2={resetCriteria2}
                  report={reportDetails}
                  qualityCriterias={qualityCriterias}
                  selectedFrame={selectedFrame}
                  plan={plan}
                  onChangeSwitch_cri1={onChangeSwitch_cri1}
                  selectedFrameID={selectedFrameID}
                  setSelectedFrameID={setSelectedFrameID}
                  setSelectedFrame={setSelectedFrame}
                  setSelectedFramePlan={setSelectedFramePlan}
                /> */}
                  <img
                    src={image ? 'data:image/png;base64,' + image : NotFound}
                    className="mx-auto my-auto h-2/3 w-2/3"
                  />
                </div>
              </div>
              <p className="text-left font-bold">Quality criteria detected:</p>
              <div className="grid grid-cols-2 grid-rows-3 text-xs">
                {qualityCriterias?.includes('QUAL_FLAG__cephal_thalamus') && (
                  <div className="col-row-2 col-span-1 flex border-b-2 px-1  text-left">
                    Plan showing thalami{' '}
                    {editMode ? (
                      <Switch
                        className={`my-auto ml-auto bg-red-500`}
                        defaultChecked={qualityCriterias.QUAL_VAL__ZOOM ? true : false}
                        size="small"
                        onChange={checked => onChangeSwitch_cri1(checked, setExamData, examData)}
                      />
                    ) : (
                      <img
                        src={
                          reportDetails?.report?.report_content?.[plan]?.[frame]
                            ?.QUAL_FLAG__cephal_thalamus === true
                            ? checkImage
                            : unCheckImage
                        }
                        className="my-auto ml-auto hidden h-3"
                      />
                    )}
                  </div>
                )}

                {qualityCriterias?.includes('STD_PLANE__cephalic') && (
                  <div className="col-row-2 col-span-1 flex border-b-2 px-1 text-left">
                    <span>Plan showing cavum septum pellucidi</span>
                    <Tooltip
                      className="my-auto"
                      color="blue"
                      title="Reset the original value"
                    >
                      <span
                        onClick={() => resetCriteria2(setExamData, examData)}
                        className="cursor-pointer text-xs"
                      >
                        🔄
                      </span>
                    </Tooltip>
                    {editMode ? (
                      <Switch
                        className=" my-auto ml-auto bg-red-500"
                        defaultChecked={
                          examData.section2.firstColFirstRow.criterias.criteria2.status ===
                          'success'
                            ? true
                            : false
                        }
                        size="small"
                        id=""
                        onChange={checked => onChangeSwitch_cri2(checked, setExamData, examData)}
                      />
                    ) : (
                      <img
                        src={
                          reportDetails?.report?.report_content?.[plan]?.[frame]
                            ?.STD_PLANE__cephalic === true
                            ? checkImage
                            : unCheckImage
                        }
                        className="my-auto ml-auto hidden h-3"
                      />
                    )}
                  </div>
                )}

                {qualityCriterias.includes('STD_PLANE__cephalic') && (
                  <div className="col-row-2 col-span-1 flex border-b-2 px-1  text-left">
                    Cerebellum not visible{' '}
                    {editMode ? (
                      <Switch
                        className=" my-auto ml-auto bg-red-500"
                        defaultChecked={
                          examData.section2.firstColFirstRow.criterias.criteria3.status ===
                          'success'
                            ? true
                            : false
                        }
                        size="small"
                        id=""
                        onChange={checked => onChangeSwitch_cri3(checked, setExamData, examData)}
                      />
                    ) : (
                      <img
                        src={
                          reportDetails?.report?.report_content?.[plan]?.[frame]
                            ?.QUAL_VAL__SCORE === true // QUAL_VAL__SCORE
                            ? checkImage
                            : unCheckImage
                        }
                        className="my-auto ml-auto hidden h-3"
                      />
                    )}
                  </div>
                )}

                {qualityCriterias.includes('QUAL_FLAG__abdo_stomach_bubble') && (
                  <div className="col-row-2 col-span-1 flex border-b-2 px-1  text-left">
                    abdo_stomach_bubble visible{' '}
                    {editMode ? (
                      <Switch
                        className=" my-auto ml-auto bg-red-500"
                        defaultChecked={
                          examData.section2.firstColFirstRow.criterias.criteria3.status ===
                          'success'
                            ? true
                            : false
                        }
                        size="small"
                        id=""
                        onChange={checked => onChangeSwitch_cri3(checked, setExamData, examData)}
                      />
                    ) : (
                      <img
                        src={
                          reportDetails?.report?.report_content?.[plan]?.[frame]
                            ?.QUAL_FLAG__abdo_stomach_bubble === true
                            ? checkImage
                            : unCheckImage
                        }
                        className="my-auto ml-auto hidden h-3"
                      />
                    )}
                  </div>
                )}

                {qualityCriterias.includes('QUAL_FLAG__abdo_portal_sinus') && (
                  <div className="col-row-2 col-span-1 flex border-b-2 px-1  text-left">
                    abdo_portal_sinus visible{' '}
                    {editMode ? (
                      <Switch
                        className=" my-auto ml-auto bg-red-500"
                        defaultChecked={
                          examData.section2.firstColFirstRow.criterias.criteria3.status ===
                          'success'
                            ? true
                            : false
                        }
                        size="small"
                        id=""
                        onChange={checked => onChangeSwitch_cri3(checked, setExamData, examData)}
                      />
                    ) : (
                      <img
                        src={
                          reportDetails?.report?.report_content?.[plan]?.[frame]
                            ?.QUAL_FLAG__abdo_portal_sinus === true
                            ? checkImage
                            : unCheckImage
                        }
                        className="my-auto ml-auto hidden h-3"
                      />
                    )}
                  </div>
                )}

                {qualityCriterias.includes('STD_PLANE__abdominal') && (
                  <div className="col-row-2 col-span-1 flex border-b-2 px-1  text-left">
                    fetal kidney not visible{' '}
                    {editMode ? (
                      <Switch
                        className=" my-auto ml-auto bg-red-500"
                        defaultChecked={
                          examData.section2.firstColFirstRow.criterias.criteria3.status ===
                          'success'
                            ? true
                            : false
                        }
                        size="small"
                        id=""
                        onChange={checked => onChangeSwitch_cri3(checked, setExamData, examData)}
                      />
                    ) : (
                      <img
                        src={
                          reportDetails?.report?.report_content?.[plan]?.[frame]
                            ?.STD_PLANE__abdominal === true
                            ? checkImage
                            : unCheckImage
                        }
                        className="my-auto ml-auto hidden h-3"
                      />
                    )}
                  </div>
                )}

                {qualityCriterias.includes('STD_PLANE__af') && (
                  <div className="col-row-2 col-span-1 flex border-b-2 px-1  text-left">
                    placanta not visible{' '}
                    {editMode ? (
                      <Switch
                        className=" my-auto ml-auto bg-red-500"
                        defaultChecked={
                          examData.section2.firstColFirstRow.criterias.criteria3.status ===
                          'success'
                            ? true
                            : false
                        }
                        size="small"
                        id=""
                        onChange={checked => onChangeSwitch_cri3(checked, setExamData, examData)}
                      />
                    ) : (
                      <img
                        src={
                          reportDetails?.report?.report_content?.[plan]?.[frame]?.STD_PLANE__af ===
                          true
                            ? checkImage
                            : unCheckImage
                        }
                        className="my-auto ml-auto hidden h-3"
                      />
                    )}
                  </div>
                )}

                {qualityCriterias.includes('STD_PLANE__af') && (
                  <div className="col-row-2 col-span-1 flex border-b-2 px-1  text-left">
                    umbilical not visible{' '}
                    {editMode ? (
                      <Switch
                        className=" my-auto ml-auto bg-red-500"
                        defaultChecked={
                          examData.section2.firstColFirstRow.criterias.criteria3.status ===
                          'success'
                            ? true
                            : false
                        }
                        size="small"
                        id=""
                        onChange={checked => onChangeSwitch_cri3(checked, setExamData, examData)}
                      />
                    ) : (
                      <img
                        src={
                          reportDetails?.report?.report_content?.[plan]?.[frame]?.STD_PLANE__af ===
                          true
                            ? checkImage
                            : unCheckImage
                        }
                        className="my-auto ml-auto hidden h-3"
                      />
                    )}
                  </div>
                )}

                {qualityCriterias.includes('QUAL_VAL__FEMURAL_ANGLE') && (
                  <div className="col-row-2 col-span-1 flex border-b-2 px-1  text-left">
                    less than 45 to horizontal{' '}
                    {editMode ? (
                      <Switch
                        className=" my-auto ml-auto bg-red-500"
                        defaultChecked={
                          examData.section2.firstColFirstRow.criterias.criteria3.status ===
                          'success'
                            ? true
                            : false
                        }
                        size="small"
                        id=""
                        onChange={checked => onChangeSwitch_cri3(checked, setExamData, examData)}
                      />
                    ) : (
                      <img
                        src={
                          reportDetails?.report?.report_content?.[plan]?.[frame]
                            ?.QUAL_VAL__FEMURAL_ANGLE === true
                            ? checkImage
                            : unCheckImage
                        }
                        className="my-auto ml-auto hidden h-3"
                      />
                    )}
                  </div>
                )}

                {qualityCriterias.includes('STD_PLANE__femur') && (
                  <div className="col-row-2 col-span-1 flex border-b-2 px-1  text-left">
                    fetal spine not showing{' '}
                    {editMode ? (
                      <Switch
                        className=" my-auto ml-auto bg-red-500"
                        defaultChecked={
                          examData.section2.firstColFirstRow.criterias.criteria3.status ===
                          'success'
                            ? true
                            : false
                        }
                        size="small"
                        id=""
                        onChange={checked => onChangeSwitch_cri3(checked, setExamData, examData)}
                      />
                    ) : (
                      <img
                        src={
                          reportDetails?.report?.report_content?.[plan]?.[frame]
                            ?.STD_PLANE__femur === true
                            ? checkImage
                            : unCheckImage
                        }
                        className="my-auto ml-auto hidden h-3"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="mr-auto mb-2 ml-2 border-[3px]  border-gray-200 bg-gray-100 px-2">
              <div className="flex h-[250px] w-full items-center justify-center ">
                <div className="flex flex-col items-center justify-center text-center text-gray-500">
                  <div className="mb-2 text-6xl">
                    <MdOutlineImageNotSupported size={80} />
                  </div>
                  <div className="text-lg">No selected plane</div>
                </div>
                {/* <div className="text-center text-gray-500">
                <div className="text-6xl mb-2">⚠️</div>
                <div className="text-lg">No selected plane</div>
              </div> */}
              </div>
            </div>
          )}
        </div>
      </>
    )
  );
};

export default ReportPlane;
