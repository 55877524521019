const { UPDATE_SR_REPORT, CLEAN_SR_REPORT } = require('./actionType');

const INIT_STATE = {
  SR_Report: {},
};

const SR_Report = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_SR_REPORT:
      console.log(action.payload);
      return {
        ...state,
        SR_Report: action.payload,
      };
    case CLEAN_SR_REPORT:
      return {
        ...state,
        SR_Report: {},
      };
    default:
      return state;
  }
};

export default SR_Report;
