import React from 'react';

interface MeasurementModeHeaderProps {
  drawingMode: boolean;
  predictMOde: boolean;
  changeDrawingMode: () => void;
}

function MeasurementModeHeader({
  drawingMode,
  predictMOde,
  changeDrawingMode,
}: MeasurementModeHeaderProps) {
  return (
    <div
      className={`text-primary-active flex flex-col items-center px-2 py-1  ${predictMOde == true ? 'my-3 h-24' : 'my-3'}`}
    >
      <span className="text-lg font-bold tracking-widest text-[#1F384C]">Measurements:</span>
      <span className="mb-4 text-xs font-bold text-[#A6ABC8] ">
        "{predictMOde === true ? 'Manual Adjustment' : 'Best Prediction'}"
      </span>
      {predictMOde == true && (
        <div className="flex h-10 w-full items-center  justify-between space-x-3 rounded-2xl bg-blue-100 p-1 text-xs">
          <span
            className={`${drawingMode === false ? 'text-gray-600' : 'rounded-3xl bg-blue-500 text-white'} flex h-full w-1/2 cursor-pointer items-center justify-center`}
            onClick={changeDrawingMode}
          >
            Labeled
          </span>
          <span
            className={`${drawingMode === true ? 'text-gray-600' : 'rounded-3xl bg-blue-500 text-white'} flex h-full w-1/2 cursor-pointer items-center justify-center`}
            onClick={changeDrawingMode}
          >
            Unlabeled
          </span>
        </div>
      )}
    </div>
  );
}

export default MeasurementModeHeader;
