import React, { useState } from 'react';
import { CiCircleCheck } from 'react-icons/ci';
import { FaCopy } from 'react-icons/fa';

const CompactUIDDisplay = ({ uid = '' }) => {
  const [copied, setCopied] = useState(false);

  const safeUID = String(uid || '').trim();

  const compactUID =
    safeUID.length > 12 ? `${safeUID.slice(0, 6)}...${safeUID.slice(-6)}` : safeUID;

  const handleCopy = () => {
    if (safeUID) {
      navigator.clipboard.writeText(safeUID);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  if (!safeUID) {
    return <span className="italic text-gray-400">No UID available</span>;
  }

  return (
    <div className="flex items-center space-x-2 text-sm">
      <span
        className="cursor-help rounded-md bg-gray-100 transition-colors hover:bg-gray-200"
        title={safeUID}
      >
        {compactUID}
      </span>
      <button
        onClick={handleCopy}
        className="text-gray-500 transition-colors hover:text-gray-800"
        aria-label="Copy full UID"
      >
        {copied ? (
          <CiCircleCheck className="h-4 w-4 text-green-500" />
        ) : (
          <FaCopy className="h-4 w-4" />
        )}
      </button>
    </div>
  );
};

export default CompactUIDDisplay;
